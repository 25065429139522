import { PatientThreadUserGroupUserMembership } from "@accurx/api/ticket";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { mapPatientThreadUserGroupUserMembershipToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupUserMembershipToTeamSummary";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { TeamSummary } from "domains/concierge/types";

type ToggleNotificationsOptions = {
    teamId: TeamSummary["id"];
    notify: boolean;
    workspaceId: number;
};

export const toggleNotifications: ConciergeFetcher<
    ToggleNotificationsOptions,
    PatientThreadUserGroupUserMembership
> = async (arg) => {
    const response = await httpClient
        .postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: "/api/conversation/usergroupmembership",
            }),
            {
                organisationId: arg.workspaceId,
                userGroupId: arg.teamId,
                updatedMembership: arg.notify,
            },
        )
        .then(returnDataOrThrow<PatientThreadUserGroupUserMembership>);

    const team = mapPatientThreadUserGroupUserMembershipToTeamSummary(response);

    return {
        updates: {
            conversations: [],
            patients: [],
            users: [],
            teams: team ? [team] : [],
        },
        response,
    };
};
