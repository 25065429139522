import { useCallback, useEffect, useState } from "react";

import { FeatureName, useAuth } from "@accurx/auth";
import { Icon } from "@accurx/design";
import isNil from "lodash/isNil";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { StyledLinkList } from "app/layout/navigationMenu";
import { StyledCollapsibleText } from "app/layout/navigationMenu/NavigationMenu.styles";
import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { NavigationHeader } from "app/layout/navigationMenu/navigationHeader";
import { useIsInOrganisation } from "app/organisations/hooks/useIsInOrganisation";
import { ROUTES_PRIMARY } from "shared/Routes";
import UserSettingsService from "shared/storage/UserSettingsService";
import { useIsMobile } from "shared/useIsMobile";
import { useSimplifiedNavigation } from "store/hooks";

import {
    getSessionStorageItem,
    removeSessionStorageItem,
    setSessionStorageItem,
} from "../../../../shared/storage/sessionStorageHelper";
import { AccountPopover } from "../accountPopover";
import { ChatWithUsButton } from "../chatWithUsButton/ChatWithUsButton";
import { ConversationsNavLink } from "../conversationsNavLink/ConversationsNavLink";
import { SelectOrganisationPopover } from "../selectOrganisationPopover";
import { BatchMessagePrimaryLinkListItem } from "./BatchMessagePrimaryLinkListItem";
import {
    StyledExpandButton,
    StyledNavigationContent,
    StyledPrimaryNavigation,
} from "./PrimaryNavigation.styles";

export const PRIMARY_NAV_TOGGLE_ID = "Primary-navigation-toggle";
export const COLLAPSE_STORAGE_KEY = "__accurx_navigation_collapsed-local__";

/**
 * Collapsed by default for simple nav routes, or if a user has it saved in their settings
 * Always expanded for mobile users as long as it's not a simple nav route
 * Auto-collapsed or expanded based on the session, which takes precedence over the user-saved
 * setting
 */

const shouldCollapseNav = ({
    isMobile,
    userId,
    isSimpleNav,
}: {
    isMobile: boolean;
    userId?: string;
    isSimpleNav: boolean;
}) => {
    return (
        isSimpleNav ||
        isMobile ||
        getSessionStorageItem(COLLAPSE_STORAGE_KEY) === "true" ||
        UserSettingsService.getIsNavigationMenuCollapsed(userId || "")
    );
};

const PrimaryNavigation = ({
    showToggle,
    toggleOnClick,
}: {
    showToggle?: boolean;
    toggleOnClick?: () => void;
}): JSX.Element => {
    const isMobile = useIsMobile();
    const simpleNav = useSimplifiedNavigation();
    const history = useHistory();

    // Feature flags
    const questionnaireScoreboardEnabled = useFeatureFlag(
        FeatureName.PerioperativeCarePilot,
    );

    const track = useAnalytics();

    const { user } = useAuth();
    const isLoggedIn = user.isLoggedIn;
    const userId = isLoggedIn ? user.accuRxUserId : undefined;

    const isInOrganisation = useIsInOrganisation();
    const { selectedOrgId: workspaceId } = useSelectedOrganisation();

    const isAutoCollapseEnabled = !(isMobile && simpleNav);

    const initialIsCollapsed = isAutoCollapseEnabled
        ? shouldCollapseNav({
              isSimpleNav: simpleNav,
              userId,
              isMobile,
          })
        : false;

    // State
    const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed);

    const expandButtonLabel = isCollapsed
        ? "Maximise navigation"
        : "Minimise navigation";

    const updateIsCollapsed = (value: boolean): void => {
        setIsCollapsed(value);
        setSessionStorageItem(COLLAPSE_STORAGE_KEY, value.toString());
    };

    const expandButtonOnClick = () => {
        track("NavigationView Button Click", {
            origin: history.location.pathname,
            navigationOptionSelected: expandButtonLabel,
        });

        updateIsCollapsed(!isCollapsed);

        userId &&
            UserSettingsService.setIsNavigationMenuCollapsed(
                userId,
                !isCollapsed,
            );
    };

    const collapseNavigation = useCallback((): void => {
        updateIsCollapsed(true);
        window.removeEventListener("click", collapseNavigation);
    }, []);

    useEffect(() => {
        if (!isAutoCollapseEnabled) {
            return;
        }
        /**
         * Add click handler to collapse the navbar when it is a new
         * session and when the user has the navbar saved to be expanded
         */
        if (
            isNil(getSessionStorageItem(COLLAPSE_STORAGE_KEY)) &&
            !initialIsCollapsed
        ) {
            window.addEventListener("click", collapseNavigation);
        }

        const clearIsCollapsedSessionStorage = (): void => {
            removeSessionStorageItem(COLLAPSE_STORAGE_KEY);
        };
        /**
         * Clears session storage on page refresh. This enables differentiating between
         * a page reload and the component being consumed by a different parent for
         * managing collapseNavigation click handlers. This is because refreshing
         * the page does not clear session storage by default.
         */
        window.addEventListener("beforeunload", clearIsCollapsedSessionStorage);

        return () => {
            window.removeEventListener("click", collapseNavigation);
            window.removeEventListener(
                "beforeunload",
                clearIsCollapsedSessionStorage,
            );
        };
    }, [isAutoCollapseEnabled, initialIsCollapsed, collapseNavigation]);

    const handleMouseLeave = (): void => {
        isAutoCollapseEnabled &&
            window.addEventListener("click", collapseNavigation);
    };

    const handleMouseEnter = (): void => {
        isAutoCollapseEnabled &&
            window.removeEventListener("click", collapseNavigation);
    };

    return (
        <StyledPrimaryNavigation
            aria-label="Primary navigation"
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            <NavigationHeader
                showToggle={showToggle}
                toggleOnClick={toggleOnClick}
                simpleNav={simpleNav}
                isCollapsed={isCollapsed}
            />
            <StyledNavigationContent>
                <StyledLinkList>
                    {!simpleNav && (
                        <>
                            <li>
                                <NavLink
                                    variant={LinkVariant.Primary}
                                    to={ROUTES_PRIMARY.patients}
                                    text="Patients"
                                    onClick={toggleOnClick}
                                    icon="Person"
                                    isCollapsed={isCollapsed}
                                />
                            </li>
                            {!!workspaceId && (
                                <li>
                                    <ConversationsNavLink
                                        onClick={toggleOnClick}
                                        isCollapsed={isCollapsed}
                                        workspaceId={workspaceId}
                                    />
                                </li>
                            )}
                            <BatchMessagePrimaryLinkListItem
                                toggleOnClick={toggleOnClick}
                                workspaceId={workspaceId}
                                isCollapsed={isCollapsed}
                            />
                            {!!workspaceId &&
                                questionnaireScoreboardEnabled && (
                                    <li>
                                        <NavLink
                                            variant={LinkVariant.Primary}
                                            to={generatePath(
                                                ROUTES_PRIMARY.questionnaireScoreboard,
                                                {
                                                    workspaceId,
                                                },
                                            )}
                                            text={"Questionnaires"}
                                            onClick={toggleOnClick}
                                            icon="Questionnaire"
                                            isCollapsed={isCollapsed}
                                        />
                                    </li>
                                )}
                        </>
                    )}
                </StyledLinkList>
                {!simpleNav && (
                    <StyledExpandButton onClick={expandButtonOnClick}>
                        <Icon
                            size={4}
                            name="Slide"
                            colour="white"
                            rotation={isCollapsed ? "down" : "up"}
                            title={expandButtonLabel}
                        />
                        <StyledCollapsibleText
                            isCollapsed={isCollapsed}
                            forwardedAs="span"
                            variant="label"
                            colour="white"
                            props={{ "aria-hidden": true }}
                        >
                            Minimise
                        </StyledCollapsibleText>
                    </StyledExpandButton>
                )}
                {isLoggedIn ? (
                    <StyledLinkList>
                        <li>
                            <ChatWithUsButton isCollapsed={isCollapsed} />
                        </li>
                        {isInOrganisation && !simpleNav && (
                            <li>
                                <SelectOrganisationPopover
                                    isCollapsed={isCollapsed}
                                    onClick={toggleOnClick}
                                />
                            </li>
                        )}
                        <li>
                            <AccountPopover
                                onClick={toggleOnClick}
                                isCollapsed={isCollapsed}
                            />
                        </li>
                    </StyledLinkList>
                ) : null}
            </StyledNavigationContent>
        </StyledPrimaryNavigation>
    );
};

export { PrimaryNavigation };
