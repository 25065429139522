import { useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { useConversationQuery } from "@accurx/concierge/hooks/queries/useConversationQuery";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";
import { useSendUserIsViewing } from "domains/inbox/hooks/useSendUserIsViewing";
import { useHasClinicianMessagingInbox } from "domains/inbox/hooks/util/useHasClinicianMessagingInbox";
import { useIsCollaborativeInboxEnabled } from "domains/inbox/hooks/util/useIsCollaborativeInboxEnabled";
import { usePatientSummaryBackgroundFixer } from "domains/inbox/hooks/util/usePatientSummaryBackgroundFixer";
import { useStalePatientSummaryBackgroundFixer } from "domains/inbox/hooks/util/useStalePatientSummaryBackgroundFixer";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { GenericErrorState } from "../GenericErrorState/GenericErrorState";
import {
    PatientParticipantContainer,
    StyledConversationViewGrid,
    StyledGridItemConversationThread,
    StyledLiveUserActivityBadgeContainer,
    StyledReplyContainer,
} from "./ConversationView.styles";
import { ReplyState } from "./ConversationView.types";
import {
    ConversationActions,
    ConversationActionsLoadingState,
    ConversationThread,
    ConversationThreadLoadingState,
    MessageActions,
    PatientHeader,
    PatientHeaderLoadingState,
} from "./components";
import { LiveUserActivityBadge } from "./components/LiveUserActivityBadge/LiveUserActivityBadge";
import { MessageActionsBarLoading } from "./components/MessageActionsBar/MessageActionsBarLoading";
import { ClinicianMessageActionsNew } from "./components/MessageActionsNew/ClinicianMessageActionsNew";
import { MessageActionsNew } from "./components/MessageActionsNew/MessageActionsNew";
import { NoMobileVerificationWarning } from "./components/NoMobileVerificationWarning/NoMobileVerificationWarning";
import { PatientHeaderNew } from "./components/PatientHeader/PatientHeaderNew";
import { PatientMatch } from "./components/PatientMatch/PatientMatch";
import { ClinicianReply } from "./components/Reply/ClinicianReply";

/**
 * <PatientMessagingConversation />
 *
 * Renders a Patient Messaging conversation.
 */
const PatientMessagingConversation = ({
    conversation,
    onClickBack,
    onMarkAsUnreadSuccess,
}: {
    conversation: Conversation;
    onClickBack: (() => void) | undefined;
    onMarkAsUnreadSuccess: (() => void) | undefined;
}) => {
    const [isPatientMatchingOpen, setIsPatientMatchingOpen] = useState(false);
    const [sentNoteStatus, setSentNoteStatus] = useState<string | null>(null);
    const [isFullHeightMessageContainer, setIsFullHeightMessageContainer] =
        useState(false);
    const [replyState, setReplyState] = useState<ReplyState>("Closed");
    const [noteState, setNoteState] = useState<ReplyState>("Closed");

    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );
    const hasClinicianMessagingInbox = useHasClinicianMessagingInbox();
    const isCollaborativeInboxEnabled = useIsCollaborativeInboxEnabled();

    const { isPatientSummaryFixing } = usePatientSummaryBackgroundFixer(
        conversation.regardingPatientId,
    );

    useSendUserIsViewing({
        conversationId: conversation.id,
        patientId: conversation.regardingPatientId,
    });

    useStalePatientSummaryBackgroundFixer(conversation.regardingPatientId);

    const patientMatchState = getPatientMatchState(conversation);

    const onExpandMessageContainerClick = () => {
        setIsFullHeightMessageContainer((prev) => !prev);
    };

    const composeAreaOpen = replyState === "Open" || noteState === "Open";

    return (
        <StyledConversationViewGrid
            rows={
                composeAreaOpen && isFullHeightMessageContainer
                    ? "auto auto auto 0 1fr"
                    : "auto auto auto 1fr auto"
            }
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                {isPatientSummaryFixing ? (
                    <PatientHeaderLoadingState />
                ) : isMessageComponentV1_1Enabled ? (
                    <PatientHeaderNew
                        conversation={conversation}
                        onClickSearchForPatient={() =>
                            setIsPatientMatchingOpen(true)
                        }
                        onClickBack={onClickBack}
                    />
                ) : (
                    <PatientHeader
                        conversation={conversation}
                        onClickSearchForPatient={() =>
                            setIsPatientMatchingOpen(true)
                        }
                        onClickBack={onClickBack}
                    />
                )}
            </UI.Cell>
            <UI.Cell>
                <ConversationActions.Container>
                    {hasClinicianMessagingInbox && (
                        <PatientParticipantContainer>
                            <ConversationActions.PatientParticipant />
                        </PatientParticipantContainer>
                    )}
                    {isCollaborativeInboxEnabled && (
                        <ConversationActions.Item $collapsible>
                            <ConversationActions.Assign
                                conversation={conversation}
                                allowTeamAssignment
                            />
                        </ConversationActions.Item>
                    )}
                    <ConversationActions.Item>
                        <ConversationActions.ToggleStatus
                            conversation={conversation}
                        />
                    </ConversationActions.Item>
                    <ConversationActions.Item>
                        <ConversationActions.ToggleUrgent
                            conversation={conversation}
                        />
                    </ConversationActions.Item>
                    <ConversationActions.Item>
                        <ConversationActions.MarkAsUnread
                            conversation={conversation}
                            onMarkAsUnreadSuccess={onMarkAsUnreadSuccess}
                        />
                    </ConversationActions.Item>
                </ConversationActions.Container>
            </UI.Cell>
            <UI.Cell>
                {/**
                 * Note on this behaviour:
                 *
                   We actually should only show this message when the patient has not completed 2FA. However, there is nothing in the data
                   to tell us this currently. Therefore we're copying the logic used in the desktop inbox, which is to display the warning
                   for all patient matches.
                */}
                {patientMatchState?.type === "SuggestedMatch" &&
                    !isMessageComponentV1_1Enabled && (
                        <NoMobileVerificationWarning />
                    )}
            </UI.Cell>
            <StyledGridItemConversationThread>
                {!conversation.isFullyLoaded ? (
                    <ConversationThreadLoadingState />
                ) : (
                    <ConversationThread
                        conversation={conversation}
                        allowOutcomeRecording
                        hasFloatingBottomElements={
                            isMessageComponentV1_1Enabled && !composeAreaOpen
                        }
                    />
                )}
            </StyledGridItemConversationThread>
            <StyledReplyContainer>
                <StyledLiveUserActivityBadgeContainer
                    $useNewPosition={isMessageComponentV1_1Enabled}
                    $composeAreaOpen={composeAreaOpen}
                    $isFullHeightMessageContainer={isFullHeightMessageContainer}
                    $actionsVisible={conversation.status !== "Done"}
                    aria-live="polite"
                >
                    <LiveUserActivityBadge conversationId={conversation.id} />
                </StyledLiveUserActivityBadgeContainer>
                {isMessageComponentV1_1Enabled ? (
                    <MessageActionsNew
                        conversation={conversation}
                        replyState={replyState}
                        setReplyState={setReplyState}
                        noteState={noteState}
                        setNoteState={setNoteState}
                        isExpanded={isFullHeightMessageContainer}
                        onExpandClick={onExpandMessageContainerClick}
                        setSentNoteStatus={setSentNoteStatus}
                    />
                ) : (
                    <MessageActions
                        conversation={conversation}
                        onClickSearchForPatient={() =>
                            setIsPatientMatchingOpen(true)
                        }
                        setSentNoteStatus={setSentNoteStatus}
                    />
                )}
            </StyledReplyContainer>
            {conversation.status !== "Done" && (
                <QuickViewPortal
                    isOpen={isPatientMatchingOpen}
                    onClose={() => setIsPatientMatchingOpen(false)}
                    label="Search and match patient to conversation"
                >
                    <PatientMatch conversation={conversation} />
                </QuickViewPortal>
            )}
            <UI.VisuallyHidden>
                <div aria-live="assertive" aria-atomic="true">
                    {sentNoteStatus}
                </div>
            </UI.VisuallyHidden>
        </StyledConversationViewGrid>
    );
};

/**
 * <ClinicianMessagingConversation />
 *
 * Renders a Clinician Messaging conversation. The eventual aim is possibly to
 * have a single view for all conversations, but currently Clinician Messaging
 * conversations don't have some of the features of Patient Messaging
 * (re-assign, mark as done, mark as urgent) so it makes sense to have different
 * views for them.
 */
const ClinicianMessagingConversation = ({
    conversation,
    onClickBack,
}: {
    conversation: Conversation;
    onClickBack: (() => void) | undefined;
}) => {
    const isCollaborativeInboxEnabled = useIsCollaborativeInboxEnabled();
    const [isFullHeightMessageContainer, setIsFullHeightMessageContainer] =
        useState(false);
    const [replyState, setReplyState] = useState<ReplyState>("Closed");

    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const onExpandMessageContainerClick = () => {
        setIsFullHeightMessageContainer((prev) => !prev);
    };

    const composeAreaOpen = replyState === "Open";

    return (
        <StyledConversationViewGrid
            rows={
                composeAreaOpen && isFullHeightMessageContainer
                    ? "auto auto 0 1fr"
                    : "auto auto 1fr auto"
            }
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                <PatientHeader
                    conversation={conversation}
                    onClickSearchForPatient={() => null}
                    onClickBack={onClickBack}
                />
            </UI.Cell>
            {!conversation.isFullyLoaded && (
                <>
                    <UI.Grid>
                        <UI.Cell>
                            <ConversationActions.LoadingState />
                        </UI.Cell>
                        <StyledGridItemConversationThread>
                            <UI.Cell>
                                <ConversationThreadLoadingState />
                            </UI.Cell>
                        </StyledGridItemConversationThread>
                    </UI.Grid>
                </>
            )}
            {conversation.isFullyLoaded && (
                <>
                    <UI.Cell>
                        <ConversationActions.Container>
                            <ConversationActions.Item $collapsible>
                                <ConversationActions.Participants
                                    participants={conversation.participants}
                                />
                            </ConversationActions.Item>
                            {isCollaborativeInboxEnabled && (
                                <ConversationActions.Item $collapsible>
                                    <ConversationActions.Assign
                                        conversation={conversation}
                                    />
                                </ConversationActions.Item>
                            )}
                            <ConversationActions.Item>
                                <ConversationActions.ToggleStatus
                                    conversation={conversation}
                                />
                            </ConversationActions.Item>
                        </ConversationActions.Container>
                    </UI.Cell>
                    <StyledGridItemConversationThread>
                        <ConversationThread
                            conversation={conversation}
                            hasFloatingBottomElements={
                                isMessageComponentV1_1Enabled &&
                                !composeAreaOpen
                            }
                        />
                    </StyledGridItemConversationThread>
                </>
            )}
            <StyledReplyContainer>
                {isMessageComponentV1_1Enabled ? (
                    <ClinicianMessageActionsNew
                        conversation={conversation}
                        replyState={replyState}
                        setReplyState={setReplyState}
                        isExpanded={isFullHeightMessageContainer}
                        onExpandClick={onExpandMessageContainerClick}
                    />
                ) : (
                    <ClinicianReply conversation={conversation} />
                )}
            </StyledReplyContainer>
        </StyledConversationViewGrid>
    );
};

/**
 * <ConversationLoadingState />
 *
 * This is a skeleton style loading state.
 */
const ConversationLoadingState = () => {
    return (
        <StyledConversationViewGrid
            rows="auto auto 1fr auto"
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                <PatientHeaderLoadingState />
            </UI.Cell>
            <UI.Cell>
                <ConversationActionsLoadingState />
            </UI.Cell>
            <StyledGridItemConversationThread>
                <ConversationThreadLoadingState />
            </StyledGridItemConversationThread>

            <MessageActionsBarLoading />
        </StyledConversationViewGrid>
    );
};

/**
 * <ConversationLoader />
 *
 * Given a conversation ID this loads the conversation from the Concierge Layer.
 * Once the conversation is being fetched it defers responsibility for rendering
 * the conversation to its child render function.
 *
 * While the conversation is loading it renders a loading state. If the
 * conversation fails to fetch it renders an error state.
 */
const ConversationLoader = (props: {
    conversationId: Conversation["id"];
    children: (arg: { conversation: Conversation }) => JSX.Element;
}): JSX.Element => {
    const query = useConversationQuery({
        conversationId: props.conversationId,
    });

    if (query.status === "error") {
        return <GenericErrorState />;
    }

    if (query.status === "loading") {
        return <ConversationLoadingState />;
    }

    return props.children({ conversation: query.data });
};

export const ConversationView = ({
    conversationId,
    onClickBack,
    onMarkAsUnreadSuccess,
}: {
    conversationId: string;
    /**
     * Add this callback so that
     * the patient header will render
     * a back arrow button on mobile devices.
     */
    onClickBack: (() => void) | undefined;
    /**
     * Add this callback to optionally navigate
     * users to a different page after they mark the
     * conversation as unread
     */
    onMarkAsUnreadSuccess: (() => void) | undefined;
}) => {
    return (
        <ConversationLoader
            // Passing the conversation ID as a key here ensures that the
            // conversation view gets re-mounted when the conversaton
            // changes, forcing the reply state to be reset.
            key={conversationId}
            conversationId={conversationId}
        >
            {({ conversation }) => {
                switch (conversation.source.system) {
                    case "Ticket":
                        return (
                            <PatientMessagingConversation
                                conversation={conversation}
                                onClickBack={onClickBack}
                                onMarkAsUnreadSuccess={onMarkAsUnreadSuccess}
                            />
                        );

                    case "ClinicianMessaging":
                        return (
                            <ClinicianMessagingConversation
                                conversation={conversation}
                                onClickBack={onClickBack}
                            />
                        );
                }
            }}
        </ConversationLoader>
    );
};
