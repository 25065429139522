import { useCompose } from "domains/compose/context";

import { MessageContentPlaceholder } from "../../MessageContentPlaceholder/MessageContentPlaceholder";

export const ComposeMessageContentPlaceholder = () => {
    const { state } = useCompose();

    return (
        <MessageContentPlaceholder
            nhsAdviceLink={state.nhsAdviceLink}
            isPatientResponseEnabled={state.isPatientResponseEnabled}
            hasAttachments={state.attachments.length > 0}
            isQuestionnaire={state.template.type === "QuestionnaireTemplate"}
            hasSelfBookLink={state.selfBookLink !== null}
        />
    );
};
