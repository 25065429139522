import { ComposeStateType } from "./reducer.types";

export const SELF_BOOK_MESSAGE_BODY = "We'd like you to book an appointment";

const MAX_MESSAGE_LENGTH = 612;

const COMPOSE_ERRORS = {
    // Validation errors shown as form errors in the compose UI
    SmsMessageTooLong: "MESSAGE_TOO_LONG",
    TemplatePlaceholderRemains: "TEMPLATE_PLACEHOLDER_REMAINS",
    NoContactDetails: "NO_CONTACT_DETAILS",
    EmptyMessage: "EMPTY_MESSAGE",
    AttachmentStillLoading: "ATTACHMENT_STILL_LOADING",
    QuestionnaireStillLoading: "QUESTIONNAIRE_STILL_LOADING",
} as const;

export { MAX_MESSAGE_LENGTH, COMPOSE_ERRORS };

export const EMPTY_STATE: ComposeStateType = {
    originalGreeting: "",
    originalBody: "",
    originalSignature: "",
    contactDetails: { value: "", method: "Mobile", origin: "UserInput" },
    messageBody: "",
    messageSignature: "",
    attachments: [],
    isPatientResponseEnabled: false,
    // This is mostly used for presentation for the "Save" checkbox when a user is connected to a EMR, for non-integrated users they will not see the "Save" checkbox as that is controlled by whether canSaveToRecord is enabled/disabled. We safe guard non-integared users from calling save to record by wrapping all the save to record actions within a canSaveToRecord check.
    isSaveToRecordEnabled: true,
    errors: [],
    template: { type: "NoTemplate" },
    maxAttachmentCount: 1,
    sendAt: null,
    selfBookLink: null,
    nhsAdviceLink: null,
    canUseQuestionnaires: true,
    conversationParticipant: "WithPatient",
    isScheduling: false,
};
