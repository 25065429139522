import * as UI from "@accurx/design";
import { Tokens } from "@accurx/design";
import { ConversationActions } from "domains/inbox/components/ConversationView/components";
import styled, { css } from "styled-components";

export const StyledConversationViewGrid = styled(UI.Grid)`
    height: 100%;
`;

export const StyledGridItemConversationThread = styled(UI.Cell)`
    overflow: hidden;
    position: relative;
    background: ${UI.Tokens.COLOURS.greyscale.cloud};
`;

export const StyledConversationViewLoading = styled(UI.Flex)`
    height: 100vh;
`;

export const StyledReplyContainer = styled(UI.Cell)`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const liveUserActivityBadgeContainerOldPosition = css`
    position: absolute;
    z-index: 1;
    top: -${UI.Tokens.SIZES[1.5]};
    left: 50%;
    transform: translate(-50%, -100%);
    max-width: calc(100% - ${Tokens.SIZES[6]});
    width: 100%;
    display: flex;
    justify-content: center;
`;

const liveUserActivityBadgeContainerNewPosition = css<{
    $composeAreaOpen: boolean;
    $isFullHeightMessageContainer: boolean;
    $actionsVisible: boolean;
}>`
    position: absolute;
    z-index: 1;
    left: ${UI.Tokens.SIZES[3]};
    max-width: calc(100% - ${Tokens.SIZES[6]});

    ${({
        $isFullHeightMessageContainer,
        $composeAreaOpen,
        $actionsVisible,
    }) => {
        if (!$actionsVisible) {
            // 24px from bottom of screen
            return `bottom: ${UI.Tokens.SIZES[3]};`;
        }

        if (!$composeAreaOpen) {
            // 64px from botom of screen, therefore 8px above buttons
            return `bottom: ${UI.Tokens.SIZES[8]};`;
        }

        if ($isFullHeightMessageContainer) {
            // 8px above the top of the container, with compensation for added margin on container
            return `transform: translateY(calc(-100% + ${UI.Tokens.SIZES[2]}));
                    top: -${UI.Tokens.SIZES[1]};`;
        }

        // 8px above the top of the container
        return `transform: translateY(-100%);
                top: -${UI.Tokens.SIZES[1]};`;
    }};
`;

export const StyledLiveUserActivityBadgeContainer = styled.div<{
    $useNewPosition: boolean;
    $composeAreaOpen: boolean;
    $isFullHeightMessageContainer: boolean;
    $actionsVisible: boolean;
}>`
    ${({ $useNewPosition }) =>
        $useNewPosition
            ? liveUserActivityBadgeContainerNewPosition
            : liveUserActivityBadgeContainerOldPosition}

    [data-radix-popper-content-wrapper] {
        // This is set by default as an inline style to be 'max-content'. This is
        // problematic for when we need text to wrap based on the parent width, rather
        // then a hard-coded max-width value.
        min-width: unset !important;
        max-width: 100%;
    }
`;

export const PatientParticipantContainer = styled(
    ConversationActions.Item,
).attrs({
    $collapsible: true,
})`
    @media (max-width: 1230px) {
        visibility: hidden;
        padding: 0;
    }
`;
