/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface SnomedCodesResponse {
    codes: SnomedCode[];
}

export interface SnomedCode {
    conceptId: string;
    term: string;
    unit?: string | null;
}

export interface QuestionnaireSummariesResponse {
    summaries: QuestionnaireSummary[];
}

export interface QuestionnaireSummary {
    name?: string | null;
    /** @format int32 */
    id: number;
    graphId: string;
    status: StatusQuery;
    questionnaireRowVersion: string;
    createdByUser: string;
    /** @format date-time */
    createdAt: string;
    lastUpdatedByUser: string;
    /** @format date-time */
    lastUpdatedAt: string;
    isGloballyAccessible?: boolean | null;
    categoryName?: string | null;
    /** @format int32 */
    userGroupId?: number | null;
    userGroupName?: string | null;
}

export enum StatusQuery {
    None = 0,
    Draft = 1,
    Reviewing = 2,
    Published = 3,
    Archived = 4,
    Deleted = 5,
}

export interface QuestionnaireCountResponse {
    /** @format int32 */
    count: number;
}

export interface UserGroupsResponse {
    userGroups: UserGroup[];
}

export interface UserGroup {
    /** @format int32 */
    id: number;
    displayName: string;
    category: UserGroupCategory;
}

export enum UserGroupCategory {
    None = 0,
    Florey = 1,
    Team = 2,
}

export interface ImportQuestionnaireResponse {
    /** @format int32 */
    importQuestionnaireId: number;
}

export interface ImportQuestionnaireRequest {
    /** @format int32 */
    organisationId: number;
    shareId: string;
}

export interface QuestionnaireResponse {
    /** @format int32 */
    id: number;
    /** @format guid */
    graphId: string;
    status: StatusQuery;
    name?: string | null;
    enrolmentMessage?: string | null;
    enrolmentSnomedCode?: SnomedCode | null;
    completionText?: string | null;
    completionSnomedCode?: SnomedCode | null;
    questions: Question[];
    questionnaireRowVersion: string;
    /** @format int32 */
    userGroupId?: number | null;
    isGloballyAccessible?: boolean | null;
    /** @format int32 */
    categoryId?: number | null;
    tagGroups?: number[][] | null;
    contentClassificationGroups?: ContentClassificationGroup[] | null;
}

export interface Question {
    /** @format int32 */
    id: number;
    questionType: QuestionType;
    questionTitle?: string | null;
    helperText?: string | null;
    /** @format int32 */
    order?: number | null;
    snomedCode?: SnomedCode | null;
    snomedCodeUnit?: string | null;
    options?: Option[] | null;
    questionRowVersion?: string | null;
    questionTemplateType?: QuestionTemplateType | null;
    numberedQuestionData?: NumberedQuestionData | null;
    branchData?: BranchData | null;
    allowMultipleAnswers?: boolean | null;
    imageDocumentUrl?: string | null;
    imageAltText?: string | null;
    recordText?: string | null;
}

export enum QuestionType {
    None = 0,
    Binary = 1,
    Date = 2,
    MultipleChoice = 3,
    Numbered = 4,
    Text = 5,
    Information = 6,
    Branching = 7,
    Calculation = 8,
    ImageAttachment = 9,
    TextWithImageAttachment = 10,
}

export interface Option {
    text: string;
    answerCode?: SnomedCode | null;
    /** @format guid */
    clientId: string;
}

export enum QuestionTemplateType {
    Height = 0,
    Weight = 1,
    ImageAttachment = 2,
}

export interface NumberedQuestionData {
    /** @format double */
    minimumValue: number;
    /** @format double */
    maximumValue: number;
}

export interface BranchData {
    condition: Condition;
    branchQuestions: Question[];
}

export interface Condition {
    conditionOperator: ConditionOperator;
    conditionQuestion: ConditionQuestion;
}

export enum ConditionOperator {
    IsEqual = 0,
    IsNotEqual = 1,
}

export interface ConditionQuestion {
    /** @format int32 */
    questionId: number;
    questionText: string;
    answerText: string;
}

export interface ContentClassificationGroup {
    contentClassifications: ContentClassification[];
}

export interface ContentClassification {
    name: string;
    type: string;
}

export interface CreateQuestionnaireRequest {
    name: string;
    /** @format int32 */
    organisationId: number;
}

export interface EditQuestionnaireRequest {
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    organisationId: number;
    name?: string | null;
    enrolmentMessage?: string | null;
    enrolmentSnomedCode?: string | null;
    completionText?: string | null;
    completionSnomedCode?: string | null;
    /** @format int32 */
    userGroupId?: number | null;
    questionnaireRowVersion: string;
    /** @format int32 */
    categoryId?: number | null;
    tagGroups?: number[][] | null;
    contentClassificationGroups?: ContentClassificationGroup[] | null;
}

export interface ShareQuestionnaireResponse {
    shareId: string;
}

export interface ShareQuestionnaireRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    questionnaireRowVersion: string;
}

export interface CreateQuestionRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId?: number | null;
    questionType: QuestionType;
    questionTitle: string;
    helperText?: string | null;
    snomedCode?: string | null;
    options?: OptionRequest[] | null;
    allowMultipleAnswers?: boolean | null;
    imageDocumentUrl?: string | null;
    imageAltText?: string | null;
    recordText?: string | null;
}

export interface OptionRequest {
    text: string;
    answerCode: string;
    /** @format guid */
    clientId: string;
}

export interface CreateBranchRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    conditionOperator: ConditionOperator;
    /** @format int32 */
    conditionQuestionId: number;
    conditionValue: string;
}

export interface EditBranchRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    branchId: number;
    /** @format int32 */
    questionnaireId: number;
    conditionOperator: ConditionOperator;
    /** @format int32 */
    conditionQuestionId: number;
    conditionValue: string;
}

export interface DeleteBranchRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId: number;
}

export interface EditQuestionRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId?: number | null;
    questionType: QuestionType;
    questionTitle: string;
    helperText?: string | null;
    snomedCode?: string | null;
    options?: OptionRequest[] | null;
    questionRowVersion: string;
    allowMultipleAnswers?: boolean | null;
    imageDocumentUrl?: string | null;
    imageAltText?: string | null;
    recordText?: string | null;
}

export interface CreateTemplatedQuestionRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId?: number | null;
    questionTemplateType?: QuestionTemplateType | null;
}

export interface StatusChangeRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    newStatus: StatusChange;
    questionnaireRowVersion: string;
    isGloballyAccessible?: boolean | null;
}

export enum StatusChange {
    None = 0,
    Review = 1,
    Publish = 2,
    Archive = 3,
    Delete = 4,
}

export interface DeleteQuestionRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId?: number | null;
    /** @format int32 */
    questionId: number;
    questionRowVersion: string;
}

export interface UpdateQuestionOrderRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    questionnaireId: number;
    /** @format int32 */
    branchId?: number | null;
    questions?: QuestionOrder[] | null;
}

export interface QuestionOrder {
    /** @format int32 */
    id: number;
    /** @format int32 */
    order: number;
}

export interface SharedQuestionnaireInfoResponse {
    questionnaireName: string;
    sharingOrgName: string;
}

export interface TagsResponse {
    tags: Tag[];
}

export interface Tag {
    /** @format int32 */
    id: number;
    name: string;
    description: string;
    type: string;
}
