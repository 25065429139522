import { Conversation } from "@accurx/concierge/types";

import { ReplyState } from "../../ConversationView.types";
import { StyledExpandableContainer } from "./MessageActionsNew.styles";
import { ClinicianReplyNew } from "./components/Actions/ClinicianReplyNew";

export const ClinicianMessageActionsNew = ({
    conversation,
    onExpandClick,
    isExpanded,
    replyState,
    setReplyState,
}: {
    conversation: Conversation;
    onExpandClick: () => void;
    isExpanded: boolean;
    replyState: ReplyState;
    setReplyState: (state: ReplyState) => void;
}) => {
    // No message actions are available when the conversation is Done
    if (conversation.status === "Done") {
        return null;
    }

    return (
        <StyledExpandableContainer
            $isExpanded={isExpanded}
            $isFloating={replyState !== "Open"}
        >
            <ClinicianReplyNew
                conversation={conversation}
                replyState={replyState}
                setReplyState={setReplyState}
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
            />
        </StyledExpandableContainer>
    );
};
