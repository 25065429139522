import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { useTransport } from "@accurx/transport";
import { useQueryClient } from "@tanstack/react-query";

import { createUsersAndTeamsManager } from "shared/concierge/usersAndTeams/UsersAndTeamsManager";
import { UsersAndTeamsManager } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import {
    WorkspaceSingletonProvider,
    createWorkspaceSingletonProvider,
} from "shared/workspace/WorkspaceSingletonProvider";

const GlobalUsersAndTeamsManagerContext =
    createContext<WorkspaceSingletonProvider<UsersAndTeamsManager> | null>(
        null,
    );

type ProviderProps = {
    children?: ReactNode;
    currentUserId?: string;
};

/**
 * GlobalUsersAndTeamsManagerProvider
 *
 * Sets up a global instance to provide UsersAndTeamsManager instances
 * for a user and exposes it via React Context.
 *
 * This in turn provides a factory for spinning up managers scoped
 * to a specific workspace, ensuring that only one may exist for a
 * given workspace at once.
 */
export const GlobalUsersAndTeamsManagerProvider = (
    props: ProviderProps,
): JSX.Element => {
    const { hubClient } = useTransport();
    const queryClient = useQueryClient();

    const [tracker, setTracker] =
        useState<WorkspaceSingletonProvider<UsersAndTeamsManager> | null>(null);

    useEffect(() => {
        if (!hubClient) return;
        const currentUserId = props.currentUserId;
        const result = currentUserId
            ? createWorkspaceSingletonProvider<UsersAndTeamsManager>(
                  "UsersAndTeamsManager",
                  (workspaceId) =>
                      createUsersAndTeamsManager({
                          workspaceId,
                          currentUserId,
                          hubClient,
                          queryClient,
                      }),
                  (mgr) => mgr.teardown(),
              )
            : null;
        setTracker(result);
        return () => result?.destroy();
    }, [props.currentUserId, queryClient, hubClient]);

    return (
        <GlobalUsersAndTeamsManagerContext.Provider value={tracker}>
            {props.children}
        </GlobalUsersAndTeamsManagerContext.Provider>
    );
};

/**
 * Provides the global manager instance.
 */
export const useGlobalUsersAndTeamsManager = () =>
    useContext(GlobalUsersAndTeamsManagerContext);

/**
 * Export the global context provider directly so that our UI tests can easily
 * wrap a component with a fake manager.
 */
export const StaticGlobalUsersAndTeamsManagerProvider =
    GlobalUsersAndTeamsManagerContext.Provider;
