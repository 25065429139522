import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import { useAllAssignees } from "@accurx/concierge/hooks/data/useAllAssignees";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import {
    AssignmentError,
    useUpdateAssigneeMutation,
} from "@accurx/concierge/hooks/mutations/useUpdateAssigneeMutation";
import {
    Conversation,
    TeamAssigneeSummary,
    TeamSummary,
    UserAssigneeSummary,
    UserSummary,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { Checkbox, Input, Pill } from "@accurx/inbox-design-library";
import { Log } from "@accurx/shared";
import { useConversationActionTrackingFields } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { userflowIds } from "domains/inbox/util";
import { getNumberOfAssignments } from "domains/inbox/util/getNumberOfAssignments";
import { toast } from "react-toastify";

import {
    StyledAssigneeAvatarWrapper,
    StyledAssigneeListWrapper,
    StyledAssigneeLists,
    StyledAssigneeNoteTextArea,
    StyledAssigneeOnlineIndicator,
    StyledContainer,
    StyledFeedback,
    StyledHeaderText,
    StyledHorizontalBorder,
    StyledIconWrapper,
    StyledNoteContainer,
    StyledOuterTextarea,
    StyledPopoverContentHeader,
    StyledPopoverFooter,
    StyledStickyHeader,
} from "./AssigneeSelectorContent.styles";

const ASSIGNEE_SELECTOR_FORM_ID = "assignee-selector" as const;

export type AssigneeSelectorContentProps = {
    conversation: Conversation;
    isAssigneeSelectorOpen: boolean;
    toggle: () => void;
    allowTeamAssignment?: boolean;
};

const getAssigneeTrackingFields = (
    userId: string,
    assignee: UserAssigneeSummary | TeamAssigneeSummary,
    allAssignees: ReturnType<typeof useAllAssignees>,
) => {
    const { type, id } = assignee;

    if (type === "Team") {
        const teamInfo = allAssignees.teams.find((item) => item.id === id);

        return {
            assigneeType: type,
            assigneeTeamId: id,
            isAssignedToSelf: false,
            assigneeTeamName: teamInfo?.displayName,
            assigneeTeamType: teamInfo?.type,
        };
    }

    return {
        assigneeType: type,
        isAssignedToSelf: id === userId,
        assigneeUserId: id,
    };
};

const RadioGroupLabel = (labelText: string): JSX.Element => {
    return (
        <StyledHeaderText skinny variant="note" colour="metal">
            {labelText}
        </StyledHeaderText>
    );
};

const RadioLabel = (
    conversation: Conversation,
    selectedAssignRef: RefObject<HTMLElement>,
    assignee: TeamSummary | UserSummary,
    assigneeType: "Team" | "User",
): JSX.Element => {
    const props =
        assigneeType === conversation.assignee.type &&
        assignee.id === conversation.assignee.id
            ? { ref: selectedAssignRef }
            : {};

    return (
        <UI.Flex gap="1" as="span" {...props}>
            <StyledAssigneeAvatarWrapper>
                <span aria-hidden={true}>
                    <UI.Avatar
                        size="small"
                        name={assignee.displayName}
                        colour="white"
                    />
                </span>

                {assigneeType === "User" && (
                    <StyledAssigneeOnlineIndicator
                        userId={assignee.id}
                        displayName={assignee.displayName}
                    />
                )}
            </StyledAssigneeAvatarWrapper>
            <UI.Text as="span" skinny variant="preview" colour="night">
                {"isCurrentUser" in assignee
                    ? `${assignee.displayName} (You)`
                    : assignee.displayName}
            </UI.Text>
        </UI.Flex>
    );
};

export const AssigneeSelectorContent = ({
    conversation,
    isAssigneeSelectorOpen,
    toggle,
    allowTeamAssignment = false,
}: AssigneeSelectorContentProps): JSX.Element => {
    const checkboxId = "add-note-checkbox";
    const defaultSelectedAssignee =
        conversation.assignee.type !== "None"
            ? conversation.assignee
            : undefined;

    const track = useAnalytics();
    const allAssignees = useAllAssignees();
    const updateAssignee = useUpdateAssigneeMutation({
        onSuccess: () => {
            if (shouldAddNote && !noteText) {
                toast(
                    <UI.Feedback
                        title="The assignee was updated but no note was added"
                        colour="information"
                    />,
                );
                return;
            }
        },
        onError: (e) => {
            if (e instanceof AssignmentError && e.reason === "AddNoteError") {
                Log.error("Adding a note using the Assignee Selector failed");
                toast(
                    <UI.Feedback
                        title="The assignee was updated but an error occured adding the note"
                        colour="error"
                    />,
                );
                return;
            }
            toast(
                <UI.Feedback
                    title="Failed to re-assign conversation, please try again!"
                    colour="error"
                />,
            );
        },
    });

    const patient = usePatient({
        patientId: conversation.regardingPatientId ?? null,
    });
    const { user } = useCurrentUser();

    const scollToSelectedAssignee = useRef<null | HTMLDivElement>(null);
    const assigneeListRef = useRef<HTMLLIElement | null>(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [scrolledToDefaultAssignee, setScrolledToDefaultAssignee] =
        useState(false);
    const [selectedAssignee, setSelectedAssignee] = useState<
        UserAssigneeSummary | TeamAssigneeSummary | undefined
    >(defaultSelectedAssignee);
    const [shouldAddNote, setShouldAddNote] = useState<boolean>(false);
    const [noteText, setNoteText] = useState("");
    const [showNoAssigneeSelectedError, setShowNoAssigneeSelectedError] =
        useState(false);

    useEffect(() => {
        if (
            !isAssigneeSelectorOpen &&
            conversation.assignee.type === "None" &&
            !scrolledToDefaultAssignee
        ) {
            return;
        }

        // Waiting for all the users and teams to be defined and ref set before scrolling to default assignee
        const scrollToSelectedAssigneeTimeout = setTimeout(() => {
            scollToSelectedAssignee.current?.scrollIntoView({
                block: "center",
            });

            setScrolledToDefaultAssignee(true);
        }, 0);

        return () => {
            clearTimeout(scrollToSelectedAssigneeTimeout);
        };
    }, [
        conversation.assignee.type,
        isAssigneeSelectorOpen,
        scrolledToDefaultAssignee,
        setScrolledToDefaultAssignee,
    ]);

    const users = useFuzzyFilter(allAssignees.users, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });
    const teams = useFuzzyFilter(allAssignees.teams, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });

    const getConversationActionTrackingFields =
        useConversationActionTrackingFields(conversation);

    const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        const type = e.target.dataset.assigneeType as "Team" | "User";
        const id = e.target.value;

        setSelectedAssignee({
            type,
            id,
        });

        setShowNoAssigneeSelectedError(false);

        const {
            assigneeType,
            assigneeUserId,
            assigneeTeamName,
            assigneeTeamType,
            assigneeTeamId,
            isAssignedToSelf,
        } = getAssigneeTrackingFields(
            user.accuRxUserId,
            { type, id },
            allAssignees,
        );

        const {
            accessType,
            conversationDescription,
            conversationId,
            conversationStartTimestampUtc,
            conversationType,
            conversationParticipant,
            countItemsInConversation,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            medicalRecordSystem,
            userMedicalRecordRole,
            conversationRequestType,
        } = getConversationActionTrackingFields({
            type: "Assign",
            appOrigin: "ConversationActions",
        });

        track("ConversationAssignSelect MenuItem Click", {
            assigneeType,
            assigneeUserId,
            assigneeTeamName,
            assigneeTeamType,
            assigneeTeamId,
            isAssignedToSelf,
            accessType,
            conversationDescription,
            conversationId,
            conversationStartTimestampUtc,
            conversationType,
            conversationParticipant,
            countItemsInConversation,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            medicalRecordSystem,
            userMedicalRecordRole,
            conversationRequestType,
            withNote: noteText.length > 0,
            numberOfAssignments: getNumberOfAssignments(conversation),
        });
    };

    const onClickAssignButton = () => {
        if (!selectedAssignee) {
            setShowNoAssigneeSelectedError(true);
            return;
        }
        const assigneeTrackingFields = getAssigneeTrackingFields(
            user.accuRxUserId,
            selectedAssignee,
            allAssignees,
        );

        track("ConversationAction Button Click", {
            ...getConversationActionTrackingFields({
                type: "Assign",
                appOrigin: "ConversationActions",
            }),
            ...assigneeTrackingFields,
            withNote: noteText.length > 0,
            noteLength: noteText.length,
        });

        updateAssignee.mutate(
            {
                conversation,
                assignee: selectedAssignee,
                note:
                    noteText.length > 0
                        ? {
                              text: noteText,
                              patientExternalIds: patient?.externalIds,
                          }
                        : null,
            },
            {
                onSettled: (_data, error) => {
                    track("ConversationAction Button Response", {
                        ...getConversationActionTrackingFields({
                            type: "Assign",
                            appOrigin: "ConversationActions",
                        }),
                        ...assigneeTrackingFields,
                        hasError: !!error,
                        withNote: noteText.length > 0,
                        noteLength: noteText.length,
                    });

                    toggle();
                },
            },
        );
    };

    return (
        <>
            <StyledStickyHeader>
                <StyledPopoverContentHeader>
                    <UI.Text variant="label" skinny>
                        Assign this conversation
                    </UI.Text>
                </StyledPopoverContentHeader>
                <StyledContainer>
                    <UI.VisuallyHidden as="span">
                        <label htmlFor="assignee-list">
                            Search for assignee
                        </label>
                    </UI.VisuallyHidden>
                    {/* This is to let screen reader users know this is a live search */}
                    <UI.VisuallyHidden id="assignee-search-description">
                        Results will update as you type.
                    </UI.VisuallyHidden>
                    <Input
                        isSearchInput
                        aria-describedby="assignee-search-description"
                        value={searchTerm}
                        id="assignee-list"
                        name="assignee-list"
                        onChange={(e) => {
                            e.preventDefault();
                            setSearchTerm(e.target.value);

                            // reset scroll position
                            if (assigneeListRef.current) {
                                assigneeListRef.current.scrollTop = 0;
                            }
                        }}
                        placeholder="Search for a colleague or team"
                    />
                    {showNoAssigneeSelectedError && (
                        <StyledFeedback
                            props={{ id: "no-assignee-error" }}
                            title="Select an assignee to continue."
                            colour="error"
                        />
                    )}
                </StyledContainer>
                <StyledHorizontalBorder />
            </StyledStickyHeader>
            <StyledAssigneeLists ref={assigneeListRef}>
                <form
                    id={ASSIGNEE_SELECTOR_FORM_ID}
                    onSubmit={(e) => {
                        e.preventDefault();
                        onClickAssignButton();
                    }}
                >
                    {allowTeamAssignment && teams.length > 0 && (
                        <StyledAssigneeListWrapper>
                            <UI.RadioGroup
                                formFieldWrapperProps={{
                                    label: RadioGroupLabel("Teams"),
                                    "aria-describedby":
                                        showNoAssigneeSelectedError
                                            ? "no-assignee-error"
                                            : undefined,
                                }}
                                name="team-assignee"
                                onChange={handleCheckedChange}
                                checkedValue={
                                    selectedAssignee?.type === "Team"
                                        ? selectedAssignee.id
                                        : undefined
                                }
                                theme="bordered"
                                checkPlacement="top-right"
                                radioInputs={teams.map((team) => {
                                    return {
                                        id: `team-${team.id}`,
                                        "aria-label": team.displayName,
                                        label: RadioLabel(
                                            conversation,
                                            scollToSelectedAssignee,
                                            team,
                                            "Team",
                                        ),
                                        value: team.id,
                                        "data-assignee-type": "Team",
                                    };
                                })}
                            />
                        </StyledAssigneeListWrapper>
                    )}

                    {users.length > 0 && (
                        <StyledAssigneeListWrapper>
                            <UI.RadioGroup
                                formFieldWrapperProps={{
                                    label: RadioGroupLabel("Colleagues"),
                                    "aria-describedby":
                                        showNoAssigneeSelectedError
                                            ? "no-assignee-error"
                                            : undefined,
                                }}
                                name="user-assignee"
                                onChange={handleCheckedChange}
                                checkedValue={
                                    selectedAssignee?.type === "User"
                                        ? selectedAssignee.id
                                        : undefined
                                }
                                theme="bordered"
                                checkPlacement="top-right"
                                radioInputs={users.map((user) => {
                                    return {
                                        id: `user-${user.id}`,
                                        "aria-label": user.isCurrentUser
                                            ? `${user.displayName} (You)`
                                            : user.displayName,
                                        label: RadioLabel(
                                            conversation,
                                            scollToSelectedAssignee,
                                            user,
                                            "User",
                                        ),
                                        value: user.id,
                                        "data-assignee-type": "User",
                                    };
                                })}
                            />
                        </StyledAssigneeListWrapper>
                    )}
                </form>

                {teams.length === 0 && users.length === 0 && (
                    <StyledHeaderText skinny variant="note" colour="metal">
                        No results for '{searchTerm}'
                    </StyledHeaderText>
                )}

                <UI.VisuallyHidden>
                    <div aria-live="assertive" aria-atomic="true">
                        {teams.length === 0 &&
                            users.length === 0 &&
                            `No users or teams match your search`}
                    </div>
                </UI.VisuallyHidden>
            </StyledAssigneeLists>
            <UI.Grid as="li">
                {shouldAddNote && (
                    <>
                        <StyledHorizontalBorder />
                        <StyledNoteContainer>
                            <UI.Flex flexDirection="column" gap="0.5">
                                <StyledOuterTextarea>
                                    <StyledAssigneeNoteTextArea
                                        form={ASSIGNEE_SELECTOR_FORM_ID}
                                        value={noteText}
                                        onChange={(
                                            e: ChangeEvent<HTMLTextAreaElement>,
                                        ) => {
                                            setNoteText(e.target.value);
                                        }}
                                        data-testid="assignee-selector-note"
                                        maxLength={2000}
                                    />
                                </StyledOuterTextarea>
                                <UI.Flex alignItems="center">
                                    <StyledIconWrapper>
                                        <UI.Icon
                                            name="LockLocked"
                                            size={3}
                                            colour="metal"
                                            theme="Fill"
                                        />
                                    </StyledIconWrapper>
                                    <UI.Text
                                        skinny
                                        variant="preview"
                                        colour="metal"
                                    >
                                        Only visible to colleagues in your
                                        workspace
                                    </UI.Text>
                                </UI.Flex>
                            </UI.Flex>
                        </StyledNoteContainer>
                    </>
                )}

                <UI.Cell>
                    <StyledPopoverFooter justifyContent="space-between">
                        <UI.Item>
                            <Pill.SecondaryButton
                                onClick={toggle}
                                dimension="small"
                            >
                                <Pill.Text>Cancel</Pill.Text>
                            </Pill.SecondaryButton>
                        </UI.Item>

                        <UI.Item>
                            <UI.Flex gap="1" alignItems="center">
                                <Checkbox
                                    id={checkboxId}
                                    checked={shouldAddNote}
                                    onCheckedChange={() => {
                                        setShouldAddNote((checked) => !checked);
                                    }}
                                >
                                    <Checkbox.Indicator />
                                    <UI.Text variant="preview" skinny>
                                        Add note
                                    </UI.Text>
                                </Checkbox>
                                <UI.Item>
                                    <Pill.PrimaryButton
                                        disabled={updateAssignee.isLoading}
                                        data-userflow-id={
                                            userflowIds.conversationView
                                                .confirmAssignButton
                                        }
                                        dimension="small"
                                        type="submit"
                                        form={ASSIGNEE_SELECTOR_FORM_ID}
                                    >
                                        <Pill.Icon
                                            name="Assign"
                                            colour="white"
                                            size={3}
                                            theme="Fill"
                                            isLoading={updateAssignee.isLoading}
                                        />
                                        <Pill.Text>Assign</Pill.Text>
                                    </Pill.PrimaryButton>
                                </UI.Item>
                            </UI.Flex>
                        </UI.Item>
                    </StyledPopoverFooter>
                </UI.Cell>
            </UI.Grid>
        </>
    );
};
