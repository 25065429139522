import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import { PracticeDetailsDto } from "app/practices/Practices.types";
import {
    InstalledComputersDto,
    InstalledComputersRequestDto,
} from "app/practices/computers/InstalledComputers.types";
import {
    CancelMessageDto,
    DelayedMessagesDto,
} from "app/practices/delayedMessages/DelayedMessages.types";
import { GetModuleAccessLimitsDto } from "app/practices/moduleAccess/moduleAccess.types";
import { SelfBookAuditDto } from "app/practices/selfBook/selfBook.types";
import { PracticeSenderId } from "app/practices/senderId/SenderId.types";
import { TeamUsageDto } from "app/reporting/components/TeamUsage/TeamUsage.types";

const ENDPOINTS = {
    practices: "/api/portal/practices",
    sender: "/api/portal/Practices/SenderIdViewModel/:practiceId",
    teamNotifications: "/api/portal/Practices/:practiceId/Teams",
    teamUsage: "/api/patientmessaging/practices/teamUsageViewModel/:practiceId",
    moduleAccessLimits: "/api/patientmessaging/LimitedFeatureUsage",
    delayedMessages:
        "/api/patientmessaging/practices/:practiceId/DelayedMessages",
    deliveryReceipts:
        "/api/patientmessaging/practices/:practiceId/DeliveryReceipts",
    installedComputers: "/api/portal/practices/computers/:practiceId",
    messageStatusList: "/api/appointments/SelfBook/Audit/:practiceId",
} as const;

export const getPractices = (): Promise<IWrappedResult<PracticeDetailsDto[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.practices,
        }),
    );

export const getSenderId = (
    practiceId: string,
): Promise<IWrappedResult<PracticeSenderId>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.sender,
            params: { practiceId },
        }),
    );

export const postSenderId = (
    practiceId: string,
    request: PracticeSenderId,
): Promise<IWrappedResult<PracticeSenderId>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.sender,
            params: { practiceId },
        }),
        request,
    );

export const getTeamNotifications = (
    practiceId: string,
): Promise<IWrappedResult<any>> => // eslint-disable-line @typescript-eslint/no-explicit-any
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.teamNotifications,
            params: { practiceId },
        }),
    );

export const postTeamNotifications = (
    practiceId: string,
    request: any, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.teamNotifications,
            params: { practiceId },
        }),
        request,
    );

export const getTeamUsage = (
    practiceId: string,
): Promise<IWrappedResult<TeamUsageDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.teamUsage,
            params: { practiceId },
        }),
    );

export const getModuleAccessLimits = (
    organisationId: string,
): Promise<IWrappedResult<GetModuleAccessLimitsDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.moduleAccessLimits,
            query: `?organisationId=${organisationId}`,
        }),
    );

export const getDelayedMessages = (
    practiceId: string,
): Promise<IWrappedResult<DelayedMessagesDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.delayedMessages,
            params: { practiceId },
        }),
    );

export const cancelDelayedMessages = (
    practiceId: string,
    request: CancelMessageDto,
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.delayedMessages,
            params: { practiceId },
        }),
        request,
    );

export const getDeliveryReceipts = (
    practiceId: number,
): Promise<IWrappedResult<any>> => // eslint-disable-line @typescript-eslint/no-explicit-any
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.deliveryReceipts,
            params: { practiceId: practiceId.toString() },
        }),
    );

export const getInstalledComputers = (
    practiceId: string,
): Promise<IWrappedResult<InstalledComputersDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.installedComputers,
            params: { practiceId },
        }),
    );

export const postInstalledComputers = (
    practiceId: string,
    request: InstalledComputersRequestDto,
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.installedComputers,
            params: { practiceId },
        }),
        request,
    );

export const getMessageStatusList = (
    practiceId: string,
): Promise<IWrappedResult<SelfBookAuditDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageStatusList,
            params: { practiceId },
        }),
    );
