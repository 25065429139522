import NotFound from "NotFound";
import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { CreateWorkspacePage } from "app/workspace/new-onboarding/CreateWorkspacePage/CreateWorkspacePage";
import { JoinWorkspacePage } from "app/workspace/new-onboarding/JoinWorkspace/JoinWorkspacePage";
import { JoinOrganisationPage } from "app/workspace/new-onboarding/Organisation/JoinOrganisationPage";
import { SelectOrganisationPage } from "app/workspace/new-onboarding/SelectOrganisation/SelectOrganisationPage";
import { ONBOARDING_ROUTES_TEMPORARY } from "app/workspace/new-onboarding/routes";

import { GuardedRoute } from "../GuardedRoute";

/**
 * @deprecated that is set of page copies for development purposes
 */
export const OnboardingRoutesSectionTemporary = () => {
    return (
        <>
            <Switch>
                <SimpleRoute
                    exact
                    path={ONBOARDING_ROUTES_TEMPORARY.organsationJoin}
                >
                    <JoinOrganisationPage />
                </SimpleRoute>

                <GuardedRoute
                    path={ONBOARDING_ROUTES_TEMPORARY.workspaceJoin}
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                    exact
                >
                    <JoinWorkspacePage />
                </GuardedRoute>

                <GuardedRoute
                    path={ONBOARDING_ROUTES_TEMPORARY.workspaceCreate}
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                    exact
                >
                    <CreateWorkspacePage />
                </GuardedRoute>

                <GuardedRoute
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                    path={ONBOARDING_ROUTES_TEMPORARY.organisationSelect}
                    exact
                >
                    <SelectOrganisationPage />
                </GuardedRoute>
                <SimpleRoute path="*">
                    <NotFound />
                </SimpleRoute>
            </Switch>
        </>
    );
};
