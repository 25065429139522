import * as UI from "@accurx/design";

export const BackButton = ({ onClickBack }: { onClickBack: () => void }) => {
    return (
        <nav>
            <UI.Button
                theme="transparent"
                dimension="large"
                icon={{
                    name: "Arrow",
                    title: "Close conversation",
                    id: "close-convsersation",
                    rotation: "left",
                }}
                onClick={onClickBack}
            />
        </nav>
    );
};
