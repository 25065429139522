import { ReactNode, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    FeatureName,
    useAuth,
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import { Ds, Popover, PopoverTrigger, Text } from "@accurx/design";
import {
    StyledDivider,
    StyledListHeader,
    StyledPopoverContent,
    StyledPopoverItem,
    StyledPopoverItemWithSpaceBetween,
} from "@accurx/navigation";
import { cleanUpSession } from "appLifecycle";
import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { generatePath, useHistory } from "react-router-dom";

import { ROUTES_WORKSPACE } from "shared/Routes";

import { UnapprovedUsersBadge } from "../UnapprovedUsersBadge/UnapprovedUsersBadge";
import { WorkspaceSwitcherContent } from "../WorkspaceSwitcher/WorkspaceSwitcher";

export const WorkspaceMenu = ({
    children,
    showWorkspaceSwitcher = false,
}: {
    children?: ReactNode;
    showWorkspaceSwitcher?: boolean;
}) => {
    const {
        orgId: workspaceId,
        organisationName: workspaceName,
        organisationNodeName,
    } = useCurrentWorkspace();
    const { user } = useCurrentUser();
    const { logOut } = useAuth();

    const track = useAnalytics();
    const history = useHistory();

    // TODO: Update logic after workspaces update
    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );
    const isWorkspaceUserManagementEnabled = useFeatureFlag(
        FeatureName.WorkspaceUserManagement,
    );
    const isEmbeddedPowerBiPOCEnabled = useFeatureFlag(
        FeatureName.EmbeddedPowerBiPOC,
    );

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [workspaceSwitcherOpen, setWorkspaceSwitcherOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        if (open) {
            setWorkspaceSwitcherOpen(false);
            setPopoverOpen(true);
        } else {
            setPopoverOpen(false);
        }
    };

    const goToManageWorkspace = () => {
        history.push(
            generatePath("/w/:workspaceId/settings/general", {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const goToUsers = () => {
        history.push(
            generatePath("/w/:workspaceId/settings/users", {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const goToReporting = () => {
        history.push(
            generatePath(ROUTES_WORKSPACE.reporting, {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const goToAccountSettings = () => {
        history.push("/account/user/name-and-email");
        setPopoverOpen(false);
    };

    const handleLogOut = () => {
        track("NavigationAccount MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "Logout",
        });
        setPopoverOpen(false);

        logOut({ cleanUpFn: cleanUpSession });
    };

    return (
        <Popover open={popoverOpen} onOpenChange={handleOpenChange} modal>
            <PopoverTrigger asChild>
                {children || (
                    <StyledTriggerButton
                        text={workspaceName}
                        icon={{ name: "Cog" }}
                    />
                )}
            </PopoverTrigger>
            {workspaceSwitcherOpen ? (
                <WorkspaceSwitcherContent
                    onClose={() => setPopoverOpen(false)}
                />
            ) : (
                <StyledPopoverContent side="bottom" align="end">
                    <StyledListHeader>
                        <Text skinny>{workspaceName}</Text>
                        {isFlexibleWorkspaceEnabled && (
                            <Text skinny>{organisationNodeName}</Text>
                        )}
                    </StyledListHeader>

                    <StyledPopoverItem onClick={goToManageWorkspace}>
                        <Ds.Icon name="Cog" size="small" color="stone" />
                        <Text skinny variant="label">
                            Manage workspace
                        </Text>
                    </StyledPopoverItem>

                    {isWorkspaceUserManagementEnabled && (
                        <StyledPopoverItemWithSpaceBetween onClick={goToUsers}>
                            <Ds.Flex gap="1">
                                <Ds.Icon
                                    name="Person"
                                    size="small"
                                    color="stone"
                                />
                                <Text skinny variant="label">
                                    Users
                                </Text>
                            </Ds.Flex>
                            <UnapprovedUsersBadge />
                        </StyledPopoverItemWithSpaceBetween>
                    )}

                    {isEmbeddedPowerBiPOCEnabled && (
                        <StyledPopoverItem onClick={goToReporting}>
                            <Ds.Icon name="Chart" size="small" color="stone" />
                            <Text skinny variant="label">
                                Reports
                            </Text>
                        </StyledPopoverItem>
                    )}

                    <StyledDivider />

                    <StyledListHeader>{user.email}</StyledListHeader>

                    <StyledPopoverItem onClick={goToAccountSettings}>
                        <Ds.Icon name="Cog" size="small" color="stone" />
                        <Text skinny variant="label">
                            Account settings
                        </Text>
                    </StyledPopoverItem>

                    <StyledPopoverItem onClick={handleLogOut}>
                        <Ds.Icon name="Reply" size="small" color="stone" />
                        <Text skinny variant="label">
                            Log out
                        </Text>
                    </StyledPopoverItem>

                    {showWorkspaceSwitcher && (
                        <>
                            <StyledDivider />

                            <StyledPopoverItemWithSpaceBetween
                                onClick={() => setWorkspaceSwitcherOpen(true)}
                            >
                                <Ds.Flex gap="1">
                                    <Ds.Icon
                                        name="ArrowTwoWay"
                                        appearance="solid"
                                        size="small"
                                        color="stone"
                                        rotate="90"
                                    />
                                    <Text skinny variant="label">
                                        Switch workspace
                                    </Text>
                                </Ds.Flex>
                                <Ds.Icon
                                    name="Arrow"
                                    size="small"
                                    color="stone"
                                    rotate="90"
                                />
                            </StyledPopoverItemWithSpaceBetween>
                        </>
                    )}
                </StyledPopoverContent>
            )}
        </Popover>
    );
};
