import React, { useState } from "react";

import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import {
    MyWorkspaceWarningModal,
    SelectNhsPharmacyModal,
    isMyWorkspace,
    isNhsPharmacy,
    isPharmacy,
    isUserOnlyInDefaultWorkspaceWithinOrg,
    supportsMultipleWorkspaces,
} from "@accurx/workspace-management";
import { Link, generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";

import { WorkspaceCard } from "../../WorkspaceCard/WorkspaceCard";
import { StyledUnderLineText } from "./WorkspaceMemberCard.styles";

export const WorkspaceMemberCard = ({
    workspace,
}: {
    workspace: Organisation;
}) => {
    const history = useHistory();

    const { user } = useCurrentUser();

    const { selectedOrgId, setSelectedOrgId } = useSelectedOrganisation();

    const [showPharmacyModal, setShowPharmacyModal] = useState(false);
    const [showMyWorkspaceModal, setShowMyWorkspaceModal] = useState(false);

    const defaultWorkspaceName = supportsMultipleWorkspaces(workspace)
        ? workspace.organisationNodeName
        : undefined;

    const userWorkspaceRole = workspace.settings.isAdminUser
        ? "Admin"
        : "Member";

    const isCurrentWorkspace = (orgId: number) => {
        return orgId === selectedOrgId;
    };

    const workspaceName = isMyWorkspace(workspace)
        ? "My Workspace"
        : workspace.organisationName;

    const selectWorkspace = (selectedWorkspace: Organisation) => {
        setSelectedOrgId(selectedWorkspace.orgId);
        history.push(ROUTES.home);
    };

    const handleSwitchWorkspace = (
        event: React.MouseEvent<HTMLAnchorElement>,
        selectedWorkspace: Organisation,
    ) => {
        event.preventDefault();
        if (
            isNhsPharmacy(workspace) &&
            user.organisations.filter(isPharmacy).length > 0
        ) {
            setShowPharmacyModal(true);
        } else if (
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            ) &&
            isMyWorkspace(workspace) &&
            !isUserOnlyInDefaultWorkspaceWithinOrg({
                user,
                organisation: workspace,
            })
        ) {
            setShowMyWorkspaceModal(true);
        } else {
            selectWorkspace(selectedWorkspace);
        }
    };

    return (
        <>
            <WorkspaceCard
                workspaceName={workspaceName}
                defaultWorkspaceName={defaultWorkspaceName}
            >
                <Text skinny>{userWorkspaceRole}</Text>
                <Popover>
                    <PopoverTrigger>
                        <StyledUnderLineText
                            colour="blue"
                            skinny
                            props={{
                                "data-testid": `actions-workspace-${workspace.orgId}`,
                            }}
                        >
                            Actions
                            <Icon
                                colour="blue"
                                name="Arrow"
                                rotation="down"
                                size={2}
                                theme="Line"
                            />
                        </StyledUnderLineText>
                    </PopoverTrigger>
                    <PopoverContent align="center" side="bottom">
                        {!isCurrentWorkspace(workspace.orgId) && (
                            <PopoverItem>
                                <Link
                                    to={ROUTES.home}
                                    onClick={(e) =>
                                        handleSwitchWorkspace(e, workspace)
                                    }
                                >
                                    <Text skinny>Switch to workspace</Text>
                                </Link>
                            </PopoverItem>
                        )}
                        <PopoverItem>
                            <Link
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceGeneralSettings,
                                    {
                                        workspaceId: workspace.orgId,
                                    },
                                )}
                            >
                                <Text skinny>Workspace settings</Text>
                            </Link>
                        </PopoverItem>
                    </PopoverContent>
                </Popover>
            </WorkspaceCard>
            {showPharmacyModal && (
                <SelectNhsPharmacyModal
                    handleClose={() => setShowPharmacyModal(false)}
                    handleSelectWorkspace={selectWorkspace}
                />
            )}
            {showMyWorkspaceModal && (
                <MyWorkspaceWarningModal
                    handleClose={() => setShowMyWorkspaceModal(false)}
                    handleSelectMyWorkspace={() => {
                        setShowMyWorkspaceModal(false);
                        selectWorkspace(workspace);
                    }}
                />
            )}
        </>
    );
};
