import { getEmbedMode } from "domains/native/utils/getEmbedMode";

export type Environment = "WebView" | "SignalR";

export const useBrowserEnvironment = (): Environment => {
    const { embedMode } = getEmbedMode();

    if (embedMode === "Desktop") {
        return "WebView";
    }

    return "SignalR";
};
