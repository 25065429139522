// Fields containing line breaks (CRLF), double quotes, and commas
// should be enclosed in double-quotes.
// If double-quotes are used to enclose fields, then a double-quote
// appearing inside a field must be escaped by preceding it with
// another double quote
// https://www.ietf.org/rfc/rfc4180.txt
export const encodeFieldForCSV = (field?: string | null) => {
    if (typeof field !== "string") return field;
    return field ? `"${field.replace(/"/g, '""')}"` : "";
};
