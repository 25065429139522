import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { QueriesState } from "domains/concierge/internal/types/QueriesState";
import { QueryStatus } from "domains/concierge/internal/types/QueryStatus";

export const setQueryStatus = (
    state: Draft<QueriesState>,
    action: PayloadAction<{ name: keyof QueriesState; value: QueryStatus }>,
) => {
    state[action.payload.name] = action.payload.value;
    return state;
};
