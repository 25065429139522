import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type Variables = {
    teamId: string;
    notify: boolean;
};

export const useToggleNotificationsMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation<Variables>(
        "ToggleNotifications",
        async (variables) => {
            const { updates } = await api.toggleNotifications({
                workspaceId,
                teamId: variables.teamId,
                notify: variables.notify,
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:ToggleNotifications",
                    ...updates,
                }),
            );

            // When team membership changes we need to recalculate unread counts
            // because patient unread counts may have changed.
            dispatch(actions.conversations.recalculateUnreadCounts());
        },
        options,
    );
};
