import React, {
    ChangeEvent,
    ClipboardEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import {
    DateFormatOptions,
    DateHelpers,
    EmailAddressHelper,
    MobileNumberHelper,
    NhsNumberHelpers,
} from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import {
    IdType,
    MessagePatientAllEndpointsRequest,
    MessageTemplateType,
    PatientListAppointment,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { PatientConversationInitiatedByType } from "app/analytics/FlemingAnalytics";
import { mapPresetTemplateToMessageTemplate } from "app/messageTemplates/MessageTemplatesHelper";
import {
    TourTestPatient,
    getTourMode,
    setTourMode,
} from "app/onboarding/Onboarding.helper";
import { WebPatient } from "app/patients/Patient.types";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    generateSignature,
    syncedCharLength,
    validateMessageSize,
} from "shared/MessageHelper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import {
    MessageTemplateOption,
    MessageTemplateTokens,
    NonEditableContentOptions,
    PREFILLED_VIDEO_CONSULT_SECTION,
    PresetTemplateSection,
    Templates,
} from "shared/Templates";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { actionCreators as patientListsActionCreators } from "../../patientLists/PatientListsActions";
import MessagePreview from "../../sharedComponents/messagePreview/MessagePreviewComponent";
import { mapToTemplateSection } from "../../workspaceConversations/components/MessageTemplates/MessageTemplate.helper";
import { useMessageTemplates } from "../../workspaceConversations/components/MessageTemplates/MessageTemplates.hooks";
import { actionCreators as sendMessageActionCreators } from "../SendMessageActions";
import {
    SEND_MESSAGE_MODAL_TITLE_ID,
    SEND_MESSAGE_PAGE_TITLE_ID,
} from "../SendMessageConstants";
import { MessageType } from "../SendMessageReducer";
import FeedbackMessages from "./FeedbackMessagesComponent";
import FileUploadInput, { Attachment } from "./FileUploadInputComponent";
import { MessageInput } from "./MessageInput";
import { EmailInput, MobileInput } from "./MobileEmailInputComponents";
import PatientResponseToggle from "./PatientResponseToggleComponent";
import { TemplatesInput } from "./TemplatesInput";
import { LoadingStatus } from "./types";

const CONVERSATION_INITIATED_BY_ANALYTICS_EVENT_PROP: PatientConversationInitiatedByType =
    "ClinicianInitiated";

const CopyButtonAnalyticsContext =
    FlemingAnalyticsTracker.CopyButtonAnalyticsContext;

export const NO_APPOINTMENT_STATE: PatientListAppointment = {
    id: 0,
    patientId: 0,
    videoConsultId: null,
    videoConsultUrl: "",
    dateAdded: "",
    dateTimeStart: null,
    hasSentVideoConsultInvite: false,
};

export interface SendMessageFormProps {
    actionButtons?: (shouldButtonsBeDisabled: boolean) => JSX.Element | null;
    isModalOpen?: boolean;
    handleToggleModal?: () => void;
    patientListId?: number | null;
    appointment?: PatientListAppointment;
    /**
     * Any custom logic to run after a message has been successfully sent */
    onMessageSendSuccess?: () => void;
    hasClickedSend?: Dispatch<SetStateAction<boolean>>; // This is an optional setter for a parent component to see whether send was clicked
    patient: WebPatient;
    loadingStatus?: LoadingStatus;
}

type PresetTemplateList = {
    preset: PresetTemplateSection[];
};

// This is different from the generateGreeting function from message practice
export const generateGreeting = (
    patient: Pick<
        WebPatient,
        "ageYear" | "prefixName" | "familyName" | "firstName"
    > | null,
): string => {
    if (!patient) {
        return "";
    }
    if (patient.ageYear > 30) {
        return patient.prefixName != null
            ? `Dear ${patient.prefixName} ${patient.familyName},`
            : `Dear ${patient.firstName},`;
    }
    return `Hi ${patient.firstName},`;
};

const generateFullTemplateBody = (
    greeting: string,
    body: string,
    signature = "",
): string => {
    const signOff = signature === "" ? "" : `\n\n${signature}`;
    return `${greeting}\n\n${body}${signOff}`;
};

// This function is used across the form components
export const checkIsVideoConsult = (state: ApplicationState): boolean => {
    return state.selectProduct?.productSelection === ProductSelection.Video;
};

const doesPatientHaveScheduledAppointment = (
    appointment: PatientListAppointment,
): boolean => {
    return appointment.dateTimeStart !== null;
};

const isImmediateVideoConsultInvite = (
    isVideoConsult: boolean,
    appointment: PatientListAppointment,
): boolean => {
    return isVideoConsult && !doesPatientHaveScheduledAppointment(appointment);
};

const SendMessageForm = ({
    actionButtons,
    isModalOpen,
    handleToggleModal,
    patientListId = null,
    appointment = NO_APPOINTMENT_STATE,
    hasClickedSend,
    onMessageSendSuccess,
    patient,
    loadingStatus = LoadingStatus.Initial,
}: SendMessageFormProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const accountState = useAppSelector((state) => state.account, shallowEqual);

    const isVideoConsult = useAppSelector((state) =>
        checkIsVideoConsult(state),
    );

    useAccurxWebTitle(
        isVideoConsult
            ? "Invite patient to video consult"
            : "Send message to patient",
    );

    const analyticsState = useAppSelector(
        ({ sessionAnalytics: analytics }) => analytics,
        shallowEqual,
    );
    const loggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const lastMessageTemplateActionLocation = useAppSelector(
        ({ messageTemplates }) =>
            messageTemplates.lastMessageTemplateActionLocation,
    );
    const productSelected = useAppSelector(
        ({ selectProduct }) => selectProduct.productSelection,
    );
    const messageType = useAppSelector(
        ({ messagePatient }) => messagePatient.messageType,
    );
    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const appointmentTime = appointment.dateTimeStart;
    const orgId = useAppSelector(({ account }) => account.selectedOrganisation);
    const nationalCode = useAppSelector(
        ({ account }) =>
            OrganisationHelper.getOrganisation(account)?.nationalCode,
    );
    const userFullName = useAppSelector(
        ({ account }) => account.user?.fullName,
    );

    const isDummyPatient = PatientHelper.isDummyPatient(patient);

    const isMessageSending = useAppSelector(
        ({ messagePatient }) => messagePatient.isMessageSending,
    );

    const patientOrigin = useAppSelector(
        (state) => state.selectProduct.searchPatientOrigin,
        shallowEqual,
    );

    const blurTimeout = useRef<undefined | number>(undefined);
    const [shouldSubmitButtonBeDisabled, setShouldSubmitButtonBeDisabled] =
        useState(false);

    // Mobile number input
    const obfuscatedMobileNumber = patient.mobileNumber
        ? MobileNumberHelper.obfuscate(patient.mobileNumber)
        : MobileNumberHelper.unknownNumber;
    const [isMobileFromPds, setIsMobileFromPds] = useState(false);
    const [mobileNumberInputValue, setMobileNumberInputValue] = useState("");
    const [mobileNumberInputError, setMobileNumberInputError] = useState("");

    // Email input
    const [emailInputValue, setEmailInputValue] = useState("");
    const [emailInputError, setEmailInputError] = useState("");

    // Message body
    const messageGreeting = generateGreeting(patient);
    const vcDefaultTemplate = useAppSelector(
        ({ messageTemplates }) => messageTemplates.vcDefaultTemplate,
        shallowEqual,
    );
    const messageSignature = useAppSelector((state) =>
        generateSignature(state.account.user),
    );
    const [emptySmsContent, setEmptySmsContent] = useState("");
    const [messageInputValue, setMessageInputValue] = useState("");
    const [messageInputError, setMessageInputError] = useState("");
    const [messageInputValidText, setMessageInputValidText] = useState("");

    // Files
    const [manuallyAttachedFileIds, setManuallyAttachedFileIds] = useState<
        string[]
    >([]);
    const [templateFiles, setTemplateFiles] = useState<Attachment[]>([]);
    const attachmentCount =
        manuallyAttachedFileIds.length + templateFiles.length;
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [showFileUploadingMessage, setShowFileUploadingMessage] =
        useState(false); // this is set to true whe the form has been submitted while a document is still uploading

    // Patient response checkbox
    const [isPatientResponseEnabled, setIsPatientResponseEnabled] =
        useState(false);
    const [hidePatientReponseToggle, setHidePatientResponseToggle] =
        useState(false);

    // Templates section
    const [isTemplateUsed, setIsTemplateUsed] = useState(false);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

    // Video Consult preset template
    const [videoConsultPresetTemplate, setVideoConsultPresetTemplate] =
        useState(Templates.presetVCTemplate);

    // State that will hold the correct displayed preset template, depending on whether message is VC or not
    const [displayedPresetTemplates, setDisplayedPresetTemplates] = useState<
        PresetTemplateSection[]
    >([]);

    // Get preset templates from hook
    const { presetTemplates } = useMessageTemplates(orgId);
    const [presetDisplayedTemplates, setPresetDisplayedTemplates] =
        useState<PresetTemplateList>({
            preset: mapToTemplateSection(presetTemplates),
        });
    useEffect(() => {
        setPresetDisplayedTemplates({
            preset: mapToTemplateSection(presetTemplates),
        });
    }, [presetTemplates]);

    const customTemplates = useAppSelector(
        ({ selectProduct, messageTemplates }) => {
            switch (selectProduct.productSelection) {
                case ProductSelection.Sms:
                    return messageTemplates.smsTemplates;
                case ProductSelection.Video:
                    return messageTemplates.videoConsultTemplates.filter(
                        (template) => template.isDefault !== true,
                    );
                default:
                    return [];
            }
        },
        shallowEqual,
    );

    const userDefaultTemplate = useAppSelector(
        ({ selectProduct, messageTemplates }) => {
            if (selectProduct.productSelection === ProductSelection.Video) {
                return messageTemplates.vcDefaultTemplate;
            }
            return null;
        },
        shallowEqual,
    );

    const messageTemplateType = useAppSelector(({ selectProduct }) => {
        return productSelected === ProductSelection.Video
            ? MessageTemplateType.VideoConsult
            : MessageTemplateType.Sms;
    });

    // The template that is currently selected
    // we initialise with nothing selected
    const [selectedTemplate, setSelectedTemplate] =
        useState<MessageTemplateOption>(Templates.noTemplateSelected);

    // Modal
    const obfuscatedMobileNumberAfterSend = useAppSelector(
        ({ messagePatient }) => {
            const mobileNumberUsed =
                messagePatient.messageInfoSent?.mobileNumber || null;
            return MobileNumberHelper.obfuscate(mobileNumberUsed) || undefined;
        },
    );
    const lastSendMessageResponse = useAppSelector(
        ({ messagePatient }) => messagePatient.lastSendMessageResponse,
    );
    const [showSuccess, setShowSuccess] = useState(false);

    //#region EFFECTS

    // Reset hasClickedSend to false on opening the modal
    useEffect(() => {
        hasClickedSend && hasClickedSend(false);
    }, [hasClickedSend]);

    // Actions to take on mount/unmount
    useEffect(() => {
        return (): void => {
            // Reset message type on unmount
            dispatch(
                sendMessageActionCreators.changeMessageType(MessageType.Sms),
            );
            clearBlurTimeout();
        };
    }, [dispatch]);

    // Check that the mobile number of the searched patient is there
    useEffect(() => {
        const isFromPds =
            obfuscatedMobileNumber !== MobileNumberHelper.unknownNumber;
        setIsMobileFromPds(isFromPds);
        if (isFromPds) {
            setMobileNumberInputValue(obfuscatedMobileNumber as string);
        }
    }, [obfuscatedMobileNumber]);

    // If a file is uploading while submitting, we prevent submission and show a helpful message - once the upload has finished we want to hide the message again
    useEffect(() => {
        if (!isFileUploading) {
            setShowFileUploadingMessage(false);
        }
    }, [isFileUploading]);

    // Set the first message body text + validation of the message on messageInputValue change
    useEffect(() => {
        if (messageInputValue.length <= 2) {
            setMessageInputError("Please add a message to the patient");
            setMessageInputValidText("");
            return;
        }

        const { isSizeValid, messageSize } = validateMessageSize(
            isVideoConsult,
            isPatientResponseEnabled,
            attachmentCount,
            orgName,
            messageInputValue,
            appointmentTime,
        );

        if (!isSizeValid) {
            setMessageInputError(
                `Sorry, your message must be equal to or less than ${syncedCharLength.maxMessageCharCount} characters (${messageSize})`,
            );
            setMessageInputValidText("");
        } else {
            setMessageInputError("");
            setMessageInputValidText(
                `${syncedCharLength.maxMessageCharCount - messageSize}/${
                    syncedCharLength.maxMessageCharCount
                }`,
            );
        }
    }, [
        isVideoConsult,
        messageInputValue,
        isPatientResponseEnabled,
        attachmentCount,
        templateFiles,
        orgName,
        appointmentTime,
    ]);

    // decide disabled state of submit button
    useEffect(() => {
        const isValidNumberField =
            messageType === MessageType.Sms ? !mobileNumberInputError : null;
        const isValidEmailField =
            messageType === MessageType.Email ? !emailInputError : null;
        // check if email or sms are invalid (depending on whether they are the selected type) or the message body is invalid
        const areFormFieldsInvalid =
            isValidNumberField === false ||
            isValidEmailField === false ||
            !!messageInputError;
        setShouldSubmitButtonBeDisabled(areFormFieldsInvalid);
    }, [
        messageType,
        mobileNumberInputError,
        emailInputError,
        messageInputError,
    ]);

    // Set empty SMS content to check for message validity upon submission
    useEffect(() => {
        setEmptySmsContent(
            generateFullTemplateBody(messageGreeting, "", messageSignature),
        );
    }, [messageGreeting, messageSignature]);

    // Set initial message body and upon adding a new Video Consult default
    useEffect(() => {
        // feature: SMS patient
        if (isVideoConsult === false) {
            setMessageInputValue(
                generateFullTemplateBody(
                    messageGreeting,
                    selectedTemplate.body,
                    messageSignature,
                ),
            );
            return;
        }

        // feature: video consults
        if (isVideoConsult === true) {
            const messageContents = generateFullTemplateBody(
                messageGreeting,
                selectedTemplate.body,
                messageSignature,
            );

            setMessageInputValue(messageContents);
            return;
        }
    }, [selectedTemplate, isVideoConsult, messageGreeting, messageSignature]);

    const isPifuEnabled = useIsFeatureEnabled(
        FeatureName.WebPatientLedFollowUp,
    );
    const trySendViaNhsApp = useFeatureFlag(FeatureName.SendSmsViaNhsApp);

    /**
     * Set the list of preset templates as well as the Video Consult template
     * that should be used if user selected the Accurx template provided
     */
    useEffect(() => {
        const isVideoConsult = productSelected === ProductSelection.Video;
        const isScheduledAppointment =
            doesPatientHaveScheduledAppointment(appointment);
        const vcPresetTemplate = Templates.getPresetVCTemplate(
            isScheduledAppointment,
            { practiceName: orgName },
        );
        setVideoConsultPresetTemplate(vcPresetTemplate);

        // Set the actual list of templates we're going to render in the dropdown
        const templates = isVideoConsult
            ? Templates.VCTemplates.map((section) => {
                  if (
                      section.sectionHeading === PREFILLED_VIDEO_CONSULT_SECTION
                  ) {
                      return {
                          ...section,
                          templateList: [vcPresetTemplate],
                      };
                  }
                  return section;
              })
            : presetDisplayedTemplates.preset;
        setDisplayedPresetTemplates(templates);
    }, [appointment, orgName, productSelected, presetDisplayedTemplates]);

    /**
     * Prefill the body of the SMS & subsequent template selection
     * When form renders as well as when user
     * creates a new default template
     */
    useEffect(() => {
        const isVideoConsult = productSelected === ProductSelection.Video;

        // If we're composing a Message Patient message we don't want to preselect any templates
        if (!isVideoConsult) {
            return;
        }

        const isScheduledAppointment =
            doesPatientHaveScheduledAppointment(appointment);

        // If we're sending an immediate Video Consult invite
        // (we disregard isDefault template prop with scheduled appts because
        // we assume the context is very different with immediate ones)
        // and the user has a predefined default template
        // set that as the currently selected template
        if (vcDefaultTemplate !== null && !isScheduledAppointment) {
            setIsTemplateUsed(true);
            setSelectedTemplate({
                ...vcDefaultTemplate,
                isPresetTemplate: false,
                group: null,
            });
        } else {
            // Get the Accurx preset template copy
            setSelectedTemplate(
                mapPresetTemplateToMessageTemplate(
                    videoConsultPresetTemplate,
                    productSelected,
                    PREFILLED_VIDEO_CONSULT_SECTION,
                ),
            );
        }
    }, [
        appointment,
        vcDefaultTemplate,
        productSelected,
        videoConsultPresetTemplate,
    ]);

    //#endregion EFFECTS

    const account = useAppSelector(({ account }) => account);
    const analytics = useAppSelector(
        ({ sessionAnalytics }) => sessionAnalytics,
    );

    //#region Action functions

    const handleFormSubmit = (e: FormEvent): void => {
        e.preventDefault();

        const mobileNumberToUse =
            messageType === MessageType.Sms ? validateMobileNumber(true) : null;

        const emailAddressToUse =
            messageType === MessageType.Email &&
            productSelected === ProductSelection.Video
                ? validateEmail()
                : null;

        const isMobileNumberValid =
            messageType === MessageType.Sms && !!mobileNumberToUse;

        const isEmailValid =
            messageType === MessageType.Email && !!emailAddressToUse;
        const isMessageBodyValid = finalMessageValidate();

        const isTestPatient = PatientHelper.isDemoPatient(
            patient.nhsNumber as string,
        );

        const messageContainsPIFULink = AnalyticsMapper.messageContainsPIFULink(
            isPifuEnabled,
            nationalCode,
            messageInputValue,
        );

        // set up analytics
        const patientMessageAnalyticsProps = {
            ...loggedInProps,
            ...meaningfulActionProps,
            isTestPatient: isTestPatient,
            origin: patientOrigin,
            withTemplate: isTemplateUsed,
            withAttachment: attachmentCount > 0,
            countAttachmentFromTemplate: templateFiles.length,
            withPatientResponse: isPatientResponseEnabled,
            templateName: selectedTemplate.title,
            templateGroup: selectedTemplate.group,
            isPresetTemplate: selectedTemplate.isPresetTemplate,
            conversationId: "",
            conversationInitiatedBy:
                CONVERSATION_INITIATED_BY_ANALYTICS_EVENT_PROP,
            withPatientInitiatedFollowUpLink: messageContainsPIFULink,
            isPatientInitiatedFollowUpUser: isPifuEnabled,
            isMobileNumberOverridden:
                mobileNumberToUse != null && isMobileFromPds === false,
            messageType: messageType === MessageType.Email ? "Email" : "Sms",
            messageLength: messageInputValue.length,
            inviteType: appointment.dateTimeStart ? "Dated" : "Anytime",
            isReply: false,
            floreyName: null,
            withFlorey: false,
        };

        if (
            (!isMobileNumberValid && !isEmailValid) ||
            !isMessageBodyValid ||
            isFileUploading ||
            !patient
        ) {
            isVideoConsult
                ? FlemingAnalyticsTracker.trackPatientVideoInviteSendButtonClickError(
                      patientMessageAnalyticsProps,
                  )
                : FlemingAnalyticsTracker.trackPatientMessageSendButtonClickError(
                      patientMessageAnalyticsProps,
                  );
            if (isFileUploading) {
                setShowFileUploadingMessage(true);
            }

            return;
        }

        isVideoConsult
            ? FlemingAnalyticsTracker.trackPatientVideoInviteSendButtonClickSuccess(
                  patientMessageAnalyticsProps,
              )
            : FlemingAnalyticsTracker.trackPatientMessageSendButtonClickSuccess(
                  patientMessageAnalyticsProps,
              );

        const timeStamp = DateHelpers.formatTime(
            DateHelpers.getCurrentTimeStamp(),
            DateFormatOptions.TIME_DATE_SHORT,
        );
        const emailSubjectLine = `Copy of your message to patient: ${NhsNumberHelpers.formatNhsNumber(
            patient.nhsNumber as string,
        )}`;

        const messageSentTo = emailAddressToUse
            ? `email address ${EmailAddressHelper.obfuscateDefined(
                  emailAddressToUse,
              )}`
            : mobileNumberToUse
            ? `mobile number ${MobileNumberHelper.obfuscateDefined(
                  mobileNumberToUse,
              )}`
            : "";

        const emailBody = `Message sent to ${patient.displayName} on ${messageSentTo} by ${userFullName} at ${timeStamp}\n`;

        const messageInfoRequest: MessagePatientAllEndpointsRequest = {
            mobileNumber: mobileNumberToUse,
            // Use the mobile number from PDS on the server if we want to send
            // via SMS (i.e. mobileNumberToUse is defined) and the mobile number
            // field hasn't been changed from PDS
            useMobileNumberFromPDS:
                mobileNumberToUse !== null && isMobileFromPds,
            emailAddress: emailAddressToUse,
            emailSubjectLine,
            emailBody,
            messageBody: messageInputValue,
            attachedDocumentIds: manuallyAttachedFileIds,
            enablePatientResponse: isPatientResponseEnabled,
            patientToken: patient?.patientToken || "", // Empty string here will be handled by sending the request with identity instead
            nhsNumber: patient.nhsNumber as string,
            organisationId: orgId,
            isVideoConsult,
            patientListId,
            patientListEntryId: appointment?.id || null,
            videoConsultTime: isVideoConsult ? appointment.dateTimeStart : null,
            ticketIdentity: null,
            trySendViaNhsApp,
            patientExternalIdentity: {
                patientExternalIds: [
                    {
                        type: IdType.NhsNumber,
                        value: patient.nhsNumber as string,
                    },
                ],
            },
            messageTemplate: selectedTemplate?.id
                ? {
                      id: selectedTemplate.id,
                      attachmentIds: templateFiles.map(({ id }) =>
                          parseInt(id, 10),
                      ),
                  }
                : undefined,
        };

        hasClickedSend && hasClickedSend(true);

        dispatch(
            sendMessageActionCreators.sendPatientMessage(
                messageInfoRequest,
                patientMessageAnalyticsProps,
                sendFailureCallback,
                sendSuccessCallback,
            ),
        );
    };

    const sendFailureCallback = (error: string): void => {
        // Show a failure feedback if the form is inside a modal
        if (handleToggleModal !== undefined) {
            renderModalFailureFeedback(error);
        }
    };

    const sendSuccessCallback = (): void => {
        // End onboarding specific tour
        const accuRxUserId = account.user?.accuRxUserId || "";
        const tourMode = getTourMode(accuRxUserId);
        if (tourMode === TourTestPatient.Sms) {
            setTourMode(TourTestPatient.SmsEnded, accuRxUserId);
        }
        if (tourMode === TourTestPatient.Video) {
            setTourMode(TourTestPatient.VideoEnded, accuRxUserId);
        }

        const isImmediateVideoConsult = isImmediateVideoConsultInvite(
            isVideoConsult,
            appointment,
        );

        // For video progress updates
        if (isImmediateVideoConsult && patientListId) {
            dispatch(
                patientListsActionCreators.getUserPatientList({
                    organisationId: orgId,
                    patientListId,
                }),
            );
        }

        if (onMessageSendSuccess !== undefined) {
            onMessageSendSuccess();
        }

        // If the form is inside a modal
        if (handleToggleModal !== undefined) {
            // Close the modal straight away & show success feedback if we're sending immediate Video Consult invite
            if (isImmediateVideoConsult) {
                handleToggleModal();
                renderModalSuccessFeedback();
                // Keep modal open and show the confirmation screen if we're using message patient OR scheduled Video Consult invite
            } else {
                setShowSuccess(true);
            }
        }
    };

    const clearBlurTimeout = (): void => {
        if (blurTimeout.current) {
            clearTimeout(blurTimeout.current);
        }
    };

    //#endregion Action functions

    //#region Mobile number input actions

    const handleMobileNumberChange = (
        e: ChangeEvent<HTMLInputElement>,
    ): void => {
        const value = e.target.value;

        setIsMobileFromPds(obfuscatedMobileNumber === value);
        setMobileNumberInputValue(value);
        setMobileNumberInputError("");
    };

    const validateMobileNumber = (
        validateEmptyMobile: boolean,
    ): string | null => {
        const mobileNumberToUse = mobileNumberInputValue.replace(/\s/g, "");
        const isMobileNumberValid = MobileNumberHelper.validatePhoneNumber(
            mobileNumberToUse,
            validateEmptyMobile,
            obfuscatedMobileNumber as string,
        );

        if (!isMobileNumberValid) {
            setMobileNumberInputError("Please enter a valid UK mobile number");
            return null;
        }

        return mobileNumberToUse;
    };

    const validateNumberOnBlur = (): void => {
        clearBlurTimeout();
        blurTimeout.current = window.setTimeout(() => {
            validateMobileNumber(false);
        }, 100);
    };

    //#endregion

    //#region Email functions

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;

        setEmailInputValue(value);
        setEmailInputError("");
    };

    const handleEmailPaste = (e: ClipboardEvent): void => {
        e.preventDefault();
        const text = e.clipboardData?.getData("Text") || "";

        setEmailInputValue(text.replace(/\s/g, ""));
        setEmailInputError("");
    };

    const validateEmail = (): string | null => {
        if (!EmailAddressHelper.isValidEmailAddress(emailInputValue)) {
            setEmailInputError("Please enter a valid email address");
            return null;
        }
        return emailInputValue;
    };

    const validateEmailOnBlur = (): void => {
        clearBlurTimeout();
        blurTimeout.current = window.setTimeout(() => {
            validateEmail();
        }, 100);
    };

    //#endregion Email functions

    //#region Message body functions

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const value = e.target.value;
        setMessageInputValue(value);
    };

    const handleSetTemplate = (template: MessageTemplateOption): void => {
        const templateMessage = `${messageGreeting}\n\n${template.body}\n\n${messageSignature}`;
        setMessageInputValue(templateMessage);
        setSelectedTemplate(template);
        setIsTemplateUsed(template.body.length > 0);

        setIsPatientResponseEnabled(
            template.isPatientResponseRequired || isPatientResponseEnabled,
        );
        setHidePatientResponseToggle(!!template.isPatientResponseRequired);

        setTemplateFiles(
            (template?.attachedDocuments || []).map(
                ({ documentId, fileSize, fileName }) => ({
                    id: documentId,
                    isFromTemplate: true,
                    file: {
                        name: fileName,
                        size: fileSize,
                        displaySize: `${(fileSize / (1024 * 1024)).toPrecision(
                            2,
                        )} MB`,
                        previewUrl: `/api/patientMessaging/template/message/${orgId}/${template.id}/attachment/${documentId}`,
                    },
                }),
            ),
        );
    };

    const finalMessageValidate = (): boolean => {
        const isTemplateComplete = !messageInputValue.includes(
            MessageTemplateTokens.FieldToken,
        );
        const isMessageBodyComplete =
            !messageInputValue.includes(emptySmsContent);

        if (
            !isTemplateComplete ||
            !isMessageBodyComplete ||
            !messageInputValue ||
            messageInputError
        ) {
            if (!messageInputError) {
                setMessageInputError("Please complete message text");
            }
            setMessageInputValidText("");
            return false;
        }
        return true;
    };

    //#endregion Message body functions

    //#region Patient response toggle functions

    const handlePatientResponseToggleChange = (
        e: ChangeEvent<HTMLInputElement>,
    ): void => {
        setIsPatientResponseEnabled(e.target.checked);
    };

    //#endregion Patient response toggle functions

    //#region Templates functions

    const toggleTemplateModal = (): void => {
        if (!isTemplateModalOpen) {
            const props = AnalyticsMapper.getMessageTemplateProps(
                selectedTemplate,
                accountState,
                analyticsState,
                lastMessageTemplateActionLocation,
                productSelected,
            );
            FlemingAnalyticsTracker.trackNewTemplateClicked({
                ...props,
                currentTemplate: selectedTemplate.title,
            });
        }
        setIsTemplateModalOpen((prevState) => !prevState);
    };

    //#endregion Templates functions

    const handleCheckMessageType = (): void => {
        if (messageType === MessageType.Email)
            dispatch(
                sendMessageActionCreators.changeMessageType(MessageType.Sms),
            );
    };

    //#region Render
    const renderModalSuccessFeedback = (): void => {
        toast(
            <Feedback title="Invitation sent" colour="success">
                <Text skinny>{`An ${
                    messageType === MessageType.Email ? "email" : "SMS"
                } invitation has been sent ${
                    patient ? `to ${patient.firstName}'s mobile` : ""
                }`}</Text>
            </Feedback>,
        );
    };

    const renderModalFailureFeedback = (error: string): void => {
        toast(
            <Feedback title="Invitation failed to send" colour="error">
                <Text skinny>{error}</Text>
            </Feedback>,
        );
    };

    const renderFormFields = (): JSX.Element => {
        const nonEditableContentOptions: NonEditableContentOptions = {
            isVideoConsult,
            patientResponseEnabled: isPatientResponseEnabled,
            numberOfDocumentsAttached: attachmentCount,
            organisationName: orgName,
        };

        if (
            doesPatientHaveScheduledAppointment(appointment) &&
            appointment.dateTimeStart !== null
        ) {
            nonEditableContentOptions.videoConsultDate = DateHelpers.formatDate(
                appointment.dateTimeStart,
                DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
            );
            nonEditableContentOptions.videoConsultTime = DateHelpers.formatTime(
                appointment.dateTimeStart,
                DateFormatOptions.TIME,
            );
        }

        return (
            <>
                {messageType === MessageType.Sms && (
                    <MobileInput
                        value={mobileNumberInputValue}
                        handleChange={handleMobileNumberChange}
                        handleBlur={validateNumberOnBlur}
                        error={mobileNumberInputError}
                        allowToggle={isVideoConsult}
                        isDummyPatient={isDummyPatient}
                        loadingStatus={loadingStatus}
                    />
                )}
                {messageType === MessageType.Email && (
                    <EmailInput
                        value={emailInputValue}
                        handleChange={handleEmailChange}
                        handlePaste={handleEmailPaste}
                        handleBlur={validateEmailOnBlur}
                        error={emailInputError}
                        allowToggle={isVideoConsult}
                        isDummyPatient={isDummyPatient}
                    />
                )}

                <TemplatesInput
                    isTemplateModalOpen={isTemplateModalOpen}
                    handleTemplateModalToggle={toggleTemplateModal}
                    isInFormModal={!!handleToggleModal}
                    templateType={messageTemplateType}
                    presetTemplatesList={displayedPresetTemplates}
                    customTemplatesList={customTemplates}
                    selectedTemplate={selectedTemplate}
                    onTemplateSelect={handleSetTemplate}
                    userDefaultTemplate={userDefaultTemplate}
                />

                <MessageInput
                    editableMessageBody={messageInputValue}
                    nonEditableMessageContent={Templates.getNonEditableContent(
                        nonEditableContentOptions,
                    )}
                    error={messageInputError}
                    validText={messageInputValidText}
                    handleChange={handleMessageChange}
                />

                {!isVideoConsult && (
                    <FileUploadInput
                        setIsFileUploading={setIsFileUploading}
                        setManuallyAttachedFileIds={setManuallyAttachedFileIds}
                        showFileUploadingMessage={showFileUploadingMessage}
                        templateAttachments={templateFiles}
                    />
                )}

                {!hidePatientReponseToggle && (
                    <PatientResponseToggle
                        handleChange={handlePatientResponseToggleChange}
                    />
                )}
            </>
        );
    };

    const renderComposeMessageModalContent = (): JSX.Element => {
        return (
            <form
                noValidate
                onSubmit={handleFormSubmit}
                data-testid="in-modal-send-message-form"
                id="send-message-form"
                aria-labelledby={SEND_MESSAGE_MODAL_TITLE_ID}
            >
                <ModalHeader tag="div" className="pb-0">
                    <Text
                        variant="subtitle"
                        as="h3"
                        colour="night"
                        props={{
                            className: "m-0 mb-3",
                            id: SEND_MESSAGE_MODAL_TITLE_ID,
                        }}
                    >
                        {isVideoConsult
                            ? `Invite ${patient?.firstName} to a ${
                                  appointment.dateTimeStart ? "scheduled " : ""
                              }Video Consult`
                            : `Message ${patient?.firstName}`}
                    </Text>

                    <FeedbackMessages
                        isVideoConsult={isVideoConsult}
                        appointment={appointment}
                    />
                </ModalHeader>
                <ModalBody className="bg-light">{renderFormFields()}</ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleToggleModal}
                        text="Cancel"
                        theme="secondary"
                        disabled={isMessageSending}
                    />
                    {isMessageSending ? (
                        <Button text="Sending..." disabled />
                    ) : (
                        <Button
                            type="submit"
                            text="Send"
                            disabled={shouldSubmitButtonBeDisabled}
                        />
                    )}
                </ModalFooter>
            </form>
        );
    };

    const trackAndRedirectToRecordView = useCallback(
        ({ useExamplePatient } = { useExamplePatient: false }) =>
            (evt: React.MouseEvent) => {
                evt.preventDefault();
                const redirectToRecordViewAnalytics = {
                    ...AnalyticsMapper.getRecordViewBaseAnalyticsProps(
                        account,
                        analytics,
                    ),
                    recordViewOrigin:
                        FlemingAnalyticsTracker.MedicalRecordViewOrigin
                            .PatientMessageSent,
                };

                if (useExamplePatient) {
                    FlemingAnalyticsTracker.trackRecordViewTryWithTestPatient(
                        redirectToRecordViewAnalytics,
                    );
                    history.push(
                        `${findBaseRoute(history.location.pathname)}${
                            ROUTES_EXTENSION.remoteRecordViewMedicalRecordTestPatient
                        }`,
                    );
                    return;
                }

                FlemingAnalyticsTracker.trackRecordViewRequestRecord(
                    redirectToRecordViewAnalytics,
                );

                history.push(
                    `${findBaseRoute(history.location.pathname)}${
                        ROUTES_EXTENSION.remoteRecordView
                    }`,
                    {
                        referredFrom: ROUTES.patient_message_history,
                    },
                );
            },
        [account, analytics, history],
    );

    // This is only for Message Patient, if video consult the modal will close after sending
    const renderSuccessMessageModalContent = (): JSX.Element => {
        const accuRxUserId = account.user?.accuRxUserId || "";
        const tourMode = getTourMode(accuRxUserId);
        const isDemoPatient = PatientHelper.isDemoPatient(
            patient.nhsNumber as string,
        );
        const isTourVisible =
            isDemoPatient &&
            (tourMode === TourTestPatient.Sms ||
                tourMode === TourTestPatient.SmsEnded);

        const recordViewNudgeCard = (
            <Card
                props={{ className: "mt-4" }}
                spacing={2}
                footer={
                    <div className="d-flex justify-content-between align-items-center flex-gap">
                        <Text
                            skinny
                            variant="link"
                            as="a"
                            props={{
                                style: {
                                    cursor: "pointer",
                                },
                                onClick: trackAndRedirectToRecordView({
                                    useExamplePatient: true,
                                }),
                                tabIndex: 0,
                                role: "link",
                            }}
                        >
                            Try with a test patient
                        </Text>
                        <Button
                            type="button"
                            theme="secondary"
                            text="Request GP record"
                            onClick={trackAndRedirectToRecordView()}
                        />
                    </div>
                }
            >
                <div className="d-flex align-items-center flex-gap">
                    <Icon
                        theme="Line"
                        name="Record"
                        size={4}
                        halo={{
                            luminosity: "high",
                            colour: "yellow",
                        }}
                    />
                    <Text
                        variant="label"
                        as="span"
                        props={{ className: "ml-1" }}
                    >
                        Request a summary of the patient's GP record
                    </Text>
                </div>
            </Card>
        );

        return (
            <>
                <ModalHeader tag="div">
                    <Text variant="subtitle" as="h3" colour="night" skinny>
                        Message sent
                        {patient?.firstName ? ` to ${patient?.firstName}` : ""}
                    </Text>
                </ModalHeader>
                <ModalBody className="bg-light">
                    {isTourVisible && (
                        <Feedback
                            title="Congratulations! You've sent a message"
                            colour="learning"
                            props={{ className: "mb-2" }}
                        >
                            <Text>
                                {
                                    "Check the phone you used to see how this message appears to patients"
                                }
                            </Text>
                        </Feedback>
                    )}
                    {/* We render this content only if messageText is not null, so when this component renders, lastSendMessageResponse.result.messageText will be truthy */}
                    {lastSendMessageResponse?.result?.messageText && (
                        <>
                            <MessagePreview
                                messageCopy={{
                                    sentTo: patient?.displayName || "Unknown",
                                    mobileNumber:
                                        obfuscatedMobileNumberAfterSend, // Message Patient only allows sms sending
                                    messageText:
                                        lastSendMessageResponse.result
                                            .messageText,
                                    sentBy: userFullName || undefined,
                                    dateTime: DateHelpers.formatTime(
                                        DateHelpers.getCurrentTimeStamp(),
                                        DateFormatOptions.TIME_DATE_SHORT,
                                    ),
                                }}
                                copyButtonVariant="primary"
                                copyButtonAnalyticsContext={
                                    CopyButtonAnalyticsContext.smsModal
                                }
                            />
                            {recordViewNudgeCard}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleToggleModal}
                        text="Done"
                        theme="secondary"
                    />
                </ModalFooter>
            </>
        );
    };

    if (!patient) return null;

    if (isModalOpen !== undefined && handleToggleModal !== undefined) {
        return (
            <Modal
                isOpen={isModalOpen}
                toggle={handleToggleModal}
                onOpened={handleCheckMessageType}
                backdrop="static"
                style={{
                    marginBottom: "6rem",
                    opacity: isTemplateModalOpen ? 0 : 1,
                    transitionProperty: "opacity, transform",
                }}
                data-testid="send-message-modal"
                aria-labelledby={SEND_MESSAGE_MODAL_TITLE_ID}
            >
                {!showSuccess && renderComposeMessageModalContent()}
                {showSuccess && renderSuccessMessageModalContent()}
            </Modal>
        );
    }

    return (
        <form
            noValidate
            onSubmit={handleFormSubmit}
            data-testid="in-page-send-message-form"
            id="send-message-form"
            aria-labelledby={SEND_MESSAGE_PAGE_TITLE_ID}
        >
            {renderFormFields()}
            {actionButtons && actionButtons(shouldSubmitButtonBeDisabled)}
        </form>
    );

    //#endregion Render
};

export default SendMessageForm;
