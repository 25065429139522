import * as UI from "@accurx/design";

type AlreadyComposingModalProps = {
    onConfirm: () => void;
    onCancel: () => void;
};

export const AlreadyComposingModal = ({
    onConfirm,
    onCancel,
}: AlreadyComposingModalProps) => {
    return (
        <UI.Ds.Modal open={true} onOpenChange={() => onCancel()}>
            <UI.Ds.Modal.Section>
                <UI.Flex flexDirection="column" gap="2">
                    <UI.Ds.Modal.Title>
                        Would you like to replace your message with the
                        template?
                    </UI.Ds.Modal.Title>
                    <UI.Text>
                        Selecting a template will replace the message you've
                        already started writing.
                    </UI.Text>
                    <UI.Flex justifyContent="flex-end" gap="2">
                        <UI.Button
                            theme="secondary"
                            type="button"
                            aria-label="Cancel replacing message with template"
                            onClick={() => onCancel()}
                            text="Cancel"
                        />
                        <UI.Button
                            theme="primary"
                            type="button"
                            onClick={() => onConfirm()}
                            text="Replace with template"
                        />
                    </UI.Flex>
                </UI.Flex>
            </UI.Ds.Modal.Section>
        </UI.Ds.Modal>
    );
};
