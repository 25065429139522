import { ReactNode } from "react";

import { FeatureName } from "@accurx/auth";
import { Hooks } from "@accurx/design";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { Layout } from "app/layout/Layout";

import {
    UnifiedLayout,
    UnifiedMobileLayout,
    UnifiedTabletLayout,
} from "./UnifiedLayout/UnifiedLayout";

export const LayoutController = ({ children }: { children?: ReactNode }) => {
    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );
    const { size } = Hooks.useViewportSize();
    const isMobile = ["xs", "sm"].includes(size);
    const isTablet = "md" === size;

    if (isUnifiedNavigationEnabled) {
        if (isMobile) {
            return <UnifiedMobileLayout>{children}</UnifiedMobileLayout>;
        } else if (isTablet) {
            return <UnifiedTabletLayout>{children}</UnifiedTabletLayout>;
        } else {
            return <UnifiedLayout>{children}</UnifiedLayout>;
        }
    }

    return <Layout>{children}</Layout>;
};
