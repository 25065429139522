import { ComponentProps } from "react";

import * as UI from "@accurx/design";

import { StyledInfoBoxIcon } from "./InfoBoxIcon.styles";

export const InfoBoxIcon = ({
    name,
    colour = "night",
    theme = "Line",
}: Pick<ComponentProps<typeof UI.Icon>, "name" | "colour" | "theme">) => {
    return (
        <StyledInfoBoxIcon>
            <UI.Icon name={name} colour={colour} theme={theme} />
        </StyledInfoBoxIcon>
    );
};
