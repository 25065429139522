import React, { MouseEvent } from "react";

import { Button, FormFieldWrapper, Input, StackPanel } from "@accurx/design";
import { Log, NhsNumberHelpers, SharedConstants } from "@accurx/shared/dist";
import { Redirect, RouteComponentProps } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import {
    IMessagePracticeRequest,
    IOrganisation,
    IUserResponse,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { PatientDetails } from "app/clinicianconversation/patientDetails/PatientDetails";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { AnalyticsState } from "app/sessionAnalytics/sessionAnalyticsReducer";
import * as MessageHelper from "shared/MessageHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES } from "shared/Routes";
import { findBaseRoute, getStringQuery } from "shared/RoutesHelper";

import type { AccountState } from "../account/AccountState.types";
import {
    GENERAL_TEMPLATES,
    MessagePracticeUseCase,
    MessageTemplate,
    MessageTemplateGroup,
    RECORD_VIEW_TEMPLATES,
} from "../clinicianconversation/conversation/templates.helper";
import * as FileUploadActions from "../fileUpload/FileUploadActions";
import FileUpload from "../fileUpload/FileUploadContainer";
import * as FileUploadStore from "../fileUpload/FileUploadReducer";
import * as SearchForPatientStore from "../searchForPatient/SearchForPatientReducer";
import * as SelectProductActions from "../selectProduct/SelectProductActions";
import type { SelectProductState } from "../selectProduct/SelectProductState.types";
import {
    StyledTextAreaGroup,
    StyledTextareaAutosize,
} from "./MessagePractice.styles";
import * as MessagePracticeActions from "./MessagePracticeActions";
import * as MessagePracticeStore from "./MessagePracticeReducer";
import MessageSent from "./messageSent/MessageSentComponent";

const getMessageTemplates = (): MessageTemplateGroup[] => {
    return [...GENERAL_TEMPLATES, ...RECORD_VIEW_TEMPLATES];
};

//#region local state definition
interface MessagePracticeLocalState {
    PatientToken: string;
    PatientIdentifier: string;
    PracticeCode: string;
    MessageBody: string;
    ValidMessageBody: boolean;
    MessageErrorMessage: string;
    ShowDropdown: boolean;
    SelectedTemplateDisplay: string;
    selectedTemplateGroup: MessageTemplateGroup | null;
    selectedTemplate: MessageTemplate | null;
    ExternalEmailAttachmentIds: Array<string>;
    ShowFileUploadingMessage: boolean;
}

interface MessageValid {
    isValid: boolean;
}
//#endregion

export type MessagePracticeProps = {
    user: IUserResponse;
} & MessagePracticeStore.MessagePracticeState & // SendMessage expects logged in user, so send in non-null user in props rather than getting nullable user from account state.
    FileUploadStore.FileUploadState & {
        account: AccountState;
    } & {
        analytics: AnalyticsState;
    } & typeof SelectProductActions.actionCreators &
    typeof MessagePracticeActions.actionCreators &
    typeof FileUploadActions.actionCreators &
    SearchForPatientStore.SearchPatientState &
    SelectProductState &
    RouteComponentProps<Record<string, string | undefined>>;

class MessagePractice extends React.Component<
    MessagePracticeProps,
    MessagePracticeLocalState
> {
    SelectTemplateDefaultDisplay = "Select a template";

    constructor(props: MessagePracticeProps) {
        super(props);
        this.state = {
            PatientToken: "",
            PatientIdentifier: "",
            PracticeCode: "",
            MessageBody: "",
            ValidMessageBody: true,
            MessageErrorMessage: "",
            ShowDropdown: false,
            SelectedTemplateDisplay: this.SelectTemplateDefaultDisplay,
            selectedTemplateGroup: null,
            selectedTemplate: null,
            ExternalEmailAttachmentIds: new Array<string>(),
            ShowFileUploadingMessage: false,
        };
    }

    componentDidMount(): void {
        this.setState({
            PatientToken: this.getPatientToken,
            PatientIdentifier: this.getNhsNumber,
            PracticeCode: this.getPracticeCode,
            MessageBody: this.createMessagePlaceholder(),
            ValidMessageBody: this.checkMessageTextAndSetState(
                this.createMessagePlaceholder(),
            ).isValid,
        });

        this.props.resetFileUpload();
        const useCase = getStringQuery(
            this.props.location.search,
            "use-case",
        ) as MessagePracticeUseCase | null;
        const category = getStringQuery(this.props.location.search, "category");

        if (useCase && useCase !== MessagePracticeUseCase.GeneralMessage) {
            this.setSelectedTemplateWithMessageType(useCase);
        } else if (category !== null) {
            const template = getStringQuery(
                this.props.location.search,
                "template",
            );
            this.setSelectedTemplateWithCategory(category, template);
        }
    }

    private setSelectedTemplateWithMessageType(
        useCase: MessagePracticeUseCase,
    ) {
        const template = getMessageTemplates()
            .map((tg) =>
                tg.TemplateList.map((t) => ({ template: t, group: tg })),
            )
            .reduce((all, list) => all.concat(list), [])
            .find((t) => t.template.SelectedForUseCase === useCase);

        if (template) {
            this.setTemplate(template.template, template.group);
        }
    }

    private setSelectedTemplateWithCategory(
        category: string,
        template: string | null,
    ) {
        // Filter all template lists and individual templates to find one that
        // matches the query parameters. fallsback to using the first template in
        // the list, or 'No Template'
        const availableTemplates = getMessageTemplates()
            .filter((t) => t.Category === category)
            .map((tg) =>
                tg.TemplateList.map((t) => ({ template: t, group: tg })),
            )
            .reduce((all, list) => all.concat(list), []);

        const selectedTemplate = availableTemplates.find(
            (t) => t.template.Name === template,
        );

        if (selectedTemplate) {
            this.setTemplate(selectedTemplate.template, selectedTemplate.group);
        } else {
            this.setTemplate(
                availableTemplates[0].template,
                availableTemplates[0].group,
            );
        }
    }

    componentDidUpdate(
        prevProps: MessagePracticeProps,
        prevState: MessagePracticeLocalState,
    ): void {
        if (this.props.lastFileUploaded !== prevProps.lastFileUploaded) {
            if (this.props.lastFileUploaded !== null) {
                const allAttachments =
                    this.state.ExternalEmailAttachmentIds.concat(
                        this.props.lastFileUploaded.id,
                    );
                this.setState(
                    { ExternalEmailAttachmentIds: allAttachments },
                    () =>
                        this.checkMessageTextAndSetState(
                            this.state.MessageBody,
                        ),
                );
                this.trackAttachFile(true);
            }
            this.setState({ ShowFileUploadingMessage: false });
        }

        if (
            prevProps.lastFileUploadedHasErrors === false &&
            this.props.lastFileUploadedHasErrors === true
        ) {
            this.trackAttachFile(false);
        }

        if (this.props.lastFileRemoved !== prevProps.lastFileRemoved) {
            if (this.props.lastFileRemoved !== null) {
                const attachments =
                    this.state.ExternalEmailAttachmentIds.filter(
                        (id) => id !== this.props.lastFileRemoved,
                    );
                this.setState({ ExternalEmailAttachmentIds: attachments }, () =>
                    this.checkMessageTextAndSetState(this.state.MessageBody),
                );
            }
        }

        if (this.state.ShowDropdown && !prevState.ShowDropdown) {
            this.trackTemplateDropdownOpened();
        }
    }

    componentWillUnmount(): void {
        if (this.getHasSendMessageSuccess) {
            this.resetPatientAndProductChoice();
        }
        this.props.resetFileUpload();
    }

    //#region Rendering

    public render() {
        if (this.props.user === null) {
            Log.error("MessagePracticeComponent expects non-null user prop.");
            return <Redirect to="/" />;
        }

        if (!this.getPatient) {
            return <Redirect to={this.props.searchPatientOrigin} />;
        }

        if (this.getHasSendMessageSuccess) {
            return this.messagePracticeResults();
        } else {
            return this.renderMessagePractice();
        }
    }

    //  only called when getHasSendMessageSuccess is true, so we know that there's a result
    private messagePracticeResults() {
        const patient = this.getPatient;
        if (patient === null) {
            return null;
        }

        if (patient.practiceCode === null) {
            Log.error(
                "MessagePracticeComponent - MessagePracticeResults does not expect a null practice",
            );
            return null;
        }

        return (
            <MessageSent
                patient={patient}
                resetProductChoice={this.searchNewPatient}
            />
        );
    }

    private getPatientInfo() {
        const patient = this.getPatient;
        return {
            nhsNumber: patient?.nhsNumber ?? undefined,
            firstName: patient?.firstName ?? undefined,
            lastName: patient?.familyName ?? undefined,
            dateOfBirth: patient?.dateOfBirth ?? undefined,
            gender: patient?.gender ?? undefined,
        };
    }

    private renderMessagePractice() {
        const category = getStringQuery(this.props.location.search, "category");
        // if a record view template has been pre-selected before reaching this page, users only see record view templates.
        // Otherwise, we show all templates apart from record view
        // (so they never see all categories at once)
        // the only template we pre-select using url parameters is `record-view` `opt-in`, hence the specificity here
        // this whole page is being deprecated soon, the new version handles templates more elegantly.
        const templatesToRender =
            category === "record-view"
                ? getMessageTemplates().filter(
                      (t) => t.Category === "record-view",
                  )
                : getMessageTemplates().filter(
                      (t) => t.Category !== "record-view",
                  );

        const templatesDropdownItems = templatesToRender.map((section) => (
            <React.Fragment key={section.SectionHeading + " Fragment"}>
                <DropdownItem
                    key={section.SectionHeading}
                    style={{
                        color: "rgb(3,124,124)",
                    }}
                    className="font-weight-bold dropdown-item"
                    disabled={true}
                >
                    {section.SectionHeading}
                </DropdownItem>
                {section.TemplateList.map((template) => {
                    return (
                        <DropdownItem
                            className="dropdown-item col-sm-12"
                            key={template.Title}
                            onClick={() => {
                                this.setTemplate(template, section);
                                this.toggleDropdown();
                                this.trackTemplateSelected(template.Title);
                            }}
                        >
                            {template.Title}
                        </DropdownItem>
                    );
                })}
            </React.Fragment>
        ));

        return (
            <React.Fragment>
                <StackPanel
                    orientation="horizontal"
                    horizontalContentAlignment="space-between"
                    verticalContentAlignment="top"
                >
                    <div className="col-12 col-md-8 h-100">
                        <PageHeader
                            type={PageHeaderType.PatientPage}
                            title={`Message ${this.getPatientFirstName()}'s GP practice`}
                            displayPatientInfo={false}
                        />
                        {this.renderPracticeDetails()}
                        {this.renderSubject()}
                        <div className="row">
                            <div className="form-group col-sm-12  font-weight-bold">
                                <label className="col-form-label font-weight-bold">
                                    Templates
                                </label>
                                <Dropdown
                                    isOpen={this.state.ShowDropdown}
                                    toggle={() => this.toggleDropdown()}
                                >
                                    <DropdownToggle
                                        className="col-sm-12 text-left"
                                        color="light"
                                        caret
                                    >
                                        {this.state.SelectedTemplateDisplay}
                                    </DropdownToggle>
                                    <DropdownMenu
                                        className="col-sm-12"
                                        style={{
                                            overflowY: "scroll",
                                            height: "12rem",
                                        }}
                                    >
                                        {category === null && (
                                            <DropdownItem
                                                className="dropdown-item col-sm-12"
                                                onClick={() => {
                                                    this.setTemplateText(
                                                        "",
                                                        "Select a template",
                                                    );
                                                    this.toggleDropdown();
                                                }}
                                            >
                                                No template
                                            </DropdownItem>
                                        )}
                                        {templatesDropdownItems}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <form
                            noValidate
                            onSubmit={(e) => {
                                this.handleMessagePracticePress(e);
                            }}
                        >
                            <div className="row">
                                {this.renderMessageInput()}
                            </div>

                            <div className="row justify-content-between mx-0 mt-2">
                                {/* Accept list, max files and max size should be kept in sync with server*/}
                                <FileUpload
                                    maxFiles={4}
                                    maxSize={3}
                                    noDrag={true}
                                    organisationId={
                                        this.props.account.selectedOrganisation
                                    }
                                    accept={SharedConstants.FileUploadTypes}
                                    onUploadFile={
                                        this.props.uploadEmailAttachment
                                    }
                                    onFileRemove={this.props.removeFile}
                                    onDialogOpen={this.onDialogOpen}
                                />
                                {this.state.ShowFileUploadingMessage && (
                                    <div className="font-weight-normal text-info mt-1">
                                        Attachment is currently uploading,
                                        please try again in a few moments...
                                    </div>
                                )}

                                {this.props.isMessageSending ? (
                                    <Button
                                        type="button"
                                        text="Sending..."
                                        disabled
                                    />
                                ) : (
                                    <Button
                                        type="submit"
                                        text="Send"
                                        disabled={!this.state.ValidMessageBody}
                                    />
                                )}
                            </div>

                            {this.getHasMessagePracticeFailed && (
                                <div className="col-sm-12 text-center mt-2">
                                    <div className="text-danger mt-1">
                                        Sorry your message failed to send!
                                        Please try again
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="col-4 d-none d-md-flex h-100 pt-4">
                        <PatientDetails
                            organisationId={
                                this.props.account.selectedOrganisation
                            }
                            patient={this.getPatientInfo()}
                            displayPatientProfileButton={false}
                            displayMessagePatientButton={false}
                        />
                    </div>
                </StackPanel>
            </React.Fragment>
        );
    }

    private renderPracticeDetails() {
        const patient = this.getPatient;
        if (patient === null) {
            return;
        }
        const practiceValue =
            patient.practiceName ||
            `Organisation with ODS code ${patient.practiceCode}`;
        return (
            <FormFieldWrapper label="Selected practice" className="mb-2">
                <Input value={`Practice: ${practiceValue}`} disabled />
            </FormFieldWrapper>
        );
    }

    private renderSubject() {
        const patient = this.getPatient;
        if (patient === null) {
            return;
        }
        const subjectValue = `Regarding ${
            patient.displayName
        }, NHS no. ${NhsNumberHelpers.formatNhsNumber(
            patient.nhsNumber as string,
        )}`;
        return (
            <FormFieldWrapper label="Subject" className="mb-2">
                <Input value={subjectValue} disabled />
            </FormFieldWrapper>
        );
    }

    private renderMessageInput() {
        return (
            <div className="form-group col-sm-12 mb-0 font-weight-bold">
                <label className="col-form-label font-weight-bold">
                    Message text
                </label>
                <StyledTextAreaGroup>
                    <StyledTextareaAutosize
                        required
                        autoFocus
                        disabled={this.props.isMessageSending}
                        value={this.state.MessageBody}
                        onChange={(e) => this.updateMessageText(e)}
                        placeholder={"Please enter a message"}
                        data-testid="message-body"
                    />
                    <StyledTextareaAutosize
                        value={this.getOrganisation}
                        disabled
                    />
                </StyledTextAreaGroup>
                {!this.state.ValidMessageBody && (
                    <div className="font-weight-normal text-danger mt-2 float-right">
                        {this.state.MessageErrorMessage}
                    </div>
                )}
            </div>
        );
    }

    private createMessagePlaceholder(): string {
        const headerPlaceholder = this.generateGreeting();
        const footerPlaceholder = MessageHelper.generateSignature(
            this.props.user,
        );
        const messageBody = "***** INSERT YOUR MESSAGE HERE *****";
        return (
            headerPlaceholder +
            "\n\n" +
            messageBody +
            "\n\n" +
            footerPlaceholder
        );
    }

    //#endregion Rendering

    //#region functions
    private updateMessageText: React.FormEventHandler<HTMLTextAreaElement> = (
        event,
    ) => {
        const messageText = event.currentTarget.value;
        this.checkMessageTextAndSetState(messageText);
        this.setState({ MessageBody: messageText });
    };

    private checkMessageTextAndSetState(textToCheck: string): MessageValid {
        if (textToCheck.length <= 2) {
            this.setState({
                ValidMessageBody: false,
                MessageErrorMessage: `Please add a message`,
            });
            return { isValid: false };
        }

        this.setState({
            ValidMessageBody: true,
            MessageErrorMessage: "",
        });
        return { isValid: true };
    }

    private setTemplate = (
        template: MessageTemplate,
        templateGroup?: MessageTemplateGroup,
    ) =>
        this.setTemplateText(
            template.Body,
            template.Title,
            template,
            templateGroup,
        );

    private setTemplateText = (
        body: string,
        title: string,
        template?: MessageTemplate,
        category?: MessageTemplateGroup,
    ) => {
        const templateMessage =
            this.generateGreeting() +
            "\n\n" +
            body +
            "\n\n" +
            MessageHelper.generateSignature(this.props.user);
        const isValid = !templateMessage.includes("*****");
        this.setState({
            MessageBody: templateMessage,
            ValidMessageBody: isValid,
            MessageErrorMessage: isValid
                ? ""
                : "Please complete the template message",
            ShowDropdown: false,
            SelectedTemplateDisplay: title,
            selectedTemplateGroup: category || null,
            selectedTemplate: template || null,
        });
    };

    private toggleDropdown = () => {
        this.setState((state) => {
            return { ShowDropdown: !state.ShowDropdown };
        });
    };

    private handleMessagePracticePress = (
        e: React.FormEvent<HTMLFormElement>,
    ) => {
        e.preventDefault();

        const patient = this.getPatient;
        if (patient === null) {
            return;
        }

        if (this.props.isUploading) {
            this.setState({
                ShowFileUploadingMessage: true,
            });
            return;
        }

        const messageInfoForRequest: IMessagePracticeRequest = {
            patientToken: this.state.PatientToken,
            nhsNumber: this.state.PatientIdentifier,
            practiceCode: this.state.PracticeCode,
            messageBody: `${this.state.MessageBody}\n${this.getOrganisation}`,
            organisationId: this.props.account.selectedOrganisation,
            externalEmailAttachmentIds: this.state.ExternalEmailAttachmentIds,
        };

        const useCase = getStringQuery(
            this.props.location.search,
            "use-case",
        ) as MessagePracticeUseCase | null;

        const analyticsLegacyArgs = {
            TemplateUsed:
                this.state.SelectedTemplateDisplay !==
                this.SelectTemplateDefaultDisplay,
            template: this.state.SelectedTemplateDisplay,
            fileTypes: this.props.files
                .map((value) => value.file.name.split(".").pop())
                .join(", "),
            enteredWithUseCase: useCase,
        };

        const analyticsProps = {
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                this.props.account,
                this.props.analytics,
            ),
            templateName: this.state.selectedTemplate?.Title ?? undefined,
            templateGroup:
                this.state.selectedTemplateGroup?.SectionHeading ?? undefined,
            withTemplate:
                this.state.SelectedTemplateDisplay !==
                this.SelectTemplateDefaultDisplay,
            withAttachment: this.props.files && this.props.files.length > 0,
            countAttachment: this.props.files ? this.props.files.length : 0,
            origin: "", // Populated in action
            appOrigin: "MessagePracticeLegacy",
            recipientOdsCode: this.state.PracticeCode,
            hasError: false, // Populated in action
            isReply: false,
            countParticipants: 1,
            usedKeyboardShortcut: false,
            organisationNationalCode: this.currentOrg?.nationalCode || null,
            isMultiFactorAuthActive: this.props.user.isSetupFor2Fa || false,
            isMultiFactorAuthEnabled: this.props.user.is2FAed || false,
            isPostPatientMessage: false,
            isTestPatient: false,
        };

        this.props.sendPracticeMessage(
            messageInfoForRequest,
            analyticsProps,
            analyticsLegacyArgs,
        );
    };

    // reset patient & product state
    private resetPatientAndProductChoice = () => {
        this.props.resetProductType();
        this.props.resetProductChoice();
    };

    private searchNewPatient = () => {
        this.resetPatientAndProductChoice();

        if (this.props.searchPatientOrigin === ROUTES.patient_lists) {
            this.props.history.push(
                findBaseRoute(this.props.history.location.pathname),
            );
        } else {
            this.props.history.push(this.props.searchPatientOrigin);
        }
    };

    private generateGreeting = (): string => {
        const patient = this.getPatient;
        if (patient === null || patient.practiceCode === null) {
            return "";
        }

        return (
            `Hi ${patient.practiceName || patient.practiceCode} - ` +
            `this is regarding ${
                patient.displayName
            } (NHS no ${NhsNumberHelpers.formatNhsNumber(
                patient.nhsNumber as string,
            )}) who is registered at your practice`
        );
    };

    private trackTemplateDropdownOpened() {
        const analyticsProps = {
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                this.props.account,
                this.props.analytics,
            ),
        };

        FlemingAnalyticsTracker.trackMessagePracticeTemplateDropdownOpened(
            analyticsProps,
        );
    }

    private trackTemplateSelected(template: string) {
        const analyticsProps = {
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                this.props.account,
                this.props.analytics,
            ),
            template: template,
        };

        FlemingAnalyticsTracker.trackMessagePracticeTemplateSelected(
            analyticsProps,
        );
    }

    private onDialogOpen = (e: MouseEvent<HTMLButtonElement>): void => {
        this.trackAttachButtonClicked();
    };

    private trackAttachButtonClicked() {
        const analyticsProps = {
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                this.props.account,
                this.props.analytics,
            ),
            searchPatientOrigin: this.props.searchPatientOrigin,
        };

        FlemingAnalyticsTracker.trackMessagePracticeClickAttachButton(
            analyticsProps,
        );
    }

    private trackAttachFile(successfullyAttached: boolean) {
        const analyticsProps = {
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                this.props.account,
                this.props.analytics,
            ),
            searchPatientOrigin: this.props.searchPatientOrigin,
            successfullyAttached: successfullyAttached,
        };

        FlemingAnalyticsTracker.trackMessagePracticeAttachedFile(
            analyticsProps,
        );
    }

    private getPatientFirstName(): string {
        const patient = this.getPatient;
        return (patient && patient.firstName) || "the patient";
    }

    //#endRegion functions

    //#region Selectors

    private get getPatient() {
        return PatientHelper.getPatient(this.props.lastResponse);
    }

    private get getNhsNumber() {
        const patient = this.getPatient;
        return (patient && patient.nhsNumber) || "Unknown";
    }

    private get getPracticeCode() {
        const patient = this.getPatient;
        return (patient && patient.practiceCode) || "Unknown";
    }

    private get getPatientToken() {
        return PatientHelper.getPatientToken(this.props.lastResponse);
    }

    private currentOrg = this.props.user.organisations.find(
        (x: IOrganisation) =>
            x.orgId === this.props.account.selectedOrganisation,
    );

    private get getOrganisation() {
        if (this.currentOrg) {
            return this.currentOrg.organisationName;
        }
        return "Unknown";
    }

    private get getHasSendMessageSuccess() {
        const { lastMessagePracticeResponse } = this.props;
        return (
            (lastMessagePracticeResponse?.success &&
                lastMessagePracticeResponse.result) ||
            false
        );
    }

    private get getHasMessagePracticeFailed() {
        return (
            (this.props.lastMessagePracticeResponse &&
                !this.props.lastMessagePracticeResponse.success) ||
            false
        );
    }

    //#endregion Selectors
}

export default MessagePractice;
