import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useViewportSize } from "domains/inbox/hooks/util";
import { formatPatientName } from "domains/inbox/util/format/patientDisplayInfo";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { OpenInEmrButton } from "../OpenInEmrButton/OpenInEmrButton";
import { BackButton } from "./BackButton";
import { ConversationActionsFeedback } from "./ConversationActionsFeedback";
import { PatientDetails } from "./PatientDetails";
import {
    StyledBackButtonContainer,
    StyledContainer,
} from "./PatientHeader.styles";
import { PatientMatchingActions } from "./PatientMatchingActions";

type PatientHeaderProps = {
    conversation: Conversation;
    onClickSearchForPatient: () => void;
    onClickBack: (() => void) | undefined;
};

export const PatientHeaderNew = ({
    conversation,
    onClickSearchForPatient,
    onClickBack,
}: PatientHeaderProps) => {
    const size = useViewportSize();
    const isMobileSize = size === "xs" || size === "sm";
    const patientId = conversation.regardingPatientId;
    const patientMatchState = getPatientMatchState(conversation);
    const patient = usePatient({ patientId });

    const nhsNumber = patient?.externalIds.find(
        (externalId) => externalId.type === "NhsNumber",
    )?.value;

    const patientName = formatPatientName({
        firstName: patient?.firstName,
        familyName: patient?.familyName,
    });

    const showBackButton = !!onClickBack && isMobileSize;

    return (
        <UI.Flex flexDirection="column">
            <UI.Item flex="1">
                <StyledBackButtonContainer>
                    {!patient && showBackButton && (
                        <BackButton onClickBack={onClickBack} />
                    )}
                    <ConversationActionsFeedback
                        conversation={conversation}
                        onClickSearchForPatient={onClickSearchForPatient}
                    />
                </StyledBackButtonContainer>
            </UI.Item>

            {patient && (
                <StyledContainer>
                    <UI.Flex gap="1">
                        {showBackButton && (
                            <BackButton onClickBack={onClickBack} />
                        )}
                        <UI.Item flex="1">
                            <UI.Flex
                                justifyContent="space-between"
                                flexWrap="wrap"
                                gap="1"
                            >
                                <PatientDetails
                                    nhsNumber={nhsNumber}
                                    dateOfBirth={patient.dateOfBirth}
                                    name={patientName}
                                    ageYear={patient.ageYear}
                                    gender={patient.gender}
                                />
                                <UI.Flex gap="1">
                                    {!!patientMatchState &&
                                        conversation.status === "Open" && (
                                            <PatientMatchingActions
                                                onClickSearchForPatient={
                                                    onClickSearchForPatient
                                                }
                                                patientMatchState={
                                                    patientMatchState
                                                }
                                                conversation={conversation}
                                            />
                                        )}
                                    {!!patientId && (
                                        <OpenInEmrButton
                                            patientId={patientId}
                                        />
                                    )}
                                </UI.Flex>
                            </UI.Flex>
                        </UI.Item>
                    </UI.Flex>
                </StyledContainer>
            )}
        </UI.Flex>
    );
};
