import { useRef } from "react";

import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { Conversation, TeamSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useIsEllipsisActive } from "domains/inbox/hooks/util/useIsEllipsisActive";

import { StyledBadge, StyledBadgeText } from "./AssigneeBadge.styles";

export type AssigneeBadgeProps = {
    assignee: Conversation["assignee"];
};

export const AssigneeBadge = ({ assignee }: AssigneeBadgeProps) => {
    const loadedAssignee = useAssignee(assignee);

    const nameRef = useRef<HTMLElement>(null);
    const isEllipsisActive = useIsEllipsisActive(nameRef.current);

    if (
        !loadedAssignee ||
        (loadedAssignee as TeamSummary).type === "ToAssignPatientMessaging"
    )
        return null;

    return (
        <>
            <UI.VisuallyHidden as="span">
                assigned to {loadedAssignee.displayName}
                {", "}
            </UI.VisuallyHidden>
            <WithTooltip
                isEnabled={isEllipsisActive}
                content={
                    <UI.Text skinny variant="note" colour="white">
                        {loadedAssignee.displayName}
                    </UI.Text>
                }
                props={{
                    root: { $withEllipsis: true },
                    content: {
                        align: "start",
                        side: "bottom",
                    },
                }}
            >
                <StyledBadge
                    color="greyscale"
                    radius="round"
                    className="conversation-preview-badge"
                    aria-hidden="true"
                >
                    <UI.Icon name="Assign" theme="Fill" size={2} />
                    <StyledBadgeText ref={nameRef}>
                        {loadedAssignee.displayName}
                    </StyledBadgeText>
                </StyledBadge>
            </WithTooltip>
        </>
    );
};
