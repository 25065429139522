import { SendMessageMetadata } from "@accurx/api/clinician-messaging";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import { PatientThreadPatientDetails } from "./FlemingDtos";

export type Attachment = {
    id: number;
    displayName: string;
};

export type PatientInfo = {
    patientId: string;
    nhsNumber?: string;
    firstName?: string;
    lastName?: string;
    prefixName?: string;
    dateOfBirth?: string;
    gender?: string;
};

export type ClinicianMessage = {
    messageId: string;
    senderDisplayName?: string;
    senderEmailAddress: string;
    body: string;
    previousMessages?: string;
    dateSent: string;
    dateRead?: string;
    attachments: Attachment[];
};

export type ParticipantWorkspace = {
    workspaceId: number;
    workspaceName: string;
    participants: Participant[];
};

export type ClinicianConversation = {
    id: number;
    caseReference: string;
    subject: string;
    messages: ClinicianMessage[];
    patient: PatientInfo;
    participantWorkspaces: ParticipantWorkspace[];
    individualParticipants: Participant[];
    urlId: string;
    workspaceConversationId: string | null;
};

export type ClinicianConversationSummary = {
    id: number;
    lastMessage: ClinicianMessage;
    urlId: string;
};

export type ConversationSummary = {
    id: string;
    caseReference: string;
    patientId: string;
    patientDisplayName: string;
    patient: PatientThreadPatientDetails;
    assignee?: User;
    from: string;
    to: string;
    preview: string;
    lastUpdated: string;
};

export type User = {
    email: string;
    name: string;
};

export type Participant = {
    displayName?: string;
    emailAddress: string;
};

export type ISendMessageRequest = {
    organisationId: number;
    conversationId: number;
    textContent: string;
    externalEmailAttachmentIds: Array<string>;
    metadata: SendMessageMetadata;
};

export type ISendMessageResponse = {
    message: ClinicianMessage;
};

export type IFirstMessageRequest = {
    patientToken: string;
    nhsNumber: string;
    practiceCode: string;
    messageBody: string;
    organisationId: number | null;
    externalEmailAttachmentIds: Array<string>;
    metadata: SendMessageMetadata;
};

export type IFirstMessageResponse = {
    id: number;
    caseReference: string;
    subject: string;
    messages: ClinicianMessage[];
    patient: PatientInfo;
    participantWorkspaces: ParticipantWorkspace[];
    individualParticipants: Participant[];
    urlId: string | null;
    workspaceConversationId: string | null;
};

const ENDPOINTS = {
    clinicianConversationUrlId: "/api/clinicianmessaging/conversation/:urlId",
    clinicianConversationWorkspaceId:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversation/:workspaceConversationId",
    clinicianConversations:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations",
    clinicianRecentConversations:
        "/api/clinicianmessaging/messagepractice/recent-conversations",
    clinicianConversationSend: "/api/clinicianmessaging/conversation/send",
    clinicianConversationAttachmentUrlId:
        "/api/clinicianmessaging/conversation/:conversationUrlId/attachment/:attachmentId/:file",
    clinicianConversationAttachmentWorkspaceConversationId:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversation/:workspaceConversationId/attachment/:attachmentId/:fileName",
    clinicianMessagePractice: "/api/clinicianmessaging/MessagePractice",
} as const;

export class ClinicianConversationApi {
    static async getConversation(
        urlId: string,
        organisationId: number | null,
    ): Promise<IWrappedResult<ClinicianConversation>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianConversationUrlId,
                params: { urlId },
                query: `?organisationId=${organisationId}`,
            }),
        );
    }
    static async getConversationByWorkspaceConversationId(
        workspaceConversationId: string,
        workspaceId: string,
    ): Promise<IWrappedResult<ClinicianConversation>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianConversationWorkspaceId,
                params: {
                    workspaceConversationId: workspaceConversationId.toString(),
                    workspaceId: workspaceId.toString(),
                },
            }),
        );
    }

    static async getRecentConversations(
        organisationId: number,
        patientToken: string,
    ): Promise<IWrappedResult<ClinicianConversationSummary[]>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianRecentConversations,
                query: `?organisationId=${organisationId}&patientToken=${patientToken}`,
            }),
        );
    }

    static async getConversations(
        workspaceId: number,
        pageSize: number,
        userId?: string,
        updatedBeforeDate?: string,
    ): Promise<IWrappedResult<{ conversations: ConversationSummary[] }>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianConversations,
                params: { workspaceId: workspaceId.toString() },
            }),
            {
                assigneeUserId: userId,
                updatedBeforeDate,
                isArchived: false,
                limit: pageSize,
            },
        );
    }

    static async sendMessage(
        sendMessageRequest: ISendMessageRequest,
    ): Promise<IWrappedResult<ISendMessageResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianConversationSend,
            }),
            sendMessageRequest,
        );
    }

    static getDownloadUrl(
        conversationUrlId: string,
        attachment: { id: string; displayName: string },
        currentOrgId: number | null,
    ): string {
        const fileExtension = attachment.displayName.split(".").pop() || "";
        const file = `attachment.${fileExtension}`;
        return getApiEndpoint({
            path: ENDPOINTS.clinicianConversationAttachmentUrlId,
            params: {
                conversationUrlId,
                attachmentId: attachment.id.toString(),
                file,
            },
            query: `?organisationId=${currentOrgId}`,
        }).urlFinal;
    }

    static getDownloadUrlByWorkspaceConversationId({
        workspaceConversationId,
        workspaceId,
        attachmentId,
        attachmentName,
    }: {
        workspaceConversationId: number;
        workspaceId: number;
        attachmentId: string;
        attachmentName: string;
    }): string {
        return getApiEndpoint({
            path: ENDPOINTS.clinicianConversationAttachmentWorkspaceConversationId,
            params: {
                workspaceConversationId: workspaceConversationId.toString(),
                workspaceId: workspaceId.toString(),
                attachmentId,
                fileName: attachmentName,
            },
        }).urlFinal;
    }

    static async firstMessage(
        firstMessageDto: IFirstMessageRequest,
    ): Promise<IWrappedResult<IFirstMessageResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianMessagePractice,
            }),
            firstMessageDto,
        );
    }
}
