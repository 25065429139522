import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Feedback, Flex, Text } from "@accurx/design";
import { getNextUrlOrDefault } from "@accurx/navigation";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    useCreateWorkspaceMutation,
} from "@accurx/workspace-management";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { StyledCardItem, StyledWorkspaceDetails } from "../Layout.styles";
import { UserflowIds } from "../constants";
import {
    StyledActionSection,
    StyledBadge,
    StyledLeftHandSide,
    StyledMembersText,
    StyledMembersWrapper,
} from "./WorkspaceItem.styles";

type ExampleWorkspaceProps = {
    name: string;
    specialtyId: number;
    isRecommended: boolean;
};

export const ExampleWorkspaceItem = ({
    name,
    specialtyId,
    isRecommended,
}: ExampleWorkspaceProps) => {
    const { defaultWorkspaceId } = useCurrentWorkspace();
    const track = useAnalytics();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const { mutate } = useCreateWorkspaceMutation({
        onSuccess: ({ workspace, failedWorkspaceCreation }) => {
            if (
                typeof failedWorkspaceCreation === "number" ||
                !workspace.orgId
            ) {
                setIsLoading(false);
                toast(
                    <Feedback
                        colour="error"
                        title="There was an error. Please refresh and try again."
                    />,
                );
                return;
            }

            toast(
                <Feedback
                    colour="success"
                    title={`Created ${workspace.organisationName}`}
                />,
            );

            history.replace(
                getNextUrlOrDefault(
                    history.location.search,
                    generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                        workspaceId: workspace.orgId,
                    }),
                ),
            );
        },
        onError: () => {
            setIsLoading(false);
            toast(
                <Feedback
                    colour="error"
                    title="There was an error. Please refresh and try again."
                />,
            );
        },
    });

    const [showModal, setShowModal] = useState(false);

    const handleCreate = () => {
        track("WorkspaceCreate Button Click", {
            hasError: false,
            errorReason: [],
            isExample: true,
            withDescription: false,
            isRecommended,
        });

        if (window.Intercom) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.Intercom(
                "trackEvent",
                "click-confirm-join-or-create-workspace",
            );
        }

        mutate({
            organisationId: defaultWorkspaceId,
            workspaceName: name,
            specialties: [specialtyId],
            isRecommended,
            isExample: true,
        });
        setIsLoading(true);
    };

    return (
        <>
            <StyledCardItem as="li">
                <StyledLeftHandSide>
                    <Text variant="label" skinny>
                        {name}
                    </Text>
                    {isRecommended ? (
                        <StyledBadge color="green">
                            Related to your specialty
                        </StyledBadge>
                    ) : null}
                </StyledLeftHandSide>
                <StyledWorkspaceDetails>
                    <StyledMembersWrapper>
                        <StyledMembersText skinny>0 members</StyledMembersText>
                    </StyledMembersWrapper>
                    <StyledActionSection>
                        <Button
                            theme="secondary"
                            text="Join"
                            aria-label={`Join ${name} workspace`}
                            onClick={() => setShowModal(true)}
                        />
                    </StyledActionSection>
                </StyledWorkspaceDetails>
            </StyledCardItem>

            <Ds.Modal open={showModal} onOpenChange={setShowModal}>
                <Ds.Modal.Section>
                    <Flex flexDirection="column" gap="2">
                        <Ds.Modal.Title>Create {name}</Ds.Modal.Title>

                        <Text skinny>
                            As the first member of the workspace you will join
                            as an admin. This means you can manage other
                            colleagues’ access to the workspace.
                        </Text>
                    </Flex>
                </Ds.Modal.Section>
                <Ds.Modal.Section>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            text="Cancel"
                            onClick={() => setShowModal(false)}
                        />
                        <Button
                            text={
                                isLoading
                                    ? "Creating workspace..."
                                    : "Create workspace"
                            }
                            aria-label={`Create ${name} workspace`}
                            onClick={handleCreate}
                            disabled={isLoading}
                            data-userflow-id={
                                UserflowIds.joinWorkspace.createButton
                            }
                        />
                    </Flex>
                </Ds.Modal.Section>
            </Ds.Modal>
        </>
    );
};
