import { NativeApi } from "@accurx/native";

import { createEndpoint } from "../createEndpoint";

export type AppointmentType = "FaceToFace" | "Telephone";

export type MutateSelfBookConfigurationPayload = {
    validationSuccess: boolean;
    appointmentType: string;
    slotType?: string;
    specificClinician?: {
        clinicianGroupId: number;
        clinicianNames: string[];
    }[];
    availabilityPeriod: string;
    crossOrgBooking?: {
        targetOdsCode: string;
        targetOdsName: string;
    };
};

export const mutateSelfBookConfiguration = createEndpoint<
    unknown,
    MutateSelfBookConfigurationPayload
>("MutateSelfBookConfiguration", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateSelfBookConfiguration",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
});
