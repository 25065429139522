import isNil from "lodash/isNil";

import {
    BasePatientThreadItem,
    PatientThreadContentType,
    PatientThreadItemTransport,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { BaseConversationItem } from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationItem,
    UnknownConversationItem,
} from "shared/concierge/conversations/types/item.types";

import {
    mapAppointmentRequest,
    mapFloreyResponseItem,
    mapGenericNoteItem,
    mapLabelTagItem,
    mapLinkItem,
    mapPatientEmailItem,
    mapPatientNHSAppMessageItem,
    mapPatientSmsItem,
    mapPatientTriageItem,
    mapStateChangeItem,
    mapUnimplementedConversationItem,
    mapUserNoteItem,
    tryFindUnknownPatientThreadItem,
} from "./conversationItemMappers";
import { conversationItemMappingLog } from "./conversationItemMappers/conversationItem.helpers";

/**
 * Maps from an external "Ticket" DTO version of a conversation item
 * to an internal domain view - A "Conversation item"
 * @param ticketItem - An external "Patient thread item" DTO
 * @returns A conversation item
 */
export function mapTicketItemToConversationItem(
    ticketItem: PatientThreadItemTransport,
): ConversationItem | undefined {
    if (isNil(ticketItem) || isNil(ticketItem.type)) {
        return undefined;
    }

    switch (ticketItem.type) {
        case PatientThreadContentType.SMS:
            return mapWithFallback(
                mapPatientSmsItem,
                ticketItem.sms,
                ticketItem.type,
            );
        case PatientThreadContentType.PatientEmail:
            return mapWithFallback(
                mapPatientEmailItem,
                ticketItem.patientEmail,
                ticketItem.type,
            );
        case PatientThreadContentType.NhsAppMessage:
            return mapWithFallback(
                mapPatientNHSAppMessageItem,
                ticketItem.nhsAppMessage,
                ticketItem.type,
            );
        case PatientThreadContentType.FloreyResponseNote:
            return mapWithFallback(
                mapFloreyResponseItem,
                ticketItem.floreyResponseNote,
                ticketItem.type,
            );
        case PatientThreadContentType.PatientTriageRequestNote:
            return mapWithFallback(
                mapPatientTriageItem,
                ticketItem.patientThreadTriageRequestNote,
                ticketItem.type,
            );
        case PatientThreadContentType.PatientAppointmentRequestNote:
            return mapWithFallback(
                mapAppointmentRequest,
                ticketItem.patientThreadPatientAppointmentRequestNote,
                ticketItem.type,
            );
        case PatientThreadContentType.StateChange:
            return mapWithFallback(
                mapStateChangeItem,
                ticketItem.ticketStateChangeNote,
                ticketItem.type,
            );
        case PatientThreadContentType.Note:
            return mapWithFallback(
                mapUserNoteItem,
                ticketItem.note,
                ticketItem.type,
            );
        case PatientThreadContentType.SmsLinkNote:
            return mapWithFallback(
                mapLinkItem,
                ticketItem.smsLinkNote,
                ticketItem.type,
            );
        case PatientThreadContentType.PatientEmailLinkNote:
            return mapWithFallback(
                mapLinkItem,
                ticketItem.patientEmailLinkNote,
                ticketItem.type,
            );
        case PatientThreadContentType.GenericNote:
            return mapWithFallback(
                mapGenericNoteItem,
                ticketItem.genericNote,
                ticketItem.type,
            );
        case PatientThreadContentType.LabelTag:
            return mapWithFallback(
                mapLabelTagItem,
                ticketItem.conversationLabel,
                ticketItem.type,
            );

        default:
            return mapWithFallback<
                BasePatientThreadItem,
                UnknownConversationItem
            >(
                // We don't know how to map this ticket item to a conversation
                // so we throw an error by default
                () => {
                    throw new Error("Unknown item type");
                },
                tryFindUnknownPatientThreadItem(ticketItem),
                ticketItem.type,
            );
    }
}

function mapWithFallback<
    TSource extends BasePatientThreadItem,
    TItem extends BaseConversationItem,
>(
    mapper: (x: TSource) => TItem | undefined,
    ticketItem: TSource | null | undefined,
    itemType: PatientThreadContentType,
): TItem | UnknownConversationItem | undefined {
    try {
        if (!ticketItem) {
            conversationItemMappingLog({
                message: "Unrecognized item content or null data",
                itemType,
            });
            return;
        }

        return mapper(ticketItem);
    } catch (e) {
        const errorMessage = typeof e === "string" ? e : e.message;

        // Mapping failed, try to map to unknown item at least
        const unknown = ticketItem
            ? mapUnimplementedConversationItem(ticketItem)
            : undefined;

        if (unknown) {
            conversationItemMappingLog({
                message: `${errorMessage}, using fallback`,
                itemType: ticketItem?.type,
                itemId: ticketItem?.id,
            });
            return unknown;
        }

        conversationItemMappingLog({
            message: errorMessage,
            itemType: ticketItem?.type,
            itemId: ticketItem?.id,
        });
    }
}
