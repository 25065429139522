import React from "react";

import { StackPanel, Text } from "@accurx/design";

import {
    getAppointmentRequestAttachmentUrl,
    getAttachmentUrl,
    getPatientTriageAttachmentUrl,
} from "app/workspaceConversations/utils/conversation.utils";
import {
    AppointmentRequestConversationItem,
    ConversationItem,
    FloreyResponseConversationItem,
    PatientResponseItem,
    PatientSingleResponseConversationItem,
    PatientTriageRequestConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { appointmentRequestThreadItemSummaryText } from "./Items/AppointmentRequestConversationThreadItem/AppointmentRequestConversationThreadItem";
import { AttachmentLink } from "./Items/AttachmentLink/AttachmentLink";
import { failedDeliveryReceiptThreadItemSummaryText } from "./Items/FailedDeliveryReceiptThreadItem/FailedDeliveryReceiptThreadItem";
import { floreyResponseThreadItemSummaryText } from "./Items/FloreyReponseNoteThreadItem/FloreyResponseNoteThreadItem";
import { patientMessageThreadItemSummaryText } from "./Items/MessageConversationItem/MessageConversationItem";
import { patientEmailThreadItemSummaryText } from "./Items/PatientEmailThreadItem/PatientEmailThreadItem";
import { patientSingleResponseThreadItemSummaryText } from "./Items/PatientSingleResponseThreadItem/PatientSingleResponseThreadItem";
import { patientTriageRequestNoteThreadItemSummaryText } from "./Items/PatientTriageRequestNoteThreadItem/PatientTriageRequestNoteThreadItem";
import { stateChangeThreadItemSummaryText } from "./Items/StateChangeThreadItem/StateChangeThreadItem";
import { videoConsultLinkThreadItemSummaryText } from "./Items/VideoConsultLinkThreadItem/VideoConsultThreadItem";

/**
 * Provide a single summary line of text content to represent the item in a list of conversations.
 * Is generally expected to be truncated/cut off by UI component overflow, rather than needing to
 * have a fixed maximum length returned here.
 */
export const getConversationItemSummaryTextContent = (
    item: ConversationItem,
): string => {
    switch (item.contentType) {
        case "PatientSms":
            return patientMessageThreadItemSummaryText(item);
        case "PatientEmail":
            return patientEmailThreadItemSummaryText(item);
        case "NhsAppMessage":
            return patientMessageThreadItemSummaryText(item);
        case "Note":
            return "Note for colleagues";
        case "PatientSingleResponse":
            return patientSingleResponseThreadItemSummaryText(item);
        case "FloreyResponseNote":
            return floreyResponseThreadItemSummaryText(item);
        case "PatientTriageRequestNote":
            return patientTriageRequestNoteThreadItemSummaryText(item);
        case "PatientAppointmentRequestNote":
            return appointmentRequestThreadItemSummaryText(item);
        case "StateChange":
            return stateChangeThreadItemSummaryText(item);
        case "FailedDeliveryReceiptLink":
            return failedDeliveryReceiptThreadItemSummaryText(item);
        case "VideoConsultLink":
            return videoConsultLinkThreadItemSummaryText(item);
        case "GenericNote":
            return "Generic Note update";
        case "LabelTag":
            return "Outcome";
        case "Unknown":
            return "New conversation update";
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = item;
            return "New conversation update";
    }
};

const renderPatientResponseItem = ({
    response,
    organisationId,
    patientId,
    hideAttachmentsLabel,
    item,
}: {
    response: PatientResponseItem;
    organisationId: number;
    patientId: string | undefined | null;
    hideAttachmentsLabel?: boolean;
    item: ConversationItemWithPatientResponseSection;
}): JSX.Element => {
    const getAttachmentLink = (attachmentId: string) => {
        switch (item.contentType) {
            case "PatientAppointmentRequestNote":
                return getAppointmentRequestAttachmentUrl({
                    attachmentId,
                    organisationId,
                    patientId,
                });
            case "PatientTriageRequestNote":
                return getPatientTriageAttachmentUrl({
                    attachmentId,
                    organisationId,
                });
            case "FloreyResponseNote":
            case "PatientSingleResponse":
                return getAttachmentUrl({
                    attachmentId,
                    organisationId,
                    patientId,
                });
        }
    };

    return (
        <div>
            {response.question && (
                <Text variant={response.displayQuestionBold ? "label" : "body"}>
                    {`${response.question}`}
                </Text>
            )}
            {response.answer && <Text>{response.answer}</Text>}
            {response.attachmentIds && response?.attachmentIds.length > 0 && (
                <>
                    {/* Patient appointment requests already have an attachment label as part
                        of the question. So no need to display the label twice. */}
                    {!hideAttachmentsLabel && <Text skinny>Attachments:</Text>}
                    {response.attachmentIds.map((attachmentId) => (
                        <React.Fragment key={`attachment-${attachmentId}`}>
                            <AttachmentLink
                                url={getAttachmentLink(attachmentId)}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}
        </div>
    );
};

type ConversationItemWithPatientResponseSection =
    | PatientTriageRequestConversationItem
    | PatientSingleResponseConversationItem
    | FloreyResponseConversationItem
    | AppointmentRequestConversationItem;

const NO_RESPONSE_TEXT = "No response from patient";

export const renderPatientResponseSections = ({
    item,
    organisationId,
    patientId,
    hideAttachmentsLabel,
    noResponseSection = NO_RESPONSE_TEXT,
}: {
    item: ConversationItemWithPatientResponseSection;
    organisationId: number;
    patientId: string | undefined | null;
    hideAttachmentsLabel?: boolean;
    noResponseSection?: string;
}): JSX.Element[] => {
    const DEFAULT_NO_RESPONSE_SECTION = {
        responses: [
            {
                displayQuestionBold: true,
                question: "Missing",
                answer: noResponseSection,
                attachmentIds: null,
            },
        ],
    };

    const sections =
        item.sections.length > 0
            ? item.sections
            : [DEFAULT_NO_RESPONSE_SECTION];

    return sections.map((section, sectionIndex) => (
        <React.Fragment key={`section-${sectionIndex}`}>
            {sectionIndex > 0 && <hr key={`spacer-${sectionIndex}`} />}
            <StackPanel gutter={1}>
                {section.responses.map((resp, respIndex) => (
                    <React.Fragment
                        key={`response-${sectionIndex}-${respIndex}`}
                    >
                        {renderPatientResponseItem({
                            response: resp,
                            organisationId,
                            patientId,
                            hideAttachmentsLabel,
                            item,
                        })}
                    </React.Fragment>
                ))}
            </StackPanel>
        </React.Fragment>
    ));
};
