import { TeamSummary, UserSummary } from "@accurx/concierge/types";

type AssigneeType = "None" | "Team" | "UserSelf" | "UserOther";

export const getAssigneeType = (
    assignee?: TeamSummary | UserSummary,
): AssigneeType => {
    if (!assignee) return "None";

    if ("type" in assignee) {
        return assignee.type === "ToAssignPatientMessaging" ? "None" : "Team";
    } else {
        return assignee.isCurrentUser ? "UserSelf" : "UserOther";
    }
};
