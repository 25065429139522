import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/clinicianMessaging";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { actions } from "domains/concierge/internal/store";

export const useClinicianMessagingUnreadsPoller = () => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    const fetchFn = useCallback(
        async ({ isInitialFetch }) => {
            try {
                const unreadItemsSummaries = await api.getUnreadSummaries({
                    workspaceId,
                });
                dispatch(
                    actions.conversations.populateClinicianMessagingUnreadItems(
                        {
                            unreadItemsSummaries,
                        },
                    ),
                );
                dispatch(
                    actions.queries.setQueryStatus({
                        name: "clinicianMessagingUnreads",
                        value: { status: "success" },
                    }),
                );
            } catch (error) {
                if (isInitialFetch) {
                    dispatch(
                        actions.queries.setQueryStatus({
                            name: "clinicianMessagingUnreads",
                            value: { status: "error", error: error as Error },
                        }),
                    );
                }
                throw error;
            }
        },
        [dispatch, workspaceId],
    );

    return usePoller({
        name: "ClinicianMessagingUnreadsPoller",
        refreshRate: 60_000, // 60 seconds,
        fetchFn,
    });
};
