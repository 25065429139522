/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
 *                                                                                           *
 *                               Auto-Generated File Warning                                 *
 *                                                                                           *
 *     This file is generated automatically by C# unit tests, do not edit manually!          *
 *                                                                                           *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * All current feature flags known by the server.
 * @readonly
 * @enum {string}
 */
export enum FeatureName {
    None = "None",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: False
     **/
    AccumailComposeNationalDirectoryDev = "AccumailComposeNationalDirectoryDev",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    AccumailContactBrowserNationalDirectory = "AccumailContactBrowserNationalDirectory",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    AccumailContactBrowserServerSideSearch = "AccumailContactBrowserServerSideSearch",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: False
     **/
    AccumailDevOnlyUserEmailTemplates = "AccumailDevOnlyUserEmailTemplates",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: False
     **/
    AccuMailDirectorySearchPrompt = "AccuMailDirectorySearchPrompt",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    AccuMailNewPracticeWelcome = "AccuMailNewPracticeWelcome",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: False
     **/
    AccumailPaginatedApi = "AccumailPaginatedApi",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    AccumailPaginatedApiV1 = "AccumailPaginatedApiV1",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: False
     **/
    AccuMailToolbarContactBrowser = "AccuMailToolbarContactBrowser",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    AccumailV1ContactBrowser = "AccumailV1ContactBrowser",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    BatchGskFlorey = "BatchGskFlorey",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    FlemingPatientResponse = "FlemingPatientResponse",

    /**
     * FIXED LEGACY FEATURE
     *
     * Is enabled: True
     **/
    InboxConsistentFolders = "InboxConsistentFolders",

    /**
     * Description: Accumail - Allow amber list
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccumailAllowAmberList = "AccumailAllowAmberList",

    /**
     * Description: accuMail - Assign
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuMailAssign = "AccuMailAssign",

    /**
     * Description: accuMail - Attach First
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuMailAttachFirst = "AccuMailAttachFirst",

    /**
     * Description: Accumail - Enable national directory for compose screen
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccumailComposeNationalDirectory = "AccumailComposeNationalDirectory",

    /**
     * Description: accuMail - Opt-out of message GP conversation screen
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    AccuMailDisableMessageGPConversation = "AccuMailDisableMessageGPConversation",

    /**
     * Description: accuMail - Disable web initiated
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuMailDisableWebInitiated = "AccuMailDisableWebInitiated",

    /**
     * Description: accuMail - Generate reply link
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    AccuMailGenerateReplyLink = "AccuMailGenerateReplyLink",

    /**
     * Description: Message GP Prompting Experiment
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccumailMessageGPPromptingExperiment = "AccumailMessageGPPromptingExperiment",

    /**
     * Description: accuMail - Onboarding prompt tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuMailOnboardingPromptTooltip = "AccuMailOnboardingPromptTooltip",

    /**
     * Description: accuMail - Rollout tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuMailRolloutTooltip = "AccuMailRolloutTooltip",

    /**
     * Description: Accumail - Sandbox inbox in the web
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccumailSandboxWebInbox = "AccumailSandboxWebInbox",

    /**
     * Description: Internal Use Only: Shows the embedded WebView compose alongside the existing WPF compose
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    AccurxDesktopEmbeddedComposeParallelMode = "AccurxDesktopEmbeddedComposeParallelMode",

    /**
     * Description: Enables the Accurx Desktop embedded Unified Inbox, replacing the WPF Inbox in the Toolbar
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    AccurxDesktopEmbeddedUnifiedInbox = "AccurxDesktopEmbeddedUnifiedInbox",

    /**
     * Description: Internal use only: enables the Accurx Desktop embedded Unified Inbox in parallel with the WPF Inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccurxDesktopEmbeddedUnifiedInboxParallelMode = "AccurxDesktopEmbeddedUnifiedInboxParallelMode",

    /**
     * Description: Patient Triage Feature flag
     *
     * Is in modules: Patient Triage
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AccurxPlusPatientTriage = "AccurxPlusPatientTriage",

    /**
     * Description: accuRx Plus - Sms Plus Limits Warning
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AccuRxPlusSmsPlusLimitsWarning = "AccuRxPlusSmsPlusLimitsWarning",

    /**
     * Description: Enables the Accurx Web embedded Unified Inbox, replacing the Accurx Web Inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    AccurxWebEmbeddedUnifiedInbox = "AccurxWebEmbeddedUnifiedInbox",

    /**
     * Description: Active Directory Integration - Login
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ActiveDirectoryIntegrationLogin = "ActiveDirectoryIntegrationLogin",

    /**
     * Description: Acute Global Custom Floreys
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AcuteGlobalCustomFloreys = "AcuteGlobalCustomFloreys",

    /**
     * Description: Web - Allow multiple SMS attachments
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    AllowMultipleWebSmsAttachments = "AllowMultipleWebSmsAttachments",

    /**
     * Description: Appointment Reminders Clinician column
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentReminderClinicianName = "AppointmentReminderClinicianName",

    /**
     * Description: Appointment Reminders Alpha
     *
     * Is in modules: Batch Messaging and Appointment Reminders
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersAlpha = "AppointmentRemindersAlpha",

    /**
     * Description: Appointment Reminders Enable to set a reason for cancelling the appointment
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersCancellationReason = "AppointmentRemindersCancellationReason",

    /**
     * Description: Appointment Reminders Enable Clinician Compare on Scraping
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersEnableClinicianCompareOnScraping = "AppointmentRemindersEnableClinicianCompareOnScraping",

    /**
     * Description: Appointment Reminders send One Week Reminder After Deadline
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersSendOneWeekReminderAfterDeadline = "AppointmentRemindersSendOneWeekReminderAfterDeadline",

    /**
     * Description: Appointment Reminders for Integrated Clinics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersUhl = "AppointmentRemindersUhl",

    /**
     * Description: Appointment Reminders use clinic codes to create new reminder
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AppointmentRemindersUseClinicCodes = "AppointmentRemindersUseClinicCodes",

    /**
     * Description: accuMail - Attach Patient Record
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    AttachPatientRecord = "AttachPatientRecord",

    /**
     * Description: Audit response of GetOrganisationMetadata request to S1 for support case
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AuditS1OrgansationMetadataResponse = "AuditS1OrgansationMetadataResponse",

    /**
     * Description: Web - Auto-Approve Users when matched PDS Mobile number message threshold passed.
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    AutoApproveUsersWhenMatchedPdsMobileNumberMessageThresholdPassed = "AutoApproveUsersWhenMatchedPdsMobileNumberMessageThresholdPassed",

    /**
     * Description: TRUSTS ONLY - Auto mark tickets for an org as done after 30 days of inaction. Clinical have only approved this for Trusts
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    AutoMarkAsDone = "AutoMarkAsDone",

    /**
     * Description: Batch Messaging Alpha
     *
     * Is in modules: Batch Messaging and Appointment Reminders, Web - Batch Messaging
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BatchMessagingAlpha = "BatchMessagingAlpha",

    /**
     * Description: Batch Messaging Florey
     *
     * Is in modules: Batch Messaging and Appointment Reminders, Web - Batch Messaging
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BatchMessagingFlorey = "BatchMessagingFlorey",

    /**
     * Description: Allow sending questionnaire and sms message batches to patients via NHS App - for national rollout
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    BatchNhsAppMessagingNationalRollout = "BatchNhsAppMessagingNationalRollout",

    /**
     * Description: Batch Self-book - Access for new Self-book Batch
     *
     * Is in modules: Self Book Batch, Web - Batch Messaging
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BatchSelfBook = "BatchSelfBook",

    /**
     * Description: Batch Self-book - Decline
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BatchSelfBookDecline = "BatchSelfBookDecline",

    /**
     * Description: Behavioural Change Alpha Group A
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BehaviouralChangeAlphaA = "BehaviouralChangeAlphaA",

    /**
     * Description: Behavioural Change Alpha Group B
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BehaviouralChangeAlphaB = "BehaviouralChangeAlphaB",

    /**
     * Description: Behavioural Change - Log shingles vaccine invitation opportunities
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BehaviouralChangeShinglesControl = "BehaviouralChangeShinglesControl",

    /**
     * Description: Behavioural Change - Shingles vaccine invitation prompt display
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BehaviouralChangeShinglesPrompt = "BehaviouralChangeShinglesPrompt",

    /**
     * Description: Behaviour Change - Inhaler RCT participant
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    BehaviourChangeInhalerRct = "BehaviourChangeInhalerRct",

    /**
     * Description: CaregiverInitiated
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    CaregiverInitiated = "CaregiverInitiated",

    /**
     * Description: Integration with CITO Document Store
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    CitoDocumentStoreIntegration = "CitoDocumentStoreIntegration",

    /**
     * Description: Enables the colitis activity questionnaire
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ColitisActivityFlorey = "ColitisActivityFlorey",

    /**
     * Description: Web - turns on collaborative features like assign and viewing teams and colleagues messages in WebInbox
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    CollaborativeWebInbox = "CollaborativeWebInbox",

    /**
     * Description: Community Global Custom Floreys
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    CommunityGlobalCustomFloreys = "CommunityGlobalCustomFloreys",

    /**
     * Description: Community Pharmacy Experiment 1
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    CommunityPharmacyExperiment1 = "CommunityPharmacyExperiment1",

    /**
     * Description: Community Pharmacy Experiment 2 - Accumail window
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    CommunityPharmacyExperiment2 = "CommunityPharmacyExperiment2",

    /**
     * Description: Compose Refresh National Roll Out
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    ComposeRefreshNationalRollOut = "ComposeRefreshNationalRollOut",

    /**
     * Description: Compose Refresh Pilot
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ComposeRefreshPilot = "ComposeRefreshPilot",

    /**
     * Description: Compose Refresh Quantitative Practices
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    ComposeRefreshQuantitative = "ComposeRefreshQuantitative",

    /**
     * Description: Content Editor
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ContentEditor = "ContentEditor",

    /**
     * Description: Content Entity Management
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ContentEntityManagement = "ContentEntityManagement",

    /**
     * Description: Create Global Custom Florey
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    CreateGlobalCustomFloreys = "CreateGlobalCustomFloreys",

    /**
     * Description: Deprecated - New Custom Florey question types
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    CustomFloreysNewQuestions = "CustomFloreysNewQuestions",

    /**
     * Description: Enables the Day Case Procedure florey for trust users only
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DayCaseProcedureFlorey = "DayCaseProcedureFlorey",

    /**
     * Description: DesktopInboxOrganisationSelector
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DesktopInboxOrganisationSelector = "DesktopInboxOrganisationSelector",

    /**
     * Description: DesktopOrganisationSelector
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DesktopOrganisationSelector = "DesktopOrganisationSelector",

    /**
     * Description: DesktopPracticeGroupAssignment
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DesktopPracticeGroupAssignment = "DesktopPracticeGroupAssignment",

    /**
     * Description: Desktop RudderStack Analytics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DesktopRudderStackAnalytics = "DesktopRudderStackAnalytics",

    /**
     * Description: DesktopScreenSearchPds [prototype only]
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    DesktopScreenSearchPds = "DesktopScreenSearchPds",

    /**
     * Description: Desktop Diagnostics On Timer
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    DesktopSendDiagnosticsOnTimer = "DesktopSendDiagnosticsOnTimer",

    /**
     * Description: Disables single send sms
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    DisableSingleSendSms = "DisableSingleSendSms",

    /**
     * Description: Display count of fragments when creating message
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    DisplayFragmentCountV2 = "DisplayFragmentCountV2",

    /**
     * Description: Enable modals that dissuade users from using My Workspace.
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    DissuadeFromUsingMyWorkspace = "DissuadeFromUsingMyWorkspace",

    /**
     * Description: Embedded PowerBI reporting
     *
     * Alternative names: EmbeddedReportingNewNavigation
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    EmbeddedPowerBiPOC = "EmbeddedPowerBiPOC",

    /**
     * Description: Eagerly load EMIS attachments (when EmisGPSoC enabled)
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    EmisGPSoCEagerlyLoadAttachments = "EmisGPSoCEagerlyLoadAttachments",

    /**
     * Description: EMIS GPSoC - GetMedicalRecord (do not enable)
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    EmisGPSoCGetMedicalRecord = "EmisGPSoCGetMedicalRecord",

    /**
     * Description: EMIS GPSoC via Server
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    EmisGPSoCServer = "EmisGPSoCServer",

    /**
     * Description: EmisPollCurrentPatientAlways
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    EmisPollCurrentPatientAlways = "EmisPollCurrentPatientAlways",

    /**
     * Description: Detailed Logging
     *
     * Scope: UserOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    EnableVerboseLogs = "EnableVerboseLogs",

    /**
     * Description: Expanded Patient List (1000)
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    ExpandedPatientList = "ExpandedPatientList",

    /**
     * Description: accuMail
     *
     * Alternative names: ExternalEmailUploadContactButtonTest
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ExternalEmail = "ExternalEmail",

    /**
     * Description: Allows default workspace users to create a Flexible Workspace.
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    FlexibleWorkspace = "FlexibleWorkspace",

    /**
     * Description: Florey
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    Florey = "Florey",

    /**
     * Description: Florey - accuRx Plus
     *
     * Is in modules: Florey Plus
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    FloreyAccuRxPlus = "FloreyAccuRxPlus",

    /**
     * Description: Florey - Alpha Testing (accuRx Users, Dummy Patients)
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    FloreyAlpha = "FloreyAlpha",

    /**
     * Description: Florey - Beta Testing (Selected Clinicians, Dummy Patients)
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    FloreyBeta = "FloreyBeta",

    /**
     * Description: Florey Builder
     *
     * Alternative names: FloreyBuilderAlpha
     *
     * Is in modules: Florey Plus
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    FloreyBuilder = "FloreyBuilder",

    /**
     * Description: Batch Self-book - Limit to 2 fragments
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    FragmentLimitBatchSelfBook = "FragmentLimitBatchSelfBook",

    /**
     * Description: Enable visibility of the Fragments dashboard
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    FragmentsDashboard = "FragmentsDashboard",

    /**
     * Description: GP Global Custom Floreys
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    GPGlobalCustomFloreys = "GPGlobalCustomFloreys",

    /**
     * Description: Manage Templates - enables template attachments for GP users
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    GPTemplateAttachments = "GPTemplateAttachments",

    /**
     * Description: Self-book cross org booking
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    HasCrossOrgBooking = "HasCrossOrgBooking",

    /**
     * Description: Enables the Headache florey for Trusts
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    HeadacheFlorey = "HeadacheFlorey",

    /**
     * Description: Web - hide Accurx preset message templates
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    HidePresetMessageTemplates = "HidePresetMessageTemplates",

    /**
     * Description: Web - Use Html Welcome Email
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    HtmlWelcomeEmail = "HtmlWelcomeEmail",

    /**
     * Description: Behaviour Change - Inhaler Reminders Experiment: Inbox folder banner
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    InhalerRemindersExperimentInbox = "InhalerRemindersExperimentInbox",

    /**
     * Description: Behaviour Change - Inhaler Reminders Experiment: Manage practice page
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    InhalerRemindersExperimentPage = "InhalerRemindersExperimentPage",

    /**
     * Description: Behaviour Change - Inhaler Reminders Experiment: Tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    InhalerRemindersExperimentTooltip = "InhalerRemindersExperimentTooltip",

    /**
     * Description: Web - Show integrated clinic lists
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    IntegratedClinicLists = "IntegratedClinicLists",

    /**
     * Description: Internal only for the development of self book configuration in webview
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    InternalOnlySelfBookConfigurationWebview = "InternalOnlySelfBookConfigurationWebview",

    /**
     * Description: Enables the IPSS questionaire
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    IPSSFlorey = "IPSSFlorey",

    /**
     * Description: Likely to Fail Numbers as Invalid in Batch
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    LikelyToFailMobilesAsInvalidInBatch = "LikelyToFailMobilesAsInvalidInBatch",

    /**
     * Description: Likely to Fail Numbers Warning in Desktop
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    LikelyToFailMobilesWarningInDesktop = "LikelyToFailMobilesWarningInDesktop",

    /**
     * Description: Enable logging of search patients metadata
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    LogSearchPatientsMetadata = "LogSearchPatientsMetadata",

    /**
     * Description: Displays version 1.1 of the message component
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    MessageComponentV1_1 = "MessageComponentV1_1",

    /**
     * Description: Multiuser Collaboration
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    MultiuserCollaboration = "MultiuserCollaboration",

    /**
     * Description: Navigation Team Experiment 1 - Approved users only
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    NavigationExperiment1 = "NavigationExperiment1",

    /**
     * Description: Navigation Team Experiment 2 - Approved users only
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    NavigationExperiment2 = "NavigationExperiment2",

    /**
     * Description: Navigation Team Experiment 3 - Includes unapproved users
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    NavigationExperiment3 = "NavigationExperiment3",

    /**
     * Description: Enable new Manage Organisation page
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    NewManageOrganisationPage = "NewManageOrganisationPage",

    /**
     * Description: Allow sending Self Book batch messages to patients via NHS App
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    NhsAppMessagingBatchSelfBook = "NhsAppMessagingBatchSelfBook",

    /**
     * Description: Nhs App Rollout tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    NhsAppRolloutTooltip = "NhsAppRolloutTooltip",

    /**
     * Description: Enable NHS login flow for patient portal
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    NhsLoginForPatientPortal = "NhsLoginForPatientPortal",

    /**
     * Description: Enable Outcome Recording
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    OutcomeRecording = "OutcomeRecording",

    /**
     * Description: Patient Initiated Inbox - Desktop Filters for Medical and Admin Requests
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedDesktopFiltersMedicalAdminUpgrade = "PatientInitiatedDesktopFiltersMedicalAdminUpgrade",

    /**
     * Description: Patient Initiated - Disable Feedback Form
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedDisableFeedbackForm = "PatientInitiatedDisableFeedbackForm",

    /**
     * Description: Enable use of the new patient initiated dynamic forms.
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedDynamicForms = "PatientInitiatedDynamicForms",

    /**
     * Description: Patient Initiated Inbox
     *
     * Is in modules: Patient Triage
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedInbox = "PatientInitiatedInbox",

    /**
     * Description: Patient Initiated - Disable NHS App
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedNhsAppDisable = "PatientInitiatedNhsAppDisable",

    /**
     * Description: Patient Initiated - NHS Login
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedNhsLogin = "PatientInitiatedNhsLogin",

    /**
     * Description: Patient Initiated - MSK Sussex custom forms
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedSussexMsk = "PatientInitiatedSussexMsk",

    /**
     * Description: Patient Initiated - Translations
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedTranslations = "PatientInitiatedTranslations",

    /**
     * Description: Patient Initiated - UHL Breast surgery custom forms
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedUhl = "PatientInitiatedUhl",

    /**
     * Description: Patient Initiated - UHL IBD custom forms
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedUhlIbd = "PatientInitiatedUhlIbd",

    /**
     * Description: Web - Enable Patient-led follow up notifications via email
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientInitiatedWebEmailNotification = "PatientInitiatedWebEmailNotification",

    /**
     * Description: PatientListShare
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    PatientListShare = "PatientListShare",

    /**
     * Description: Patient Single Response
     *
     * Alternative names: PatientSingleResponseWithImage
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientSingleResponse = "PatientSingleResponse",

    /**
     * Description: Patient Initiated - Ticket Created From Note
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientThreadCreateTicketFromNote = "PatientThreadCreateTicketFromNote",

    /**
     * Description: Connected Status
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    PatientThreadPushConnected = "PatientThreadPushConnected",

    /**
     * Description: SMS In Patient Thread
     *
     * Alternative names: SMSAdviceOnly
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    PatientThreadSMS = "PatientThreadSMS",

    /**
     * Description: Team Messaging
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientThreadTeamMessaging = "PatientThreadTeamMessaging",

    /**
     * Description: Patient Initiated Inbox - Split Disabling Medical and Admin
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageAllowAdminRequests = "PatientTriageAllowAdminRequests",

    /**
     * Description: Patient Triage - Dashboards
     *
     * Is in modules: Patient Triage
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageDashboards = "PatientTriageDashboards",

    /**
     * Description: Patient Triage - Live Assignment Dashboards
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageLiveAssignmentDashboards = "PatientTriageLiveAssignmentDashboards",

    /**
     * Description: Patient Triage - Reception Flow
     *
     * Is in modules: Patient Triage
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageReceptionFlow = "PatientTriageReceptionFlow",

    /**
     * Description: Patient Triage - Self Referral Setting
     *
     * Is in modules: Patient Triage
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageSelfReferralSetting = "PatientTriageSelfReferralSetting",

    /**
     * Description: Patient Initiated Inbox - Solicited Appointment Booking
     *
     * Is in modules: Patient Triage
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageSolicitedAppointmentBooking = "PatientTriageSolicitedAppointmentBooking",

    /**
     * Description: Patient Initiated Inbox - Solicited Appointment Booking Send Link
     *
     * Is in modules: Patient Triage
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PatientTriageSolicitedAppointmentBookingSendLink = "PatientTriageSolicitedAppointmentBookingSendLink",

    /**
     * Description: Pds search for Desktop Client [prototype only]
     *
     * Alternative names: PdsSearchDemographicsStagingOnlyForNow
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    PdsSearchDemographicsForDesktopClient = "PdsSearchDemographicsForDesktopClient",

    /**
     * Description: Allows perioperative care pilot feature for org
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    PerioperativeCarePilot = "PerioperativeCarePilot",

    /**
     * Description: Pharmacy Global Custom Floreys
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PharmacyGlobalCustomFloreys = "PharmacyGlobalCustomFloreys",

    /**
     * Description: PracticeGroups
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PracticeGroups = "PracticeGroups",

    /**
     * Description: Enables the Pre-appointment questionnaire
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PreAppointmentFlorey = "PreAppointmentFlorey",

    /**
     * Description: Enables the Procedure Reminder florey for trust users only
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ProcedureReminderFlorey = "ProcedureReminderFlorey",

    /**
     * Description: Patient triage free trial
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PTFreeTrialTooltip = "PTFreeTrialTooltip",

    /**
     * Description: Patient triage purchased by CCG
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    PTPurchasedByCcgTooltip = "PTPurchasedByCcgTooltip",

    /**
     * Description: Content Review
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ReceptionContentReview = "ReceptionContentReview",

    /**
     * Description: Content Search
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ReceptionStreaming = "ReceptionStreaming",

    /**
     * Description: Record Access - Beta
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    RecordAccessBeta = "RecordAccessBeta",

    /**
     * Description: Record Access No 2FA - for internal use only
     *
     * Scope: UserOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    RecordAccessNoPatient2FA = "RecordAccessNoPatient2FA",

    /**
     * Description: Record View - Florey copy
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RecordViewFloreyCopy = "RecordViewFloreyCopy",

    /**
     * Description: Record View - GP opted in to sharing records
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RecordViewGpOptIn = "RecordViewGpOptIn",

    /**
     * Description: Record View - Accumail Latest Consultations
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    RecordViewLatestConsultations = "RecordViewLatestConsultations",

    /**
     * Description: Record View - alternative optin tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RecordViewOptInTooltipAltCopy = "RecordViewOptInTooltipAltCopy",

    /**
     * Description: Record View - prompt GP opt in from web
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RecordViewPromptOptInWeb = "RecordViewPromptOptInWeb",

    /**
     * Description: Enables the Rectal Bleeding florey for Trusts
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RectalBleedingFlorey = "RectalBleedingFlorey",

    /**
     * Description: Enables Remote Monitoring Florey for GPs (requires Accurx Plus)
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    RemoteMonitoringFlorey = "RemoteMonitoringFlorey",

    /**
     * Description: Restrict archived users to re-join organisation
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    RestrictAutoJoinArchivedUsers = "RestrictAutoJoinArchivedUsers",

    /**
     * Description: Allow scheduling self-book links for the next working hour
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ScheduleSelfBookLinkNextWorkingHour = "ScheduleSelfBookLinkNextWorkingHour",

    /**
     * Description: Handle scraping of embargoed slots from S1
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    ScrapeChangeS1EmbargoedSlots = "ScrapeChangeS1EmbargoedSlots",

    /**
     * Description: Self-book - 12 week scrapes
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBook12WeekScrapes = "SelfBook12WeekScrapes",

    /**
     * Description: Self-book - 7 days expiry link duration
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBook7DaysExpiryLinks = "SelfBook7DaysExpiryLinks",

    /**
     * Description: Self book configuration in webview, version five
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookConfigurationWebviewV5 = "SelfBookConfigurationWebviewV5",

    /**
     * Description: Self-Book disable rate-limiting for EMIS scraping
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookDisableScrapeRateLimitingEmis = "SelfBookDisableScrapeRateLimitingEmis",

    /**
     * Description: Self-book enable specific clinician per booking
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookEnableSpecificClinicianPerBooking = "SelfBookEnableSpecificClinicianPerBooking",

    /**
     * Description: Self-Book enter flow change
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookEnterFlowChange = "SelfBookEnterFlowChange",

    /**
     * Description: Self-Book experiment banner based on trigger words
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentBannerOnTriggerWords = "SelfBookExperimentBannerOnTriggerWords",

    /**
     * Description: Self-Book overlay after coming back from prompt
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentBookingOverlayOnBackFromPrompt = "SelfBookExperimentBookingOverlayOnBackFromPrompt",

    /**
     * Description: Self-Book overlay to highlight booking button in compose
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentHighlightBookingButton = "SelfBookExperimentHighlightBookingButton",

    /**
     * Description: Self-Book experiment: inline prompt on trigger words
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentInlinePromptOnTriggerWords = "SelfBookExperimentInlinePromptOnTriggerWords",

    /**
     * Description: Self-Book experiment on compose screen
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentOnCompose = "SelfBookExperimentOnCompose",

    /**
     * Description: Self-Book experiment on new support article
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentSupportArticleInPopup = "SelfBookExperimentSupportArticleInPopup",

    /**
     * Description: Self-Book experiment to promote SB within the batch SB flow to Practice Managers
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentTargetedPromotionToPracticeMangers = "SelfBookExperimentTargetedPromotionToPracticeMangers",

    /**
     * Description: Self-Book experiment target users who never used individual self book
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentTargetedToolTipForIndividualSelfBook = "SelfBookExperimentTargetedToolTipForIndividualSelfBook",

    /**
     * Description: Self-Book experiment target users who haven't used SF in the past 3 months
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentTargetedToolTipForNotCurrentActiveUsers = "SelfBookExperimentTargetedToolTipForNotCurrentActiveUsers",

    /**
     * Description: Self-Book experiment target PCN super users
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentTargetedToolTipPcnSuperUsers = "SelfBookExperimentTargetedToolTipPcnSuperUsers",

    /**
     * Description: Self-Book experiment target all S1 users
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookExperimentTargetedToolTipS1 = "SelfBookExperimentTargetedToolTipS1",

    /**
     * Description: Patient Initiated Inbox - SelfBook Force IsOnboarded To False
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookForceIsOnboardedToFalse = "SelfBookForceIsOnboardedToFalse",

    /**
     * Description: Self-book - Appointment Scraping
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookFromCompose = "SelfBookFromCompose",

    /**
     * Description: Self-book - SelfBookSending links from compose
     *
     * Is in modules: Self Book Individual
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookFromComposeSendLink = "SelfBookFromComposeSendLink",

    /**
     * Description: Self-book handle duplicate clinician in S1
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookHandleDuplicateCliniciansInS1 = "SelfBookHandleDuplicateCliniciansInS1",

    /**
     * Description: Self-book ignore scraping on partial failure on Emis
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookIgnoreScrapingOnPartialFailureEmis = "SelfBookIgnoreScrapingOnPartialFailureEmis",

    /**
     * Description: Self-book (Inc-566) Verify Appointment Booking Post API Success
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookInc566VerifyAppointmentBookingPostApiSuccessV2 = "SelfBookInc566VerifyAppointmentBookingPostApiSuccessV2",

    /**
     * Description: Self-Book navigate patient to single entry page
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookNavigatePatientToSingleEntryPage = "SelfBookNavigatePatientToSingleEntryPage",

    /**
     * Description: Self-book new badge over add link button
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookNewBadgeOnDesktop = "SelfBookNewBadgeOnDesktop",

    /**
     * Description: Self-book persist structured clinicians
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookPersistStructuredClinicians = "SelfBookPersistStructuredClinicians",

    /**
     * Description: Self-Book prompt on send if contains trigger words
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookPromptOnSendOnTriggerWords = "SelfBookPromptOnSendOnTriggerWords",

    /**
     * Description: Improve scraping of clinicians in Emis
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookScrapeChangeEmisClinician = "SelfBookScrapeChangeEmisClinician",

    /**
     * Description: Self-book with a specific clinician
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookSpecificClinician = "SelfBookSpecificClinician",

    /**
     * Description: Self-Book enable setting time horizon
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SelfBookTimeHorizon = "SelfBookTimeHorizon",

    /**
     * Description: SEL Pilot - attempt send SMS messages via NHS App first
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    SendSmsViaNhsApp = "SendSmsViaNhsApp",

    /**
     * Description: Attach Documents
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    SmsAttachDocuments = "SmsAttachDocuments",

    /**
     * Description: SMS Direct Debit Setup tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SmsDirectDebitToolTip = "SmsDirectDebitToolTip",

    /**
     * Description: SMS plus ending tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    SmsPlusEndingTooltip = "SmsPlusEndingTooltip",

    /**
     * Description: Telephone Reminder Character Limit
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    TelephoneReminderCharLimit = "TelephoneReminderCharLimit",

    /**
     * Description: Thread active users
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    ThreadActiveUsers = "ThreadActiveUsers",

    /**
     * Description: Trust Integration - Login
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    TrustIntegrationLogin = "TrustIntegrationLogin",

    /**
     * Description: Release the Care Providers part of the Unified Inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxAccumailSandboxBuild = "UnifiedInboxAccumailSandboxBuild",

    /**
     * Description: Deprecated - Used to temporarily hide Patient search UI in the inbox while the feature is being built
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxEmrPatientSearchBuild = "UnifiedInboxEmrPatientSearchBuild",

    /**
     * Description: Deprecated - Internal use only: enables attaching NHS advice links in the Unified inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxNHSAdvice = "UnifiedInboxNHSAdvice",

    /**
     * Description: Enables notes functionality within compose in the Unified Inbox
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxNotesInCompose = "UnifiedInboxNotesInCompose",

    /**
     * Description: Internal use only: enables patient matching for unassigned conversations in the Unified inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxPatientMatching = "UnifiedInboxPatientMatching",

    /**
     * Description: Used to temporarily hide questionnaires UI in the inbox while the feature is being built
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxQuestionnairesBuild = "UnifiedInboxQuestionnairesBuild",

    /**
     * Description: Used to temporarily hide Schedule Send UI in the inbox while the feature is being built
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxScheduleSendBuild = "UnifiedInboxScheduleSendBuild",

    /**
     * Description: Used to temporarily hide searching for a patient via Vision in the Unified Inbox while in development
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxSearchPatientVision = "UnifiedInboxSearchPatientVision",

    /**
     * Description: Used to prevent using a shared single r connection for desktop users while in development
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    UnifiedInboxSingleSignalRConnection = "UnifiedInboxSingleSignalRConnection",

    /**
     * Description: Unified Navigation
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    UnifiedNavigation = "UnifiedNavigation",

    /**
     * Description: Unlimited Attachments
     *
     * Is in modules: SMS Plus, SMS Lite, SMS Non GP Default
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    UnlimitedAttachments = "UnlimitedAttachments",

    /**
     * Description: Unlimited Patient Responses
     *
     * Is in modules: SMS Plus, SMS Lite, SMS Non GP Default
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    UnlimitedPatientResponses = "UnlimitedPatientResponses",

    /**
     * Description: Unlimited SMS
     *
     * Is in modules: SMS Plus, SMS Lite, SMS Non GP Default
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    UnlimitedSms = "UnlimitedSms",

    /**
     * Description: Enable UserWorkspaceSpecialty features
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: True
     *
     **/
    UserWorkspaceSpecialty = "UserWorkspaceSpecialty",

    /**
     * Description: Vaccine Practice - Capacity Checks Use Age
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccineAgeBasedCapacity = "VaccineAgeBasedCapacity",

    /**
     * Description: Vaccine Capacity Overbook
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccineCapacityOverbook = "VaccineCapacityOverbook",

    /**
     * Description: Vaccine Delivery Site
     *
     * Alternative names: VaccinePatientBackendSearch, VaccineBooster
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccineDeliverySite = "VaccineDeliverySite",

    /**
     * Description: Vaccine Delivery Site - Last Minute Cancel
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccineDeliverySiteLastMinuteCancel = "VaccineDeliverySiteLastMinuteCancel",

    /**
     * Description: Vaccine Practice - Access Old View
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccineLegacyPatientList = "VaccineLegacyPatientList",

    /**
     * Description: Vaccine - Paediatric Clinics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePaediatric = "VaccinePaediatric",

    /**
     * Description: Vaccine Practice
     *
     * Alternative names: VaccinePatientBackendSearch, VaccineBooster
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePractice = "VaccinePractice",

    /**
     * Description: Vaccine - Autumn 2022 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeAutumn2022BoosterTab = "VaccinePracticeAutumn2022BoosterTab",

    /**
     * Description: Vaccine - Autumn 2023 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeAutumn2023BoosterTab = "VaccinePracticeAutumn2023BoosterTab",

    /**
     * Description: Vaccine - Autumn 2024 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeAutumn2024BoosterTab = "VaccinePracticeAutumn2024BoosterTab",

    /**
     * Description: Vaccine - Enforce 12 year old age limit
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeEnforceUnder12Limit = "VaccinePracticeEnforceUnder12Limit",

    /**
     * Description: Vaccine Practice - Extended Booster Time Range Filter
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeExtendedBoosterTimeRangeFilter = "VaccinePracticeExtendedBoosterTimeRangeFilter",

    /**
     * Description: Vaccine Practice - Reduced Separation for Booster Doses
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeReducedBoosterDoseSeparation = "VaccinePracticeReducedBoosterDoseSeparation",

    /**
     * Description: Vaccine - Create Sanofi Clinics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeSanofi = "VaccinePracticeSanofi",

    /**
     * Description: Vaccine Practice - See All Future Clinics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeSeeAllFutureClinics = "VaccinePracticeSeeAllFutureClinics",

    /**
     * Description: Vaccine - Spring 2022 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeSpring2022BoosterTab = "VaccinePracticeSpring2022BoosterTab",

    /**
     * Description: Vaccine - Spring 2023 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeSpring2023BoosterTab = "VaccinePracticeSpring2023BoosterTab",

    /**
     * Description: Vaccine - Spring 2024 Booster tab
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeSpring2024BoosterTab = "VaccinePracticeSpring2024BoosterTab",

    /**
     * Description: Vaccine Practice - Strict Separation for Booster Doses
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeStrictBoosterDoseSeparation = "VaccinePracticeStrictBoosterDoseSeparation",

    /**
     * Description: Vaccine Practice - Strict Separation for Primary Doses
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VaccinePracticeStrictPrimaryDoseSeparation = "VaccinePracticeStrictPrimaryDoseSeparation",

    /**
     * Description: Video Consult Desktop
     *
     * Is in modules: Video
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    VideoConsult = "VideoConsult",

    /**
     * Description: Video Consult - Embed Links
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VideoConsultEmbed = "VideoConsultEmbed",

    /**
     * Description: Video Consult Large Rooms
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VideoConsultLargeRooms = "VideoConsultLargeRooms",

    /**
     * Description: Video Consult Web
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    VideoConsultWeb = "VideoConsultWeb",

    /**
     * Description: Video ending tooltip
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VideoEndingTooltip = "VideoEndingTooltip",

    /**
     * Description: Display the view all messages button in the portal conversation page.
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    ViewAllMessagesPatientPortal = "ViewAllMessagesPatientPortal",

    /**
     * Description: View Global Custom Florey
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    ViewGlobalCustomFloreys = "ViewGlobalCustomFloreys",

    /**
     * Description: Vision 3 Snomed Improvement
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    Vision3SnomedImprovement = "Vision3SnomedImprovement",

    /**
     * Description: Vision Medical Coding
     *
     * Alternative names: VisionSnomedCodeIntegrationImprovement
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    VisionIntegrationImprovement = "VisionIntegrationImprovement",

    /**
     * Description: Vision Partner API integration via Server
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VisionPartnerApiServer = "VisionPartnerApiServer",

    /**
     * Description: Vision Prefer legacy API for demographics
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    VisionPreferLegacyApiForDemographics = "VisionPreferLegacyApiForDemographics",

    /**
     * Description: VoiceCall
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    VoiceCall = "VoiceCall",

    /**
     * Description: VOIP
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    Voip = "Voip",

    /**
     * Description: Enable wayfinder features
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    Wayfinder = "Wayfinder",

    /**
     * Description: Web - for use by GTM to disable sending of new batch messages
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    WebBatchDisableSendingNewBatchesDueToFreemium = "WebBatchDisableSendingNewBatchesDueToFreemium",

    /**
     * Description: Web - batch messaging in Web
     *
     * Is in modules: Web - Batch Messaging
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    WebBatchMessaging = "WebBatchMessaging",

    /**
     * Description: Web - for use by Product Marketing to give users ability to sign-up for batch in Web via Userflow
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebBatchMessagingHint = "WebBatchMessagingHint",

    /**
     * Description: Web - Enable email notifications to group from assign
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebEmailGroupFromAssign = "WebEmailGroupFromAssign",

    /**
     * Description: Web - Org Wide Templates
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebOrgWideTemplates = "WebOrgWideTemplates",

    /**
     * Description: Web - Enable Patient-led follow up
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    WebPatientLedFollowUp = "WebPatientLedFollowUp",

    /**
     * Description: Web - can send patient messages via email
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebPatientMessageEmails = "WebPatientMessageEmails",

    /**
     * Description: Web - Enable quick launch with patient token
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebPatientQuickLaunch = "WebPatientQuickLaunch",

    /**
     * Description: Web - single send Floreys
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebSingleSendFloreys = "WebSingleSendFloreys",

    /**
     * Description: Enables fallback for webview2 inbox
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    Webview2FallbackForInbox = "Webview2FallbackForInbox",

    /**
     * Description: Enable attachment preview in Inbox using Webview2
     *
     * Scope: Any
     *
     * Allowed for unapproved users: True
     *
     **/
    WebView2PreviewAttachments = "WebView2PreviewAttachments",

    /**
     * Description: Web - waiting list validation
     *
     * Scope: OrgOnly
     *
     * Allowed for unapproved users: False
     *
     **/
    WebWaitingListValidation = "WebWaitingListValidation",

    /**
     * Description: Enable WorkspaceUserManagement features
     *
     * Scope: Any
     *
     * Allowed for unapproved users: False
     *
     **/
    WorkspaceUserManagement = "WorkspaceUserManagement",
}
