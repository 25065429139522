import { AssigneeType, PatientThreadAssignee } from "@accurx/api/ticket";
import { AssigneeSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

export function mapPatientThreadAssigneeToAssigneeSummary(
    dto: PatientThreadAssignee | null | undefined,
): AssigneeSummary {
    if (isNil(dto)) {
        // currently still supported for pseudo tickets
        return { type: "None" };
    }

    if (dto.type === AssigneeType.User && !isNil(dto.userId)) {
        return {
            type: "User",
            id: dto.userId,
        };
    }

    if (dto.type === AssigneeType.UserGroup && !isNil(dto.userGroupId)) {
        return {
            type: "Team",
            id: dto.userGroupId,
        };
    }

    return { type: "None" };
}
