import { useEffect, useMemo, useRef, useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useMedicalRecordConnection } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useCompose } from "domains/compose/context";
import { PatientExternalId, TemplateItem } from "domains/compose/types";

import { LiveSearchInput } from "../../../LiveSearchInput/LiveSearchInput";
import { RefreshableErrorState } from "../../../RefreshableErrorState/RefreshableErrorState";
import { useTemplatesAnalytics } from "../../useTemplatesAnalytics";
import { useTemplateSearchDebounced } from "../TemplatesCombobox/useTemplateSearchDebounced";
import {
    StyledFilterContainer,
    StyledPaddedBody,
    StyledTabContainer,
    StyledTemplateSearchContainer,
    StyledTemplatesScroller,
} from "./MessageTemplateSelector.styles";
import { QuestionnaireTemplatePreview } from "./components/QuestionnaireTemplatePreview";
import { SelectedTemplate } from "./components/SelectedTemplate/SelectedTemplate";
import {
    TemplateFilterSelector,
    TemplatesFilter,
} from "./components/TemplateFilterSelector";
import { TemplateList } from "./components/TemplateList";
import { TemplatePreview } from "./components/TemplatePreview";
import { groupTemplatesByOwnerAndHeading } from "./utils/groupTemplatesByOwnerAndHeading";

export type MessageTemplateSelectorProps = {
    onClickTemplate: (value: TemplateItem) => void;
    onClose: () => void;
    patientExternalIds: PatientExternalId[];
    manageTemplatesLink?: string;
};

export type MessageTemplateDataProps =
    | {
          templates: TemplateItem[];
          status: "loaded";
          refetch?: undefined;
      }
    | {
          status: "loading";
          templates?: undefined;
          refetch?: undefined;
      }
    | { status: "failed"; templates?: undefined; refetch: () => void };

export const MessageTemplateSelector = ({
    onClickTemplate,
    onClose,
    patientExternalIds,
    status,
    templates,
    refetch,
    manageTemplatesLink,
}: MessageTemplateSelectorProps & MessageTemplateDataProps) => {
    const events = useTemplatesAnalytics();
    const connection = useMedicalRecordConnection();
    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord;

    const scrollerRef = useRef<HTMLElement>(null);
    const [filter, setFilter] = useState<TemplatesFilter>("All");

    const [selectedTemplate, setSelectedTemplate] =
        useState<TemplateItem | null>(null);
    const [
        isLoadingQuestionnaireEnrolment,
        setIsLoadingQuestionnaireEnrolment,
    ] = useState(false);

    const [templatePreview, setTemplatePreview] = useState<TemplateItem | null>(
        null,
    );

    const searchTermRef = useRef("");

    const { state } = useCompose();
    const buttonVerb = useFeatureFlag("MessageComponentV1_1")
        ? "Use"
        : "Select";

    const hasQuestionnaireTemplates = templates
        ? templates.some(({ type }) => type === "QuestionnaireTemplate")
        : false;

    const [searchedTemplates, setSearchedTemplates] = useState<TemplateItem[]>(
        [],
    );

    // Not ideal, but neccessary while this component handles both loading and success state of fetching templates.
    // Once we've rolled out MessageComponentV1_1, we'll update this component to always expect there to be templates.
    // Loading and errors will be handled by the parent.
    // Ticket: https://linear.app/accurx/issue/COMP-181/[post-rollout]-clean-up-messagetemplateselectortsx-code
    useEffect(() => {
        if (status === "loaded") {
            setSearchedTemplates(templates);
        }
    }, [status, templates]);

    const searchDebounced = useTemplateSearchDebounced(
        templates ?? [],
        setSearchedTemplates,
    );

    const filteredTemplates = useMemo(
        () =>
            searchedTemplates.filter(
                ({ type }) =>
                    filter === "All" ||
                    (filter === "Questionnaires" &&
                        type === "QuestionnaireTemplate") ||
                    (filter === "Templates" && type === "MessageTemplate"),
            ),
        [searchedTemplates, filter],
    );

    const templateGroups = groupTemplatesByOwnerAndHeading(filteredTemplates);
    const templatesCount = filteredTemplates.length;

    const onSelectTemplate = (value: TemplateItem) => {
        setSelectedTemplate(value);
    };

    const onClickPreview = (value: TemplateItem) => {
        setTemplatePreview(value);
        events.trackTemplatePreviewButtonClick(value);
    };

    const onFilterChange = (value: TemplatesFilter) => {
        events.trackConversationFilterMenuItemClick(value);
        setFilter(value);
    };

    const onFilterClick = () => {
        events.trackConversationFilterButtonClick(filter);
    };

    // When you search or filter the list we scroll it to the top
    useEffect(() => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTop = 0;
        }
    }, [filter]);

    // When questionnaires are supported we want to wait for all templates to
    // have been fetched
    const isFetchingTemplates = status === "loading";

    const resetSelectedTemplate = () => {
        setSelectedTemplate(null);
    };

    const heading = hasQuestionnaireTemplates
        ? "Select template or questionnaire"
        : "Use a template";

    return (
        <>
            <QuickViewPortal.Header>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => {
                        onClose();
                        events.trackTemplateBrowseCloseButtonClick();
                    }}
                >
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>{" "}
                {templatePreview && (
                    <Pill.SecondaryButton
                        dimension="small"
                        onClick={() => {
                            events.trackTemplatePreviewBackButtonClick(
                                templatePreview,
                            );
                            setTemplatePreview(null);
                        }}
                    >
                        <Pill.Icon
                            name="ArrowTail"
                            colour="blue"
                            size={3}
                            rotation="left"
                        />
                        <Pill.Text>Back</Pill.Text>
                    </Pill.SecondaryButton>
                )}
            </QuickViewPortal.Header>
            <QuickViewPortal.Body>
                {status === "failed" ? (
                    <RefreshableErrorState
                        text="Sorry, something went wrong loading message templates. Click
                    below to try again."
                        onClickRefresh={() => {
                            refetch();
                        }}
                    />
                ) : templatePreview?.type === "MessageTemplate" ? (
                    <TemplatePreview
                        title={templatePreview.value.title}
                        body={templatePreview.value.body}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                        snomedCodes={
                            canSaveToRecord
                                ? templatePreview.value.snomedCodes
                                : []
                        }
                        attachments={templatePreview.value.attachments}
                        allowReplyByDefault={
                            templatePreview.value.allowReplyByDefault
                        }
                    />
                ) : templatePreview?.type === "QuestionnaireTemplate" ? (
                    <QuestionnaireTemplatePreview
                        template={templatePreview.value}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                    />
                ) : (
                    <StyledTabContainer>
                        <StyledTemplateSearchContainer>
                            {isFetchingTemplates ? (
                                <SkeletonLoader height="24px" />
                            ) : (
                                <UI.Text as="h2" variant="label" skinny>
                                    {heading}
                                </UI.Text>
                            )}
                            <LiveSearchInput
                                placeholder={"Search"}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    searchTermRef.current = value;
                                    if (value === "") {
                                        setSearchedTemplates(templates ?? []);
                                        // Cancel any pending invocations to prevent overriding results
                                        searchDebounced.cancel();
                                    } else {
                                        searchDebounced(value);
                                    }

                                    events.trackTemplateSearchDebounced({
                                        term: value,
                                        appOrigin: "TemplatesQuickview",
                                    });
                                }}
                                aria-label={
                                    hasQuestionnaireTemplates
                                        ? "Search for template or questionnaire"
                                        : "Search for template"
                                }
                                disabled={isFetchingTemplates}
                            />
                        </StyledTemplateSearchContainer>
                        {hasQuestionnaireTemplates && (
                            <StyledFilterContainer>
                                <TemplateFilterSelector
                                    value={filter}
                                    onChange={onFilterChange}
                                    onClick={onFilterClick}
                                />
                            </StyledFilterContainer>
                        )}
                        <StyledTemplatesScroller ref={scrollerRef}>
                            {isFetchingTemplates ? (
                                <StyledPaddedBody>
                                    <UI.Spinner />
                                </StyledPaddedBody>
                            ) : templatesCount > 0 ? (
                                <TemplateList
                                    templates={templateGroups}
                                    onClickTemplate={(template) => {
                                        events.trackTemplateSelectButtonClick({
                                            template,
                                            appOrigin: "TemplatesQuickview",
                                        });
                                        onSelectTemplate(template);
                                    }}
                                    onClickPreview={onClickPreview}
                                    loadingTemplate={
                                        selectedTemplate &&
                                        isLoadingQuestionnaireEnrolment
                                            ? selectedTemplate
                                            : undefined
                                    }
                                />
                            ) : (
                                // aria-live=assertive is recommended here so screen reader users
                                // do not continue typing when we've found no results.
                                <StyledPaddedBody aria-live="assertive">
                                    <UI.Text skinny variant="body">
                                        {searchTermRef.current === ""
                                            ? "No templates found."
                                            : `No results found for '${searchTermRef.current}'.`}
                                    </UI.Text>
                                </StyledPaddedBody>
                            )}
                        </StyledTemplatesScroller>
                    </StyledTabContainer>
                )}
            </QuickViewPortal.Body>
            {(templatePreview || manageTemplatesLink) && (
                <QuickViewPortal.Footer>
                    {templatePreview && (
                        <UI.Flex justifyContent={"flex-end"}>
                            <Pill.PrimaryButton
                                dimension="small"
                                onClick={() => {
                                    events.trackTemplateSelectButtonClick({
                                        template: templatePreview,
                                        appOrigin: "TemplatePreview",
                                    });
                                    onSelectTemplate(templatePreview);
                                }}
                                aria-label={`${buttonVerb} ${templatePreview.value.title}`}
                                isLoading={isLoadingQuestionnaireEnrolment}
                            >
                                <Pill.Text>
                                    {templatePreview.type ===
                                    "QuestionnaireTemplate"
                                        ? `${buttonVerb} questionnaire`
                                        : `${buttonVerb} template`}
                                </Pill.Text>
                            </Pill.PrimaryButton>
                        </UI.Flex>
                    )}
                    {!templatePreview && manageTemplatesLink && (
                        <UI.Link
                            href={manageTemplatesLink}
                            openInNewTab
                            onClick={() => {
                                events.trackTemplateBrowseManageLinkClick();
                            }}
                        >
                            Create and manage templates
                            <UI.Link.Icon />
                        </UI.Link>
                    )}
                </QuickViewPortal.Footer>
            )}

            {selectedTemplate && (
                <SelectedTemplate
                    onClickTemplate={onClickTemplate}
                    resetSelectedTemplate={resetSelectedTemplate}
                    template={selectedTemplate}
                    patientExternalIds={patientExternalIds}
                    onIsLoading={setIsLoadingQuestionnaireEnrolment}
                />
            )}
        </>
    );
};
