/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface BankHolidaysResponse {
    bankHolidays?: string[] | null;
}

export interface BatchSettings {
    batchPatientDataRequirements: BatchPatientDataRequirements;
    batchCommunicationMethods: BatchCommunicationMethod[];
    integratedSystem?: PrincipalSystem | null;
}

export interface BatchPatientDataRequirements {
    identifier: BatchPatientIdentifier;
    contactDetailsRequirement: BatchPatientContactDetailsRequirement;
    dateOfBirthRequired: boolean;
}

export enum BatchPatientIdentifier {
    NhsNumber = "NhsNumber",
    HcnNumber = "HcnNumber",
}

export enum BatchPatientContactDetailsRequirement {
    None = "None",
    MobileOptional = "MobileOptional",
    EmailOptional = "EmailOptional",
    MobileOrEmailOptional = "MobileOrEmailOptional",
    MobileRequired = "MobileRequired",
    MobileOrEmailRequired = "MobileOrEmailRequired",
}

export enum BatchCommunicationMethod {
    Sms = "Sms",
    NhsApp = "NhsApp",
    Email = "Email",
}

export enum PrincipalSystem {
    None = "None",
    Emis = "Emis",
    SystmOne = "SystmOne",
    Vision = "Vision",
}

export interface BatchMessageUploadPatientFileResponse {
    isUploadSuccess: boolean;
    batchMessageId?: string | null;
    validationErrors?: BatchMessageUploadValidationErrors | null;
}

export interface BatchMessageUploadValidationErrors {
    parsingErrors: BatchMessageUploadParsingError[];
    dataErrors: BatchMessageUploadDataError[];
}

export interface BatchMessageUploadParsingError {
    type: BatchMessageUploadParsingErrorType;
    values?: string[] | null;
}

export enum BatchMessageUploadParsingErrorType {
    MissingColumns = "MissingColumns",
    DuplicateColumns = "DuplicateColumns",
    UnknownDateFormat = "UnknownDateFormat",
    ExceedsMaxNumberOfPatients = "ExceedsMaxNumberOfPatients",
    NoPatientsFound = "NoPatientsFound",
}

export interface BatchMessageUploadDataError {
    type: BatchMessageUploadDataErrorType;
    rowNumbers: number[];
}

export enum BatchMessageUploadDataErrorType {
    MissingDateOfBirth = "MissingDateOfBirth",
    CannotReadDateOfBirth = "CannotReadDateOfBirth",
    DateOfBirthInFuture = "DateOfBirthInFuture",
    ContainsDeceasedPatient = "ContainsDeceasedPatient",
    MissingPatientIdentifier = "MissingPatientIdentifier",
    InvalidPatientIdentifier = "InvalidPatientIdentifier",
    InvalidEmailAddress = "InvalidEmailAddress",
    ContactNumberTooLong = "ContactNumberTooLong",
    DuplicatePatients = "DuplicatePatients",
    MultipleContactNumbersForPatient = "MultipleContactNumbersForPatient",
    MultipleEmailAddressesForPatient = "MultipleEmailAddressesForPatient",
    CannotParseRow = "CannotParseRow",
}

export type BatchMessageRefWithTotal = BatchMessageRef & {
    /** @format int32 */
    totalItems: number;
};

export interface BatchMessageRef {
    id?: string | null;
}

export interface BatchMessageUploadJsonRequest {
    batchMessageItems: BatchMessageItemUpload[];
}

export interface BatchMessageItemUpload {
    nhsNumber?: string | null;
    /** @format date-time */
    dateOfBirth: string;
}

export type QueueBatchMessageRequest = BatchActionRequest & {
    messageTemplate?: string | null;
    autoSaveToRecord?: boolean | null;
    saveMessageToRecord?: boolean | null;
    snomedConceptId?: string | null;
    /** @format guid */
    floreyConditionId?: string | null;
    documentId?: string | null;
    sendAt?: ComposedDateTime | null;
    /** @format int32 */
    staggerOverDays?: number | null;
    slotType?: string | null;
    /** @format int32 */
    selfBookTimeoutDays?: number | null;
    declineSettings?: DeclineSettingsData | null;
    messageTemplateAttachments?: MessageTemplateWithAttachments | null;
    template?: TemplateProperties | null;
    /** @format int32 */
    assignedUserGroupId?: number | null;
};

export interface ComposedDateTime {
    /** @format int32 */
    year?: number | null;
    /** @format int32 */
    month?: number | null;
    /** @format int32 */
    day?: number | null;
    /** @format int32 */
    hour?: number | null;
}

export interface DeclineSettingsData {
    snomedConceptId?: string | null;
    confirmationText?: string | null;
}

export interface MessageTemplateWithAttachments {
    /** @format int32 */
    templateId: number;
    attachmentIds: number[];
}

export interface TemplateProperties {
    group?: string | null;
    /** @minLength 1 */
    name: string;
    isPreset: boolean;
}

export interface BatchActionRequest {
    id?: string | null;
}

export interface BatchMessageId {
    /** @format int32 */
    id: number;
}

export type SnomedCodeFull = MedicalCode & {
    term?: string | null;
    descriptionId?: string | null;
};

export type MedicalCode = BasePersistenceEntity & {
    conceptId?: string | null;
    scheme: MedicalCodeScheme;
};

export enum MedicalCodeScheme {
    None = 0,
    SnomedCt = 1,
}

export interface BasePersistenceEntity {
    /** @format int32 */
    id: number;
}

export interface BatchMessageSummariesForTable {
    summaries: BatchMessageTableSummaryWithLegacyProperties[];
    continuationToken?: string | null;
}

export type BatchMessageTableSummaryWithLegacyProperties =
    BatchMessageTableSummary & {
        /** @format date-time */
        sendTime?: string | null;
        name?: string | null;
        messageTemplate?: string | null;
        status?: string | null;
        /** @format int32 */
        totalNonTextable: number;
        /** @format int32 */
        totalDelivered: number;
        /** @format int32 */
        totalFailed: number;
        /** @format int32 */
        totalSent: number;
        /** @format int32 */
        totalWriteSuccess: number;
        /** @format int32 */
        totalWriteFailure: number;
    };

export type BatchMessageTableSummary = BatchMessageRefWithTotal & {
    sender?: BatchMessageUser | null;
    /** @format date-time */
    sendAt?: string | null;
    batchStatus?: string | null;
    batchType?: string | null;
    /** @format int32 */
    failedToDeliver: number;
    isCancellable: boolean;
    type?: string | null;
    /** @format int32 */
    bookedCount?: number | null;
};

export interface BatchMessageUser {
    id: string;
    fullName?: string | null;
    email: string;
}

export interface BatchMessageDetails {
    id: string;
    /** @format int32 */
    totalItems: number;
    name: string;
    sender: BatchMessageUser;
    messageTemplate?: string | null;
    status: string;
    /** @format date-time */
    sendTime?: string | null;
    /** @format int32 */
    totalNonTextable: number;
    /** @format int32 */
    maximumPatientGreetingLength: number;
    items: BatchMessageItemSummary[];
}

export interface BatchMessageItemSummary {
    patientExternalIdentity: PatientExternalIdentityDto;
    mobileNumber?: string | null;
    emailAddress?: string | null;
    isTextable: boolean;
    reasonUncontactable?: ReasonUncontactable | null;
    patientName: string;
    userProvidedMobileValidation: BatchMessageDemographicValidationState;
}

export interface PatientExternalIdentityDto {
    patientExternalIds?: PatientExternalIdDto[] | null;
}

export interface PatientExternalIdDto {
    type: IdType;
    /** @minLength 1 */
    value: string;
}

export enum IdType {
    None = 0,
    NhsNumber = 1,
    Emis = 2,
    Dummy = 3,
    SystmOne = 4,
    Vision = 5,
    Hospital = 6,
}

export interface ReasonUncontactable {
    description: string;
}

export enum BatchMessageDemographicValidationState {
    Match = "Match",
    Mismatch = "Mismatch",
    Unvalidated = "Unvalidated",
    NotProvided = "NotProvided",
}

export type BatchMessageDetailsForTable = BatchMessageTableSummary & {
    /** @format int32 */
    totalPatients: number;
    /** @format int32 */
    nonTextablePatients: number;
    /** @format int32 */
    failedToSave: number;
    /** @format int32 */
    delivered: number;
    /** @format int32 */
    staggerOverDays?: number | null;
    savingStatus?: string | null;
    /** @format date-time */
    createdTime: string;
    messageTemplate?: string | null;
    saveMessageToRecord: boolean;
    /** @format int32 */
    totalProcessed: number;
    /** @format int32 */
    totalMessages: number;
    isFloreyCsvDownloadSupported: boolean;
    slotType?: string | null;
    declineSettings?: DeclineSettingsData | null;
};

export interface BatchMessageStatusResponse {
    status: string;
    /** @format double */
    progressPercentage?: number | null;
}

export interface BehaviourChangeCampaignRef {
    id?: string | null;
}

export interface CreateBehaviourChangeCampaignRequest {
    batchMessageId?: string | null;
    /** @format guid */
    floreyConditionId?: string | null;
}

export type SnomedCodeFull2 = SnomedCode & {
    term?: string | null;
    descriptionId?: string | null;
};

export interface SnomedCode {
    conceptId?: string | null;
}

export interface SnomedCodesListResponse {
    items: SnomedCodeFull2[];
}

export interface DelayedMessageCancel {
    /** @minLength 1 */
    messageId: string;
    isCancelled: boolean;
}

export interface DeliveryReceiptResponse {
    /** @format int32 */
    organisationId?: number | null;
    receipts: DeliveryReceipt[];
}

export interface DeliveryReceipt {
    nhsNumber?: string | null;
    sentTime?: string | null;
    status?: string | null;
}

export interface DocumentUploadResponse {
    link?: string | null;
    id?: string | null;
    urlId?: string | null;
}

export interface SendAdviceResult {
    patient?: PatientThreadPatientId | null;
    messages?: PatientThreadSMS[] | null;
    emailMessages?: PatientThreadEmail[] | null;
    nhsAppMessages?: PatientThreadNhsAppMessage[] | null;
    videoConsultClinicianUrl?: string | null;
    videoConsultId?: string | null;
    wasReplayedRequest: boolean;
}

export interface PatientThreadPatientId {
    accuRxId?: string | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
}

export type PatientThreadSMS = PatientThreadItemOfPatientThreadContentSMS &
    object;

export type PatientThreadItemOfPatientThreadContentSMS = PatientThreadItem & {
    message?: PatientThreadContentSMS | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentSMS = PatientThreadContentMessage & {
    type: PatientThreadContentType;
    mobileNumber?: string | null;
};

export enum PatientThreadContentType {
    None = 0,
    Note = 1,
    SMS = 2,
    SmsLinkNote = 3,
    FloreyResponseNote = 4,
    OutgoingEmail = 5,
    IncomingEmail = 6,
    StateChange = 7,
    PatientEmail = 8,
    PatientEmailLinkNote = 9,
    PatientTriageRequestNote = 10,
    GenericNote = 11,
    PatientAppointmentRequestNote = 12,
    NhsAppMessageLinkNote = 13,
    NhsAppMessage = 14,
    LabelTag = 15,
}

export type PatientThreadContentMessage = PatientThreadContent & {
    /** Null for Decision Support and Florey messages.  */
    sentByUserId?: string | null;
    messageBody?: string | null;
    deliveryStatus?: string | null;
    /** @format date-time */
    deliveryStatusUpdatedAt?: string | null;
    /**
     * Null if message not scheduled or is after message has been sent
     * @format date-time
     */
    deliveryScheduledAt?: string | null;
    /** This is the "external" SMS/Email id. Mapped from "IBasicPatientSms.AccuRxMessageId"  */
    accuRxMessageId?: string | null;
    /** Whether the patient thread content (email/SMS) has been written to the medical record.  */
    medicalRecordWriteState: MedicalRecordWriteStatus;
};

export enum MedicalRecordWriteStatus {
    Unknown = 0,
    Success = 1,
    Failed = 2,
}

export interface PatientThreadContent {
    type: PatientThreadContentType;
}

export interface PatientThreadItem {
    id?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    patientAccuRxId?: string | null;
    /** @format date-time */
    createdAt: string;
    type: PatientThreadContentType;
}

export type PatientThreadEmail = PatientThreadItemOfPatientThreadContentEmail &
    object;

export type PatientThreadItemOfPatientThreadContentEmail = PatientThreadItem & {
    message?: PatientThreadContentEmail | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentEmail = PatientThreadContentMessage & {
    type: PatientThreadContentType;
    emailAddress?: string | null;
};

export type PatientThreadNhsAppMessage =
    PatientThreadItemOfPatientThreadContentNhsAppMessage & object;

export type PatientThreadItemOfPatientThreadContentNhsAppMessage =
    PatientThreadItem & {
        message?: PatientThreadContentNhsAppMessage | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentNhsAppMessage = PatientThreadContentMessage & {
    type: PatientThreadContentType;
};

export type FloreyEnrolmentRequest = PatientDetailsRequest & {
    /**
     * @format guid
     * @minLength 1
     */
    conditionId: string;
    conditionName?: string | null;
    /** @format int32 */
    timezoneOffsetMinutes?: number | null;
    textMessageGreeting?: string | null;
    textMessageBody?: string | null;
    textMessageFooter?: string | null;
    /** @format date-time */
    sendTime?: string | null;
    scheduleTimes?: string[] | null;
    clientMessageId?: string | null;
};

export type PatientDetailsRequest = PatientDemographicDetails & {
    /** @format int32 */
    accuRxOrgId: number;
    ticketIdentity?: TicketIdentity | null;
    assignee?: PatientThreadAssignee | null;
    mobileNumber?: string | null;
    mobileNumberOrigin?: MobileNumberOriginDto | null;
    emailAddress?: string | null;
    patientName?: string | null;
    trySendViaNhsApp?: boolean | null;
};

export interface TicketIdentity {
    type: TicketIdentityType;
    id?: string | null;
}

export enum TicketIdentityType {
    None = 0,
    SMS = 1,
    Note = 2,
    HealthcareEmail = 3,
    PatientEmail = 4,
    NhsAppMessage = 5,
}

export interface PatientThreadAssignee {
    type: AssigneeType;
    userId?: string | null;
    userGroupId?: string | null;
}

export enum AssigneeType {
    None = 0,
    User = 1,
    UserGroup = 2,
}

export enum MobileNumberOriginDto {
    Unknown = 0,
    Pds = 1,
    ProvidedByUserOrMedicalRecord = 2,
}

export type PatientDemographicDetails = PatientExternalIdentityDto & {
    demographicMobileNumber?: string | null;
    names?: PatientName[] | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    gender?: string | null;
};

export interface PatientName {
    use: PatientNameUse;
    prefix?: string[] | null;
    given?: string[] | null;
    family?: string[] | null;
}

export enum PatientNameUse {
    None = 0,
    Usual = 1,
    Official = 2,
    Temp = 3,
    Nickname = 4,
    Anonymous = 5,
    Old = 6,
    Maiden = 7,
}

export interface PreviewFloreyEnrolmentResponse {
    enrolmentUrl?: string | null;
}

export interface PreviewFloreyEnrolmentRequest {
    /** @format int32 */
    organisationId: number;
    /**
     * @format guid
     * @minLength 1
     */
    conditionId: string;
}

export interface FloreyEnrolmentCanBeEnrolledResponse {
    canBeEnrolled: boolean;
    enrolmentSchedules: EnrolmentSchedule[];
    /**
     * @format duration
     * @minLength 1
     */
    enrolmentDuration: string;
    enrolmentResultReason: EnrolmentResultReason;
}

export interface EnrolmentSchedule {
    /** @format date-time */
    startDate?: string | null;
    /** @format date-time */
    endDate?: string | null;
}

export enum EnrolmentResultReason {
    Unknown = 0,
    FailedMustBeTestPatient = 1,
    FailedMustBeInternalUser = 2,
    FailedNotYetImplemented = 3,
    FailedUnexpectedMaturity = 4,
    Success = 5,
    FailOther = 6,
    FailAlreadyEnrolled = 7,
}

export type FloreyEnrolmentCanBeEnrolledRequest = PatientExternalIdentityDto & {
    /** @format int32 */
    accuRxOrgId: number;
    /**
     * @format guid
     * @minLength 1
     */
    conditionId: string;
};

export interface FloreyTemplatesResponse {
    groups?: FloreyMessageGroup[] | null;
}

export interface FloreyMessageGroup {
    messageTemplates?: FloreyMessageTemplate[] | null;
    heading?: string | null;
}

export interface FloreyMessageTemplate {
    title?: string | null;
    /** @format guid */
    id: string;
    medicalCodeIdentity?: MedicalCodeIdentity | null;
    templateBody?: string | null;
    canSchedule: boolean;
    availableInBatchForFree: boolean;
    canAutoSaveToRecord: boolean;
    supportArticleUrl?: string | null;
    availability: FloreyAvailabilityState;
    type: FloreyProductType;
    displayCategory: FloreyDisplayCategory;
    /** @format int32 */
    defaultUserGroupId?: number | null;
}

export interface MedicalCodeIdentity {
    debugDisplay: string;
    code?: string | null;
    codeScheme: Scheme;
}

export enum Scheme {
    None = 0,
    READV2 = 1,
    SNOMED = 2,
    EMIS = 3,
    OTHER = 4,
    CTV3 = 5,
    MULTILEX = 6,
    DMD = 7,
}

export enum FloreyAvailabilityState {
    None = 0,
    Available = 1,
    ComingSoon = 2,
    Blocked = 3,
    AvailableForTestPatient = 4,
}

export enum FloreyProductType {
    None = 0,
    Standard = 1,
    Paywall = 2,
    AccuRxPlus = 3,
    Partnered = 4,
    BehaviourChange = 5,
    OrganisationCustom = 6,
    WaitingListValidation = 7,
    GsttQuestionnaires = 8,
}

export enum FloreyDisplayCategory {
    None = 0,
    Standard = 1,
    Lifestyle = 2,
    AccuRxPlus = 3,
    Partnered = 4,
    BehaviourChange = 5,
    Acute = 6,
    Chronic = 7,
    Covid19 = 8,
    LifestyleAndDemographic = 9,
    MedicationReviews = 10,
    MentalHealth = 11,
    VitalSigns = 12,
    OrganisationCustom = 13,
    WaitingListValidation = 14,
    General = 15,
    MusculoskeletalHealth = 16,
    MedicationAndReviews = 17,
    SurgicalSpecialties = 18,
    Ophthalmology = 19,
    Paediatrics = 20,
    WomensHealth = 21,
    Dermatology = 22,
    MedicalSubSpecialities = 23,
    Neurological = 24,
    CareOfOlderAdults = 25,
    Cardiology = 26,
    Respiratory = 27,
    ImagingAndProcedures = 28,
    Gastroenterology = 29,
    DiabetesAndEndocrinology = 30,
    Pharmacy = 31,
    CommunitySpecific = 32,
    SexualHealth = 33,
}

export interface WorkspaceFragmentUsage {
    overview: FragmentUsageOverview;
    summary: FragmentUsageSummary[];
    dailyUsage: FragmentUsageDaily[];
}

export type FragmentUsageOverview = FragmentUsageBase & object;

export interface FragmentUsageBase {
    /** @format int32 */
    numberOfMessages: number;
    /** @format int32 */
    numberOfFragments: number;
}

export type FragmentUsageSummary = FragmentProductUsageBase & object;

export type FragmentProductUsageBase = FragmentUsageBase & {
    product: string;
};

export type FragmentUsageDaily = FragmentProductUsageBase & {
    /** @format date */
    date?: string | null;
};

export interface FragmentUsageDashboardRequest {
    /** @format int32 */
    organisationId: number;
    /** @format date */
    startDate?: string | null;
    /** @format date */
    endDate?: string | null;
}

export interface SaveRecentSearchRequest {
    patientToken?: string | null;
    /** @format int32 */
    organisationId?: number | null;
}

export interface LatestSearchListDto {
    patients: PatientSearchResult[];
    /** @format int32 */
    totalCount: number;
}

export type PatientSearchResult = PatientSearchResultBase & {
    /** @format date-time */
    dateCreated?: string | null;
};

export interface PatientSearchResultBase {
    displayName?: string | null;
    firstName?: string | null;
    familyName?: string | null;
    prefixName?: string | null;
    gender?: string | null;
    nhsNumber?: string | null;
    dateOfBirth?: string | null;
    ageDisplay?: string | null;
}

export interface RecentSearchQueryRequest {
    /** @format int32 */
    organisationId: number;
    search?: string | null;
}

export interface FeatureUsageRemaining {
    /** @format int32 */
    organisationId?: number | null;
    counts?: FeatureUsageRemainingCount[] | null;
    usagePeriod?: UsagePeriod | null;
}

export interface FeatureUsageRemainingCount {
    unlimitedFeatureName?: string | null;
    /** @format int32 */
    remainingUses?: number | null;
}

export interface UsagePeriod {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface MessagePatientResponse {
    videoConsultUrl?: string | null;
    videoConsultId?: string | null;
    messageText?: string | null;
    messages?: PatientThreadSMS[] | null;
    emailMessages?: PatientThreadEmail[] | null;
    nhsAppMessages?: PatientThreadNhsAppMessage[] | null;
}

export type MessagePatientRequestWithExternalIdentity =
    MessagePatientRequestBase & {
        patientExternalIdentity?: PatientExternalIdentityDto | null;
        demographicsMobileNumber?: string | null;
    };

export interface MessagePatientRequestBase {
    nhsNumber?: string | null;
    mobileNumber?: string | null;
    emailAddress?: string | null;
    messageBody?: string | null;
    messageFooter?: string | null;
    isVideoConsult?: boolean | null;
    /** @format date-time */
    videoConsultTime?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    clientMessageId?: string | null;
    attachedDocumentIds?: string[] | null;
    messageTemplate?: MessageTemplateWithAttachments2 | null;
    enablePatientResponse?: boolean | null;
    /** @format int32 */
    patientListEntryId?: number | null;
    /** @format int32 */
    patientListId?: number | null;
    ticketIdentity?: TicketIdentity | null;
    assignee?: PatientThreadAssignee | null;
    /** @format guid */
    conditionId?: string | null;
    conditionName?: string | null;
    /** @format date-time */
    sendTime?: string | null;
    selfBook?: SelfBookForMessagePatientRequest | null;
    nhsChoicesLink?: NHSChoicesLink | null;
    trySendViaNhsApp?: boolean | null;
}

export interface MessageTemplateWithAttachments2 {
    /** @format int32 */
    id: number;
    attachmentIds: number[];
    isPreset?: boolean | null;
    group?: string | null;
    name?: string | null;
    level?: string | null;
}

export interface SelfBookForMessagePatientRequest {
    snomedConceptId?: string | null;
    slotType?: string | null;
    specificClinicianEnabled?: boolean | null;
    clinicianGroupIds?: number[] | null;
    targetOdsCode?: string | null;
    /** @format int32 */
    timeoutDays: number;
    type?: SelfBookRequestType | null;
    availabilityRestriction?: AvailabilityRestriction | null;
}

export enum SelfBookRequestType {
    Telephone = 0,
    FaceToFace = 1,
}

export interface AvailabilityRestriction {
    /**
     * An inclusive lower bound of bookable appointment start times
     * @format date-time
     */
    from: string;
    /**
     * An inclusive upper bound of bookable appointment start times
     * @format date-time
     */
    to: string;
}

export interface NHSChoicesLink {
    name?: string | null;
    url?: string | null;
}

export type MessagePatientRequestWithToken = MessagePatientRequestBase & {
    patientToken?: string | null;
    useMobileNumberFromPds?: boolean | null;
};

export interface AttachmentUploadResponse {
    id: string;
}

export interface MessageTemplatesResponse {
    templates: MessageTemplateResponse[];
}

export type MessageTemplateResponse = MessageTemplateDtoBase & {
    allowReplyByDefault: boolean;
    isDefault: boolean;
    attachments?: MessageTemplateAttachment[] | null;
};

export interface MessageTemplateAttachment {
    /** @format int32 */
    id: number;
    fileName?: string | null;
    /** @format int64 */
    fileSize: number;
}

export type MessageTemplateDtoBase = MessageTemplateBase & {
    type?: string | null;
    /**
     * @minLength 1
     * @maxLength 50
     */
    title: string;
    /**
     * @minLength 1
     * @maxLength 450
     */
    body: string;
    snomedCodes?: SnomedCodeFull2[] | null;
    path?: TemplatePath | null;
};

export interface TemplatePath {
    folders?: TemplateFolder[] | null;
}

export interface TemplateFolder {
    name?: string | null;
}

export interface MessageTemplateBase {
    id?: string | null;
    owner?: string | null;
}

export interface MessageTemplateCategoryResponse {
    categories?: TemplateCategory[] | null;
}

export interface TemplateCategory {
    heading?: string | null;
    path?: TemplatePath | null;
}

export interface MessageTemplateManagementViewResponse {
    templates: MessageTemplateManagementView[];
}

export type MessageTemplateManagementView = MessageTemplateResponse & {
    isAllowedAsSms: boolean;
    isAllowedAsBatch: boolean;
};

export type MessageTemplate = MessageTemplateDtoBase & {
    allowReplyByDefault: boolean;
    isDefault: boolean;
    /** @maxItems 5 */
    attachedDocumentIds?: number[] | null;
    allowAsSms?: boolean | null;
    allowAsBatch?: boolean | null;
    tagGroups?: number[][] | null;
};

export type MessageTemplateUpdateSendTypeRequest = MessageTemplateBase & {
    /** @minLength 1 */
    sendType: string;
    isEnabled: boolean;
};

export interface NHSApiLinks {
    links: NHSApiLink[];
}

export interface NHSApiLink {
    name?: string | null;
    url?: string | null;
    alternateNames?: string[] | null;
}

export interface PathwayTemplate {
    id?: string | null;
    title?: string | null;
    scheduledMessageTemplates?: ScheduledMessageTemplate[] | null;
    snomedCodes?: SnomedCode[] | null;
    path?: TemplatePath | null;
}

export interface ScheduledMessageTemplate {
    title?: string | null;
    body?: string | null;
    delaySpan?: TimeSpanDto | null;
    /** @format int32 */
    order?: number | null;
}

export interface TimeSpanDto {
    /** @format int64 */
    ticks?: number | null;
}

export interface PatientAttachments {
    attachments: PatientAttachment[];
}

export interface PatientAttachment {
    documentUrlId: string;
    fileName: string;
    sentBy: string;
    /** @format date-time */
    sentAt: string;
    sentByCurrentUser: boolean;
    downloadUrl: string;
    deletedBy?: string | null;
    /** @format date-time */
    deletedAt?: string | null;
    deletedByCurrentUser?: boolean | null;
}

export interface PatientAttachmentRequest {
    documentUrlId?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    patientToken?: string | null;
}

export type PatientListResponse = PatientListDetailResponseBase & {
    patients: PatientListEntryResponse[];
    appointmentDays: PatientListAppointmentDayResponse[];
    patientDetails: Record<string, PatientDetailResponse>;
    /** @format date-time */
    lastUpdated?: string | null;
};

export type PatientListEntryResponse = PatientDetailResponse & {
    /** @format int32 */
    id?: number | null;
    /** @format date-time */
    dateAdded?: string | null;
    videoConsultId?: string | null;
    videoConsultUrl?: string | null;
    appointments: PatientListAppointmentResponse[];
};

export interface PatientListAppointmentResponse {
    /** @format date-time */
    scheduledStartTime: string;
}

export type PatientDetailResponse = PatientSearchResultBase & {
    /** @format int32 */
    patientId: number;
};

export interface PatientListAppointmentDayResponse {
    date?: string | null;
    appointments: PatientListEntryBaseResponse[];
}

export interface PatientListEntryBaseResponse {
    /** @format int32 */
    id?: number | null;
    /** @format date-time */
    dateAdded?: string | null;
    videoConsultId?: string | null;
    videoConsultUrl?: string | null;
    /** @format date-time */
    dateTimeStart?: string | null;
    /** @format int32 */
    patientId: number;
    hasSentVideoConsultInvite: boolean;
}

export type PatientListDetailResponseBase = PatientListBaseResponse & {
    /** @format date-time */
    dateCreated?: string | null;
    /** @format int32 */
    shareCount: number;
    isCurrentUserListOwner: boolean;
    isReadonly: boolean;
    listType: PatientListType;
    createdByUserFullName?: string | null;
};

export enum PatientListType {
    UserManaged = "UserManaged",
    WorkspaceManaged = "WorkspaceManaged",
    PAS = "PAS",
}

export type PatientListBaseResponse = PatientListBase & {
    name?: string | null;
};

export interface PatientListBase {
    /** @format int32 */
    patientListId: number;
    versionToken?: string | null;
}

export interface PatientListCreateUpdateRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    patientListId?: number | null;
    /** @minLength 1 */
    name: string;
}

export type PatientListDeleteRequest = PatientListBaseRequest & object;

export type PatientListBaseRequest = PatientListBase & {
    /** @format int32 */
    organisationId: number;
};

export type PatientListShareRequest = PatientListBaseRequest & {
    /** @minLength 1 */
    emailAddress: string;
};

export type PatientListWorkspaceShareRequest = PatientListBaseRequest & object;

export type AddPatientResponse = PatientListBaseResponse & {
    patient?: PatientListEntryResponse | null;
    appointmentDays: PatientListAppointmentDayResponse[];
    patientDetails: Record<string, PatientDetailResponse>;
};

export type AddPatientRequest = SearchForPatientByNhsNumberRequest & {
    /** @format int32 */
    patientListId: number;
};

export interface SearchForPatientByNhsNumberRequest {
    /** @minLength 1 */
    nhsNumber: string;
    /** @format int32 */
    dateOfBirthYear: number;
    /** @format int32 */
    dateOfBirthMonth: number;
    /** @format int32 */
    dateOfBirthDay: number;
    /** @format int32 */
    organisationId?: number | null;
    isTestPatient?: boolean | null;
    isUserAction?: boolean | null;
}

export type BulkAddPatientResponse = PatientListBaseResponse & {
    /** @minLength 1 */
    fileId: string;
    replacedDates: string[];
    scheduledVideoConsults: PatientListEntryBaseResponse[];
};

export type ConfirmBulkAddPatientResponse = PatientListBaseResponse & {
    /** @format int32 */
    countAdded: number;
    patients: PatientListEntryResponse[];
    appointmentDays: PatientListAppointmentDayResponse[];
    patientDetails: Record<string, PatientDetailResponse>;
    errors: PatientListEntryError[];
};

export interface PatientListEntryError {
    nhsNumber: string;
    dateOfBirth: string;
    appointmentTime?: string | null;
    errorDetails: PatientEntryError;
}

export enum PatientEntryError {
    PatientNotFound = "PatientNotFound",
    InvalidNhsNumber = "InvalidNhsNumber",
    InvalidDateOfBirth = "InvalidDateOfBirth",
    DuplicateCsvEntry = "DuplicateCsvEntry",
    InvalidAppointmentTime = "InvalidAppointmentTime",
}

export type ConfirmBulkAddPatientRequest = PatientListBaseRequest & {
    /** @minLength 1 */
    fileId: string;
};

export type RemovePatientsRequest = PatientListDeleteRequest & {
    patientListEntryIds: number[];
};

export type GetScheduledVideoConsultsResponse = PatientListBase & {
    scheduledVideoConsults: PatientListEntryBaseResponse[];
};

export interface GetScheduledVideoConsultsRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    patientListId: number;
    patientListEntryIds?: number[] | null;
}

export type PatientListSummaryResponse = PatientListDetailResponseBase & {
    /** @format int32 */
    patientCount: number;
};

export type PatientMessageRequest = PatientDetailsRequest & {
    messages: PatientMessage[];
};

export interface PatientMessage {
    /** @minLength 1 */
    messageBody: string;
    messageSignoff?: string | null;
    /** @format date-time */
    sendTime?: string | null;
    enablePatientResponse?: boolean | null;
    attachedDocumentIds?: string[] | null;
    messageTemplate?: MessageTemplateWithAttachments2 | null;
    nhsChoicesLink?: NHSChoicesLink | null;
    clientMessageId?: string | null;
}

export interface SmsSuccessPredictionResponse {
    isMessageLikelyToFail?: boolean | null;
}

export type SmsSuccessPredictionRequest = PatientExternalIdentityDto & {
    /** @format int32 */
    organisationId: number;
    /** @minLength 1 */
    mobileNumber: string;
};

export type PatientVideoConsultRequest = PatientDetailsRequest & {
    messageBody?: string | null;
    messageFooter?: string | null;
    clinicianMobileNumber?: string | null;
    clientMessageId?: string | null;
};

export type PatientSelfBookRequest = PatientDetailsRequest & {
    messageBody?: string | null;
    messageFooter?: string | null;
    clientMessageId?: string | null;
    slotType?: string | null;
    targetOdsCode?: string | null;
    type?: SelfBookRequestType | null;
    snomedConceptId?: string | null;
    /** @format int32 */
    timeoutDays?: number | null;
    specificClinicianEnabled?: boolean | null;
    clinicianGroupIds?: number[] | null;
    /** @format date-time */
    sendTime?: string | null;
    availabilityRestriction?: AvailabilityRestriction | null;
};

export interface PatientDemographicSyncWorkResult {
    /** @format int32 */
    accuRxOrgId: number;
    patientDemographicUpdates: PatientDemographicUpdate[];
}

export interface PatientDemographicUpdate {
    patientIdentity: PatientExternalIdentityDto;
    mobileNumber?: string | null;
    cancelMessagesType?: CancelMessagesType | null;
    clinicalSystem?: PrincipalSystemConnectionType | null;
}

export enum CancelMessagesType {
    All = 0,
    FromPractice = 1,
}

export enum PrincipalSystemConnectionType {
    None = 0,
    EmisPartnerApi = 1,
    EmisGPSoC = 2,
    SystmOne = 3,
    Vision = 4,
}

export interface VideoTrackingResponse {
    consultationId: string;
    /** @format date-time */
    patientMessageSent?: string | null;
    /** @format date-time */
    patientMessageDelivered?: string | null;
    /** @format date-time */
    patientClickedLink?: string | null;
    /** @format date-time */
    patientSettingUpDevice?: string | null;
    /** @format date-time */
    patientLastSeenInCall?: string | null;
    /** @format int32 */
    numberOfPeopleInCall: number;
}

export interface BulkVideoTrackingResponse {
    videoTrackingResponses: VideoTrackingResponse[];
}

export interface BulkVideoTrackingRequest {
    consultationIds?: string[] | null;
}

export interface VoipTokenResponse {
    jwt: string;
    mobileNumber: string;
    voipToken: string;
    nexmoApiUrl?: string | null;
    url?: string | null;
    ipsUrl?: string | null;
    turnServers: TurnServer[];
}

export interface TurnServer {
    uris: string[];
    username: string;
    credential: string;
}

export interface EndCallData {
    /** @minLength 1 */
    callId: string;
}

export interface OrganisationAssignees {
    userGroups: UserGroupAssignee[];
    users: UserAssignee[];
}

export interface UserGroupAssignee {
    /** @format int32 */
    id: number;
    displayName?: string | null;
}

export interface UserAssignee {
    /** @format int32 */
    id: number;
    fullName?: string | null;
}

export interface ReceptionTriageToken {
    token?: string | null;
}

export interface TokenRequest {
    orgCode: string;
    /** @format date-time */
    dateOfBirth?: string | null;
    surname?: string | null;
    name: string;
    phoneNumber?: string | null;
    gender: string;
    nhsNumber?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    postCode?: string | null;
    externalIdentity?: PatientExternalIdentityDto | null;
}

export interface ReceptionTriageDetails {
    /** @format date-time */
    dateOfBirth?: string | null;
    surname: string;
    name: string;
    phoneNumber: string;
    gender: string;
    clinicianAppId: string;
    /** @format int32 */
    organisationId?: number | null;
    clinicianToken: string;
    postCode: string;
}

export interface ReceptionData {
    clinicianName: string;
    /** @format int32 */
    clinicianId: number;
    clinicianOrgCode: string;
    patientNhsNumber?: string | null;
    externalIdentity?: PatientExternalIdentityDto | null;
}

export interface ListBeastSeriesDto {
    series: BeastSeriesDto[];
}

export interface BeastSeriesDto {
    id: string;
    name: string;
    inviteSmsBody: string;
}

export interface SendBeastInvitesRequest {
    batchMessageId: string;
    beastSeriesId: string;
}
