import { useEffect } from "react";

import { Organisation, useCurrentWorkspace } from "@accurx/auth";
import { Button, Card, Ds, Link, Text } from "@accurx/design";
import {
    Link as RouterLink,
    generatePath,
    useLocation,
} from "react-router-dom";

import {
    ReportingHomepagePageViewProps,
    trackReportingHomepagePageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { REPORTS_LINKS, ReportsLinks } from "../reportsLinks";
import { isPatientTriageEnabled } from "../utils/isPatientTriageEnabled";

const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "ReportingHomepage",
} as const;

export const AllReportsPage = () => {
    const workspace = useCurrentWorkspace();

    return (
        <StyledContainer>
            <PageHeader title="Reporting" type={PageHeaderType.ListPage} />

            <Ds.Flex flexDirection="column" gap="2">
                <Text skinny>
                    We are taking a new approach to reporting, please let us
                    know what you think by providing feedback{" "}
                    <Link
                        href="https://forms.gle/h2P81jGx9Xp4GFtq6"
                        openInNewTab
                    >
                        here
                        <Link.Icon />
                    </Link>
                </Text>

                <AllReportsPageContent
                    reportsMap={REPORTS_LINKS}
                    workspace={workspace}
                />
            </Ds.Flex>
        </StyledContainer>
    );
};

export const ReportCard = ({
    name,
    description,
    path,
}: {
    name: string;
    description: string;
    path: string;
}) => {
    return (
        <Card key={name} spacingBody={3}>
            <Ds.Flex
                flexWrap={{ xs: "wrap", md: "nowrap" }}
                justifyContent="space-between"
                alignItems="center"
                gap="3"
            >
                <div>
                    <Text variant="subtitle" skinny>
                        {name}
                    </Text>
                    <Text skinny>{description}</Text>
                </div>
                <Ds.Flex.Item flex="0 0 auto">
                    <RouterLink
                        to={{ pathname: path, state: ORIGIN_PROPS }}
                        aria-label={`View ${name} report`}
                        style={{ display: "inline-block" }}
                    >
                        <Button
                            as="span"
                            text="View report"
                            theme="secondary"
                            icon={{ name: "Chart" }}
                        />
                    </RouterLink>
                </Ds.Flex.Item>
            </Ds.Flex>
        </Card>
    );
};

type AllReportsPageContentProps = {
    reportsMap: ReportsLinks;
    workspace: Organisation;
};
export const AllReportsPageContent = ({
    reportsMap,
    workspace,
}: AllReportsPageContentProps) => {
    const workspaceId = workspace.orgId;
    const { state, search } = useLocation<
        Partial<ReportingHomepagePageViewProps> | undefined
    >();
    const queryParams = new URLSearchParams(search);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        if (state && state.navigationOrigin && state.pageOrigin) {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: state.navigationOrigin,
                pageOrigin: state.pageOrigin,
            });
        } else if (
            queryParams.get("navigationOrigin")?.toLowerCase() === "desktop"
        ) {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: "Desktop",
                pageOrigin: "DesktopToolbar",
            });
        } else {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: "URL",
            });
        }
        // Only track page view once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reports = [
        ...(reportsMap.TeamUsage ? [reportsMap.TeamUsage] : []),
        ...(isPatientTriageEnabled(workspace)
            ? [reportsMap.PatientTriage]
            : []),
        reportsMap.ResponseRates,
        reportsMap.Fragments,
    ];

    return (
        <>
            {reports.map(({ name, description, path }) => (
                <ReportCard
                    key={name}
                    name={name}
                    description={description}
                    path={generatePath(path, {
                        workspaceId,
                        orgId: workspaceId,
                    })}
                />
            ))}
        </>
    );
};
