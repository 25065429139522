import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Input, Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";
import { useCompose } from "domains/compose/context";
import { getContactDetailLabel } from "domains/compose/utils";

import { ContactDetail } from "../../types";
import { ContactDetailLabel } from "./ContactDetailLabel";
import { StyledContactDetailsForm } from "./ContactDetailSelector.styles";
import { CustomRadio } from "./CustomRadio";
import { ErrorFeedback } from "./ErrorFeedback";
import { StyledUserInputWrapper } from "./UserInput.styles";

const RADIO_NAME = "Contact detail";
const RADIO_ID = {
    MOBILE: "contact-details-mobile",
    EMAIL: "contact-details-email",
};

export type ContactDetailSelectorFormProps = {
    onSubmit: (selectedContactDetail: ContactDetail) => void;
    onCancel: () => void;
    contactDetails: ContactDetail[];
    emailValue: string;
    emailOnChange: (val: string) => void;
    mobileValue: string;
    mobileOnChange: (val: string) => void;
    shouldDisableEmail?: boolean;
};

export const ContactDetailSelectorForm = ({
    onSubmit,
    onCancel,
    contactDetails,
    emailValue,
    emailOnChange,
    mobileValue,
    mobileOnChange,
    shouldDisableEmail = false,
}: ContactDetailSelectorFormProps) => {
    const { state } = useCompose();

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const [selectedContactDetail, setSelectedContactDetail] =
        useState<ContactDetail>(state.contactDetails);
    const [mobileInputError, setMobileInputError] = useState<string | null>(
        null,
    );
    const [emailInputError, setEmailInputError] = useState<string | null>(null);

    const clearErrors = () => {
        setMobileInputError(null);
        setEmailInputError(null);
    };

    const getIsValidInput = (): boolean => {
        if (selectedContactDetail.origin === "UserInput") {
            if (selectedContactDetail.method === "Mobile") {
                const isValidMobile =
                    MobileNumberHelper.isValidMobileNumber(mobileValue);

                if (!isValidMobile) {
                    setMobileInputError("Enter a valid UK mobile number");
                    return false;
                }
            } else {
                const isValidEmail =
                    EmailAddressHelper.isValidEmailAddress(emailValue);

                if (!isValidEmail) {
                    setEmailInputError("Enter a valid email address");
                    return false;
                }
            }
        }

        // Return true if contact detail is not user input
        return true;
    };

    const trackContactOptionSelect = useCallback(
        ({
            contactMethod,
            contactOrigin,
        }: {
            contactMethod: ContactDetail["method"];
            contactOrigin: ContactDetail["origin"];
        }) => {
            track("PatientMessageContactOption MenuItem Click", {
                ...nativeTrackingFields,
                contactType: contactMethod,
                contactOrigin,
                eventVersion: 3, // current dektop event has eventVersion: 2
            });
        },
        [nativeTrackingFields, track],
    );

    const isLoaded = useRef(false);

    const trackRef = useRef(trackContactOptionSelect);

    useEffect(() => {
        trackRef.current = trackContactOptionSelect;
    }, [trackContactOptionSelect]);

    useEffect(() => {
        // that check here is to avoid sending on initial load
        if (isLoaded.current) {
            trackRef.current({
                contactMethod: selectedContactDetail.method,
                contactOrigin: selectedContactDetail.origin,
            });
        }
    }, [selectedContactDetail.method, selectedContactDetail.origin]);

    useEffect(() => {
        isLoaded.current = true;
    }, []);

    const mobileInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Only update the selected contact detail if the mobile input radio
        // is selected.
        if (
            selectedContactDetail.method === "Mobile" &&
            selectedContactDetail.origin === "UserInput"
        ) {
            setSelectedContactDetail({
                ...selectedContactDetail,
                value: e.currentTarget.value,
            });
        }
        mobileOnChange(e.currentTarget.value);
    };

    const emailInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Only update the selected contact detail if the email input radio
        // is selected.
        if (
            selectedContactDetail.method === "Email" &&
            selectedContactDetail.origin === "UserInput"
        ) {
            setSelectedContactDetail({
                ...selectedContactDetail,
                value: e.currentTarget.value,
            });
        }

        emailOnChange(e.currentTarget.value);
    };

    const setUserInputMobileContactDetail = (): void => {
        setSelectedContactDetail({
            origin: "UserInput",
            method: "Mobile",
            value: mobileValue,
        });
    };

    const setUserInputEmailContactDetail = (): void => {
        setSelectedContactDetail({
            origin: "UserInput",
            method: "Email",
            value: emailValue,
        });
    };

    return (
        <>
            <StyledContactDetailsForm.Form
                onSubmit={(e) => {
                    e.preventDefault();
                    const isValidInput = getIsValidInput();

                    if (!isValidInput) {
                        return;
                    }

                    onSubmit(selectedContactDetail);
                }}
                id="contact-details"
            >
                <fieldset>
                    <StyledContactDetailsForm.Legend>
                        <UI.Text skinny variant="label" colour="night">
                            Select a contact method
                        </UI.Text>
                    </StyledContactDetailsForm.Legend>
                    <StyledContactDetailsForm.RadioWrapper>
                        {contactDetails.map((contactDetail) => (
                            <CustomRadio
                                checkPlacement="top-right"
                                key={contactDetail.value}
                                name={RADIO_NAME}
                                theme="bordered"
                                disabled={
                                    contactDetail.method === "Email" &&
                                    shouldDisableEmail
                                }
                                id={contactDetail.value}
                                label={
                                    <ContactDetailLabel
                                        text={contactDetail.value}
                                        badgeProps={{
                                            text: getContactDetailLabel(
                                                contactDetail.origin,
                                            ),
                                            selected:
                                                contactDetail.value ===
                                                selectedContactDetail.value,
                                        }}
                                    />
                                }
                                value={contactDetail.value}
                                checkedValue={getCheckedValue(
                                    selectedContactDetail,
                                )}
                                onChange={() => {
                                    setSelectedContactDetail(contactDetail);

                                    clearErrors();
                                }}
                            />
                        ))}

                        <UI.Flex flexDirection="column" gap="0.5">
                            <CustomRadio
                                key="MobileUserInput"
                                name={RADIO_NAME}
                                theme="bordered"
                                id="Mobile"
                                aria-labelledby={RADIO_ID.MOBILE}
                                value="Mobile"
                                checkPlacement="top-right"
                                checkedValue={getCheckedValue(
                                    selectedContactDetail,
                                )}
                                label={
                                    <StyledUserInputWrapper
                                        flexDirection={"column"}
                                        gap="1"
                                    >
                                        <UI.Text
                                            variant="preview"
                                            skinny
                                            as="label"
                                            props={{
                                                id: RADIO_ID.MOBILE,
                                                htmlFor: "Mobile",
                                            }}
                                        >
                                            Enter patient's mobile number
                                        </UI.Text>
                                        <Input
                                            onChange={mobileInputOnChange}
                                            onFocus={() => {
                                                setUserInputMobileContactDetail();
                                                clearErrors();
                                            }}
                                            value={mobileValue}
                                            title="Mobile number"
                                            aria-describedby={
                                                mobileInputError !== null
                                                    ? "mobile-input-error"
                                                    : undefined
                                            }
                                        />
                                    </StyledUserInputWrapper>
                                }
                                onChange={() => {
                                    setUserInputMobileContactDetail();
                                    clearErrors();
                                }}
                            />
                            {mobileInputError !== null && (
                                <ErrorFeedback
                                    id="mobile-input-error"
                                    errorMessage={mobileInputError}
                                />
                            )}
                        </UI.Flex>
                        <UI.Flex flexDirection="column" gap="0.5">
                            <CustomRadio
                                key="EmailUserInput"
                                name={RADIO_NAME}
                                theme="bordered"
                                disabled={shouldDisableEmail}
                                id="Email"
                                aria-labelledby={RADIO_ID.EMAIL}
                                value="Email"
                                aria-label="Enter patient's email address"
                                checkPlacement="top-right"
                                checkedValue={getCheckedValue(
                                    selectedContactDetail,
                                )}
                                label={
                                    <StyledUserInputWrapper
                                        flexDirection={"column"}
                                        gap="1"
                                    >
                                        <UI.Text
                                            variant="preview"
                                            skinny
                                            as="label"
                                            props={{
                                                id: RADIO_ID.EMAIL,
                                                htmlFor: "Email",
                                            }}
                                        >
                                            Enter patient's email address
                                        </UI.Text>
                                        <Input
                                            onChange={emailInputOnChange}
                                            onFocus={() => {
                                                setUserInputEmailContactDetail();
                                                clearErrors();
                                            }}
                                            value={emailValue}
                                            disabled={shouldDisableEmail}
                                            title="Email address"
                                            aria-describedby={
                                                emailInputError !== null
                                                    ? "email-input-error"
                                                    : undefined
                                            }
                                        />
                                    </StyledUserInputWrapper>
                                }
                                onChange={() => {
                                    setUserInputEmailContactDetail();
                                    clearErrors();
                                }}
                            />
                            {emailInputError !== null && (
                                <ErrorFeedback
                                    id="email-input-error"
                                    errorMessage={emailInputError}
                                />
                            )}
                        </UI.Flex>
                    </StyledContactDetailsForm.RadioWrapper>
                </fieldset>
            </StyledContactDetailsForm.Form>
            <StyledContactDetailsForm.HorizontalBorder />
            <StyledContactDetailsForm.ButtonsWrapper
                justifyContent={"space-between"}
            >
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={onCancel}
                    type="button"
                >
                    <Pill.Text>Cancel</Pill.Text>
                </Pill.SecondaryButton>
                <Pill.PrimaryButton
                    form="contact-details"
                    type="submit"
                    dimension="small"
                >
                    <Pill.Text>Confirm</Pill.Text>
                </Pill.PrimaryButton>
            </StyledContactDetailsForm.ButtonsWrapper>
        </>
    );
};

const getCheckedValue = (selectedContactDetail: ContactDetail) => {
    if (selectedContactDetail.origin === "UserInput") {
        if (selectedContactDetail.method === "Email") {
            return "Email";
        }
        return "Mobile";
    }

    return selectedContactDetail.value;
};
