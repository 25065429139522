import { UserGroupType } from "@accurx/api/ticket";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

const mapTeamType = (
    type: UserGroupType | null | undefined,
): TeamSummary["type"] => {
    switch (type) {
        case UserGroupType.Florey:
            return "Florey";
        case UserGroupType.ToAssign:
            return "ToAssignPatientMessaging";
        case UserGroupType.PracticeGroup:
            return "Hub";
        case UserGroupType.FailedDeliveryReceipts:
            return "FailedDeliveryReceipts";
        case UserGroupType.PatientSingleResponse:
            return "PatientSingleResponse";
        default:
            return "Default";
    }
};

export const mapUserGroupToTeamSummary = (input: {
    id?: string | null;
    name?: string | null;
    type?: UserGroupType | null;
    isMember?: boolean | null;
    canBeAssignedTo?: boolean;
}): TeamSummary | undefined => {
    if (!input.id) {
        return undefined;
    }

    const team: TeamSummary = {
        id: input.id,
        displayName: input.name || input.id,
        type: mapTeamType(input.type),
    };

    /**
     * Both isMember and canBeAssigned are optional fields as some API responses
     * expectedly do not have them. In cases where a response does not send
     * these fields, we do not include it in the returned team so that the
     * existing value is retained when this team is added to the store.
     */
    if (!isNil(input.isMember)) {
        team.isMember = input.isMember;
    }

    if (!isNil(input.canBeAssignedTo)) {
        team.canBeAssignedTo = input.canBeAssignedTo;
    }

    // Unfortunately the patient messaging unassigned team and the clinician
    // messaging unassigned team have the same team type on the server and the
    // only way we can tell the difference is that the clinician messaging
    // unassigned team is called "Accumail Unassigned"
    if (
        team.type === "ToAssignPatientMessaging" &&
        team.displayName === "Accumail Unassigned"
    ) {
        team.type = "ToAssignClinicianMessaging";
    }

    // Change all ToAssign team display names to "Unassigned"
    if (
        team.type === "ToAssignPatientMessaging" ||
        team.type === "ToAssignClinicianMessaging"
    ) {
        team.displayName = "Unassigned";
    }

    return team;
};
