import { CollaborativeWorkspaceResponse } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const getWorkspacesByNationalCode = (
    nationalCode: string,
): Promise<IWrappedResult<CollaborativeWorkspaceResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/account/nhsorganisations/${nationalCode}/workspaces`,
        }),
    );

const GET_WORKSPACES_CACHE_KEY = "GetWorkspacesByNationalCode";

type GetWorkspacesQueryVariables = {
    nationalCode?: string;
};

type GetWorkspacesQueryOptions = Pick<
    UseQueryOptions<CollaborativeWorkspaceResponse[], Error>,
    "refetchOnMount"
>;

export const useGetWorkspacesByNationalCodeQuery = (
    { nationalCode }: GetWorkspacesQueryVariables,
    { refetchOnMount }: GetWorkspacesQueryOptions = { refetchOnMount: false },
) => {
    return useQuery<CollaborativeWorkspaceResponse[], Error>({
        queryKey: [GET_WORKSPACES_CACHE_KEY, { nationalCode }],
        queryFn: async () => {
            if (!nationalCode) {
                throw new Error(
                    "No national code provided to fetch workspaces",
                );
            }
            const response = await getWorkspacesByNationalCode(nationalCode);
            return returnDataOrThrow(response);
        },
        refetchOnMount,
        enabled: !!nationalCode,
    });
};
