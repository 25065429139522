import { OUTER_CLASSNAME } from "./TemplatesCombobox";
import {
    StyledInput,
    StyledTemplateSearchWrapper,
} from "./TemplatesCombobox.styles";

export const DisabledCombobox = ({
    value,
    labelPlaceholder,
}: {
    value: string;
    labelPlaceholder: string;
}) => {
    return (
        <StyledTemplateSearchWrapper>
            <StyledInput
                disabled
                role="decoration"
                isSearchInput
                value={value}
                placeholder={labelPlaceholder}
                outerClassName={OUTER_CLASSNAME}
            />
        </StyledTemplateSearchWrapper>
    );
};
