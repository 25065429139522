import { useCurrentWorkspace } from "@accurx/auth";
import { Log } from "@accurx/shared";

import { mutateSignalRClientOn } from "../api/endpoints/mutateSignalRClientOn";
import { NativeTransportError } from "../errors";
import { useNativeTransport } from "./useNativeTransport";

// A hook to enable setting up a SignalR subscription via the native bridge
// This is a pre-requisite for subscribing to a SignalR method via the native bridge
export const useSignalRClientOn = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return async ({ methodName }: { methodName: string }): Promise<void> => {
        if (!transport) {
            throw new NativeTransportError(
                "useSignalRClientOn: No native transport",
            );
        }

        try {
            await mutateSignalRClientOn(
                {
                    transport,
                    workspaceId,
                },
                { methodName },
            );
        } catch (err) {
            Log.error(
                "MutateSignalRClientOn: Failed sending a SignalR message to the Native Bridge",
                {
                    tags: {
                        methodName,
                        error: (err as Error).message,
                    },
                },
            );
        }
    };
};
