import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Link, Text } from "@accurx/design";

import { AllReportsPageContent } from "app/reporting/pages/AllReportsPage";
import { ROUTES_ORGS } from "shared/Routes";

import { MainPanel } from "../settings/SettingsPage.styles";
import { Breadcrumb, Breadcrumbs, Container, Header } from "../shared";

const REPORTS = {
    PatientTriage: {
        name: "Patient Triage",
        description:
            "Use this report to better understand and plan for your organisation's inbound demand.",
        path: ROUTES_ORGS.reportPatientTriage,
    },
    ResponseRates: {
        name: "Response rates: Questionnaires & Self-Book",
        description:
            "Use this report to better understand the performance of the questionnaires you send, and the appointments that you offer.",
        path: ROUTES_ORGS.reportResponseRates,
    },
    Fragments: {
        name: "Fragments",
        description:
            "Use this report to better understand the number of fragments and SMS messages sent by your organisation, as well as insights about other messaging channels (e.g. the NHS App).",
        path: ROUTES_ORGS.reportFragments,
    },
} as const;

export const ReportingPage = () => {
    const workspace = useCurrentWorkspace();

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb text="Reporting" path={ROUTES_ORGS.reporting} />
                </Breadcrumbs>
                <Text as="h1" variant="title">
                    Reporting
                </Text>
                <Text>
                    We are taking a new approach to reporting, please let us
                    know what you think by providing feedback{" "}
                    <Link
                        href="https://forms.gle/h2P81jGx9Xp4GFtq6"
                        openInNewTab
                    >
                        here
                        <Link.Icon />
                    </Link>
                    .
                </Text>
            </Header>
            <Container>
                <MainPanel>
                    <Ds.Flex flexDirection="column" gap="2">
                        <AllReportsPageContent
                            reportsMap={REPORTS}
                            workspace={workspace}
                        />
                    </Ds.Flex>
                </MainPanel>
            </Container>
        </>
    );
};
