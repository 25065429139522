import { Log } from "@accurx/shared";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { updateUnreadState } from "domains/concierge/internal/util/updateUnreadState";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import pickBy from "lodash/pickBy";

type Payload = {
    unreadItemsSummaries: UnreadItemsSummary[];
};

export const populateClinicianMessagingUnreadItems = (
    state: Draft<ConversationsState>,
    action: PayloadAction<Payload>,
) => {
    // annoyingly the conversations slice complains if we type the meta as part
    // of the function definition. I'm sure there's a better way but typecasting
    // here for now
    const { payload, meta } = action as ConciergeAction<Payload>;

    // Reset clinician messaging unread count state
    state.unreadCounts.clinicianMessaging = {
        user: 0,
        teams: {},
    };

    // Clear all pre-existing unread item summaries for clinician messaging
    // based conversations.
    state.unreadItems = pickBy(
        state.unreadItems,
        (item) => item.system !== "ClinicianMessaging",
    );

    for (const update of payload.unreadItemsSummaries) {
        // Initial unreads should only ever send conversations with unread
        // items.
        if (!update.itemIds.length) {
            Log.warn(
                "Conversation appeared in unreads payload without any unread items",
                {
                    tags: {
                        product: "Inbox",
                        conversationId: update.conversationId,
                    },
                },
            );
            continue;
        }

        // Initial unreads should only ever send open conversations.
        if (update.status !== "Open") {
            Log.warn("A non-open conversation appeared in unreads payload", {
                tags: {
                    product: "Inbox",
                    conversationId: update.conversationId,
                    conversationStatus: update.status,
                },
            });
            continue;
        }

        updateUnreadState({
            state,
            current: undefined,
            next: update,
            userId: meta.userId,
        });
    }

    return state;
};
