import { Feature } from "../../types/Feature";
import { FeatureName } from "../../types/FeatureName";

/**
 * Returns an arrays with every single feature flag set as enabled
 */
export const allOrgUserFeatureFlagsEnabled = (
    Object.keys(FeatureName) as Feature[]
).map((f) => ({
    name: f,
    isEnabled: true,
}));
