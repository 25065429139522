import { TemplateItem } from "domains/compose/types";
import orderBy from "lodash/orderBy";

const SORTING_OWNER_RANK = {
    User: 1,
    Organisation: 2,
    Accurx: 3,
} as const;
const SORTING_TYPE_RANK = {
    MessageTemplate: 1,
    QuestionnaireTemplate: 2,
} as const;

export const sortTemplates = (templates: TemplateItem[]) => {
    const sorted = orderBy(templates, [
        // sort User / Organisation / Accurx
        (template) => SORTING_OWNER_RANK[template.value.owner],
        // sort no heading, then in alphabetical order
        (template) => (template.value.heading || "").toLowerCase(),
        // sort by type
        (template) => SORTING_TYPE_RANK[template.type],
        (template) => (template.value.title || "").toLowerCase(),
    ]);

    return sorted;
};
