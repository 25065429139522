import { useEffect, useMemo } from "react";

import { TemplateItem } from "domains/compose/types";
import Fuse from "fuse.js";
import debounce from "lodash/debounce";

export const useTemplateSearchDebounced = (
    data: TemplateItem[],
    callback: (results: TemplateItem[]) => void,
) => {
    const fuseSearchFn = useMemo(() => {
        return new Fuse(data, {
            keys: ["value.title", "value.heading"],
            threshold: 0.2,
            // We are pre-sorting templates in a custom way so we don't want them re-sorted
            shouldSort: false,
            // Ignore the location and the distance of the matched search term. This is important
            // for templates with longer titles
            ignoreLocation: true,
        });
    }, [data]);

    const debouncedSearchfn = useMemo(
        () =>
            debounce((searchTerm: string) => {
                const results = fuseSearchFn
                    .search(searchTerm)
                    .map((result) => result.item);
                callback(results);
            }, 300),
        [fuseSearchFn, callback],
    );

    useEffect(() => {
        return () => debouncedSearchfn.cancel();
    }, [debouncedSearchfn]);

    return debouncedSearchfn;
};
