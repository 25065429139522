import { z } from "zod";

export const SubscribeSignalRInvocationsSchema = z.object({
    methodName: z.string(),
    parameter: z.unknown(),
});

export type SubscribeSignalRInvocations = z.infer<
    typeof SubscribeSignalRInvocationsSchema
>;
