import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledExpandableContainer = styled(UI.Flex).attrs({ gap: "1" })<{
    $isExpanded: boolean;
    $isFloating: boolean;
}>`
    ${(props) => props.$isExpanded && "flex: 1;"}
    margin: ${(props) => {
        if (props.$isFloating) {
            return UI.Tokens.SIZES[3];
        }

        const topMargin = props.$isExpanded ? UI.Tokens.SIZES[2] : "0";

        return `${topMargin} ${UI.Tokens.SIZES[2]} ${UI.Tokens.SIZES[1]}`;
    }};

    position: ${(props) => (props.$isFloating ? "absolute" : "inherit")};
    bottom: 0;
    left: 0;
`;
