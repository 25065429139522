import { WorkspaceStatus } from "@accurx/api/account";

import { User } from "../../types/User";

export const theShirePractice: Omit<User["organisations"][0], "settings"> = {
    defaultWorkspaceId: 1,
    orgId: 1,
    organisationName: "The Shire Practice",
    organisationNodeName: "The Shire Practice",
    nationalCode: "ABBBB",
    userSpecialtyRequired: false,
    workspaceSpecialties: [],
    userSpecialty: { name: "Immunology", id: 3 },
    status: WorkspaceStatus.Active,
};

export const theLondonPractice: Omit<User["organisations"][0], "settings"> = {
    defaultWorkspaceId: 2,
    orgId: 2,
    organisationName: "The London Practice",
    organisationNodeName: "The London Practice",
    nationalCode: "AB123",
    userSpecialtyRequired: false,
    workspaceSpecialties: [],
    userSpecialty: { name: "Immunology", id: 3 },
    status: WorkspaceStatus.Active,
};

/** Careful - this and aLondonPracticeWorkspace have the same id */
export const sunnyHillSurgery: Omit<User["organisations"][0], "settings"> = {
    defaultWorkspaceId: 3,
    orgId: 3,
    organisationName: "Sunny Hill Surgery",
    organisationNodeName: "Sunny Hill Surgery",
    nationalCode: "SHS3",
    userSpecialtyRequired: false,
    workspaceSpecialties: [],
    userSpecialty: { name: "Immunology", id: 3 },
    status: WorkspaceStatus.Deprecated,
};

export const parkLanePractice: Omit<User["organisations"][0], "settings"> = {
    defaultWorkspaceId: 4,
    orgId: 4,
    organisationName: "Park Lane Practice",
    organisationNodeName: "Park Lane Practice",
    nationalCode: "PLP1",
    userSpecialtyRequired: false,
    workspaceSpecialties: [],
    userSpecialty: { name: "Immunology", id: 3 },
    status: WorkspaceStatus.Active,
};

export const aLondonPracticeWorkspace: Omit<
    User["organisations"][0],
    "settings"
> = {
    defaultWorkspaceId: 2,
    orgId: 11,
    organisationName: "A London Practice workspace",
    organisationNodeName: theLondonPractice.organisationName,
    nationalCode: "AB123",
    userSpecialtyRequired: false,
    workspaceSpecialties: [],
    status: WorkspaceStatus.Active,
};

// A collaborative workspace within the sunnyHillSurgery organisation
export const cardiologyWorkspace: Omit<User["organisations"][0], "settings"> = {
    orgId: 5,
    organisationName: "Cardiology",
    organisationNodeName: sunnyHillSurgery.organisationName,
    description: "The cardiology department at Sunny Hill Surgery",
    nationalCode: sunnyHillSurgery.nationalCode,
    defaultWorkspaceId: sunnyHillSurgery.orgId,
    userSpecialtyRequired: false,
    workspaceSpecialties: ["Cardiology and Stroke"],
    status: WorkspaceStatus.Active,
};
