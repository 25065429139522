import { ExampleWorkspace } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type ExampleWorkspacesRequest = { nationalCode: string; limit?: number };

type ExampleWorkspacesQueryOptions = Pick<
    UseQueryOptions<ExampleWorkspace[], Error>,
    "refetchOnMount"
>;

const WORKSPACE_MANAGEMENT_EXAMPLE_WORKSPACES_CACHE_KEY =
    "GetExampleWorkspacesByNationalCode";

const fetchExampleWorkspaces = async ({
    limit,
    nationalCode,
}: ExampleWorkspacesRequest): Promise<IWrappedResult<ExampleWorkspace[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/account/nhsorganisations/${nationalCode}/exampleworkspaces`,
            query: limit ? `?limit=${limit}` : undefined,
        }),
    );

export const useGetExampleWorkspacesByNationalCodeQuery = (
    { limit, nationalCode }: { nationalCode?: string; limit?: number },
    { refetchOnMount }: ExampleWorkspacesQueryOptions = {
        refetchOnMount: false,
    },
) => {
    return useQuery<ExampleWorkspace[], Error, ExampleWorkspace[]>({
        queryKey: [
            WORKSPACE_MANAGEMENT_EXAMPLE_WORKSPACES_CACHE_KEY,
            { nationalCode, limit },
        ],
        queryFn: async () => {
            if (!nationalCode) {
                throw new Error(
                    "No national code provided to fetch example workspaces",
                );
            }
            const response = await fetchExampleWorkspaces({
                limit,
                nationalCode,
            });

            return returnDataOrThrow(response);
        },
        refetchOnMount,
        enabled: !!nationalCode,
    });
};
