import { useEffect, useMemo, useState } from "react";

import {
    FeatureFlag,
    getFeatureOverrides,
    useAuth,
    useCurrentWorkspace,
} from "@accurx/auth";
import {
    Button,
    Checkbox,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import sortBy from "lodash/sortBy";

const FeatureFlagPopoverContent = () => {
    const workspace = useCurrentWorkspace();
    const overrides = useMemo(() => getFeatureOverrides(), []);

    const featuresFromBE = workspace.settings.features;

    const [newFeatureFlags, setNewFeatureFlags] = useState<FeatureFlag[]>([]);
    const [newOverrides, setNewOverrides] = useState(overrides);

    useEffect(() => {
        if (overrides && featuresFromBE) {
            const featuresWithOverrides = featuresFromBE.map((feature) => {
                const override = overrides[feature.name];

                return {
                    name: feature.name,
                    isEnabled:
                        override !== undefined ? override : feature.isEnabled,
                };
            });
            setNewFeatureFlags(featuresWithOverrides);
        }
    }, [overrides, featuresFromBE]);

    const updateFeatureFlags = (): void => {
        // Update search params
        const searchParams = new URLSearchParams(window.location.search);

        const featuresOn = Object.keys(newOverrides).filter(
            (key) => newOverrides[key] === true,
        );
        const featuresOff = Object.keys(newOverrides).filter(
            (key) => newOverrides[key] === false,
        );

        if (featuresOn.length > 0) {
            searchParams.set("featuresOn", featuresOn.join(","));
        }
        if (featuresOff.length > 0) {
            searchParams.set("featuresOff", featuresOff.join(","));
        }

        Log.info("Feature flags overridden via feature flag popover 🚩");
        window.location.search = searchParams.toString();
    };

    const onChange = (checked: boolean, featureName: string) => {
        setNewFeatureFlags((prevFeatureFlags) => {
            const updatedFlags = [...prevFeatureFlags];
            const flagToUpdate = prevFeatureFlags.find(
                (flag) => flag?.name === featureName,
            );
            if (flagToUpdate) {
                flagToUpdate.isEnabled = checked;
                setNewOverrides((prevOverrides) => ({
                    ...prevOverrides,
                    [flagToUpdate.name]: checked,
                }));
            }
            return updatedFlags;
        });
    };

    const sortedFeatureFlags = sortBy(newFeatureFlags, "name");
    return (
        <>
            <Button onClick={updateFeatureFlags} text="Apply changes" />
            <ul style={{ padding: "6px 0", margin: 0 }}>
                {sortedFeatureFlags.map((feature) => (
                    <li
                        style={{ listStyle: "none", padding: 0, margin: 0 }}
                        key={feature.name}
                    >
                        <Checkbox
                            text={feature.name}
                            value={feature.name}
                            checked={feature.isEnabled}
                            id={feature.name}
                            onCheckChange={(checked) => {
                                onChange(checked, feature.name);
                            }}
                        ></Checkbox>
                    </li>
                ))}
            </ul>
        </>
    );
};

export const FeatureFlagPopover = (): JSX.Element | null => {
    const [open, setOpen] = useState(false);
    const { user } = useAuth();

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <Button
                    theme="secondary"
                    onClick={() => void 0}
                    icon={{ name: "Flag" }}
                    text="Feature flags"
                    dimension="small"
                    disabled={!user.isLoggedIn}
                />
            </PopoverTrigger>
            <PopoverContent side="top" align="center" width="max-content">
                <FeatureFlagPopoverContent />
            </PopoverContent>
        </Popover>
    );
};
