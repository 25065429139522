import { ReactNode } from "react";

import { useAuth } from "@accurx/auth";
import { NativeTransportProvider } from "@accurx/native";
import { useHubClientTransport } from "domains/transport/hooks/useHubClientTransport";
import { useNativeSignalRTransport } from "domains/transport/hooks/useNativeSignalRTransport";
import { useOptionalHubClient } from "domains/transport/hooks/useOptionalHubClient";
import { useSignalRConnectionState } from "domains/transport/hooks/useSignalRConnectionState";

import { NativeSubscriptionsProvider } from "./NativeSubscriptionProvider";
import { TransportContext } from "./TransportContext";

const TransportContextProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useAuth();

    const hubClient = useOptionalHubClient({
        currentUserId: user.isLoggedIn ? user.accuRxUserId : null,
    });
    const connectionState = useSignalRConnectionState(hubClient);

    const hubClientTransport = useHubClientTransport(hubClient);
    const nativeTransport = useNativeSignalRTransport();

    const transport = hubClient ? hubClientTransport : nativeTransport;

    return (
        <TransportContext.Provider
            value={{ transport, connectionState, hubClient }}
        >
            {children}
        </TransportContext.Provider>
    );
};

export const TransportProvider = ({
    workspaceId,
    children,
}: {
    workspaceId: number;
    children: ReactNode;
}) => {
    return (
        <NativeTransportProvider>
            <NativeSubscriptionsProvider workspaceId={workspaceId}>
                <TransportContextProvider>{children}</TransportContextProvider>
            </NativeSubscriptionsProvider>
        </NativeTransportProvider>
    );
};
