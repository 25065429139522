import {
    FloreyAvailabilityState,
    FloreyMessageTemplate,
    FloreyProductType,
    FloreyTemplatesResponse,
} from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";

import {
    DisplayFloreyMessageTemplate,
    DisplayFloreyQuestionnaireGroup,
    DisplayFloreyQuestionnaires,
} from "./types";

const mapAvailabilityEnumToString = (
    availability: FloreyAvailabilityState,
): DisplayFloreyMessageTemplate["availability"] => {
    switch (availability) {
        case FloreyAvailabilityState.Available:
        case FloreyAvailabilityState.AvailableForTestPatient:
            return "available";

        case FloreyAvailabilityState.Blocked:
            return "blocked";

        case FloreyAvailabilityState.ComingSoon:
            return "comingSoon";

        case FloreyAvailabilityState.None:
            return "unknown";

        default:
            // Needed so typescript build fails when a new
            // enum value is added without being mapped here
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = availability;
            return "unknown";
    }
};

const mapFloreyTemplateToDisplay = (
    template: FloreyMessageTemplate,
): DisplayFloreyMessageTemplate | null => {
    if (!template.title || !template.templateBody) {
        Log.error(`Unable to map Florey. Missing title or body.`, {
            tags: { floreyId: template.id },
        });

        return null;
    } else if (!template.defaultUserGroupId) {
        Log.error(`Unable to map Florey. Missing defaultUserGroupId.`, {
            tags: { floreyId: template.id },
        });

        return null;
    } else {
        return {
            title: template.title,
            id: template.id,
            body: template.templateBody,
            defaultAssigneeTeamId: template.defaultUserGroupId,
            availability: mapAvailabilityEnumToString(template.availability),
        };
    }
};

/**
 * Maps a FloreyTemplatesResponse to a UI friendly, simplified object.
 *
 * @param response API response when fetching florey questionnaires
 * @returns A UI friendly, simplified object result
 */
export const mapFloreyTemplatesResponseToDisplay = (
    response: FloreyTemplatesResponse,
): DisplayFloreyQuestionnaires => {
    if (!response.groups) {
        return {
            groups: [],
        };
    }

    const mappedGroups = response.groups.reduce<{
        nonCustom: DisplayFloreyQuestionnaireGroup[];
        custom: DisplayFloreyQuestionnaireGroup[];
    }>(
        (prevGroupsMapped, currentGroup) => {
            if (
                currentGroup.messageTemplates &&
                currentGroup.messageTemplates.length > 0
            ) {
                const mappedTemplates = currentGroup.messageTemplates.reduce<
                    DisplayFloreyMessageTemplate[]
                >((prevTemplatesMapped, currentTemplate) => {
                    const mappedTemplate =
                        mapFloreyTemplateToDisplay(currentTemplate);
                    if (mappedTemplate) {
                        return [...prevTemplatesMapped, mappedTemplate];
                    }
                    return prevTemplatesMapped;
                }, []);

                if (mappedTemplates.length > 0) {
                    const mappedGroup = {
                        heading: currentGroup.heading || null,
                        messageTemplates: mappedTemplates,
                        // A group is either all custom floreys or not, so just check the first entry
                        isCustomFloreyGroup:
                            currentGroup.messageTemplates[0].type ===
                            FloreyProductType.OrganisationCustom,
                    };

                    if (mappedGroup.isCustomFloreyGroup) {
                        return {
                            ...prevGroupsMapped,
                            custom: [...prevGroupsMapped.custom, mappedGroup],
                        };
                    } else {
                        return {
                            ...prevGroupsMapped,
                            nonCustom: [
                                ...prevGroupsMapped.nonCustom,
                                mappedGroup,
                            ],
                        };
                    }
                }
            }
            return prevGroupsMapped;
        },
        { nonCustom: [], custom: [] },
    );

    // Return non-custom templates before custom
    return {
        groups: [...mappedGroups.nonCustom, ...mappedGroups.custom],
    };
};

/**
 * Filters groups which have available templates within them
 */
export const filterGroupsWithAvailableTemplatesOnly = (
    groups: DisplayFloreyQuestionnaires,
) => {
    return {
        groups: groups.groups.reduce<DisplayFloreyQuestionnaireGroup[]>(
            (prevMappedGroup, currentGroup) => {
                const availableTemplates = currentGroup.messageTemplates.filter(
                    (template) => template.availability === "available",
                );

                if (availableTemplates.length > 0) {
                    return [
                        ...prevMappedGroup,
                        {
                            ...currentGroup,
                            messageTemplates: availableTemplates,
                        },
                    ];
                }

                return prevMappedGroup;
            },
            [],
        ),
    };
};
