import { NavTabs } from "@accurx/navigation";
import { AppSettings } from "@accurx/shared";
import { useLocation } from "react-router-dom";

import {
    ReportingPageViewProps,
    trackReportingPageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { PowerBIReport } from "../components/PowerBIReport";

const TABS = {
    overview: { tabId: "overview", text: "Overview" },
    "message-usage": { tabId: "message-usage", text: "Message Usage" },
    "fragment-usage": { tabId: "fragment-usage", text: "Fragment Usage" },
    "nhs-app": { tabId: "nhs-app", text: "NHS App" },
} as const;
type TabId = keyof typeof TABS;

const REPORT_NAME =
    AppSettings.accurxEnvironment === "production"
        ? "Fragment Report"
        : "Fragment Report [DEV]";

const TRACKING_REPORT_NAME = "FragmentsReport";
const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "FragmentsReport",
} as const;

export const FragmentsPage = () => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { state } = useLocation<
        Partial<ReportingPageViewProps> | undefined
    >();

    const handleTabDisplay = (nextTabId: string) => {
        trackReportingPageView({
            ...analyticsLoggedInProps,
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: TABS[nextTabId as TabId].text,
            reportName: TRACKING_REPORT_NAME,
        });
    };

    return (
        <StyledContainer>
            <PageHeader title="Fragments" type={PageHeaderType.ListPage} />

            <NavTabs
                baseRoute={ROUTES_WORKSPACE.reportingFragments}
                defaultTabId={TABS["overview"].tabId}
                onTabDisplay={handleTabDisplay}
                routeState={ORIGIN_PROPS}
            >
                <NavTabs.Tabs tabs={Object.values(TABS)} />

                <NavTabs.Panel tabId={TABS.overview.tabId}>
                    <PowerBIReport
                        pageName="Overview"
                        reportName={REPORT_NAME}
                        pageOrigin={TRACKING_REPORT_NAME}
                    />
                </NavTabs.Panel>
                <NavTabs.Panel tabId={TABS["message-usage"].tabId}>
                    <PowerBIReport
                        pageName="Message Usage"
                        reportName={REPORT_NAME}
                        pageOrigin={TRACKING_REPORT_NAME}
                    />
                </NavTabs.Panel>
                <NavTabs.Panel tabId={TABS["fragment-usage"].tabId}>
                    <PowerBIReport
                        pageName="Fragment Usage"
                        reportName={REPORT_NAME}
                        pageOrigin={TRACKING_REPORT_NAME}
                    />
                </NavTabs.Panel>
                <NavTabs.Panel tabId={TABS["nhs-app"].tabId}>
                    <PowerBIReport
                        pageName="NHS App"
                        reportName={REPORT_NAME}
                        pageOrigin={TRACKING_REPORT_NAME}
                    />
                </NavTabs.Panel>
            </NavTabs>
        </StyledContainer>
    );
};
