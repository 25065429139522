import * as UI from "@accurx/design";
import { Input } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledInput = styled(Input)`
    width: 100%;
`;

export const StyledTemplateSearchWrapper = styled.div`
    position: relative;
    flex: 1;

    .combobox-input {
        &:before {
            border-color: ${UI.Tokens.COLOURS.greyscale.silver};
        }
    }
`;

const EXTRA_MARGIN = 4;
export const StyledComboboxDropdown = styled.div<{
    $isOpen: boolean;
    $dropdownHeight: number;
    $openSide: "top" | "bottom";
}>`
    z-index: 1;
    position: absolute;
    max-height: ${({ $dropdownHeight }) => {
        // Do not exceed 300px of height
        if ($dropdownHeight > 300) {
            return "300px";
        }

        return `${$dropdownHeight - EXTRA_MARGIN}px`;
    }};

    ${({ $openSide }) => {
        if ($openSide === "top") {
            return `
                top: auto;
                bottom: 100%;
                // Have the dropdown sit outside of the focus area
                margin-bottom: ${EXTRA_MARGIN}px;
                border-top-left-radius: ${UI.Tokens.BORDERS.radius};
                border-top-right-radius: ${UI.Tokens.BORDERS.radius};
            `;
        } else {
            return `
                // Have the dropdown sit outside of the focus area
                margin-top: ${EXTRA_MARGIN}px;
                border-top: none;
                border-bottom-left-radius: ${UI.Tokens.BORDERS.radius};
                border-bottom-right-radius: ${UI.Tokens.BORDERS.radius};
            `;
        }
    }}

    width: 100%;

    display: flex;
    flex-direction: column;

    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    border: solid 1px ${UI.Tokens.COLOURS.greyscale.silver};
    overflow: hidden;

    ${({ $isOpen }) => {
        if (!$isOpen) {
            return "display: none;";
        }
        return null;
    }}
`;

export const StyledList = styled.ul`
    overflow: auto;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
    position: relative;
`;

type StyledOptionProps = {
    $isHighlighted: boolean;
};

export const StyledOption = styled(UI.Ds.Flex).attrs({
    alignItems: "center",
    gap: "1",
})<StyledOptionProps>`
    cursor: pointer;
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};
    color: ${UI.Tokens.COLOURS.greyscale.night};

    ${({ $isHighlighted }) => {
        if ($isHighlighted) {
            return `
                background-color: ${UI.Tokens.COLOURS.primary.blue[5]};
            `;
        }
        return `
            background-color: ${UI.Tokens.COLOURS.greyscale.white};
        `;
    }}
`;
export const StyledEllipsisText = styled(UI.Text).attrs({
    skinny: true,
    variant: "preview",
})`
    word-wrap: break-word;
    overflow: hidden;
    hyphens: auto;
`;

export const StyledChevron = styled(UI.Ds.Icon).attrs({
    color: "blue",
    size: "xsmall",
    name: "Arrow",
    rotate: "90",
})``;
export const StyledPreviewOption = styled.div<StyledOptionProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};

    font-size: ${UI.Tokens.TYPOGRAPHY.preview.mobile.fontSize};
    line-height: normal;
    font-weight: bold;
    color: ${UI.Tokens.COLOURS.primary.blue[100]};
    border-top: ${UI.Tokens.BORDERS.normal.silver};

    ${({ $isHighlighted }) => {
        if ($isHighlighted) {
            return `
                background-color: ${UI.Tokens.COLOURS.primary.blue[5]};
                text-decoration: underline;
                ${StyledChevron} {
                    visibility: visible;
                }
            `;
        }
        return `
            background-color: ${UI.Tokens.COLOURS.greyscale.white};
            ${StyledChevron} {
                visibility: hidden;
            }
        `;
    }}
`;

export const StyledNoResultsMessage = styled.div`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
`;

export const StyledHeadingLevelOne = styled.li`
    cursor: default;
    position: sticky;
    z-index: 1;
    top: 0;

    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1.5]};

    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};

    color: ${UI.Tokens.COLOURS.greyscale.zinc};
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.mobile.fontSize};
    font-weight: bold;
`;

export const StyledHeadingLevelTwo = styled.li`
    cursor: default;
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1.5]};

    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};

    color: ${UI.Tokens.COLOURS.greyscale.zinc};
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.mobile.fontSize};
    font-weight: bold;
`;
