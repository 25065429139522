import { ChangeEvent, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/compose/context";
import { Attachment } from "domains/compose/reducer.types";
import { v4 as uuid } from "uuid";

import { TextArea } from "../Textarea";
import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { AttachmentFailedMessage } from "./components/Attach/components/AttachFeedback/AttachmentFailedMessage";
import { AttachSelector } from "./components/Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButton } from "./components/Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { ClinicianAttachmentDisplay } from "./components/Attach/components/ClinicianAttachmentDisplay/ClinicianAttachmentDisplay";
import {
    MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
    SUPPORTED_FILE_EXTENSIONS,
} from "./components/Attach/upload.constants";
import { useUploadFromDesktop } from "./components/Attach/useUploadFromDesktop";
import { ComposeErrors } from "./components/ComposeErrors/ComposeErrors";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { ClinicianMessageTemplateSelector } from "./components/MessageTemplates/ClinicianMessageTemplateSelector/ClinicianMessageTemplateSelector";
import { BrowseTemplatesButton } from "./components/MessageTemplates/components/BrowseTemplatesButton/BrowseTemplatesButton";
import { RemoveTemplateButton } from "./components/MessageTemplates/components/RemoveTemplateButton/RemoveTemplateButton";
import { ClinicianSendMessage } from "./components/SendMessage/ClinicianSendMessage";
import { ClinicianComposeProps } from "./types/Compose.types";

export const ClinicianCompose = ({
    onMessageSend,
    isMessageSending,
    recipient,
    conversationId,
    patient,
    countParticipant,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
}: ClinicianComposeProps) => {
    const removeTemplateButtonRef = useRef<HTMLButtonElement>(null);
    const [showTemplates, setShowTemplates] = useState(false);

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state, dispatch } = useCompose();

    const [announcements, setAnnouncements] = useState<string | null>(null);
    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);

    const { onUpload } = useUploadFromDesktop("ClinicianMessaging");
    const [uploadErrors, setUploadErrors] = useState<string[] | null>(null);
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const hasReachedUploadLimit =
        state.attachments.length === state.maxAttachmentCount;

    const shouldDisableAttachments = hasReachedUploadLimit;

    const uploadFile = async (file: File) => {
        const fileName = file.name;
        const tempAttachment: Attachment = {
            name: fileName,
            origin: "TempUpload",
            id: uuid(),
            size: file.size,
        };
        dispatch({
            type: "ADD_ATTACHMENT",
            payload: { attachment: tempAttachment },
        });

        const result = await onUpload(file, {
            maxFileSizeInMegabytes: MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });

        if (result.status === "error") {
            dispatch({
                type: "REMOVE_ATTACHMENT",
                payload: { attachment: tempAttachment },
            });
            setUploadErrors(result.result.errors);
        } else {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "TempUpload",
                    attachment: {
                        origin: "Upload",
                        name: fileName,
                        ...result.result,
                        size: file.size,
                    },
                },
            });
        }
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAttachSelectorOpen(false);

        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            void uploadFile(file);
        }
    };

    return (
        <StyledContainer>
            {isMessageComponentV1_1Enabled && (
                <ExpandMinimiseBar
                    onMinimiseClick={onMinimiseClick}
                    onExpandClick={onExpandClick}
                    isHeightRestricted={isHeightRestricted ?? false}
                >
                    <>Recipient to go here</>
                </ExpandMinimiseBar>
            )}
            <StyledFlexGrowContainer>
                <UI.Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"1"}
                >
                    <UI.Item flex="1">
                        <UI.Flex alignItems={"center"} gap={"0.5"}>
                            <UI.Text variant="note" skinny>
                                To:
                            </UI.Text>
                            <UI.Text variant="preview" skinny>
                                {recipient}
                            </UI.Text>
                        </UI.Flex>
                    </UI.Item>
                    {!state.template.value ? (
                        <BrowseTemplatesButton
                            onClick={() => setShowTemplates(true)}
                        />
                    ) : (
                        <RemoveTemplateButton
                            ref={removeTemplateButtonRef}
                            template={state.template}
                            onClick={() =>
                                dispatch({ type: "REMOVE_TEMPLATE" })
                            }
                        />
                    )}
                </UI.Flex>
                <ComposeErrors />
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <TextArea.Container>
                        <TextArea.EditableMessage />
                    </TextArea.Container>
                    <UI.Flex
                        flexDirection="column"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        gap="1"
                    >
                        {state.attachments.map((attachment) => (
                            <ClinicianAttachmentDisplay
                                key={attachment.id}
                                attachment={attachment}
                            />
                        ))}
                    </UI.Flex>
                </StyledScrollableFixedHeightContainer>
                <UI.Flex flexDirection={"column"} gap="0.5">
                    {uploadErrors !== null && (
                        <AttachmentFailedMessage errors={uploadErrors} />
                    )}
                    <UI.Flex gap="1" justifyContent="space-between">
                        <AttachSelector
                            isOpen={isAttachSelectorOpen}
                            toggle={() => {
                                // only track when its being opened
                                if (!isAttachSelectorOpen) {
                                    track("Attachment Button Click", {
                                        ...nativeTrackingFields,
                                        conversationParticipant:
                                            state.conversationParticipant,
                                        eventVersion: 2,
                                    });
                                }

                                setIsAttachSelectorOpen((prev) => !prev);
                            }}
                        >
                            <UI.Flex flexDirection="column" gap="1">
                                {hasReachedUploadLimit && (
                                    <Feedback
                                        colour="warning"
                                        title="File attachment limit reached"
                                    />
                                )}
                                <AttachFileFromDesktopButton
                                    isDisabled={shouldDisableAttachments}
                                    accepts={`.${SUPPORTED_FILE_EXTENSIONS.join(
                                        ",.",
                                    )}`}
                                    onClick={() => {
                                        track("Attachment MenuItem Click", {
                                            eventVersion: 2,
                                            attachmentType: "LocalFile",
                                            conversationParticipant:
                                                state.conversationParticipant,
                                            ...nativeTrackingFields,
                                        });

                                        setUploadErrors(null);
                                    }}
                                    onChange={handleFileUpload}
                                />
                            </UI.Flex>
                        </AttachSelector>

                        <ClinicianSendMessage
                            isLoading={isMessageSending}
                            onMessageSend={onMessageSend}
                            countParticipant={countParticipant}
                            conversationId={conversationId}
                            patient={patient}
                        />
                    </UI.Flex>
                </UI.Flex>
            </StyledFlexGrowContainer>
            <QuickViewPortal
                isOpen={showTemplates}
                onClose={() => {
                    setShowTemplates(false);
                }}
                returnFocusRef={removeTemplateButtonRef}
            >
                <ClinicianMessageTemplateSelector
                    onClose={() => {
                        setShowTemplates(false);
                    }}
                    onClickTemplate={(template) => {
                        dispatch({
                            type: "ADD_MESSAGE_TEMPLATE",
                            payload: { template },
                        });
                        setAnnouncements(`${template.title} selected`);
                        setShowTemplates(false);
                    }}
                />
            </QuickViewPortal>
            {/* Aria live regions - use for annonucements */}
            <UI.VisuallyHidden>
                <div id="compose-announcements" aria-live="polite">
                    {announcements}
                </div>
            </UI.VisuallyHidden>
        </StyledContainer>
    );
};
