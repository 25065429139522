import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/clinicianMessaging";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { actions } from "domains/concierge/internal/store";

export const useClinicianMessagingUnassignedTeamPoller = () => {
    const { workspaceId } = useConciergeMeta();
    const dispatch = useConciergeDispatch();

    const fetchFn = useCallback(async () => {
        const teams = await api.getTeams({ workspaceId });
        dispatch(
            actions.processUpdates({
                source: "Query:ClinicianMessagingUnassignedTeam",
                teams,
                conversations: [],
                patients: [],
                users: [],
            }),
        );
    }, [dispatch, workspaceId]);

    return usePoller({
        name: "ClinicianMessagingUnassignedTeamPoller",
        fetchFn,
        onFetchSuccess: () => {
            dispatch(
                actions.queries.setQueryStatus({
                    name: "clinicianMessagingTeams",
                    value: { status: "success" },
                }),
            );
        },
        onFetchError: (e, meta) => {
            if (!meta.initialized) {
                dispatch(
                    actions.queries.setQueryStatus({
                        name: "clinicianMessagingTeams",
                        value: { status: "error", error: e },
                    }),
                );
            }
        },
        refreshRate: 600_000,
    });
};
