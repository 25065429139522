import { Log } from "@accurx/shared";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { conversationGroupIndexOf } from "domains/concierge/internal/util/conversationGroupIndexOf";
import { getConversation } from "domains/concierge/internal/util/getConversation";
import { isConversationInGroup } from "domains/concierge/internal/util/isConversationInGroup";
import { sortConversationsForGroup } from "domains/concierge/internal/util/sortConversationsForGroup";
import { Conversation } from "domains/concierge/types";
import isEqual from "lodash/isEqual";
import last from "lodash/last";

/**
 * NB. relies on `processUpdates` having been called beforehand, such that the
 * group has already been updated with the new conversations.
 *
 * After fetching a page of a conversation group the updateGroupCeiling
 * action should get dispatched and we do two things:
 * 1. Store the new API continuation token
 * 2. Recalculate the group "ceiling".
 *
 * "But what's the group ceiling?" - that's a great question. The inbox may
 * receive a conversation via a SignalR background update, and that conversation
 * may happen to fall into a group that we're tracking. But just because it
 * falls into a group, doesn't mean we want to display it. Here's an example:
 *
 * Let's say that we're on page 3 of a group and we haven't yet fetched page 4.
 * We receive a conversation via SignalR that would sit somewhere on page 8 of
 * the group. In state we would have all the conversations for page 1,2,3
 * followed directly by this stray conversation from page 8. We wouldn't want to
 * display that conversation because there's a load of missing conversations
 * in between the last conversation of page 3 and this conversation on page 8.
 *
 * So after fetching a conversation group and updating the members list of the
 * group, we set the ceiling at the last conversation we received in the
 * response. Then the UI can ignore any conversation who's index >= the ceiling.
 *
 * For clarity, the ceiling is 1 + the index of the last fetched conversation,
 * so any conversation who's index is equal to or greater than the ceiling
 * should be hidden.
 *
 * Once we've fetched all pages of a group (we know this, because the API
 * will return a null continuationToken) we set the isFullyLoaded flag on
 * the group to true, and the ceiling to the length of the group.
 * From this point on we will always show all conversations in the group,
 * and we'll always update the ceiling to include any new conversations that
 * get added to the group by SignalR updates or other means.
 */
export const updateGroupCeiling: CaseReducer<
    ConversationsState,
    PayloadAction<{
        id: string;
        continuationToken: string | undefined;
        conversations: Conversation[];
    }>
> = (state, action) => {
    const { id, continuationToken, conversations } = action.payload;
    const group = state.groups.items[id];

    if (!group) {
        Log.error("Tried to update a group not in the store", {
            tags: { product: "Inbox", id },
        });
        return state;
    }

    // Update the continunation token
    group.continuationToken = continuationToken;

    // If the group has been marked as fully loaded,
    // the group ceiling should already match the group length. If so,
    // there's nothing to do, and we can return the state unchanged.
    if (group.isFullyLoaded && group.members.length === group.ceiling) {
        return state;
    }

    // If the group has been marked as fully loaded,
    // but the group ceiling doesn't match the group length, something
    // has gone wrong. We log an error and then fix the ceiling to
    // match the group length.
    if (group.isFullyLoaded && group.members.length !== group.ceiling) {
        Log.error(
            "Conversation group is fully loaded but its ceiling does not match its length",
            {
                tags: {
                    product: "Inbox",
                    length: group.members.length,
                    ceiling: group.ceiling,
                    groupName: group.loggingInfo.name,
                    ...group.loggingInfo.tags,
                },
            },
        );
        group.ceiling = group.members.length;
        return state;
    }

    // If there's no continuation token, we know that we've fetched the entire
    // group and have a completely dense set of conversations. Therefore we can
    // show all the conversations that we have.
    // We set the ceiling to the length of the group, so all conversations
    // will be shown, and we set isFullyLoaded to true if it isn't already.
    if (!group.continuationToken) {
        group.ceiling = group.members.length;
        group.isFullyLoaded = true;
        return state;
    }

    const filteredAndSortedConversations = sortConversationsForGroup(
        conversations,
        group.sortOptions,
    ).filter((c) => isConversationInGroup(c, group.filters));

    if (filteredAndSortedConversations.length !== conversations.length) {
        const hasBeenFilteredOut = (conversation: Conversation) =>
            !filteredAndSortedConversations.find(
                (c) => c.id === conversation.id,
            );
        const filteredOutIds = conversations
            .filter(hasBeenFilteredOut)
            .map((c) => c.id);
        Log.error(
            "Conversation group client side filtering does not match API filtering",
            {
                tags: {
                    product: "Inbox",
                    sortOptions: JSON.stringify(group.sortOptions),
                    filteredOutIds: JSON.stringify(filteredOutIds),
                    groupName: group.loggingInfo.name,
                    ...group.loggingInfo.tags,
                },
            },
        );
    } else if (!isEqual(conversations, filteredAndSortedConversations)) {
        const containsDuplicates =
            new Set(conversations.map((item) => item.id)).size !==
            conversations.length;
        Log.info(
            "Conversation group client side ordering does not match API ordering",
            {
                tags: {
                    product: "Inbox",
                    sortOptions: JSON.stringify(group.sortOptions),
                    groupName: group.loggingInfo.name,
                    containsDuplicates,
                    ...group.loggingInfo.tags,
                },
            },
        );
    }

    const lastInGroup = last(filteredAndSortedConversations);

    if (lastInGroup) {
        const lastInGroupCurrent = getConversation(state, lastInGroup?.id);

        if (!lastInGroupCurrent) {
            Log.error(
                "Last item in fetched page isn't in the state - this shouldn't happen",
                {
                    tags: {
                        product: "Inbox",
                        id,
                        lastConversationId: lastInGroup.id,
                        ceiling: group.ceiling,
                        groupName: group.loggingInfo.name,
                        ...group.loggingInfo.tags,
                    },
                },
            );
            return state;
        }

        const indexOfLastMember = conversationGroupIndexOf(
            state,
            group,
            lastInGroupCurrent,
        );

        if (indexOfLastMember < group.ceiling) {
            Log.info("Fetched a conversation group page already in state", {
                tags: {
                    product: "Inbox",
                    id,
                    lastConversationId: lastInGroup.id,
                    ceiling: group.ceiling,
                    indexOfLastMember,
                    groupName: group.loggingInfo.name,
                    ...group.loggingInfo.tags,
                },
            });
            return state;
        }

        group.ceiling = indexOfLastMember + 1;
    }

    return state;
};
