import * as UI from "@accurx/design";
import styled from "styled-components";

/*
    The styling here is not really ideal, we shouldn't be targeting the `p` 
    element rendered by the Feedback component. But unfortunately there's no way
    to add the text to the element other than as a string, so we need to do
    this kind of styling.
    
    I've assumed that, while the element can be something other than a `p`, we'd
    only want to style `p` elements with this "note" styling. 
*/

export const StyledFeedback = styled(UI.Feedback)`
    padding: ${UI.Tokens.SIZES[1]};

    p {
        ${UI.Tokens.TYPOGRAPHY["note"].base}
        ${UI.Tokens.TYPOGRAPHY["note"].desktop}
        margin: 0;
        line-height: 1.5rem;
    }
`;
