import { ReactNode } from "react";

import { NativeSubscriptionsProvider as BaseNativeSubscriptionsProvider } from "@accurx/native";

import { useNativeSignalRSubscriptions } from "../hooks/useNativeSignalRSubscriptions";
import { useOnNativeSignalRSubscriptionEvent } from "../hooks/useOnNativeSignalRSubscriptionEvent";

export const NativeSubscriptionsProvider = ({
    workspaceId,
    children,
}: {
    workspaceId: number;
    children: ReactNode;
}) => {
    useOnNativeSignalRSubscriptionEvent();
    useNativeSignalRSubscriptions(workspaceId);

    return (
        <BaseNativeSubscriptionsProvider>
            {children}
        </BaseNativeSubscriptionsProvider>
    );
};
