import { useEffect, useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Accordion, Sidenav } from "@accurx/navigation";
import { generatePath, matchPath, useLocation } from "react-router-dom";

import { ROUTES_BASE, ROUTES_PRIMARY, ROUTES_WORKSPACE } from "shared/Routes";

import { SecondaryNavLink } from "../SecondaryNavLink";
import { SecondaryNavToggle } from "../SecondaryNavToggle";
import { ReportingLinks } from "./ReportingLinks";

export const ManageNavigation = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { pathname } = useLocation();

    const isWayfinderEnabled = useFeatureFlag(FeatureName.Wayfinder);
    const isRecordAccessEnabled = useFeatureFlag(FeatureName.RecordAccessBeta);
    const isAppointmentRemindersAlphaEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersAlpha,
    );
    const isAppointmentRemindersUhlEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersUhl,
    );
    const isIntegratedClinicListsEnabled = useFeatureFlag(
        FeatureName.IntegratedClinicLists,
    );
    const isWorkspaceUserManagementEnabled = useFeatureFlag(
        FeatureName.WorkspaceUserManagement,
    );
    const isEmbeddedPowerBiPOCEnabled = useFeatureFlag(
        FeatureName.EmbeddedPowerBiPOC,
    );

    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
        const isMatchTemplates = matchPath(pathname, {
            path: [
                ROUTES_WORKSPACE.workspacePersonalTemplates,
                ROUTES_WORKSPACE.workspaceSharedTemplates,
            ],
        });
        const isMatchReporting = matchPath(pathname, {
            path: ROUTES_WORKSPACE.reporting,
        });
        const isMatchAccountSettings = matchPath(pathname, {
            path: ROUTES_PRIMARY.account,
        });
        if (isMatchTemplates) {
            setActiveSection("Templates");
        } else if (isMatchReporting) {
            setActiveSection("Reporting");
        } else if (isMatchAccountSettings) {
            setActiveSection("AccountSettings");
        }
    }, [pathname]);

    return (
        <Sidenav.Content>
            <Sidenav.Title>Manage</Sidenav.Title>
            <Accordion.Root
                value={activeSection}
                onValueChange={setActiveSection}
                collapsible
            >
                <Accordion.Item.Level2 value="Templates">
                    <Accordion.Header>
                        <Accordion.Trigger asChild>
                            <SecondaryNavToggle
                                icon={{ name: "Messages" }}
                                isOpen={activeSection === "Templates"}
                                onClick={() => setActiveSection("Templates")}
                            >
                                Templates
                            </SecondaryNavToggle>
                        </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content>
                        <Accordion.Indented>
                            <SecondaryNavLink
                                icon={{ name: "Message" }}
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspacePersonalTemplates,
                                    { workspaceId },
                                )}
                            >
                                My templates
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                icon={{ name: "Messages" }}
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceSharedTemplates,
                                    { workspaceId },
                                )}
                            >
                                Shared templates
                            </SecondaryNavLink>
                        </Accordion.Indented>
                    </Accordion.Content>
                </Accordion.Item.Level2>
                <SecondaryNavLink
                    icon={{ name: "TickDouble" }}
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceDeliveryReceipts,
                        { workspaceId },
                    )}
                >
                    Delivery receipts
                </SecondaryNavLink>
                {isWayfinderEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Photo" }}
                        to={generatePath(ROUTES_WORKSPACE.workspaceResources, {
                            workspaceId,
                        })}
                    >
                        Resources
                    </SecondaryNavLink>
                )}
                {isRecordAccessEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Record" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceMedicalRecords,
                            { workspaceId },
                        )}
                    >
                        Medical records
                    </SecondaryNavLink>
                )}
                {isAppointmentRemindersAlphaEnabled &&
                    isAppointmentRemindersUhlEnabled &&
                    isIntegratedClinicListsEnabled && (
                        <SecondaryNavLink
                            icon={{ name: "Bell" }}
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceAppointmentReminders,
                                { workspaceId },
                            )}
                        >
                            Appointments
                        </SecondaryNavLink>
                    )}
                {isWorkspaceUserManagementEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Person" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceUserManagement,
                            { workspaceId },
                        )}
                    >
                        Users
                    </SecondaryNavLink>
                )}
                {isEmbeddedPowerBiPOCEnabled && (
                    <ReportingLinks isOpen={activeSection === "Reporting"} />
                )}

                <Sidenav.Divider />
                <SecondaryNavLink
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceGeneralSettings,
                        { workspaceId },
                    )}
                >
                    Workspace settings
                </SecondaryNavLink>

                <Accordion.Item.Level2 value="AccountSettings">
                    <Accordion.Header>
                        <Accordion.Trigger asChild>
                            <SecondaryNavToggle
                                isOpen={activeSection === "AccountSettings"}
                                onClick={() =>
                                    setActiveSection("AccountSettings")
                                }
                            >
                                Account settings
                            </SecondaryNavToggle>
                        </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content>
                        <Accordion.Indented>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserNameAndEmail}
                            >
                                Name and email
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserWorkspaceAccess}
                            >
                                Workspace access
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserManageSignature}
                            >
                                Message signature
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserCookies}
                            >
                                Cookies
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserSecurity}
                            >
                                Security
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={ROUTES_BASE.accountUserTermsAndConditions}
                            >
                                Terms and conditions
                            </SecondaryNavLink>
                        </Accordion.Indented>
                    </Accordion.Content>
                </Accordion.Item.Level2>
            </Accordion.Root>
        </Sidenav.Content>
    );
};
