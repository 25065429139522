import { useMemo } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";
import { TemplateItem } from "domains/compose/types";
import { getTemplateType } from "domains/compose/utils/getTemplateType";
import debounce from "lodash/debounce";

import { TemplatesFilter } from "./components/MessageTemplateSelector/components/TemplateFilterSelector";

const getTrackingFilterName = (filter: TemplatesFilter) => {
    switch (filter) {
        case "All":
            return "Filter";
        case "Questionnaires":
            return "Questionnaire";
        case "Templates":
            return "Template";
    }
};

export const useTemplatesAnalytics = () => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state } = useCompose();

    const trackTemplateSearchDebounced = useMemo(() => {
        return debounce(
            ({
                term,
                appOrigin,
            }: {
                term: string;
                appOrigin: "TemplatesCombobox" | "TemplatesQuickview";
            }) => {
                track("TemplateBrowseSearch TextBox Input", {
                    ...nativeTrackingFields,
                    searchString: term,
                    searchStringLength: term.length,
                    conversationParticipant: state.conversationParticipant,
                    eventVersion: 3,
                    appOrigin,
                });
            },
            1000,
        );
    }, [nativeTrackingFields, state.conversationParticipant, track]);

    const trackTemplateSelectButtonClick = ({
        template,
        appOrigin,
    }: {
        template: TemplateItem;
        appOrigin:
            | "TemplatesCombobox"
            | "TemplatesQuickview"
            | "TemplatePreview";
    }) => {
        track("TemplateSelect Button Click", {
            ...nativeTrackingFields,
            templateId: template.value.id,
            templateName: template.value.title,
            templateGroup: template.value.heading,
            templateType: getTemplateType(template),
            conversationParticipant: state.conversationParticipant,
            eventVersion: 4,
            appOrigin,
        });
    };

    const trackTemplateBrowsePreviewOptionClick = () => {
        track("TemplateBrowsePreview Option Click", {
            ...nativeTrackingFields,
            eventVersion: 1,
            conversationParticipant: state.conversationParticipant,
        });
    };
    const trackTemplatePreviewButtonClick = (template: TemplateItem) => {
        track("TemplatePreview Button Click", {
            ...nativeTrackingFields,
            templateId: template.value.id,
            templateName: template.value.title,
            templateGroup: template.value.heading,
            templateType: getTemplateType(template),
            conversationParticipant: state.conversationParticipant,
            eventVersion: 4,
        });
    };

    const trackTemplateBrowseManageLinkClick = () => {
        track("TemplateBrowseManage Link Click", {
            ...nativeTrackingFields,
            conversationParticipant: state.conversationParticipant,
            eventVersion: 2,
        });
    };
    const trackTemplatePreviewBackButtonClick = (template: TemplateItem) => {
        track("TemplatePreviewBack Button Click", {
            ...nativeTrackingFields,
            templateId: template.value.id,
            templateName: template.value.title,
            templateGroup: template.value.heading,
            conversationParticipant: state.conversationParticipant,
            eventVersion: 2,
        });
    };

    const trackTemplateBrowseCloseButtonClick = () => {
        track("TemplateBrowseClose Button Click", {
            ...nativeTrackingFields,
            conversationParticipant: state.conversationParticipant,
            eventVersion: 2,
        });
    };

    const trackConversationFilterMenuItemClick = (filter: TemplatesFilter) => {
        track("ConversationFilter MenuItem Click", {
            accessType: nativeTrackingFields.accessType,
            appOrigin: "TemplateList",
            filterName: getTrackingFilterName(filter),
            eventVersion: 2,
        });
    };

    const trackConversationFilterButtonClick = (filter: TemplatesFilter) => {
        track("ConversationFilter Button Click", {
            accessType: nativeTrackingFields.accessType,
            appOrigin: "TemplateList",
            filterName: getTrackingFilterName(filter),
            eventVersion: 2,
        });
    };

    return {
        trackTemplateSearchDebounced,
        trackTemplateSelectButtonClick,
        trackTemplateBrowseCloseButtonClick,
        trackTemplatePreviewButtonClick,
        trackTemplateBrowsePreviewOptionClick,
        trackTemplatePreviewBackButtonClick,
        trackTemplateBrowseManageLinkClick,
        trackConversationFilterButtonClick,
        trackConversationFilterMenuItemClick,
    };
};
