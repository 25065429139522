import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { ConversationItem } from "@accurx/concierge/types";
import { useNativeTrackingFields } from "@accurx/native";
import { getAssigneeType } from "domains/inbox/util/getAssigneeType";
import { getConversationDisplayStatus } from "domains/inbox/util/getConversationDisplayStatus";

type TabName = "Open" | "Done" | "Sent" | "Scheduled";
type TabClickFields = {
    currentTab: TabName;
    newTab: TabName;
    conversationParticipant: "WithHcp" | "WithPatient";
};

type ListItemClickFields = {
    assignee: ReturnType<typeof useAssignee>;
    isConversationUnread: boolean;
    conversationServerId: string;
    conversationItem: ConversationItem;
    conversationGroup: string | undefined;
    conversationParticipant: "WithHcp" | "WithPatient";
};

export const useConversationListAnalytics = () => {
    const { accessType, medicalRecordSystem } = useNativeTrackingFields();
    const track = useAnalytics();

    const { user } = useCurrentUser();

    const trackListItemClick = ({
        assignee,
        isConversationUnread,
        conversationServerId,
        conversationItem,
        conversationGroup = "Other",
        conversationParticipant,
    }: ListItemClickFields) => {
        const assigneeType = getAssigneeType(assignee);
        const teamAssigneeFields =
            assignee && assigneeType === "Team"
                ? {
                      assigneeTeamId: assignee.id,
                      assigneeTeamName: assignee.displayName,
                  }
                : {};

        track("Conversation ListItem Click", {
            eventVersion: 3,
            accessType,
            medicalRecordSystem,
            isConversationUnread,
            conversationId: conversationServerId,
            conversationDisplayStatus: getConversationDisplayStatus({
                item: conversationItem,
                userId: user.accuRxUserId,
            }),
            conversationGroup,
            assigneeType,
            conversationParticipant,
            ...teamAssigneeFields,
        });
    };

    const trackTabClick = ({
        currentTab,
        newTab,
        conversationParticipant,
    }: TabClickFields) => {
        track("ConversationList Tab Click", {
            eventVersion: 3,
            accessType,
            medicalRecordSystem,
            currentTab,
            newTab,
            conversationParticipant,
        });
    };

    return { trackListItemClick, trackTabClick };
};
