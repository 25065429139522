import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledCheckbox = styled(UI.Ds.Checkbox)`
    min-height: ${UI.Tokens.SIZES[4]};
    max-width: fit-content;
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1]};
    align-items: center;

    label[for="${({ id }) => id}"] {
        // removes unneeded padding from checkbox label
        padding: 0;

        > button > span {
            line-height: 1;
        }
    }
`;

export const StyledIndicator = styled(UI.Ds.Checkbox.Indicator)`
    // Needed to align the indicator with the label
    width: 16px;
    height: 14px;
    align-self: baseline;
`;
