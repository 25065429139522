import { ReactNode, useLayoutEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/compose/context";
import { ComposeActionsTypes } from "domains/compose/reducer.types";

import { ScheduleSend } from "../ScheduleSend/ScheduleSend";
import { ScheduleSendCustomDate } from "../ScheduleSend/components/CustomDate/ScheduleSendCustomDate";
import { ScheduleSendAt } from "../ScheduleSend/types/ScheduleSend.types";
import {
    StyledDisabledLoadingButton,
    StyledSplitButton,
    StyledSplitButtonDropdown,
    StyledSplitButtonDropdownLoading,
    StyledSplitButtonGroup,
} from "./SendSplitButton.styles";

type SendSplitButtonProps = {
    isDropdownLoading?: boolean;
    isSending: boolean;
    sendMessageHandler: () => void;
    children: ReactNode;
};

export const SendSplitButton = ({
    isDropdownLoading = false,
    isSending,
    sendMessageHandler,
    children,
}: SendSplitButtonProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const buttonGroupRef = useRef<HTMLDivElement>(null);
    const buttonWidth = useRef<number>();

    const { state, dispatch } = useCompose();
    const { isScheduling } = state;

    const [isScheduleSendOpen, setIsScheduleSendOpen] = useState(false);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

    // We want to set the width of the loading button to be the same as the actual button
    useLayoutEffect(() => {
        if (buttonGroupRef.current) {
            const { width } = buttonGroupRef.current.getBoundingClientRect();
            buttonWidth.current = width;
        }
    }, [state.sendAt]);

    const onConfirmSheduleSend = (sendAt: ScheduleSendAt) => {
        dispatch({
            type: ComposeActionsTypes.UpdateSendAt,
            payload: {
                sendAt,
            },
        });
        setIsScheduleSendOpen(false);
    };

    const handleOnQuickviewOpenChange = () => {
        setIsQuickViewOpen(true);
    };

    const handleOnQuickviewCloseChange = () => {
        setIsQuickViewOpen(false);
        setIsScheduleSendOpen(false);
    };

    const handlePopoverChange = () => {
        setIsScheduleSendOpen((o) => !o);
    };

    return (
        <>
            {isSending && (
                <StyledDisabledLoadingButton
                    aria-label="Send"
                    width={buttonWidth.current}
                    disabled={isSending}
                >
                    <Pill.Icon
                        name="Send"
                        colour="white"
                        size={3}
                        theme="Fill"
                        isLoading={true}
                    />
                </StyledDisabledLoadingButton>
            )}

            {isScheduling && (
                <StyledSplitButton disabled>Scheduling...</StyledSplitButton>
            )}

            {!isSending && !isScheduling && (
                <StyledSplitButtonGroup ref={buttonGroupRef}>
                    <StyledSplitButton
                        borderDirection="left"
                        onClick={sendMessageHandler}
                        data-testid="split-button-send-message"
                    >
                        {children}
                    </StyledSplitButton>

                    {isDropdownLoading ? (
                        <StyledSplitButtonDropdownLoading
                            borderDirection="right"
                            disabled={true}
                        >
                            <UI.Ds.Spinner size="xsmall" color="white" />
                        </StyledSplitButtonDropdownLoading>
                    ) : (
                        <ScheduleSend
                            isOpen={isScheduleSendOpen}
                            onPopoverOpenChange={handlePopoverChange}
                            onConfirmSheduleSend={onConfirmSheduleSend}
                            handleOnQuickviewOpenChange={
                                handleOnQuickviewOpenChange
                            }
                        >
                            <StyledSplitButtonDropdown
                                borderDirection="right"
                                isOpen={isScheduleSendOpen}
                                aria-label="Scheduled send options"
                                onClick={() => {
                                    track("ConversationSchedule Button Click", {
                                        indexMessage: 0,
                                        templateName:
                                            state.template.value?.title,
                                        scheduleType: state.sendAt
                                            ? "Custom"
                                            : "Now",
                                        ...nativeTrackingFields,
                                    });
                                }}
                            >
                                <UI.Icon
                                    name="Arrow"
                                    colour="white"
                                    size={3}
                                    title="Schedule send"
                                    aria-hidden="true"
                                />
                            </StyledSplitButtonDropdown>
                        </ScheduleSend>
                    )}
                </StyledSplitButtonGroup>
            )}

            <QuickViewPortal
                isOpen={isQuickViewOpen}
                onClose={handleOnQuickviewCloseChange}
            >
                <ScheduleSendCustomDate
                    onConfirm={(sendAt: ScheduleSendAt) => {
                        onConfirmSheduleSend(sendAt);
                        handleOnQuickviewCloseChange();
                    }}
                    sendAt={state.sendAt}
                />
            </QuickViewPortal>
        </>
    );
};
