import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { WorkspaceCreationResponse } from "@accurx/api/account";
import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    ErrorSummaryProvider,
    Feedback,
    Flex,
    Option,
    Text,
} from "@accurx/design";
import { BackButton, getNextUrlOrDefault } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import {
    ROOT_ROUTES,
    WORKSPACE_MANAGEMENT_ROUTES,
    useCreateWorkspaceByNationalCodeMutation,
    useSpecialtiesQuery,
} from "@accurx/workspace-management";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";
import { trackWorkspaceCreatePageView } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    StyledMain,
    StyledPageWrapper,
} from "app/workspace/pages/shared/Layout/Layout.styles";
import {
    FormWithErrorSummary,
    Legend,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/FormLayout";
import {
    StyledButton,
    StyledFlex,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/WorkspaceDetailsForm.styles";
import {
    WorkspaceAdminField,
    WorkspaceDescriptionField,
    WorkspaceNameField,
    WorkspaceSpecialtyField,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/WorkspaceDetailsFormFields";
import { useValidateForm } from "app/workspace/pages/shared/WorkspaceDetailsForm/useValidateForm";
import { UserflowIds } from "app/workspace/pages/shared/constants";
import {
    getSpecialtyAsOption,
    getSpecialtyOptionValue,
    sortSpecialtiesAlphabetically,
} from "app/workspace/pages/shared/specialtyHelpers";

import { ONBOARDING_ROUTES_TEMPORARY } from "../routes";

const CreateWorkspacePageContent = () => {
    const history = useHistory<{
        workspaceName?: string;
        allowedOrganisation?: IAllowedNhsOrganisationResponse;
    }>();

    const allowedOrganisation = history.location.state?.allowedOrganisation;

    const { selectedOrgId } = useSelectedOrganisation();

    const { user } = useCurrentUser();

    const currentWorkspace = user.organisations?.find(
        (w) => w.orgId === selectedOrgId,
    );

    const isOnboarding = Boolean(
        useRouteMatch([
            ROOT_ROUTES.workspaceCreate,
            ONBOARDING_ROUTES_TEMPORARY.workspaceCreate,
        ]),
    );

    const nationalCode =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.nationalCode
            : currentWorkspace?.nationalCode;

    const supportsMultipleWorkspaces = allowedOrganisation
        ? allowedOrganisation.supportsMultipleWorkspaces
        : currentWorkspace &&
          isFeatureEnabledForWorkspace(
              FeatureName.FlexibleWorkspace,
              currentWorkspace,
          );

    const {
        data: { specialties = [], noApplicableSpecialty } = {},
        isLoading: isLoadingSpecialties,
        isError: hasSpecialtiesError,
    } = useSpecialtiesQuery();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { validateForm, nameError, descriptionError, specialtyError } =
        useValidateForm();

    const track = useAnalytics();

    const [description, setDescription] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [selectedSpecialtyOption, setSelectedSpecialtyOption] = useState<
        Option | undefined
    >();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        trackWorkspaceCreatePageView(analyticsLoggedInProps);
    }, [analyticsLoggedInProps]);

    const specialtyOptions: Option[] = useMemo(
        () => sortSpecialtiesAlphabetically(specialties),
        [specialties],
    );

    const onSuccess = (data: WorkspaceCreationResponse) => {
        // NOTE: response structure will probably be changed to contain information about an error type

        // there is alredy a check for orgId within mutation
        const workspace = data.workspace as Organisation;

        toast(
            <Feedback
                colour="success"
                title={`Created ${workspace.organisationName}`}
            />,
        );

        history.replace(
            getNextUrlOrDefault(
                history.location.search,
                generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                    workspaceId: workspace.orgId,
                }),
            ),
        );
    };

    const { mutate, isError: hasNetworkError } =
        useCreateWorkspaceByNationalCodeMutation({
            onSuccess,
        });

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleSpecialtyChange = (selectedOption: Option) => {
        setSelectedSpecialtyOption(selectedOption);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        const specialty = getSpecialtyOptionValue(selectedSpecialtyOption);
        const errors = validateForm({ name, description, specialty });
        const isValid = errors.length === 0;

        track("WorkspaceCreate Button Click", {
            hasError: !isValid,
            withDescription: !!description,
            errorReason: errors,
            isExample: false,
            isRecommended: false,
        });

        if (window.Intercom) {
            window.Intercom(
                "trackEvent",
                "click-confirm-join-or-create-workspace",
            );
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }

        mutate({
            nationalCode,
            name,
            description,
            isRecommended: false,
            isExample: false,
            // Validation function has verified this is a number
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            specialities: [specialty!],
        });
    };

    const hasValidationErrors =
        !!nameError || !!descriptionError || !!specialtyError;

    if (!supportsMultipleWorkspaces) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback
                        colour="error"
                        title="This organisation does not support workspaces"
                    />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (!allowedOrganisation && !currentWorkspace) {
        // NOTE: this error state needs validation before
        // combining join-organisation and join-workspace pages together

        Log.error("There is no organisation to create a workspace for");
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback colour="error" title="Something went wrong" />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    return (
        <StyledPageWrapper flexDirection="column" justifyContent="flex-start">
            <BackButton backCallback={history.goBack} />
            <StyledMain flexDirection="column">
                {hasSpecialtiesError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <FormWithErrorSummary
                        hasFormValidationErrors={hasValidationErrors}
                        hasNetworkError={hasNetworkError}
                        onSubmit={handleSubmit}
                    >
                        <Legend>Create new workspace</Legend>
                        <Text variant="body" skinny>
                            Workspaces allow you to collaborate with your
                            colleagues.
                        </Text>
                        <StyledFlex flexDirection="column" gap="3">
                            <WorkspaceNameField
                                onChange={handleNameChange}
                                value={name}
                                error={nameError?.detailMessage}
                            />
                            <WorkspaceDescriptionField
                                onChange={handleDescriptionChange}
                                value={description}
                                error={descriptionError}
                            />
                            <Flex>
                                <WorkspaceSpecialtyField
                                    options={specialtyOptions}
                                    onChange={handleSpecialtyChange}
                                    noApplicableSpecialtyOption={getSpecialtyAsOption(
                                        noApplicableSpecialty,
                                    )}
                                    isLoading={isLoadingSpecialties}
                                    error={specialtyError}
                                />
                            </Flex>
                            <WorkspaceAdminField
                                value={user.fullName || user.email}
                            />
                            <StyledButton
                                type="submit"
                                text="Create workspace"
                                disabled={isLoading}
                                data-userflow-id={
                                    UserflowIds.selectOrganisation.selectButton
                                }
                            />
                        </StyledFlex>
                    </FormWithErrorSummary>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};

/**
 * @deprecated that is a copy of the original page for development purposes
 */
export const CreateWorkspacePage = () => {
    return (
        <ErrorSummaryProvider>
            <CreateWorkspacePageContent />
        </ErrorSummaryProvider>
    );
};
