import { Option } from "@accurx/design";
import { addWeeks, formatISODuration, intervalToDuration } from "date-fns";

import { SlotTypeAvailabilityDto } from "../queries/useAppointmentAvailability";
import {
    ChooseClinicianValue,
    ClinicianOption,
    CliniciansForSlotType,
    KeyValueDTO,
    OrganisationOption,
    QueryStatus,
    SelfBookFormData,
    SelfbookConfigurationPayload,
} from "./types";

export const transformToSlotTypesOptions = (
    selfbookAvailability: KeyValueDTO | undefined,
): Option[] =>
    // Transforming the test object into an array of objects with label and value properties
    Object.entries(selfbookAvailability || {}).map(([key, value]) => ({
        label: key,
        value: `${value}`,
    }));

export const transformToOrganisationOptions = (
    orgId: number,
    organisationName: string,
    crossOrgAvailableOdsAndNames: KeyValueDTO | undefined,
): OrganisationOption[] => {
    const currentOrgObj: OrganisationOption = {
        label: `${organisationName} (Default)`,
        value: `${orgId}`,
        isDefault: true,
    };

    // Transforming the test object into an array of objects with label, value and isDefault properties
    const crossOrgs: OrganisationOption[] = Object.entries(
        crossOrgAvailableOdsAndNames || {},
    ).map(([key, value]) => ({
        label: value,
        value: key,
        isDefault: false,
    }));

    return [currentOrgObj, ...crossOrgs];
};

export const transformToClinicianOptions = (
    slotTypesWithClinicians: SlotTypeAvailabilityDto[] | undefined,
): CliniciansForSlotType[] => {
    // Transforming the test object into an array of objects with label and value properties
    if (!slotTypesWithClinicians) {
        return [];
    }

    return slotTypesWithClinicians.map((item) => ({
        label: item.slotTypeName,
        value: item.clinicianGroups,
        appointmentCount: item.appointmentCount,
    }));
};

export const getClinicianOptions = (
    clinicianListForSlotTypes: CliniciansForSlotType[],
    slotType: Option | undefined,
): ClinicianOption[] => {
    if (!slotType) {
        return [];
    }

    const cliniciansObj: CliniciansForSlotType | undefined =
        clinicianListForSlotTypes.find((item) => item.label === slotType.label);

    if (!cliniciansObj || !cliniciansObj.value) {
        return [];
    }
    let combinedOptions: ClinicianOption[] = [];
    const clinicianOptions: ClinicianOption[] = [];
    const groupOptions: ClinicianOption[] = [];

    cliniciansObj.value.forEach((item) => {
        const clinicianNames: string[] = [];
        item.clinicians.map((clinician) => {
            return clinicianNames.push(clinician.displayName);
        });

        const isClinicianOption = item.clinicians.length === 1;

        const clinicianOption = {
            label:
                item.appointmentCount === 0
                    ? `${clinicianNames.join(", ")} (no availability)`
                    : clinicianNames.join(", "),
            value: `${item.clinicianGroupId}`,
            clinicianNames: clinicianNames,
            grouping: isClinicianOption ? "Clinicians" : "Groups",
            appointmentCount: item.appointmentCount,
        };
        // push to clinicians or group options based on clinicians array length
        isClinicianOption
            ? clinicianOptions.push(clinicianOption)
            : groupOptions.push(clinicianOption);
    });

    // join list of clinicians and groups
    combinedOptions = [...clinicianOptions, ...groupOptions];

    return combinedOptions;
};

export const shouldShowSlotTypeError = (
    slotType: Option | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (addBookingLinkClicked && slotType === undefined) {
        return ["Select a slot type that patients will book into."];
    }
    if (slotType && slotType.value === "0") {
        return [
            "No appointments. Make more available in your clinical system.",
        ];
    }
    return undefined;
};

export const shouldShowChooseClinicianError = (
    clinicians:
        | {
              clinicians: ClinicianOption[] | undefined;
              clinicianType: ChooseClinicianValue;
          }
        | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (
        addBookingLinkClicked &&
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians?.length === 0
    ) {
        return ["Select at least one clinician."];
    } else if (
        addBookingLinkClicked &&
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians?.every((item) => item.appointmentCount === 0)
    ) {
        return clinicians.clinicians.length === 1
            ? ["Selected clinician has no availability"]
            : ["Selected clinicians have no availability"];
    }
    return undefined;
};

const clinicianTranformation = (
    clinicians:
        | { clinicians: ClinicianOption[]; clinicianType: ChooseClinicianValue }
        | undefined,
): { clinicianGroupId: number; clinicianNames: string[] }[] => {
    if (!clinicians) {
        return [];
    }

    return clinicians.clinicians.map((clinician) => {
        return {
            clinicianGroupId: Number(clinician.value),
            clinicianNames: clinician.clinicianNames,
        };
    });
};

export const getSelfbookConfigObject = (
    selfBookData: SelfBookFormData,
    appointmentAvailabilityStatus: QueryStatus,
    crossOrgStatus: QueryStatus,
    showCrossOrg: boolean,
): SelfbookConfigurationPayload => {
    const clinician = clinicianTranformation(selfBookData.clinicians);

    const validationSuccess = isFormValid(
        selfBookData.slotType?.value,
        selfBookData.clinicians,
        appointmentAvailabilityStatus,
        crossOrgStatus,
    );
    const currentDate = new Date();
    // Add 12 weeks by default until UI component is built, this will need to change by week or days depending what user selects
    const futureDate = addWeeks(currentDate, 12);
    const duration = intervalToDuration({
        start: currentDate,
        end: futureDate,
    });
    // Format the date as an ISO 8601 string
    const availabilityPeriodDefaultValueISO = formatISODuration(duration);

    return {
        validationSuccess: validationSuccess,
        appointmentType: selfBookData.appointmentType,
        slotType: selfBookData.slotType?.label,
        specificClinician:
            selfBookData.clinicians?.clinicianType === "SpecificClinicians"
                ? clinician
                : undefined,
        crossOrgBooking:
            showCrossOrg && selfBookData.organisation.isDefault === false
                ? {
                      targetOdsCode: selfBookData.organisation.value,
                      targetOdsName: selfBookData.organisation.label,
                  }
                : undefined,
        availabilityPeriod: availabilityPeriodDefaultValueISO,
    };
};

export const isCrossOrgLoading = (
    crossOrgStatus: QueryStatus,
    organisationIsDefault: boolean,
) => {
    return !organisationIsDefault && crossOrgStatus === "loading";
};

const isFormValid = (
    slotType: string | undefined,
    clinicians:
        | {
              clinicians: ClinicianOption[];
              clinicianType: ChooseClinicianValue;
          }
        | undefined,
    appointmentAvailabilityStatus: QueryStatus,
    crossOrgStatus: QueryStatus,
): boolean => {
    let isSlotTypeValid = true;
    let isClinicianValid = true;

    isSlotTypeValid = slotType
        ? slotType !== "0" &&
          appointmentAvailabilityStatus === "success" &&
          crossOrgStatus !== "error"
        : false;

    isClinicianValid =
        clinicians && clinicians.clinicianType === "SpecificClinicians"
            ? clinicians.clinicians.length > 0 &&
              clinicians.clinicians.some((item) => item.appointmentCount !== 0)
            : true;
    return isSlotTypeValid && isClinicianValid;
};
