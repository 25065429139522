import { ReactNode, useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { TabList, TabProps, Tabs, Tokens } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

type NavTabsProps = {
    baseRoute: `/${string}/:workspaceId/${string}/:tab?`;
    defaultTabId: string;
    onTabDisplay?: (nextTabId: string) => void;
    routeState?: Record<string, string>;
    children: ReactNode;
};
export const NavTabs = ({
    baseRoute,
    defaultTabId,
    onTabDisplay,
    routeState,
    children,
}: NavTabsProps) => {
    const history = useHistory();
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { tab = defaultTabId } = useParams<{
        tab: string;
    }>();

    const handleTabChange = (nextTabId: string) => {
        history.push(
            generatePath(baseRoute, {
                workspaceId,
                tab: nextTabId,
            }),
            routeState,
        );
        onTabDisplay?.(nextTabId);
    };

    // Effect to track initial tab display
    useEffect(() => {
        onTabDisplay?.(tab);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tabs defaultSelected={tab} onTabChange={handleTabChange}>
            {children}
        </Tabs>
    );
};

const StyledTabList = styled.div`
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        height: 4px;

        background-color: #e0e1ebff;
    }
`;

NavTabs.Tabs = ({ tabs }: { tabs: TabProps[] }) => (
    <StyledTabList>
        <TabList tabs={tabs} />
    </StyledTabList>
);

const StyledTabPanel = styled.div`
    padding: ${Tokens.SIZES[3]} 0;
`;

type NavTabsPanelProps = {
    tabId: string;
    children: ReactNode;
};
NavTabs.Panel = ({ tabId, children }: NavTabsPanelProps) => (
    <Tabs.Panel tabId={tabId}>
        <StyledTabPanel>{children}</StyledTabPanel>
    </Tabs.Panel>
);
