import { SingleSelect, Table, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledTabPanelLayout = styled.div`
    margin: ${Tokens.SIZES[4]} 0;
`;

export const StyledSelect = styled(SingleSelect)`
    width: 30rem;
`;

const sharedCellStyles = css`
    max-width: 40rem;
    padding: ${Tokens.SIZES[2]};
`;

// To be used by text below the table, to have correct spacing styles matching the table cell.
export const StyledSpacedItem = styled.span`
    ${sharedCellStyles}
`;

// Standard Visibility Hidden styles
// TODO: to move out to accurx-design as shared styling.
export const StyledVisibilityHidden = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
`;

export const StyledTableBody = styled(Table.Body)`
    vertical-align: top;
`;
