import { useCallback } from "react";

import { useSignalRClientOn, useSignalRInvoke } from "@accurx/native";

import { Events } from "shared/hubClient/HubClient";

import { Transport, TransportSendArgs, TransportSubscribeArgs } from "../types";
import { getEventSubscription } from "./useOnNativeSignalRSubscriptionEvent";

// A hook to encapsulate the logic for sending and subscribing to SignalR events
// via the native bridge. The hook is composed of being able to send SignalR events and
// setting up and subscribing events, which is abstracted under a single subscribe function

export const useNativeSignalRTransport = (): Transport => {
    const nativeSignalRInvoke = useSignalRInvoke();
    const nativeSignalRClientOn = useSignalRClientOn();

    const send = useCallback(
        (args: TransportSendArgs) => {
            void nativeSignalRInvoke(args);
        },
        [nativeSignalRInvoke],
    );

    const subscribe = useCallback(
        <M extends Events>({
            methodName,
            eventFilter = () => true,
            onEvent,
        }: TransportSubscribeArgs<M>) => {
            const subscription = getEventSubscription({
                methodName,
                onNewSubscription: () =>
                    void nativeSignalRClientOn({ methodName }),
            }).subscribe((event) => {
                // Call eventFiler on every event instead of piping the subscription subject in case of a function reference issue
                if (eventFilter(event)) {
                    onEvent(event);
                }
            });

            return {
                unsubscribe: () => subscription.unsubscribe(),
            };
        },
        [nativeSignalRClientOn],
    );

    return { send, subscribe };
};
