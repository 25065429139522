import {
    MessageTemplateManagementView,
    MessageTemplateResponse,
} from "@accurx/api/patient-messaging";
import { Option } from "@accurx/design";
import { Log } from "@accurx/shared";

import {
    MessageTemplate,
    MessageTemplateOwner,
    MessageTemplateType,
} from "api/FlemingDtos";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import {
    FILE_UPLOAD_ACCEPT_FORMATS,
    FILE_UPLOAD_ALLOWED_EXTENSIONS,
    FILE_UPLOAD_MAX_SIZE_BYTES,
    FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE,
} from "app/workspaceConversations/components/Conversation/ComposeArea/ComposeArea.constants";
import {
    PreviewUrl,
    TemplateInList,
} from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";
import { MessageTemplateOption, PresetTemplate } from "shared/Templates";

import { MessageTemplateCategorySelectValues } from "./messageTemplatesEditCreate/ManageMessageTemplateConstants";

type NewMessageTemplateOptions = {
    owner: MessageTemplateOwner;
    type: MessageTemplateType | null;
};

export const getNewMessageTemplate = ({
    owner,
    type,
}: NewMessageTemplateOptions): MessageTemplate => {
    return {
        id: null,
        type,
        body: "",
        title: "",
        isDefault: false,
        owner,
    };
};

/**
 * Maps from number enum type MessageTemplateType to string enum MessageTemplateCategorySelectValues
 */
export const mapMessageTemplateTypeToOptionValue = (
    type: MessageTemplateType | null,
): MessageTemplateCategorySelectValues => {
    switch (type) {
        case MessageTemplateType.Sms:
            return MessageTemplateCategorySelectValues.Sms;
        case MessageTemplateType.VideoConsult:
            return MessageTemplateCategorySelectValues.VideoConsult;
        case null:
        default:
            return MessageTemplateCategorySelectValues.Unselected;
    }
};

/**
 * Maps from string enum MessageTemplateCategorySelectValues to number enum type MessageTemplateType
 */
export const mapCategoryOptionToMessageTemplateType = (
    option: Option,
): MessageTemplateType | null => {
    switch (option.value) {
        case MessageTemplateCategorySelectValues.Sms:
            return MessageTemplateType.Sms;
        case MessageTemplateCategorySelectValues.VideoConsult:
            return MessageTemplateType.VideoConsult;
        case null:
        default:
            return null;
    }
};

/**
 * Maps from number enum type MessageTemplateType to category option
 * Falls back to first option in the array if no valid option is found
 */
export const mapMessageTemplateTypeToOption = (
    type: MessageTemplateType | null,
    options: Option[],
): Option => {
    const mappedValue = mapMessageTemplateTypeToOptionValue(type);
    // Fallback to first option if values cannot be found
    return options.find((option) => option.value === mappedValue) || options[0];
};

/**
 * Pass array of errors to check whether there are none
 */
export const getAreFormFieldsValid = (errorMessages: string[]): boolean => {
    return errorMessages.reduce((prev, next) => prev + next, "") === "";
};

/**
 * Helper function that transform a preset template
 * into a MessageTemplate object
 *
 * The current selected template - we need to ensure that ALL templates take the same shape
 * of MessageTemplate object, so we can very easily send analytics events consistently
 * whether the selectedTemplate is a custom one (MessageTemplate type) or
 * it's a preset one (PresetTemplate)
 */
export const mapPresetTemplateToMessageTemplate = (
    presetTemplate: PresetTemplate,
    productSelection: ProductSelection,
    group: string,
): MessageTemplateOption => {
    // for preset templates the type is whatever product is currently selected
    // but nothing else should be set
    const isVideoConsult = productSelection === ProductSelection.Video;
    const templateType = isVideoConsult
        ? MessageTemplateType.VideoConsult
        : MessageTemplateType.Sms;

    return {
        id: null,
        body: presetTemplate.body,
        title: presetTemplate.title,
        isPatientResponseRequired: presetTemplate.isPatientResponseRequired,
        type: templateType,
        isDefault: null,
        owner: null,
        isPresetTemplate: true,
        group: group,
    };
};

/**
 * Convert file to base64 data URL for a preview
 */
export const readFileForAttachment = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        // Read the file via FileReader API and save file result in state.
        reader.onload = function (e: ProgressEvent<FileReader>) {
            // Add the file name to the data URL
            const dataURL = e.target?.result as string;
            const result = dataURL.replace(
                ";base64",
                `;name=${file.name};base64`,
            );
            resolve(result);
        };

        reader.readAsDataURL(file);
    });
};

export const validateTemplateAttachment = (file: File) => {
    const failureReasons: string[] = [];
    let success = false;

    if (file.size === 0) {
        failureReasons.push("Cannot upload an empty file");
    }

    if (file.size > FILE_UPLOAD_MAX_SIZE_BYTES) {
        failureReasons.push(
            "File too big, max file size: " +
                FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE,
        );
    }

    const isAllowedType = FILE_UPLOAD_ACCEPT_FORMATS.includes(file.type);
    if (!isAllowedType) {
        const fileExtension = file.name?.split(".")?.pop()?.toLowerCase();
        failureReasons.push(
            `Unsupported file type: File with the extension .${fileExtension} was encountered, but files can only be one of the supported extensions (${FILE_UPLOAD_ALLOWED_EXTENSIONS.join(
                ", ",
            )})`,
        );
    }

    success = failureReasons.length === 0;

    return {
        success,
        failureReasons,
    };
};

export const createPreviewUrl = ({
    orgId,
    templateId,
    documentId,
}: {
    orgId: number | null;
    templateId: number | null;
    documentId: string;
}): PreviewUrl => {
    if (orgId === null || templateId === null) {
        Log.error(
            "Trying to create a template attachment preview URL, but not all the data provided",
            {
                tags: {
                    orgId,
                    templateId,
                },
            },
        );
    }

    return `/api/patientMessaging/template/message/${orgId}/${templateId}/attachment/${documentId}`;
};
const mapTemplateStringTypeToEnum = (
    type: Optional<string>,
): MessageTemplateType => {
    switch (type) {
        case "PatientAdvice":
            return MessageTemplateType.Sms;
        case "VideoInvite":
            return MessageTemplateType.VideoConsult;
        default:
            throw new Error(`Unspported template type: ${type}`);
    }
};

export const mapMessageTemplateTypeToString = (
    type: MessageTemplateType | null,
): string => {
    switch (type) {
        case MessageTemplateType.Sms:
            return "PatientAdvice";
        case MessageTemplateType.VideoConsult:
            return "VideoInvite";
        default:
            throw new Error(`Unsupported message template type: ${type}`);
    }
};

const mapTemplateStringOwnerToEnum = (
    owner: Optional<string>,
): MessageTemplateOwner => {
    switch (owner) {
        case "User":
            return MessageTemplateOwner.User;
        case "Organisation":
            return MessageTemplateOwner.Organisation;
        default:
            throw new Error(`Unspported template owner: ${owner}`);
    }
};

export const mapMessageTemplateOwnerToString = (
    owner: MessageTemplateOwner,
): "User" | "Organisation" => {
    switch (owner) {
        case MessageTemplateOwner.User:
            return "User";
        case MessageTemplateOwner.Organisation:
            return "Organisation";
        default:
            throw new Error(`Unsupported message template owner: ${owner}`);
    }
};

export const mapMessageTemplateManagementViewToTemplateManagementEntity = (
    templates: MessageTemplateManagementView[],
    orgId: number | null,
): TemplateInList[] =>
    templates.map((templateManagementView) =>
        mapMessageTemplateManagementViewToTemplateInList(
            templateManagementView,
            orgId,
        ),
    );

export const mapMessageTemplateManagementViewToTemplateInList = (
    templateManagementView: MessageTemplateManagementView,
    orgId: number | null,
): TemplateInList => ({
    ...mapMessageTemplateResponseToTemplateInList(
        templateManagementView,
        orgId,
    ),
    isAllowedAsSms: templateManagementView.isAllowedAsSms,
    isAllowedAsBatch: templateManagementView.isAllowedAsBatch,
});

export const mapMessageTemplateResponseToTemplateInList = (
    messageTemplateResponse: MessageTemplateResponse,
    orgId: number | null,
): TemplateInList => ({
    id: messageTemplateResponse.id
        ? parseInt(messageTemplateResponse.id, 10)
        : null,
    title: messageTemplateResponse.title,
    body: messageTemplateResponse.body,
    type: mapTemplateStringTypeToEnum(messageTemplateResponse.type),
    isDefault: messageTemplateResponse.isDefault,
    owner: mapTemplateStringOwnerToEnum(messageTemplateResponse.owner),
    attachedDocuments: messageTemplateResponse.attachments?.map(
        (attachment) => ({
            documentId: attachment.id.toString(),
            fileSize: attachment.fileSize,
            // this shouldn't ever be null, but our generated API types tell us that it can,
            // so we have to handle it by using a non-null assertion
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fileName: attachment.fileName!,
            previewUrl: createPreviewUrl({
                orgId,
                documentId: attachment.id.toString(),
                templateId: messageTemplateResponse.id
                    ? parseInt(messageTemplateResponse.id, 10)
                    : null,
            }),
        }),
    ),
});
