import { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { Log } from "@accurx/shared";
import { WORKSPACE_MANAGEMENT_PRIMARY_ROUTES } from "@accurx/workspace-management";
import NotFound from "NotFound";
import { Redirect, Switch } from "react-router";

import {
    AppRoute,
    GuardedAppRouteRedirectProps,
    SimpleRoute,
} from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
import { BatchMessageTrustSection } from "app/batchMessage/trust/BatchMessageTrustSection";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import integratedClinicRoutes from "app/patientLists/integratedClinics/routes";
import { useIsQuestionnaireScoreboardEnabled } from "app/questionnaireScoreboard/hooks/useIsQuestionnaireScoreboardEnabled";
import { QuestionnaireScoreboardPage } from "app/questionnaireScoreboard/pages/QuestionnaireScoreboardPage";
import { RequestMedicalRecord } from "app/recordView/RequestMedicalRecord";
import { RedirectToUnifiedInbox } from "app/workspace/RedirectToUnifiedInbox";
import { PatientHelper } from "shared/PatientHelper";
import {
    ROUTES,
    ROUTES_BASE,
    ROUTES_PRIMARY,
    ROUTES_WORKSPACE,
} from "shared/Routes";
import { ConversationManagerProvider } from "shared/concierge/conversations/context/ConversationManagerContext";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { PatientManagerProvider } from "shared/concierge/patients/context/PatientManagerContext";
import { UsersAndTeamsManagerProvider } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";
import { useCurrentUserId, useIsFeatureEnabled } from "store/hooks";

import { WorkspaceOnboardingRoutesSection } from "../guardedRoutes/routes/WorkspaceOnboardingRoutesSection";
import { WorkspaceSettingsRoutesSection } from "../guardedRoutes/routes/WorkspaceSettingsRoutesSection";
import { WorkspaceConversationsSection } from "../workspaceConversations/WorkspaceConversationsSection";
import { SearchedPatientSection } from "./SearchedPatientSection";
import { useCheckUrlWithReduxUpdateOrRedirect } from "./hooks";

export const WorkspaceRoutes = ({
    twoFactorAuthGuard,
}: {
    twoFactorAuthGuard: GuardedAppRouteRedirectProps;
}): JSX.Element => {
    // Get the workspace/org id from the URL
    const workspaceId = useWorkspaceId();
    const currentUserId = useCurrentUserId();

    const { redirect } = useCheckUrlWithReduxUpdateOrRedirect(workspaceId);

    const canSendBatchMessageTrust = useIsFeatureEnabled(
        FeatureName.WebBatchMessaging,
    );

    const hasIntegratedClinicsEnabled = useIsFeatureEnabled(
        FeatureName.IntegratedClinicLists,
    );

    const canViewQuestionnaireScoreboardPage =
        useIsQuestionnaireScoreboardEnabled();

    useEffect(() => {
        if (!currentUserId) {
            Log.error("WorkspaceRoutes used when a user is not logged in", {
                tags: {
                    product: "WebInbox 2.0",
                    domain: "Workspace Routes",
                },
            });
        }
    }, [currentUserId]);

    if (!currentUserId) {
        return <></>;
    }

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        // Include the layout within the conversation manager provider so the full layout can access the conversation manager
        <PatientManagerProvider workspaceId={workspaceId}>
            <UsersAndTeamsManagerProvider workspaceId={workspaceId}>
                <ConversationManagerProvider
                    workspaceId={workspaceId}
                    is2FAed={twoFactorAuthGuard.isAllowed}
                >
                    <Switch>
                        <SimpleRoute
                            path={
                                WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding
                            }
                        >
                            <WorkspaceOnboardingRoutesSection />
                        </SimpleRoute>
                        <SimpleRoute path={ROUTES_PRIMARY.workspaceSettings}>
                            <WorkspaceSettingsRoutesSection />
                        </SimpleRoute>
                        <GuardedRoute
                            requires={{
                                authenticationStatus: "LoggedInWith2FA",
                                onboarded: true,
                            }}
                            path={ROUTES_PRIMARY.workspaceConversations}
                        >
                            <WorkspaceConversationsSection
                                workspaceId={workspaceId}
                            />
                        </GuardedRoute>
                        <AppRoute
                            path={ROUTES_PRIMARY.workspacePatientProfile}
                            allowRoute={{
                                isAllowed:
                                    PatientHelper.getPatientTokenWithStorageFallback(
                                        null,
                                    ) !== "",
                                redirectTo: ROUTES.home,
                            }}
                        >
                            <SearchedPatientSection />
                        </AppRoute>
                        <AppRoute
                            allowRoute={{
                                isAllowed: canSendBatchMessageTrust,
                                redirectTo: ROUTES_BASE.patientsSearch,
                            }}
                            path={ROUTES_PRIMARY.batchMessageTrust}
                        >
                            <BatchMessageTrustSection />
                        </AppRoute>
                        {hasIntegratedClinicsEnabled &&
                            integratedClinicRoutes.map(
                                ({
                                    Component,
                                    isTwoFactorRequired,
                                    ...routeProps
                                }) =>
                                    isTwoFactorRequired ? (
                                        <GuardedRoute
                                            key={routeProps.path}
                                            requires={{
                                                authenticationStatus:
                                                    "LoggedInWith2FA",
                                                onboarded: true,
                                            }}
                                            {...routeProps}
                                        >
                                            <Component />
                                        </GuardedRoute>
                                    ) : (
                                        <SimpleRoute
                                            key={routeProps.path}
                                            {...routeProps}
                                        >
                                            <Component />
                                        </SimpleRoute>
                                    ),
                            )}
                        <AppRoute
                            allowRoute={twoFactorAuthGuard}
                            path={ROUTES_WORKSPACE.remoteRecordView}
                            exact
                        >
                            <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                                <RequestMedicalRecord />
                            </FeatureGuard>
                        </AppRoute>
                        {canViewQuestionnaireScoreboardPage && (
                            <AppRoute
                                path={ROUTES_PRIMARY.questionnaireScoreboard}
                                allowRoute={twoFactorAuthGuard}
                            >
                                <QuestionnaireScoreboardPage />
                            </AppRoute>
                        )}
                        <SimpleRoute
                            path={"/w/:workspaceId/clinician-conversations"}
                        >
                            <RedirectToUnifiedInbox workspaceId={workspaceId} />
                        </SimpleRoute>
                        <AppRoute path="*" component={NotFound} />
                    </Switch>
                </ConversationManagerProvider>
            </UsersAndTeamsManagerProvider>
        </PatientManagerProvider>
    );
};
