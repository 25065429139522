import * as React from "react";
import { useEffect } from "react";

import { Card, Feedback, StackPanel, Text } from "@accurx/design";
import { Link, generatePath } from "react-router-dom";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { trackPatientTriageDashboardPageView } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { usePatientTriageDashboardFortnightlyDataQuery } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_ORGS } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { DownloadReportButton } from "../downloadReportButton";
import { LiveAssignmentDashboard } from "./LiveAssignmentDashboard";
import { RequestsByDayGraph } from "./RequestsByDay";
import { RequestsByHourGraph } from "./RequestsByHour";
import { RequestsByOutcome } from "./RequestsByOutcome/RequestsByOutcome";

export const Dashboard = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const dataQuery = usePatientTriageDashboardFortnightlyDataQuery({
        workspaceId: parseInt(practiceId, 10),
    });

    const isLiveDashboardFeatureEnabled = useFeatureFlag(
        "PatientTriageLiveAssignmentDashboards",
    );
    const isOutcomeDashboardFeatureEnabled = useFeatureFlag("OutcomeRecording");
    const isNewReportingEnabled = useFeatureFlag("EmbeddedPowerBiPOC");

    useEffect(() => {
        trackPatientTriageDashboardPageView(analyticsLoggedInProps);
    }, [analyticsLoggedInProps]);

    const renderBody = (): JSX.Element => {
        switch (dataQuery.status) {
            case "error":
                return (
                    <Card>
                        <Feedback
                            colour="error"
                            title="Something went wrong, please refresh the page."
                        />
                    </Card>
                );
            default:
                return (
                    <>
                        <StackPanel gutter={4}>
                            <RequestsByDayGraph
                                isLoading={dataQuery.status === "loading"}
                                dashboardData={dataQuery.data?.usage ?? []}
                            />
                            <RequestsByHourGraph
                                isLoading={dataQuery.status === "loading"}
                                dashboardData={dataQuery.data?.usage ?? []}
                            />
                            {isOutcomeDashboardFeatureEnabled && (
                                <RequestsByOutcome practiceId={practiceId} />
                            )}
                        </StackPanel>
                    </>
                );
        }
    };

    if (!isNewReportingEnabled) {
        return (
            <>
                <Text>
                    Download data from the last 90 days. It may take a few
                    seconds to start downloading.
                </Text>
                <DownloadReportButton practiceId={practiceId} />
                <hr />
                {isLiveDashboardFeatureEnabled && (
                    <LiveAssignmentDashboard organisationId={practiceId} />
                )}
                <div className="mb-5">{renderBody()}</div>
            </>
        );
    }

    return (
        <Text>
            Patient Triage reporting has a new home, navigate to it by clicking{" "}
            <Link
                to={{
                    pathname: generatePath(ROUTES_ORGS.reporting, {
                        orgId: practiceId,
                    }),
                    state: {
                        navigationOrigin: "Web",
                        pageOrigin: "PatientTriageDashboard",
                    },
                }}
            >
                here
            </Link>
            !
        </Text>
    );
};
