import { PatientSearchResultBase } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { Button, Card, Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import {
    StyledContent,
    StyledEntryCardWrapper,
    StyledPatientDetails,
} from "app/patientLists/patientCard/PatientEntryCard.styles";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { actionCreators as selectProductActionCreators } from "app/selectProduct/SelectProductActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import ContactDropdown, {
    InviteToVideoConsultOption,
    MessageGpPracticeOption,
    NewConversationOption,
    PatientProfileOption,
    RecordViewOption,
} from "app/sharedComponents/patientContactDropdown";
import { useNavigateToWorkspaceRoute } from "app/workspace/hooks";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute, getMessagePracticeUrl } from "shared/RoutesHelper";
import { sentenceCase } from "shared/formatters/String";
import { useAppSelector } from "store/hooks";

export type RecentSearchProps = {
    patient: PatientSearchResultBase;
};

export const RecentSearch = ({
    patient,
}: RecentSearchProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { navigateToWorkspaceRoute } = useNavigateToWorkspaceRoute();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const commonProductSelectionAnalyticsProps = {
        ...analyticsLoggedInProps,
        context:
            FlemingAnalyticsTracker.ProductSelectLocation
                .RecentSearchesDropDown,
        searchPatientOrigin: history.location.pathname,
    };

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const handleSelectProductClick = (product: ProductSelection): void => {
        if (product !== ProductSelection.None) {
            const analyticsProps = {
                ...commonProductSelectionAnalyticsProps,
                Product: AnalyticsMapper.getAnalyticsProductSelected(product),
            };
            FlemingAnalyticsTracker.trackProductSelected(analyticsProps);
        }

        switch (product) {
            case ProductSelection.Video:
                FlemingAnalyticsTracker.trackRecentSearchesContactItemClicked({
                    ...analyticsLoggedInProps,
                    selectedItem: "VideoInvite",
                });
                history.push(
                    findBaseRoute(history.location.pathname) +
                        ROUTES_EXTENSION.sendMessage,
                );
                break;

            case ProductSelection.Sms:
                FlemingAnalyticsTracker.trackRecentSearchesContactItemClicked({
                    ...analyticsLoggedInProps,
                    selectedItem: "PatientMessage",
                });
                const path = `${findBaseRoute(history.location.pathname)}${
                    ROUTES_EXTENSION.patientProfile
                }/conversation/new`;
                navigateToWorkspaceRoute(path);
                break;

            case ProductSelection.RecordView:
                FlemingAnalyticsTracker.trackRecentSearchesContactItemClicked({
                    ...analyticsLoggedInProps,
                    selectedItem: "RecordView",
                });
                history.push(
                    findBaseRoute(history.location.pathname) +
                        ROUTES_EXTENSION.remoteRecordView,
                    {
                        referredFrom: history.location.pathname,
                    },
                );
                break;

            case ProductSelection.MessagePractice:
                FlemingAnalyticsTracker.trackRecentSearchesContactItemClicked({
                    ...analyticsLoggedInProps,
                    selectedItem: "ClinicianMessage",
                });
                const navigateToMessageGPForm =
                    OrganisationHelper.isFeatureEnabled(
                        organisation,
                        FeatureName.AccuMailDisableMessageGPConversation,
                    );

                navigateToMessageGPForm &&
                    dispatch(
                        selectProductActionCreators.selectMessagePracticeProductType(),
                    );
                history.push(
                    `${findBaseRoute(
                        history.location.pathname,
                    )}${getMessagePracticeUrl(organisation)}`,
                    {
                        appOrigin: "PatientSearchDropdown",
                    },
                );
                break;
            // In case no product needs to be selected, go to the product page!
            case ProductSelection.None:
            default:
                FlemingAnalyticsTracker.trackRecentSearchesContactItemClicked({
                    ...analyticsLoggedInProps,
                    selectedItem: "PatientProfile",
                });
                navigateToWorkspaceRoute(
                    findBaseRoute(history.location.pathname) +
                        ROUTES_EXTENSION.patientProfile,
                );
                break;
        }
    };

    return (
        <Card spacing={2}>
            <StyledEntryCardWrapper>
                <StyledContent>
                    <Text variant="label">
                        {patient.displayName || "Unknown name"}
                    </Text>
                    <StyledPatientDetails>
                        <Text variant="body" skinny>
                            NHS no:{" "}
                            {NhsNumberHelpers.formatNhsNumber(
                                patient.nhsNumber as string,
                            )}
                        </Text>
                        <Text variant="body" skinny>
                            Born:{" "}
                            {DateHelpers.formatDate(
                                patient.dateOfBirth as string,
                                DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                            )}{" "}
                            {patient.ageDisplay && `${patient.ageDisplay}`}
                        </Text>
                        <Text variant="body" skinny>
                            Gender: {sentenceCase(patient.gender as string)}
                        </Text>
                    </StyledPatientDetails>
                </StyledContent>
                <ContactDropdown
                    isUserAction
                    workspaceId={organisation?.orgId.toString() || ""}
                    nhsNumber={patient.nhsNumber}
                    dateOfBirth={patient.dateOfBirth as string}
                    triggerButton={({ isDropdownOpen, status }) => (
                        <Button
                            icon={{ name: "Chat", colour: "blue" }}
                            theme="secondary"
                            text="Contact"
                            onClick={(): void => {
                                if (status !== "loading") {
                                    if (!isDropdownOpen) {
                                        FlemingAnalyticsTracker.trackRecentSearchesContactButtonClicked(
                                            analyticsLoggedInProps,
                                        );
                                    }
                                }
                            }}
                            disabled={status === "loading"}
                        />
                    )}
                    dropdownContent={({ data }) => (
                        <>
                            <InviteToVideoConsultOption
                                searchResult={data}
                                onClick={() =>
                                    handleSelectProductClick(
                                        ProductSelection.Video,
                                    )
                                }
                            />
                            <NewConversationOption
                                searchResult={data}
                                onClick={() =>
                                    handleSelectProductClick(
                                        ProductSelection.Sms,
                                    )
                                }
                            />
                            <RecordViewOption
                                searchResult={data}
                                onClick={() =>
                                    handleSelectProductClick(
                                        ProductSelection.RecordView,
                                    )
                                }
                            />
                            <MessageGpPracticeOption
                                searchResult={data}
                                onClick={() =>
                                    handleSelectProductClick(
                                        ProductSelection.MessagePractice,
                                    )
                                }
                            />
                            <PatientProfileOption
                                searchResult={data}
                                onClick={() =>
                                    handleSelectProductClick(
                                        ProductSelection.None,
                                    )
                                }
                            />
                        </>
                    )}
                />
            </StyledEntryCardWrapper>
        </Card>
    );
};
