import { ScheduleSendAt } from "../../types/ScheduleSend.types";

export const getCalendarOrFrameFromScheduleSendAt = (
    sendAt: ScheduleSendAt | undefined,
): (NonNullable<ScheduleSendAt> & { type: "calendar" | "frame" }) | null => {
    if (sendAt === null || sendAt === undefined) {
        return null;
    }

    switch (sendAt.type) {
        case "frame":
            return { ...sendAt, type: "frame" };
        case "calendar":
        case "nextWorkingDay":
        case "todayEvening":
        case "todayMorning":
            return { ...sendAt, type: "calendar" };

        default:
            // Make sure build breaks if we don't map through every type
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
            const exhaustive: never = sendAt;
            return null;
    }
};
