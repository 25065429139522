import { ReactNode, useState } from "react";

import { conversationIdMapper } from "@accurx/concierge";
import { ROUTES_INBOX } from "@accurx/inbox";
import { generatePath, useParams } from "react-router";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { ClinicianConversationApi } from "api/ClinicianConversationApi";
import { useClinicianConversationByUrlIdQuery } from "app/hooks/queries";
import {
    useCurrentOrgId,
    useCurrentUserEmailAddress,
    useIsUserApproved,
} from "store/hooks";

import { ClinicianConversationContext } from ".";
import {
    buildSubjectFromPatient,
    getPatientDetailsWithDisplayNames,
} from "../clinicianConversation.helper";
import { getParticipantsFromConversation } from "../participantsPanel/ParticipantsPanel.mappers";
import { ClinicianConversationContextType } from "./types";

export const FromUrlId = ({ children }: { children: ReactNode }) => {
    const workspaceId = useCurrentOrgId();
    const { urlId } = useParams<{ urlId: string }>();
    const userEmailAddress = useCurrentUserEmailAddress();
    const [queryEnabled, setQueryEnabled] = useState(true);
    const isApprovedUser = useIsUserApproved();

    const hasCareProvidersinbox = useFeatureFlag(
        "UnifiedInboxAccumailSandboxBuild",
    );

    const {
        data: conversation,
        isLoading,
        isError,
    } = useClinicianConversationByUrlIdQuery(
        { workspaceId, urlId },
        { refetchInterval: 10000, enabled: queryEnabled },
    );

    if (!workspaceId) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromUrlId without having an org id in the state",
        );
    }

    if (!userEmailAddress) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromUrlId without having an userEmailAddress in the state",
        );
    }

    if (isError && queryEnabled) {
        // Prevent polling in error state
        setQueryEnabled(false);
    }

    const getContext: () => ClinicianConversationContextType = () => {
        if (isLoading) {
            return {
                state: "loading",
                urlId,
                userEmailAddress,
                isNewConversation: false,
            };
        }

        if (isError) {
            return {
                state: "error",
                urlId,
                error: "Failed to fetch conversation via unique link",
                userEmailAddress,
                isNewConversation: false,
            };
        }

        if (
            hasCareProvidersinbox &&
            conversation.workspaceConversationId &&
            isApprovedUser
        ) {
            const conversationId =
                conversationIdMapper.clinicianMessaging.fromSource(
                    conversation.workspaceConversationId,
                );

            return {
                state: "redirect",
                urlId,
                userEmailAddress,
                isNewConversation: false,
                redirectTo:
                    generatePath(ROUTES_INBOX.CareProvidersMyInbox, {
                        workspaceId,
                    }) + `?conversationId=${conversationId}`,
            };
        }

        const patientDetailsWithDisplayNames = conversation.patient
            ? getPatientDetailsWithDisplayNames(conversation.patient)
            : null;

        return {
            state: "fetched",
            error: null,
            messages: conversation.messages,
            conversationId: conversation.id,
            patient: patientDetailsWithDisplayNames,
            getAttachmentUrl: (attachmentId, attachmentName) =>
                ClinicianConversationApi.getDownloadUrl(
                    urlId,
                    { id: attachmentId, displayName: attachmentName },
                    workspaceId,
                ),
            sendMessage: async ({ messageBody, attachedFiles, metadata }) => {
                setQueryEnabled(false);
                const result = await ClinicianConversationApi.sendMessage({
                    organisationId: workspaceId,
                    conversationId: conversation.id,
                    textContent: messageBody,
                    externalEmailAttachmentIds: attachedFiles.map(
                        ({ id }) => id,
                    ),
                    metadata: {
                        ...metadata,
                        templateLevel: metadata.isPresetTemplate
                            ? "Accurx"
                            : null,
                    },
                });
                setQueryEnabled(true);
                return result;
            },
            onSendFn: () => {
                // noop
            },
            urlId,
            participants: getParticipantsFromConversation({
                individualParticipants: conversation.individualParticipants,
                participantWorkspaces: conversation.participantWorkspaces,
                userEmailAddress: userEmailAddress,
            }),
            userEmailAddress,
            subject: patientDetailsWithDisplayNames
                ? buildSubjectFromPatient(patientDetailsWithDisplayNames)
                : conversation.subject,
            workspaceId,
            hasPolled: conversation.hasPolled,
            isNewConversation: false,
        };
    };

    return (
        <ClinicianConversationContext.Provider value={getContext()}>
            {children}
        </ClinicianConversationContext.Provider>
    );
};
