import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/clinicianMessaging";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";

export const useTeamClinicianConversationGroupQuery: ConversationGroupHook<{
    teamId: string;
    status: "Open" | "Done";
}> = ({ teamId, status }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getConversations({
                workspaceId,
                assigneeGroupId: teamId,
                isArchived: status === "Done",
                updatedBeforeDate: continuationToken,
                limit: 20,
            });

            return {
                continuationToken: response.nextCursor ?? undefined,
                updates,
            };
        },
        [workspaceId, status, teamId],
    );

    return useConversationGroupQuery({
        useCache: false,
        filters: [
            { type: "Source", value: "ClinicianMessaging" },
            {
                type: "AssignedTo",
                value: { type: "Team", id: teamId },
            },
            { type: "Status", value: status },
        ],
        sortOptions: [
            { key: "LastUpdated", direction: "desc" },
            { key: "Id", direction: "desc" },
        ],
        loggingInfo: {
            name: "Team clinician conversations",
            tags: {
                statusFilter: status,
                teamIdFilter: teamId,
            },
        },
        fetcher,
    });
};
