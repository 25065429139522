import {
    AssigneeType,
    PatientThreadAssignee,
    PatientThreadTicketDetails,
    TicketSummary,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { mapPatientThreadAssigneeToAssigneeSummary } from "domains/concierge/internal/api/ticket/mappers/mapPatientThreadAssigneeToAssigneeSummary";
import {
    AssigneeSummary,
    Conversation,
    ConversationItem,
} from "domains/concierge/types";
import isNil from "lodash/isNil";

import { mapTicketIdentityToConversationId } from "./mapTicketIdentityToConversationId";
import { mapTicketItemToConversationItem } from "./mapTicketItemToConversationItem";

export const mapTicketToConversation = (
    ticket: PatientThreadTicketDetails,
): Conversation | undefined => {
    const ticketSummary = ticket.ticketSummary;
    const ticketIdentity = ticketSummary?.identity;

    if (
        isNil(ticketSummary) ||
        isNil(ticketIdentity) ||
        isNil(ticketIdentity.id)
    ) {
        Log.error("Missing conversation identity", {
            tags: { product: "Inbox" },
        });
        return undefined;
    }

    const conversationId = mapTicketIdentityToConversationId(ticketIdentity);

    if (isNil(ticketSummary.lastUpdated) || isNil(ticketSummary.latestToken)) {
        Log.error("Missing last update information", {
            tags: {
                product: "Inbox",
                conversationId,
            },
        });
        return undefined;
    }

    const status = mapConversationState(ticketSummary);
    const assignee = mapPatientThreadAssigneeToAssigneeSummary(
        ticketSummary.assignee,
    );

    // FOU-149: deprecating ticket.patient over ticketSummary.patientId
    const patientId =
        ticketSummary.patientId ?? ticket.patient?.accuRxId ?? null;

    const initialSummary: Omit<Conversation, "items" | "isFullyLoaded"> = {
        id: conversationId,
        source: {
            system: "Ticket",
            ticketIdentity: {
                id: ticketIdentity.id,
                type: ticketIdentity.type,
            },
        },
        status: status,
        assignee: assignee,
        isUrgent: ticketSummary.isUrgent ?? false,
        lastUpdated: ticketSummary.lastUpdated,
        latestToken: ticketSummary.latestToken,
        regardingPatientId: patientId,
        participants: [],
        // not currently tracking whether a conversation is fully loaded or not
    };

    // Items within a conversation.
    const items = mapConversationItems(ticket);

    // Ignore conversations we can't show any data for.
    if (items.length === 0) {
        Log.error("Missing conversation items", {
            tags: { product: "Inbox", conversationId },
        });
        return undefined;
    }

    return {
        ...initialSummary,
        items,
        isFullyLoaded: !!ticket.isFullyLoaded,
    };
};

export const getPatientThreadAssignee = (
    assignee: AssigneeSummary,
): PatientThreadAssignee | null => {
    if (assignee.type === "User" && assignee.id) {
        return {
            type: AssigneeType.User,
            userId: assignee.id,
        };
    }
    if (assignee.type === "Team" && assignee.id) {
        return {
            type: AssigneeType.UserGroup,
            userGroupId: assignee.id,
        };
    }
    return null;
};

function mapConversationItems(
    ticket: PatientThreadTicketDetails,
): ConversationItem[] {
    const mappedConversationItems = new Array<ConversationItem>();
    if (ticket.items) {
        ticket.items.forEach((ticketItem) => {
            const mappedItem = mapTicketItemToConversationItem(ticketItem);

            if (mappedItem) {
                mappedConversationItems.push(mappedItem);
            }
        });
    }
    return mappedConversationItems;
}

function mapConversationState(ticket: TicketSummary): Conversation["status"] {
    switch (ticket.isDone) {
        case true:
            return "Done";
        case false:
            return "Open";
        default:
            // The isDone property passes a null value when the backend returns a pseudo-ticket e.g. representing a single SMS
            return "None";
    }
}
