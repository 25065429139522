import { createContext } from "react";

import { CurrentlyViewingConversations } from "./types";

type CurrentlyViewingContextValue = {
    conversations: CurrentlyViewingConversations;
};

const initialState: CurrentlyViewingContextValue = {
    conversations: {},
};

export const CurrentlyViewingContext =
    createContext<CurrentlyViewingContextValue>(initialState);
