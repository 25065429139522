import { forwardRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import { ToolippedButton } from "../TooltippedButton/TooltippedButton";

export const BookingInviteButton = forwardRef<
    HTMLButtonElement,
    {
        userflowId?: string;
        onClick: () => void;
        disabled?: boolean;
        "data-testid"?: string;
    }
>(({ onClick, userflowId, disabled = false, ...props }, ref) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const isIconOnly = isMessageComponentV1_1Enabled;

    return (
        <ToolippedButton
            toggle={onClick}
            disabled={disabled}
            ariaLabel="Add a Self-Book invite"
            userFlowId={userflowId}
            iconOnly={isIconOnly}
            tooltipContent="Booking invite"
            ref={ref}
            data-testid={props["data-testid"]}
        >
            <Pill.Icon
                name="Calendar"
                colour="blue"
                theme="Line"
                useDisabledColour={disabled}
                props={{
                    "aria-hidden": true,
                }}
            />
            {!isIconOnly && (
                <UI.Text as="span" skinny variant="preview">
                    Booking invite
                </UI.Text>
            )}
        </ToolippedButton>
    );
});
