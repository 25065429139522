import { useEffect, useMemo, useRef, useState } from "react";

import { poller } from "domains/concierge/internal/util/poller";
import {
    PollerOptions,
    PollerStatus,
} from "domains/concierge/internal/util/poller.types";

import { useOfflinePoller } from "../util/offlinePoller";

type UsePollerOptions = PollerOptions & {
    onlyPollWhenOffline?: boolean;
};

/*
 * WARNING: the fetchFn passed to this
 * hook MUST BE MEMOIZED using useCallback. We've tried to do this
 * memoization internal to the hook but it
 * makes it very hard to figure out when it should change
 * - so you as a consumer of this hook are responsible
 * for knowing this and using a useCallback hook and dependency
 * array appropriately.
 */
export const usePoller = (options: UsePollerOptions): PollerStatus => {
    const { name, fetchFn, refreshRate, onlyPollWhenOffline } = options;

    // Keep a live ref of skipInitialFetch - we only
    // need this value once each time we spin up an offlinePoller
    // - we don't want to trigger the teardown and creation of a
    // poller when it changes - so we want to use it in the
    // useEffect without including it in the dependency array
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const [state, setState] = useState<PollerStatus>({
        status: optionsRef.current.skipInitialFetch ? "success" : "loading",
        refresh: { status: "idle" },
    });

    const pollerOptions = useMemo<PollerOptions>(() => {
        return {
            name,
            fetchFn,
            refreshRate,
            skipInitialFetch: optionsRef.current.skipInitialFetch,
            onFetchStart: (meta) => {
                optionsRef.current.onFetchStart?.(meta);
                if (meta.initialized) {
                    setState((s) => ({
                        ...s,
                        refresh: { status: "loading" },
                    }));
                }
            },
            onFetchSuccess: (meta) => {
                optionsRef.current.onFetchSuccess?.(meta);
                setState(() => ({
                    status: "success",
                    refresh: { status: "idle" },
                }));
            },
            onFetchError: (e, meta) => {
                optionsRef.current.onFetchError?.(e, meta);
                if (meta.initialized) {
                    setState((s) => ({
                        ...s,
                        refresh: { status: "error", error: e },
                    }));
                } else {
                    setState(() => ({
                        status: "error",
                        error: e,
                        refresh: { status: "idle" },
                    }));
                }
            },
            onPollingStateChange: (meta) =>
                optionsRef.current.onPollingStateChange?.(meta),
            onRefreshInterval: (meta) =>
                optionsRef.current.onRefreshInterval?.(meta),
        };
    }, [name, fetchFn, refreshRate]);

    const offlinePoller = useOfflinePoller(pollerOptions);

    useEffect(() => {
        setState({
            status: optionsRef.current.skipInitialFetch ? "success" : "loading",
            refresh: { status: "idle" },
        });

        if (onlyPollWhenOffline) {
            offlinePoller.start();
            return offlinePoller.end;
        } else {
            return poller(pollerOptions);
        }
    }, [onlyPollWhenOffline, pollerOptions, offlinePoller]);

    return state;
};
