import { useEffect, useState } from "react";

import { SessionStorageHelpers } from "@accurx/shared";
import { useLocation } from "react-router-dom";

// query params can be changed when the route independently changes so we want to save it once we see it
export const STORAGE_KEY = "AccurxDesktopVersion";

const getDesktopVersionQueryParam = (
    locationSearch: ReturnType<typeof useLocation>["search"],
): string | null => {
    let version = null;
    new URLSearchParams(locationSearch)
        .getAll("desktopVersion")
        .forEach((v) => {
            version = v;
        });
    return version;
};

// A query param `desktopVersion` is provided when opening a WebView window
export const useDesktopVersion = (): string | null => {
    const location = useLocation();
    const [desktopVersion, setDesktopVersion] = useState<string | null>(
        SessionStorageHelpers.getSessionStorageItem(STORAGE_KEY) ??
            getDesktopVersionQueryParam(location.search),
    );

    const updateDesktopVersion = (version: string) => {
        SessionStorageHelpers.setSessionStorageItem(STORAGE_KEY, version);
        setDesktopVersion(version);
    };

    useEffect(() => {
        const version = getDesktopVersionQueryParam(location.search);
        if (version) {
            updateDesktopVersion(version);
        }
    }, [location.search]);

    return desktopVersion;
};
