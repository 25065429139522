import { useCurrentWorkspace } from "@accurx/auth";
import {
    ClinicianCompose,
    ComposePatient,
    useIsComposing,
} from "@accurx/compose";
import { useSendMutation } from "@accurx/concierge/hooks/mutations/useSendMutation";
import { Conversation } from "@accurx/concierge/types";
import { ReplyState } from "domains/inbox/components/ConversationView/ConversationView.types";
import { ReplyFailedFeedback } from "domains/inbox/components/ConversationView/components/Reply/ReplyFailedFeedback";
import { getMainParticipant } from "domains/inbox/util/getMainParticipant";
import { getParticipantName } from "domains/inbox/util/getParticipantName";
import { toast } from "react-toastify";

import { ReplyActionButton } from "../MessageActionButton/MessageActionButton";
import {
    StyledInnerContainer,
    StyledOuterContainer,
} from "./ContentsNew.styles";

type ReplyContentsProps = {
    conversation: Conversation;
    patient?: ComposePatient;
    replyState: Omit<ReplyState, "Closed">; // ReplyContents is only be displayed when Reply is open
    setReplyState: (state: ReplyState) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const ClinicianReplyContentsNew = ({
    patient,
    conversation,
    replyState,
    setReplyState,
    isExpanded,
    onExpandClick,
}: ReplyContentsProps) => {
    const isComposing = useIsComposing();
    const sendMessage = useSendMutation();

    const { orgId } = useCurrentWorkspace();

    if (replyState === "Minimised") {
        return (
            <ReplyActionButton
                onClick={() => setReplyState("Open")}
                text={isComposing ? "Continue reply" : "Reply"}
            />
        );
    }
    const recipient = getParticipantName(
        getMainParticipant(conversation, orgId),
    );

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <ClinicianCompose
                    isMessageSending={sendMessage.isLoading}
                    onMessageSend={(args) => {
                        if (
                            // Just for type safety
                            conversation.source.system === "ClinicianMessaging"
                        ) {
                            sendMessage.mutate(
                                {
                                    workspaceId: orgId.toString(),
                                    workspaceConversationId:
                                        conversation.source.serverId,
                                    message: `${args.messageBody}${
                                        args.messageSignature
                                            ? `\n${args.messageSignature}`
                                            : ""
                                    }`,
                                    attachmentIds: args.attachments.map(
                                        (att) => att.id,
                                    ),
                                    isPresetTemplate: !!args.template.value,
                                    isPostPatientMessage: false,
                                    templateGroup:
                                        args.template.value?.heading ?? null,
                                    templateName:
                                        args.template.value?.title ?? null,
                                },
                                {
                                    onSuccess: () => {
                                        setReplyState("Closed");
                                    },
                                    onError: () => {
                                        toast(<ReplyFailedFeedback />, {
                                            autoClose: false,
                                        });
                                    },
                                },
                            );
                        }
                    }}
                    recipient={recipient}
                    conversationId={conversation.id}
                    patient={patient}
                    countParticipant={conversation.participants.length}
                    onMinimiseClick={() => setReplyState("Minimised")}
                    onExpandClick={onExpandClick}
                    isHeightRestricted={!isExpanded}
                />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
