import { createSlice } from "@reduxjs/toolkit";
import { QueriesState } from "domains/concierge/internal/types/QueriesState";

import * as reducers from "./reducers";

export const initialState: QueriesState = {
    ticketUnreads: { status: "loading" },
    clinicianMessagingUnreads: { status: "loading" },
    clinicianMessagingTeams: { status: "loading" },
};

export const queries = createSlice({
    name: "queries",
    initialState,
    reducers,
});
