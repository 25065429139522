import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { COMPOSE_ERRORS } from "domains/compose/constants";
import { useCompose } from "domains/compose/context";
import { isErrorActive } from "domains/compose/utils";

export const ComposeErrors = () => {
    const { state } = useCompose();

    if (state.errors.length === 0) {
        return null;
    }

    return (
        <UI.Flex flexDirection="column" gap="0.5">
            {isErrorActive(state.errors, COMPOSE_ERRORS.SmsMessageTooLong) && (
                <Feedback
                    title="Message is too long to send via SMS"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {isErrorActive(
                state.errors,
                COMPOSE_ERRORS.TemplatePlaceholderRemains,
            ) && (
                <Feedback
                    title="Please complete the template message"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {isErrorActive(state.errors, COMPOSE_ERRORS.EmptyMessage) && (
                <Feedback
                    title="Write a message before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {isErrorActive(
                state.errors,
                COMPOSE_ERRORS.AttachmentStillLoading,
            ) && (
                <Feedback
                    title="Wait for the attachments to complete uploading before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {isErrorActive(
                state.errors,
                COMPOSE_ERRORS.QuestionnaireStillLoading,
            ) && (
                <UI.Feedback
                    title="Wait for the questionnaire to complete loading before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}
        </UI.Flex>
    );
};
