import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { getTeamUsage } from "api/PracticesApi";
import { TeamUsageDto } from "app/reporting/components/TeamUsage/TeamUsage.types";

export const TEAM_USAGE_CACHE_KEY = "TeamUsage";

type TeamUsageQueryVariables = {
    workspaceId: number;
};

export const useTeamUsageQuery = createQueryHook<
    TeamUsageQueryVariables,
    TeamUsageDto
>(({ workspaceId }) => ({
    queryKey: [TEAM_USAGE_CACHE_KEY, { workspaceId }],
    queryFn: async () => {
        const response = await getTeamUsage(workspaceId.toString());
        return returnDataOrThrow(response);
    },
}));
