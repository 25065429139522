import { useConciergeSelector } from "domains/concierge/internal/context";

export const useUnreadCountSelector = (
    selector: Parameters<typeof useConciergeSelector<number>>[0],
) => {
    const queryStatus = useConciergeSelector(
        (s) => s.queries.ticketUnreads.status,
    );
    const count = useConciergeSelector(selector);
    return queryStatus === "success" ? count : 0;
};
