import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

import { TeamUsage } from "../components/TeamUsage";

export const TeamUsagePage = () => {
    return (
        <StyledContainer>
            <PageHeader title="Team usage" type={PageHeaderType.ListPage} />

            <TeamUsage />
        </StyledContainer>
    );
};
