import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledFetchingError = styled(UI.Ds.Flex).attrs({
    alignItems: "center",
    gap: "0.25",
})`
    width: 100%;
    height: ${UI.Tokens.SIZES[4]};
    padding: ${UI.Tokens.SIZES[1]};

    border: ${UI.Tokens.BORDERS.normal.silver};
    border-radius: ${UI.Tokens.BORDERS.radius};
`;

export const StyledReloadButton = styled.button`
    border: none;
    padding: 0;
    background: none;

    color: ${UI.Tokens.COLOURS.primary.blue[100]};
    text-decoration: underline;
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.mobile.fontSize};

    &:hover {
        color: ${UI.Tokens.COLOURS.primary.blue[130]};
    }
`;
