import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import {
    Accordion,
    ExpandingScrollableArea,
    FocusRingSpacing,
    NavLink,
    NavLinkWithToggle,
} from "@accurx/navigation";
import { generatePath, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { usePatientListSummariesQuery } from "app/hooks/queries/usePatientListSummariesQuery";
import { ROUTES, ROUTES_BASE } from "shared/Routes";

const StyledListName = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type PatientListsProps = {
    isOpen?: boolean;
};

export const PatientLists = ({ isOpen = false }: PatientListsProps) => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const patientListsMatch = useRouteMatch({
        path: ROUTES_BASE.patientsListsAll,
        exact: true,
    });
    const patientListMatch = useRouteMatch<{ patientListId: string }>([
        ROUTES_BASE.patientsListsId,
    ]);

    const { data = [] } = usePatientListSummariesQuery({
        workspaceId,
    });

    const checkIsListActive = (patientListId: number) => {
        return (
            patientListMatch?.params.patientListId === patientListId.toString()
        );
    };

    return (
        <>
            <Accordion.Item.Level2 value="patient-lists">
                <Accordion.Header>
                    <FocusRingSpacing>
                        <NavLinkWithToggle isActive={!!patientListsMatch}>
                            <NavLinkWithToggle.Link asChild>
                                <Link to={ROUTES_BASE.patientsListsAll}>
                                    <Ds.Flex gap="1" alignItems="center">
                                        <Ds.Icon
                                            name="List"
                                            color="white"
                                            size="small"
                                        />
                                        Lists
                                    </Ds.Flex>
                                </Link>
                            </NavLinkWithToggle.Link>
                            {data.length > 0 && (
                                <Accordion.Trigger asChild>
                                    <NavLinkWithToggle.Toggle aria-label="Open patient lists">
                                        <Ds.Icon
                                            name="Arrow"
                                            color="white"
                                            size="xsmall"
                                            rotate={isOpen ? "0" : "90"}
                                        />
                                    </NavLinkWithToggle.Toggle>
                                </Accordion.Trigger>
                            )}
                        </NavLinkWithToggle>
                    </FocusRingSpacing>
                </Accordion.Header>
                {data.length > 0 && (
                    <Accordion.Content>
                        <ExpandingScrollableArea>
                            <Accordion.Indented>
                                {data.map(({ name, patientListId }) => (
                                    <FocusRingSpacing key={patientListId}>
                                        <NavLink
                                            asChild
                                            isActive={checkIsListActive(
                                                patientListId,
                                            )}
                                            size="small"
                                        >
                                            <Link
                                                to={generatePath(
                                                    ROUTES.patientsList,
                                                    { patientListId },
                                                )}
                                            >
                                                <NavLink.Icon name="List" />
                                                <StyledListName>
                                                    {name}
                                                </StyledListName>
                                            </Link>
                                        </NavLink>
                                    </FocusRingSpacing>
                                ))}
                            </Accordion.Indented>
                        </ExpandingScrollableArea>
                    </Accordion.Content>
                )}
            </Accordion.Item.Level2>
        </>
    );
};
