export const DAYS_OF_THE_WEEK = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const TIME_OPTIONS = [
    { label: "6am", value: "6" },
    { label: "7am", value: "7" },
    { label: "8am", value: "8" },
    { label: "9am", value: "9" },
    { label: "10am", value: "10" },
    { label: "11am", value: "11" },
    { label: "12pm", value: "12" },
    { label: "1pm", value: "13" },
    { label: "2pm", value: "14" },
    { label: "3pm", value: "15" },
    { label: "4pm", value: "16" },
    { label: "5pm", value: "17" },
    { label: "6pm", value: "18" },
    { label: "7pm", value: "19" },
    { label: "8pm", value: "20" },
    { label: "9pm", value: "21" },
];

export const NUMBER_REGEXP = /^[0-9\b]+$/;

export const DEFAULT_TIME = "9";

export const TimeFrameType = {
    days: "Days",
    weeks: "Weeks",
    months: "Months",
};
