/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface AppointmentBookWork {
    /** @format int32 */
    organisationId?: number | null;
    requestId?: string | null;
    /** @format date-time */
    from?: string | null;
    /** @format date-time */
    to?: string | null;
    /** @format date-time */
    now?: string | null;
}

export interface AppointmentBookWorkResult {
    /** @format int32 */
    organisationId: number;
    /** @minLength 1 */
    requestId: string;
    success: boolean;
    /** @format date-time */
    from?: string | null;
    /** @format date-time */
    to?: string | null;
    appointments?: Appointment[] | null;
    sourceSystemType?: PrincipalSystemType | null;
}

export interface Appointment {
    /** @minLength 1 */
    id: string;
    /** @format int32 */
    emisSessionId?: number | null;
    /** @minLength 1 */
    slotType: string;
    siteName?: string | null;
    /**
     * @format date-time
     * @minLength 1
     */
    startTime: string;
    bookedPatientDemographics?: PatientDemographicDetails | null;
    structuredClinicians: Clinician[];
}

export type PatientDemographicDetails = PatientExternalIdentityDto & {
    demographicMobileNumber?: string | null;
    names?: PatientName[] | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    gender?: string | null;
};

export interface PatientName {
    use: PatientNameUse;
    prefix?: string[] | null;
    given?: string[] | null;
    family?: string[] | null;
}

export enum PatientNameUse {
    None = 0,
    Usual = 1,
    Official = 2,
    Temp = 3,
    Nickname = 4,
    Anonymous = 5,
    Old = 6,
    Maiden = 7,
}

export interface PatientExternalIdentityDto {
    patientExternalIds?: PatientExternalIdDto[] | null;
}

export interface PatientExternalIdDto {
    type: IdType;
    /** @minLength 1 */
    value: string;
}

export enum IdType {
    None = 0,
    NhsNumber = 1,
    Emis = 2,
    Dummy = 3,
    SystmOne = 4,
    Vision = 5,
    Hospital = 6,
}

export interface Clinician {
    userId?: string | null;
    displayName?: string | null;
}

export enum PrincipalSystemType {
    None = 0,
    Emis = 1,
    SystmOne = 2,
    Vision = 3,
    Pas = 4,
}

export interface AppointmentCancelWork {
    /** @format int32 */
    organisationId?: number | null;
    requestId?: string | null;
    appointmentId?: string | null;
    patient: PatientExternalIdentityDto;
}

export interface AppointmentCancelWorkResult {
    /** @format int32 */
    accuRxOrgId: number;
    /** @minLength 1 */
    requestId: string;
    succeeded: boolean;
}

export interface AppointmentChangeRequestResponse {
    appointmentChangeRequests: AppointmentChangeRequest[];
}

export interface AppointmentChangeRequest {
    externalIdDisplay: PatientExternalIdDto;
    /** @format date-time */
    appointmentStartTime: string;
    patientName: string;
    /** @format date-time */
    appointmentChangedAt: string;
    clinicianName?: string[] | null;
    slotType?: string | null;
    changeRequestType: AppointmentChangeRequestTypeV2;
    status: AppointmentChangeRequestStatus;
}

export enum AppointmentChangeRequestTypeV2 {
    Cancel = "Cancel",
    Rebook = "Rebook",
}

export enum AppointmentChangeRequestStatus {
    Skipped = "Skipped",
    Succeeded = "Succeeded",
    Failed = "Failed",
}

export interface PatientMessageResponse {
    patientMessageResult: ValueTupleOfDateTimeOffsetAndString[];
}

export type ValueTupleOfDateTimeOffsetAndString = object;

export interface AppointmentReminderAuditResponse {
    appointmentReminderDeliveryReceipts: AppointmentReminderDeliveryReceipt[];
}

export interface AppointmentReminderDeliveryReceipt {
    id: string;
    /** @format int64 */
    appointmentId: number;
    externalIdDisplay: PatientExternalIdDto;
    patientIdDisplayType?: LegacyPatientExternalIdType | null;
    patientIdDisplay?: string | null;
    dateLastUpdated: string;
    status: string;
    patientName: string;
    appointmentStartTime: string;
    slotType?: string | null;
    clinicianName?: string[] | null;
    /** @format date-time */
    appointmentChangedAt: string;
}

export enum LegacyPatientExternalIdType {
    None = 0,
    NhsNumber = 1,
    Emis = 4,
    SystmOne = 5,
    Vision = 6,
    Hospital = 7,
}

export interface AppointmentReminderConfigOptions {
    availableFilters: AppointmentReminderFilterAttribute[];
    supportedChanges: AppointmentSupportedChanges;
    changeDeadline: AppointmentChangeDeadline;
}

export enum AppointmentReminderFilterAttribute {
    AppointmentTypes = "AppointmentTypes",
    ClinicCodes = "ClinicCodes",
    SiteNames = "SiteNames",
    SlotTypes = "SlotTypes",
}

export interface AppointmentSupportedChanges {
    cancellation: AppointmentChangeSupport;
    rebooking: AppointmentChangeSupport;
}

export enum AppointmentChangeSupport {
    NotSupported = "NotSupported",
    AllowedToRequest = "AllowedToRequest",
    Allowed = "Allowed",
}

export enum AppointmentChangeDeadline {
    OneHour = "OneHour",
    OneDay = "OneDay",
}

export type GetAppointmentRemindersResponse =
    BaseAppointmentRemindersResponse & {
        slotTypes: string[];
        siteNames: string[];
        appointmentTypes: AppointmentTypeFilterOption[];
    };

export interface AppointmentTypeFilterOption {
    value: AppointmentTypeFilter;
    description: string;
}

export enum AppointmentTypeFilter {
    FaceToFace = "FaceToFace",
    Telephone = "Telephone",
}

export interface BaseAppointmentRemindersResponse {
    appointmentReminders: AppointmentReminder[];
}

export interface AppointmentReminder {
    /** @format int32 */
    id: number;
    customMessageBody?: string | null;
    slotTypes: string[];
    siteNames: string[];
    isLive: boolean;
    oneWeekReminderEnabled: boolean;
    threeWorkingDayReminderEnabled: boolean;
    postAppointmentMessageEnabled: boolean;
    postAppointmentCustomMessage?: string | null;
    type: string;
    appointmentTypeFilters: AppointmentTypeFilter[];
}

export interface CreateAppointmentReminderRequest {
    customMessageBody?: string | null;
    slotTypes: string[];
    siteNames: string[];
    oneWeekReminderEnabled?: boolean | null;
    threeWorkingDayReminderEnabled?: boolean | null;
    postAppointmentMessageEnabled?: boolean | null;
    postAppointmentCustomMessage?: string | null;
    type?: string | null;
    appointmentTypeFilters?: AppointmentTypeFilter[] | null;
}

export type UpdateAppointmentReminderRequest =
    BaseAppointmentReminderRequest & {
        customMessageBody?: string | null;
        slotTypes: string[];
        siteNames: string[];
        oneWeekReminderEnabled?: boolean | null;
        threeWorkingDayReminderEnabled?: boolean | null;
        postAppointmentMessageEnabled?: boolean | null;
        postAppointmentCustomMessage?: string | null;
        type?: string | null;
        appointmentTypeFilters?: AppointmentTypeFilter[] | null;
    };

export interface BaseAppointmentReminderRequest {
    /** @format int32 */
    appointmentReminderId: number;
}

export interface AppointmentConfiguration {
    /** @format int32 */
    selfBookSchedulingWeeksIntoFuture: number;
    selfBookSchedulingIntoFutureEnabled: boolean;
    /** @format int32 */
    selfBookMaxAppointmentAvailabilityWeeks: number;
    selfBookCrossOrgBookableOrganisations: CrossOrgBookableOrganisation[];
}

export interface CrossOrgBookableOrganisation {
    name: string;
    odsCode: string;
}

export interface IntegratedClinicListResponse {
    /** @format date-time */
    lastUpdated?: string | null;
    /** @format date-time */
    startDate: string;
    /** @format date-time */
    endDate: string;
    clinics: IntegratedClinicNoPiiResponse[];
}

export interface IntegratedClinicNoPiiResponse {
    name?: string | null;
    /** @format int32 */
    organisationId: number;
    /** @format date */
    clinicDate: string;
    responsibleClinicians: string[];
    clinicCode?: string | null;
    location?: string | null;
    /** @format date-time */
    lastUpdated: string;
    /** @format int32 */
    appointmentCount: number;
}

export interface IntegratedClinicResponse {
    name?: string | null;
    /** @format int32 */
    organisationId: number;
    /** @format date */
    clinicDate: string;
    responsibleClinicians: string[];
    clinicCode?: string | null;
    /** @format date-time */
    lastUpdated: string;
    appointments: IntegratedClinicAppointmentResponse[];
}

export interface IntegratedClinicAppointmentResponse {
    /** @format int64 */
    id: number;
    /** @format date-time */
    startTime?: string | null;
    /** @format date */
    dateOfBirth?: string | null;
    nhsNumber?: string | null;
    displayName?: string | null;
    gender?: string | null;
    appointmentType?: string | null;
    hospitalNumber?: string | null;
    location?: string | null;
    patientLookupResult?: PatientLookupStatus | null;
}

export enum PatientLookupStatus {
    Success = "Success",
    PdsLookupFailed = "PdsLookupFailed",
    MissingNhsNumber = "MissingNhsNumber",
    MissingDateOfBirth = "MissingDateOfBirth",
    FailedOther = "FailedOther",
}

export interface StoreAppointmentResult {
    /** @format int64 */
    appointmentId: number;
}

export interface StoreAppointmentRequest {
    /** @format int32 */
    organisationId: number;
    /** @minLength 1 */
    externalId: string;
    /** @format int32 */
    patientId: number;
    /**
     * @format date-time
     * @minLength 1
     */
    startTime: string;
    status?: string | null;
    priority?: string | null;
    consultationMedium?: string | null;
    description?: string | null;
    specialtyCode?: string | null;
    specialtyDisplay?: string | null;
    location?: AppointmentLocationDto | null;
    attendingClinicians?: AppointmentAttendingClinicianDto[] | null;
    healthcareServices?: AppointmentHealthcareServiceDto[] | null;
}

export interface AppointmentLocationDto {
    addressLine?: string | null;
    city?: string | null;
    district?: string | null;
    state?: string | null;
    postalCode?: string | null;
}

export interface AppointmentAttendingClinicianDto {
    idSystem?: string | null;
    id?: string | null;
    title?: string | null;
    firstName?: string | null;
    lastName?: string | null;
}

export interface AppointmentHealthcareServiceDto {
    idSystem?: string | null;
    id?: string | null;
    display?: string | null;
}

export interface PatientAppointmentSummary {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    patientId: number;
    /** @format int64 */
    appointmentId: number;
    /** @minLength 1 */
    serviceName: string;
    serviceLocation?: string | null;
    /**
     * @format date-time
     * @minLength 1
     */
    appointmentTime: string;
    isCancelled?: boolean | null;
    clinician?: string | null;
    clinicCode?: string | null;
    appointmentType?: string | null;
    isIntegrated?: boolean | null;
    slotTypeName?: string | null;
    patientChangeReason?: string | null;
}

export interface AppointmentSms {
    selfBookRequest?: SelfBookRequest | null;
}

export interface SelfBookRequest {
    urlId: string;
    status: SelfBookRequestStatus;
    type: SelfBookAppointmentType;
    /** @format date-time */
    expiryDate?: string | null;
    canBeDeclined: boolean;
    /** @format date-time */
    startTime?: string | null;
    location?: string | null;
    /** @format date-time */
    updatedToBookedAt?: string | null;
    slotType: string;
    clinicians?: string[] | null;
}

export enum SelfBookRequestStatus {
    Incomplete = 0,
    Pending = 1,
    Booked = 2,
    Retry = 3,
    Failed = 4,
    Cancelled = 5,
    Expired = 6,
    Declined = 7,
}

export enum SelfBookAppointmentType {
    Telephone = 0,
    FaceToFace = 1,
}

export interface BookingRequestStatus {
    status: SelfBookRequestStatus;
}

export interface SelfBookAuditTrailResponse {
    auditList: SelfBookAuditTrailItem[];
}

export interface SelfBookAuditTrailItem {
    nhsNumber: string;
    patientName: string;
    bookingStatus: string;
    /** @format date-time */
    sentTime: string;
    deliveryStatus?: string | null;
    senderName: string;
}

export interface SelfBookAvailability {
    /** @format int32 */
    weeksAvailable: number;
    defaultOrgSlotTypesAvailability: SlotTypesAvailability;
    crossOrgAvailableOdsAndNames: Record<string, string>;
    slotTypesWithAvailabilityCount: Record<string, number>;
}

export interface SlotTypesAvailability {
    telephone: Record<string, number>;
    faceToFace: Record<string, number>;
}

export interface AppointmentAvailability {
    /** @format int32 */
    appointmentCount: number;
    slotTypeAvailabilities: SlotTypeAvailability[];
    /** @format int32 */
    weeksAvailable: number;
    crossOrgAvailableOdsAndNames: Record<string, string>;
}

export interface SlotTypeAvailability {
    /** @format int32 */
    appointmentCount: number;
    slotTypeName: string;
    clinicianGroups: ClinicianGroupAvailability[];
}

export interface ClinicianGroupAvailability {
    /** @format int32 */
    appointmentCount: number;
    /** @format int32 */
    clinicianGroupId?: number | null;
    clinicians: Clinician[];
}

export interface SelfBookPatientBookingWork {
    /** @format int32 */
    organisationId?: number | null;
    requestId?: string | null;
    appointmentIds?: string[] | null;
    appointments?: BookableAppointment[] | null;
    patient: PatientExternalIdentityDto;
    bookingNote?: string | null;
}

export interface BookableAppointment {
    id?: string | null;
    /** @format int32 */
    emisSessionId?: number | null;
    /** @minLength 1 */
    slotType: string;
    siteName?: string | null;
    /**
     * @format date-time
     * @minLength 1
     */
    startTime: string;
    clinicians?: string[] | null;
    structuredClinicians?: Clinician[] | null;
    duration?: TimeSpanDto | null;
}

export interface TimeSpanDto {
    /** @format int64 */
    ticks?: number | null;
}

export interface SelfBookPatientBookingWorkResult {
    /** @format int32 */
    accuRxOrgId: number;
    /** @minLength 1 */
    requestId: string;
    appointmentId?: string | null;
    isTerminalFailure?: boolean | null;
    error?: BookingErrorType | null;
}

export enum BookingErrorType {
    None = 0,
    Unknown = 1,
    SlotError = 2,
    PatientError = 3,
    InvalidIdError = 4,
}

export interface SelfBookScrapWork {
    /** @format int32 */
    organisationId?: number | null;
    requestId?: string | null;
    /** @format date-time */
    from?: string | null;
    /** @format date-time */
    to?: string | null;
    /** @format date-time */
    now?: string | null;
    slotTypes?: string[] | null;
}

export interface SelfBookScrapBookWorkResult {
    /** @format int32 */
    organisationId: number;
    /** @minLength 1 */
    requestId: string;
    success: boolean;
    /** @format date-time */
    from?: string | null;
    /** @format date-time */
    to?: string | null;
    appointments?: BookableAppointment[] | null;
    sourceSystemType?: PrincipalSystemType | null;
    failedSessionIds?: number[] | null;
}

export interface CreateUserToPatientBookingRequestResponse {
    bookingRequestUrlId: string;
    friendlyErrorMessage: string;
    error?: Errors | null;
}

export enum Errors {
    NoAvailableAppointments = 0,
    Unknown = 1,
    PatientDemographicsMismatch = 2,
}

export interface CreateUserToPatientBookingRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    clinicianId: number;
    /** @format int32 */
    patientId: number;
    /** @minLength 1 */
    patientPhoneNumber: string;
    type: SelfBookRequestType;
    slotType?: string | null;
    targetOdsCode?: string | null;
    /** @format int32 */
    contextId?: number | null;
    snomedConceptId?: string | null;
    ignoreBasicCapacityCheck?: boolean | null;
    /** @format date-time */
    expireAt?: string | null;
    declineSettings?: DeclineSettingsData | null;
    specificClinicianEnabled?: boolean | null;
    clinicianGroupIds?: number[] | null;
    availabilityRestriction?: AvailabilityRestriction | null;
}

export enum SelfBookRequestType {
    Telephone = 0,
    FaceToFace = 1,
}

export interface DeclineSettingsData {
    snomedConceptId: string;
    confirmationText: string;
}

export interface AvailabilityRestriction {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface LinkUserToPatientBookingRequestToSmsRequest {
    /** @minLength 1 */
    bookingRequestUrlId: string;
    /** @format int32 */
    smsId?: number | null;
    /** @format int32 */
    userToPatientSmsId?: number | null;
    /** @format int32 */
    patientSmsId?: number | null;
    /** @format int32 */
    smsLength: number;
    accurxMessageId?: string | null;
}

export interface BatchSelfBookStatusSummariesResponse {
    /** @format int32 */
    organisationId: number;
    selfBookCountByStatus: BatchSelfBookStatusSummaryItem[];
}

export interface BatchSelfBookStatusSummaryItem {
    /** @format int32 */
    batchContextId: number;
    /** @format int32 */
    bookedCount: number;
}

export interface BatchSelfBookStatusSummariesRequest {
    /** @format int32 */
    organisationId: number;
    batchContextIds?: number[] | null;
}

export interface CancelBatchUserToPatientBookingRequestsResponse {
    /** @format int32 */
    cancelledCount: number;
}

export interface CancelBatchUserToPatientBookingRequests {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    contextId: number;
}
