import { WorkspaceStatus } from "@accurx/api/account";
import {
    FeatureName,
    Organisation,
    User,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";

const PHARMACY_ROLE_CODE = "RO182";
const NHS_PHARMACY_NATIONAL_CODE = "X31";

export const isPharmacy = (organisation: Organisation) =>
    organisation.roleCode === PHARMACY_ROLE_CODE;

export const isNhsPharmacy = (workspace: Organisation) =>
    workspace.nationalCode === NHS_PHARMACY_NATIONAL_CODE;

export const supportsMultipleWorkspaces = (organisation: Organisation) =>
    isFeatureEnabledForWorkspace(FeatureName.FlexibleWorkspace, organisation);

export const isMyWorkspace = (organisation: Organisation) =>
    organisation.status === WorkspaceStatus.Deprecated &&
    supportsMultipleWorkspaces(organisation);

export const findFirstPharmacy = (organisations: Organisation[]) =>
    organisations.find(
        (workspace) => isPharmacy(workspace) && !isNhsPharmacy(workspace),
    );

export const findNhsPharmacy = (organisations: Organisation[]) =>
    organisations.find((workspace) => isNhsPharmacy(workspace));

export const isActiveWorkspace = (workspace: Organisation) =>
    workspace.status === WorkspaceStatus.Active;

export const isUserCreatedWorkspace = (workspace: Organisation) =>
    isActiveWorkspace(workspace) && supportsMultipleWorkspaces(workspace);

export const isUserOnlyInDefaultWorkspaceWithinOrg = ({
    user,
    organisation,
}: {
    user: User;
    organisation: Organisation;
}): boolean => {
    if (isUserCreatedWorkspace(organisation)) return false;

    return !user.organisations.some((item) => {
        const isWithinTheSameOrg =
            item.nationalCode === organisation.nationalCode;

        return isWithinTheSameOrg && isUserCreatedWorkspace(item);
    });
};
