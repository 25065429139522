import { getEmbedMode } from "../utils/getEmbedMode";
import { useDesktopVersion } from "./useDesktopVersion";

const REQUIRED_DESKTOP_VERSION = "4.194.14939.0";

export const isNativeSignalRSupportedDesktopVersion = (
    version: Optional<string>,
): boolean => {
    if (!version) return false;

    return version >= REQUIRED_DESKTOP_VERSION;
};

export const useIsNativeSignalRConnectionAvailable = (): boolean => {
    const { embedMode } = getEmbedMode();
    const desktopVersion = useDesktopVersion();

    return (
        embedMode === "Desktop" &&
        isNativeSignalRSupportedDesktopVersion(desktopVersion)
    );
};
