import { useCallback, useEffect, useRef, useState } from "react";

import {
    ConditionOperator,
    Option,
    OptionRequest,
    QuestionOrder,
    QuestionTemplateType,
    QuestionType,
} from "@accurx/api/florey-builder";
import { FeatureName } from "@accurx/auth";
import {
    Ds,
    ErrorSummary,
    Feedback,
    FormFieldWrapper,
    Input,
    Text,
    useErrorSummary,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import debounce from "lodash/debounce";
import { DragDropContext } from "react-beautiful-dnd";
import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import {
    addBranch,
    addQuestion,
    addQuestionFromTemplate,
    createQuestionnaire,
    deleteBranch,
    deleteQuestion,
    editBranch,
    editQuestion,
    editQuestionnaire,
    getQuestionnaire,
    getSnomedCodes,
    reorderQuestions,
} from "api/FloreyBuilder/FloreyBuilderApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import { trackSelectQuestionType } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import {
    SaveableCard,
    ViewStatus,
} from "app/sharedComponents/saveableCard/SaveableCard";
import { LoadingStatus } from "shared/LoadingStatus";
import { getStringQuery } from "shared/RoutesHelper";

import { useFloreyBuilder } from "../FloreyBuilder.context";
import AddQuestionButton from "../components/AddQuestionButton";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { genericError, genericSuccess } from "../components/ErrorToasts";
import {
    HandleCancelMeasurementTypeSelectorCard,
    getQuestionTemplateType,
} from "../components/MeasurementTypeSelector.types";
import { PageContainerForStickyFooter } from "../components/PageContainer.styles";
import { PageTitle } from "../components/PageTitles";
import * as helpers from "../components/Question/Question.helpers";
import { generateCardKey } from "../components/Question/Question.helpers";
import {
    HandleCloseQuestionSelectCard,
    HandleSaveMeasurmentType,
    HandleSelectQuestionType,
    SupportedQuestionType,
} from "../components/QuestionTypeSelector.types";
import { Questions } from "../components/Questions/Questions";
import { Actions } from "../constants";
import {
    routeInvitationMessage,
    routeNameAndQuestions,
    routeSummary,
} from "../constants/paths";
import { ContextProps, State } from "../types";
import { SelectedQuestionnaire } from "../types/context.types";
import {
    CancelQuestionProps,
    CardId,
    CloseQuestionProps,
    Errors,
    PageActionText,
    QuestionEditProps,
    QuestionTemplateDataType,
    QuestionnaireFlow,
    QuestionsPageError,
    SaveQuestionProps,
    SelectedQuestionProps,
} from "../types/data.types";
import {
    checkBranchConditionValidity,
    isQuestionOrderValid,
} from "./AreBranchQuestionsValid";
import { mapQuestionnaireResponseToSelectedQuestionnaire } from "./MapResponseToSelectedQuestionnaire.utils";
import { reorder } from "./ReorderList";
import {
    isValidBranchQuestion,
    isValidDraftBranchQuestion,
    isValidNonBranchDraftQuestion,
    isValidNonBranchQuestion,
} from "./ValidQuestionTypes";
import { validateQuestion } from "./ValidateQuestion";

const MIN_QUESTION_LIMIT = 1;
const MAX_QUESTION_LIMIT = 30; // Should always be an integer of 2 or greater.
// a limit for orgs with the CreateGlobalFloreys feature flag
const MAX_QUESTION_LIMIT_INTERNAL_FLOREYS = 100;

const useQuestionnaireName = (state: State): Optional<string> => {
    return state.selectedQuestionnaire?.name;
};

export const ErrorMessage = {
    measurementQuestionSelectOption:
        "Select one measurement question to continue",
    templateQuestionGeneric:
        "There was a problem adding this question. Please refresh and try again",
} as const;

const questionnaireName = "questionnaireName";

export const FloreyBuilderNameAndQuestions = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const location = useLocation();
    const imported = getStringQuery(location.search, "imported");
    const isGlobalCustomFloreyEnabled = useFeatureFlag(
        FeatureName.CreateGlobalCustomFloreys,
    );
    const maxQuestionLimit = isGlobalCustomFloreyEnabled
        ? MAX_QUESTION_LIMIT_INTERNAL_FLOREYS
        : MAX_QUESTION_LIMIT;

    const { dispatch, state } = useFloreyBuilder() as ContextProps;
    const { isRemovingQuestion, isSavingPage, showAddQuestionButton } = state;
    const { orgId: orgIdString, questionnaireId: questionnaireIdString } =
        useParams<{ orgId: string; questionnaireId: string }>();
    const [localQuestionnaireName, setLocalQuestionnaireName] = useState("");
    const history = useHistory();
    const orgId = parseInt(orgIdString);
    const [questionnaireId, setQuestionnaireId] = useState<number | undefined>(
        questionnaireIdString ? parseInt(questionnaireIdString) : undefined,
    );
    const [questionnaireFlow, setQuestionnaireFlow] = useState(
        QuestionnaireFlow.Edit,
    );

    const { addError, clearAllErrors } = useErrorSummary();
    const [validationErrorsByKey, setValidationErrorsByKey] = useState<Errors>(
        {},
    );
    const [hasError, setHasError] = useState(false);
    const [cardsCanBeReordered, setCardsCanBeReordered] = useState(true); // feature flag assessed separately

    const questionnaireData = state.selectedQuestionnaire;
    // initialQuestionnaireData is used when cancelling in-progress questions, to have the correct initial state to revert back to
    // otherwise the in-progress updates in state would be still present upon cancel if questionnaireData above was to be used
    const [initialQuestionnaireData, setInitialQuestionnaireData] =
        useState<SelectedQuestionnaire>();

    const questions = state.selectedQuestionnaire?.questions || [];

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
        LoadingStatus.Initial,
    );

    const [addQuestionLimitError, setAddQuestionLimitError] =
        useState<boolean>(false);

    const [questionnaireErrors, setQuestionnaireErrors] = useState<
        QuestionsPageError[]
    >([]);
    const cardActionErrorRef = useRef<HTMLDivElement | null>(null);

    const [isRemoveQuestionModalOpen, setIsRemoveQuestionModalOpen] =
        useState(false);
    const [selectedQuestion, setSelectedQuestion] =
        useState<SelectedQuestionProps>({
            branchId: undefined,
            branchPosition: undefined,
            cardKey: "",
            questionId: undefined,
            questionLevel: "Questionnaire",
            questionRowVersion: undefined,
            questionType: QuestionType.None,
        });
    const handleCloseQuestionModal = () => {
        setIsRemoveQuestionModalOpen(false);
    };
    const handleCancelQuestionModal = () => {
        setIsRemoveQuestionModalOpen(false);
    };
    const loadQuestionnaire = useCallback(async () => {
        if (questionnaireId !== undefined) {
            setLoadingStatus(LoadingStatus.Loading);
            dispatch({
                type: Actions.UPDATE_QUESTIONNAIRE_NAME_VIEW_STATUS,
                payload: { viewStatus: ViewStatus.Preview },
            });
            const response = await getQuestionnaire(
                orgIdString,
                questionnaireId.toString(),
            );
            const codes = await getSnomedCodes(orgIdString);
            if (codes.success && codes.result) {
                dispatch({ type: Actions.SET_CODES, payload: codes.result });
            }
            if (response.success && response.result) {
                const questionnaire =
                    mapQuestionnaireResponseToSelectedQuestionnaire(
                        response.result,
                    );
                setInitialQuestionnaireData(questionnaire);
                setLocalQuestionnaireName(response.result.name || "");
                dispatch({
                    type: Actions.SET_QUESTIONNAIRE,
                    payload: questionnaire,
                });
            }
            if (
                codes.success &&
                codes.result &&
                response.success &&
                response.result
            ) {
                setLoadingStatus(LoadingStatus.Loaded);
            } else {
                setLoadingStatus(LoadingStatus.Failed);
            }
            if (imported) {
                setQuestionnaireFlow(QuestionnaireFlow.Import);
            }
        } else {
            // when no questionnaireId is provided, should load Initial view for Questions
            setQuestionnaireFlow(QuestionnaireFlow.Create);
            setLoadingStatus(LoadingStatus.Loaded);
        }
    }, [orgIdString, questionnaireId, dispatch, imported]);

    // On load
    useEffect(() => {
        loadQuestionnaire();
    }, [loadQuestionnaire]);

    useEffect(() => {
        if (
            cardActionErrorRef &&
            cardActionErrorRef.current &&
            cardActionErrorRef.current.scrollIntoView
        ) {
            cardActionErrorRef.current.scrollIntoView();
        }
    }, [questionnaireErrors]);

    useEffect(() => {
        FlemingAnalyticsTracker.trackQuestionnaireQuestionCreate({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
        });
    }, [analyticsLoggedInProps, questionnaireId]);

    enum CardLevelErrors {
        NameIsOpen = "Save the questionnaire name",
        QuestionIsOpen = "Save your question",
        QuestionTypeSelectorIsOpen = "Add a question or cancel",
        TemplateSelectorIsOpen = "Finish adding the question or cancel",
    }
    const areCardsAllSaved = (): boolean => {
        const cardsInEditMode = [];
        if (
            state.nameViewStatus === ViewStatus.Edit ||
            state.nameViewStatus === ViewStatus.Initial
        ) {
            cardsInEditMode.push(helpers.generateCardKey(questionnaireName));
        }

        state.selectedQuestionnaire?.questions
            ?.filter((question) => question.viewStatus === ViewStatus.Edit)
            .map((question) => helpers.generateCardKey(question.clientId))
            .map((cardKey) => cardsInEditMode.push(cardKey));

        if (cardsInEditMode.length > 0) {
            const newErrors = cardsInEditMode.map(
                (cardKey) =>
                    ({
                        id: cardKey,
                        message:
                            cardKey ===
                            helpers.generateCardKey(questionnaireName)
                                ? CardLevelErrors.NameIsOpen
                                : CardLevelErrors.QuestionIsOpen,
                    } as QuestionsPageError),
            );
            setQuestionnaireErrors([...questionnaireErrors, ...newErrors]);
            return false;
        }
        return true;
    };

    const isValidToEdit = (cardKey: string): boolean => {
        const cardsInEditMode = [];
        const questionnaireNameCardKey =
            helpers.generateCardKey(questionnaireName);
        if (
            state.nameViewStatus === ViewStatus.Edit ||
            state.nameViewStatus === ViewStatus.Initial
        ) {
            cardsInEditMode.push(questionnaireNameCardKey);
        }

        if (state.cardsByKey[CardId.questionTypeSelector]) {
            cardsInEditMode.push(CardId.questionTypeSelector);
        }
        if (state.cardsByKey[CardId.measurementTypeSelector]) {
            cardsInEditMode.push(CardId.measurementTypeSelector);
        }

        state.selectedQuestionnaire?.questions
            ?.filter(
                (enrichedQuestion) =>
                    enrichedQuestion.viewStatus === ViewStatus.Edit,
            )
            .map((enrichedQuestion) =>
                helpers.generateCardKey(enrichedQuestion.clientId),
            )
            .map((cardKey) => cardsInEditMode.push(cardKey));

        const otherOpenCards = cardsInEditMode.filter(
            (card) => card !== cardKey,
        );

        if (otherOpenCards.length > 0) {
            const newErrors = otherOpenCards.map((cardKey) => {
                let message;
                switch (cardKey) {
                    case CardId.measurementTypeSelector:
                        message = CardLevelErrors.TemplateSelectorIsOpen;
                        break;
                    case questionnaireNameCardKey:
                        message = CardLevelErrors.NameIsOpen;
                        break;
                    case CardId.questionTypeSelector:
                        message = CardLevelErrors.QuestionTypeSelectorIsOpen;
                        break;
                    default:
                        message = CardLevelErrors.QuestionIsOpen;
                }

                return {
                    id: cardKey,
                    message: message,
                } as QuestionsPageError;
            });
            const relevantRemainingErrors = questionnaireErrors.filter(
                (error: QuestionsPageError) => error.id !== cardKey,
            );
            setQuestionnaireErrors([...relevantRemainingErrors, ...newErrors]);
            return false;
        }
        return true;
    };

    const closeQuestionCard = ({
        branchPosition,
        questionLevel,
        clientId,
    }: CloseQuestionProps): void => {
        dispatch({
            type: Actions.UPDATE_QUESTION_VIEW_STATUS,
            payload: {
                clientId,
                viewStatus: ViewStatus.Preview,
                questionLevel,
                branchPosition,
            },
        });
        removeCardErrors(helpers.generateCardKey(clientId));
    };

    const closeNameCard = (): void => {
        handleNameViewStatusChanges(ViewStatus.Preview);
        removeCardErrors(helpers.generateCardKey(questionnaireName));
    };

    const isValidQuestionnaireName = (): boolean => {
        if (
            localQuestionnaireName === undefined ||
            localQuestionnaireName.length === 0
        ) {
            const relevantRemainingErrors = questionnaireErrors.filter(
                (error: QuestionsPageError) => error.id !== questionnaireName,
            );
            setQuestionnaireErrors([
                ...relevantRemainingErrors,
                {
                    id: questionnaireName,
                    message: "Add a name for your questionnaire",
                },
            ]);
            return false;
        }
        if (localQuestionnaireName.trim().length === 0) {
            const relevantRemainingErrors = questionnaireErrors.filter(
                (error: QuestionsPageError) => error.id !== questionnaireName,
            );
            setQuestionnaireErrors([
                ...relevantRemainingErrors,
                {
                    id: questionnaireName,
                    message: "Add non-whitespace name for your questionnaire",
                },
            ]);
            return false;
        }
        if (localQuestionnaireName.length > 250) {
            const relevantRemainingErrors = questionnaireErrors.filter(
                (error: QuestionsPageError) => error.id !== questionnaireName,
            );
            setQuestionnaireErrors([
                ...relevantRemainingErrors,
                {
                    id: questionnaireName,
                    message:
                        "Your questionnaire name is too long. It should be 250 characters or fewer",
                },
            ]);
            return false;
        }
        setQuestionnaireErrors(
            questionnaireErrors.filter(
                (item: QuestionsPageError) => item.id !== questionnaireName,
            ),
        );
        return true;
    };

    function addQuestionnaireNameError(error: string | null) {
        const relevantRemainingErrors = questionnaireErrors.filter(
            (error: QuestionsPageError) => error.id !== questionnaireName,
        );
        setQuestionnaireErrors([
            ...relevantRemainingErrors,
            {
                id: questionnaireName,
                message:
                    error ??
                    "There was a problem saving this questionnaire name",
            },
        ]);
    }

    const handleSaveName = async (): Promise<boolean> => {
        FlemingAnalyticsTracker.trackQuestionnaireNameSave({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionnaireNameLength: localQuestionnaireName.length,
        });
        const setIsSavingValue = (isSaving: boolean) => {
            dispatch({
                type: Actions.UPDATE_CARD_IS_SAVING,
                payload: {
                    cardKey: "questionnaireName",
                    isSaving,
                    questionLevel: "Questionnaire",
                },
            });
        };
        const valid = isValidQuestionnaireName();
        if (!valid) {
            return false;
        }
        if (questionnaireId === undefined) {
            // Create flow
            setIsSavingValue(true);
            const createdIdResult = await createQuestionnaire(orgIdString, {
                name: localQuestionnaireName,
                organisationId: orgId,
            });
            setIsSavingValue(false);
            if (createdIdResult.success && createdIdResult.result?.id) {
                setQuestionnaireId(createdIdResult.result.id);
                loadQuestionnaire();
            } else {
                addQuestionnaireNameError(createdIdResult.error);
                return false;
            }
            if (createdIdResult.result?.id) {
                setQuestionnaireId(createdIdResult.result.id);
                // load the page with the questionnaire number
                // this is so that if a user refreshes after setting the questionnaireName,
                // they'll get the edit/preview view of the questionnaire, rather than the create view
                history.push(
                    routeNameAndQuestions(
                        orgIdString,
                        `${createdIdResult.result?.id}`,
                    ),
                );
                return false; // return false so we don't continue with this function & don't require the SaveableCard to switch modes
            }

            loadQuestionnaire();
        } else {
            // Error if questionnaireId is not defined.
            if (!questionnaireData?.questionnaireRowVersion) {
                addQuestionnaireNameError(
                    "There was a problem saving this questionnaire. Please refresh and try again",
                );
            } else {
                // Edit flow
                setIsSavingValue(true);
                const result = await editQuestionnaire(orgIdString, {
                    questionnaireId: questionnaireId,
                    name: localQuestionnaireName,
                    questionnaireRowVersion:
                        questionnaireData.questionnaireRowVersion,
                    organisationId: orgId,
                });
                setIsSavingValue(false);
                if (!result.success) {
                    addQuestionnaireNameError(result.error);
                    return false;
                }
            }
        }
        closeNameCard();
        return true;
    };

    const handleCancelNameChange = () => {
        FlemingAnalyticsTracker.trackFloreyBuilderQuestionnaireNameCancel({
            ...analyticsLoggedInProps,
            questionnaireNameLength: localQuestionnaireName.length,
        });
        if (
            initialQuestionnaireData?.name !== undefined &&
            initialQuestionnaireData?.name !== null
        ) {
            setLocalQuestionnaireName(initialQuestionnaireData?.name);
        }
        closeNameCard();
    };

    const QuestionnaireNamePreviewDisplay = (): JSX.Element => {
        const initialQuestionnaireName = useQuestionnaireName(state);
        return (
            <Text variant={"label"}>
                {localQuestionnaireName || initialQuestionnaireName}
            </Text>
        );
    };

    // debouncing to store to Context state
    const debouncedQuestionnaireNameUpdate = useRef(
        debounce(
            (
                questionnaireName: string,
                dispatch: (arg0: {
                    type: Actions;
                    payload: { questionnaireName: unknown };
                }) => void,
            ) => {
                dispatch({
                    type: Actions.UPDATE_QUESTIONNAIRE_NAME,
                    payload: { questionnaireName },
                });
            },
            50,
        ),
    );

    const handleOnChangeName = (
        event: React.ChangeEvent<HTMLInputElement>,
        fieldSetter: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        fieldSetter(event.target.value);
        debouncedQuestionnaireNameUpdate.current(event.target.value, dispatch);
    };
    const QuestionnaireNameEditDisplay = (): JSX.Element => {
        return (
            <>
                <FormFieldWrapper
                    errors={questionnaireErrors
                        .filter(
                            (x: QuestionsPageError) =>
                                x.id === questionnaireName,
                        )
                        .map((x: QuestionsPageError) => x.message)}
                    label="Questionnaire name"
                    labelProps={{ htmlFor: "fb-input-questionnaire-name" }}
                >
                    <Text>
                        Keep it descriptive and unique. It will be visible to
                        everyone in your organisation and patients will see the
                        names of questionnaires they are sent.
                    </Text>
                    <Input
                        id="fb-input-questionnaire-name"
                        dimension="large"
                        value={localQuestionnaireName}
                        onChange={(e) =>
                            handleOnChangeName(e, setLocalQuestionnaireName)
                        }
                    />
                </FormFieldWrapper>
            </>
        );
    };

    /* Questions */

    const handleSaveQuestion = async ({
        question,
        questionIndex, //question index is used for card title, eg Q1
        branchId,
        questionLevel,
        branchPosition,
    }: SaveQuestionProps): Promise<boolean> => {
        clearAllErrors();
        setValidationErrorsByKey({});
        setHasError(false);
        setCardsCanBeReordered(true);
        FlemingAnalyticsTracker.trackQuestionnaireQuestionSave({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionId: question.id ?? null,
            questionType: question.questionType?.toString() ?? null,
            questionNumber: questionIndex + 1,
            optionCount: question.options?.length ?? null,
        });
        const setIsSavingValue = (isSaving: boolean) => {
            dispatch({
                type: Actions.UPDATE_CARD_IS_SAVING,
                payload: {
                    cardKey: generateCardKey(question.clientId),
                    isSaving,
                    questionLevel,
                    branchPosition,
                },
            });
        };

        const { isValid, errors } = validateQuestion(question);
        if (!isValid) {
            setHasError(true);
            setValidationErrorsByKey(errors);
            // Loop through all the errors and add to the errors
            // shown in the Error Summary component
            Object.entries(errors).forEach(([errorKey, errorValue]) => {
                switch (errorValue) {
                    // This is to ignore the key/value pair of questionType: QuestionType
                    case QuestionType.Branching:
                    case QuestionType.Information:
                    case QuestionType.MultipleChoice:
                    case QuestionType.Text:
                        return;
                    default:
                        if (Array.isArray(errorValue)) {
                            if (errorValue && errorValue.length > 0) {
                                errorValue.forEach(
                                    ({ errorId, errorMessage }) => {
                                        if (errorId) {
                                            addError({
                                                id: errorId,
                                                body: errorMessage,
                                            });
                                        }
                                    },
                                );
                            }
                        } else {
                            if (errorValue.errorId && errorValue.errorMessage) {
                                addError({
                                    id: errorValue.errorId,
                                    body: errorValue.errorMessage,
                                });
                            }
                        }
                }
            });
            return false;
        }

        if (questions.length <= maxQuestionLimit /*validation*/) {
            // Ensure the options from local state match the OptionRequest data type that is required for the API calls.
            const formatRequestQuestionOptions = (
                options: Optional<Option[]>,
            ): OptionRequest[] | undefined =>
                options?.map(({ clientId, text, answerCode }) => ({
                    clientId: clientId || "",
                    text: text || "",
                    answerCode: answerCode?.conceptId ?? "",
                }));
            if (!questionnaireId) {
                genericError(
                    "There was an issue with saving this question",
                    "No questionnaire id.",
                );
                return false;
            }
            if (!question.questionType) {
                genericError(
                    "There was an issue with saving this question",
                    "No question type",
                );
                return false;
            }
            setIsSavingValue(true);
            let response;
            let failureDisplayMessage;
            let successDisplayMessage;
            if (isValidNonBranchQuestion(question)) {
                response = await editQuestion(orgIdString, questionnaireId, {
                    organisationId: orgId,
                    questionnaireId: questionnaireId,
                    questionId: question.id,
                    questionType: question.questionType,
                    questionTitle: question.questionTitle,
                    helperText: question.helperText,
                    imageDocumentUrl: question.imageDocumentUrl,
                    imageAltText: question.imageAltText,
                    recordText: question.recordText,
                    options: formatRequestQuestionOptions(question.options),
                    questionRowVersion: question.questionRowVersion,
                    allowMultipleAnswers: question.allowMultipleAnswers,
                    branchId, // defined if question has a parent branch
                });
                failureDisplayMessage =
                    "Saving this question has been unsuccessful";
                successDisplayMessage =
                    "Editing this question has been successful";
            } else if (isValidNonBranchDraftQuestion(question)) {
                response = await addQuestion(orgIdString, {
                    organisationId: orgId,
                    questionnaireId: questionnaireId,
                    questionType: question.questionType,
                    questionTitle: question.questionTitle,
                    helperText: question.helperText,
                    imageDocumentUrl: question.imageDocumentUrl,
                    imageAltText: question.imageAltText,
                    recordText: question.recordText,
                    options: formatRequestQuestionOptions(question.options),
                    allowMultipleAnswers: question.allowMultipleAnswers,
                    branchId, // defined if question has a parent branch
                });
                successDisplayMessage =
                    "Creating this question has been successful";
                failureDisplayMessage =
                    "Saving this new question has been unsuccessful";
            } else if (isValidBranchQuestion(question)) {
                response = await editBranch(orgId, {
                    organisationId: orgId,
                    branchId: question.id,
                    questionnaireId,
                    conditionOperator:
                        question.branchData.condition.conditionOperator ??
                        ConditionOperator.IsEqual,
                    conditionQuestionId:
                        question.branchData.condition.conditionQuestion
                            .questionId ?? 0,
                    conditionValue:
                        question.branchData.condition.conditionQuestion
                            .answerText ?? "",
                });
                successDisplayMessage =
                    "Editing this branch has been successful";
                failureDisplayMessage =
                    "Saving this branch has been unsuccessful";
            } else if (isValidDraftBranchQuestion(question)) {
                response = await addBranch(orgId, {
                    organisationId: orgId,
                    questionnaireId,
                    conditionOperator:
                        question.branchData.condition.conditionOperator ??
                        ConditionOperator.IsEqual,
                    conditionQuestionId:
                        question.branchData.condition.conditionQuestion
                            .questionId ?? 0,
                    conditionValue:
                        question.branchData.condition.conditionQuestion
                            .answerText ?? "",
                });
                successDisplayMessage =
                    "Creating this branch has been successful";
                failureDisplayMessage =
                    "Saving this new branch has been unsuccessful";
            }
            setIsSavingValue(false);

            if (!response) {
                genericError(
                    "There was an issue with saving this question",
                    "Please try again.",
                );
                return false;
            }

            if (response.success && response.result) {
                const questionnaire =
                    mapQuestionnaireResponseToSelectedQuestionnaire(
                        response.result,
                    );
                setInitialQuestionnaireData(questionnaire);
                dispatch({
                    type: Actions.SET_QUESTIONNAIRE,
                    payload: questionnaire,
                });
                genericSuccess(
                    successDisplayMessage ??
                        "Saving this question has been successful",
                );

                closeQuestionCard({
                    branchPosition,
                    questionLevel,
                    clientId: question.clientId,
                });
                return true;
            }
            if (response.error) {
                genericError(
                    failureDisplayMessage ??
                        "Saving this question has been unsuccessful",
                    `${response.error} Please try again.`,
                );
                return false;
            }
        }
        genericError(
            "There was an issue with saving this question",
            "Please try again.",
        );
        return false;
    };

    const handleCancelQuestion = ({
        branchPosition,
        questionLevel,
        analyticsQuestionType,
        clientId,
    }: CancelQuestionProps): void => {
        clearAllErrors();
        setValidationErrorsByKey({});
        setCardsCanBeReordered(true);
        // Set questionnaire to initialQuestionnaireData, which only has server responses, not clientside changes from context state
        FlemingAnalyticsTracker.trackQuestionnaireQuestionCancel({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType: analyticsQuestionType ?? null,
        });
        dispatch({
            type: Actions.SET_QUESTIONNAIRE,
            payload: initialQuestionnaireData,
        });

        closeQuestionCard({ branchPosition, questionLevel, clientId });
    };

    const handleQuestionEditChanges = ({
        cardKey,
        questionLevel,
        branchPosition,
    }: QuestionEditProps): boolean => {
        if (isValidToEdit(cardKey)) {
            setQuestionnaireErrors([]);
            const questionClientId =
                helpers.generateClientIdFromCardKey(cardKey);
            dispatch({
                type: Actions.UPDATE_QUESTION_VIEW_STATUS,
                payload: {
                    clientId: questionClientId,
                    viewStatus: ViewStatus.Edit,
                    questionLevel,
                    branchPosition,
                },
            });
            return true;
        }
        return false;
    };

    const handleEditName = (): boolean => {
        FlemingAnalyticsTracker.trackQuestionnaireNameEdit({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionnaireNameLength: localQuestionnaireName.length,
        });
        if (isValidToEdit(helpers.generateCardKey(questionnaireName))) {
            handleNameViewStatusChanges(ViewStatus.Edit);
            return true;
        }
        return false;
    };

    const handleNameViewStatusChanges = (status: ViewStatus | null) => {
        dispatch({
            type: Actions.UPDATE_QUESTIONNAIRE_NAME_VIEW_STATUS,
            payload: { viewStatus: status },
        });
    };

    const handleAddQuestion = () => {
        FlemingAnalyticsTracker.trackQuestionnaireQuestionAdd({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
        });
        if (questions.length < maxQuestionLimit && areCardsAllSaved()) {
            dispatch({
                type: Actions.ADD_QUESTION_SELECTOR,
                payload: {},
            });
        }
        if (questions.length >= maxQuestionLimit) {
            setAddQuestionLimitError(true);
        }
    };

    const handleSelectQuestionType = ({
        questionType,
        questionLevel,
        branchPosition,
    }: HandleSelectQuestionType): void => {
        trackSelectQuestionType({
            ...analyticsLoggedInProps,
            questionType: questionType,
            questionnaireId: state.selectedQuestionnaire?.id ?? null,
        });
        removeCardErrors(CardId.questionTypeSelector);
        switch (questionType) {
            case SupportedQuestionType.MultipleChoice:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_QUESTION,
                    payload: {
                        questionLevel,
                        branchPosition,
                        questionType: QuestionType.MultipleChoice,
                    },
                });
                return;
            case SupportedQuestionType.Measurement:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_MEASUREMENT_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                return;
            case SupportedQuestionType.FreeText:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_QUESTION,
                    payload: {
                        questionLevel,
                        branchPosition,
                        questionType: QuestionType.Text,
                    },
                });
                return;
            case SupportedQuestionType.Information:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_QUESTION,
                    payload: {
                        questionLevel,
                        branchPosition,
                        questionType: QuestionType.Information,
                    },
                });
                return;
            case SupportedQuestionType.Branch:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_QUESTION,
                    payload: {
                        questionLevel,
                        branchPosition,
                        questionType: QuestionType.Branching,
                    },
                });
                return;
            case SupportedQuestionType.PhotoUpload:
                dispatch({
                    type: Actions.REMOVE_QUESTION_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                dispatch({
                    type: Actions.ADD_PHOTO_UPLOAD_SELECTOR,
                    payload: {
                        questionLevel,
                        branchPosition,
                    },
                });
                return;
            default:
                // We don't expect to get to this condition.
                Log.error(
                    `[FloreyBuilder] Select question type has unsupported question type ${questionType}`,
                );
                return;
        }
    };

    const handleCloseQuestionSelectCard = ({
        questionLevel,
        branchPosition,
    }: HandleCloseQuestionSelectCard) => {
        removeCardErrors(CardId.questionTypeSelector);
        dispatch({
            type: Actions.REMOVE_QUESTION_SELECTOR,
            payload: {
                questionLevel,
                branchPosition,
            },
        });
    };

    function removeCardErrors(cardKey: string) {
        const relevantRemainingErrors = questionnaireErrors.filter(
            (error: QuestionsPageError) => error.id !== cardKey,
        );
        setQuestionnaireErrors(relevantRemainingErrors);
    }

    const handleRemoveQuestion = async ({
        questionId,
        questionRowVersion,
        cardKey,
        questionType,
        questionLevel,
        branchPosition,
        branchId,
    }: SelectedQuestionProps): Promise<void> => {
        clearAllErrors();
        setValidationErrorsByKey({});
        setCardsCanBeReordered(true);
        FlemingAnalyticsTracker.trackQuestionnaireQuestionRemove({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType: helpers.mapQuestionTypeToString(questionType) ?? null,
        });
        if (
            orgId === undefined ||
            questionnaireId === undefined ||
            cardKey === undefined
        ) {
            Log.error(
                `[FloreyBuilder] handleRemoveQuestion insufficient data: orgId ${orgId}, questionnaireId ${questionnaireId}`,
            );
            return;
        }
        if (!questionId || !questionRowVersion) {
            // This case is when there is a newly created question that's not yet saved to the server
            // so there's no questionId or questionRowVersion
            dispatch({
                type: Actions.REMOVE_LAST_QUESTION,
                payload: {
                    questionLevel,
                    branchPosition,
                },
            });
            setIsRemoveQuestionModalOpen(false);
            removeCardErrors(cardKey);
            return;
        }
        dispatch({
            type: Actions.UPDATE_QUESTION_IS_REMOVING,
            payload: {
                branchPosition,
                isRemovingQuestion: true,
                questionLevel,
            },
        });

        let response;

        switch (questionType) {
            case QuestionType.Branching:
                response = await deleteBranch(orgId, {
                    organisationId: orgId,
                    questionnaireId,
                    branchId: questionId,
                });
                break;
            default:
                response = await deleteQuestion(orgIdString, {
                    organisationId: orgId,
                    questionnaireId,
                    questionId,
                    questionRowVersion,
                    branchId,
                });
        }

        if (response.success && response.result) {
            // Set locally first, in order to immediately show the page with one fewer question
            const questionnaire =
                mapQuestionnaireResponseToSelectedQuestionnaire(
                    response.result,
                );
            setInitialQuestionnaireData(questionnaire);
            dispatch({
                type: Actions.SET_QUESTIONNAIRE,
                payload: questionnaire,
            });
            setIsRemoveQuestionModalOpen(false);
            dispatch({
                type: Actions.UPDATE_QUESTION_IS_REMOVING,
                payload: {
                    branchPosition,
                    isRemovingQuestion: false,
                    questionLevel,
                },
            });
            genericSuccess("Remove question has been successful");
            removeCardErrors(cardKey);
        } else {
            setIsRemoveQuestionModalOpen(false);
            dispatch({
                type: Actions.UPDATE_QUESTION_IS_REMOVING,
                payload: {
                    branchPosition,
                    isRemovingQuestion: false,
                    questionLevel,
                },
            });
            genericError(
                "Removing this question has been unsuccessful",
                "Please try again.",
            );
        }
    };

    const handleRemoveQuestionModal = (props: SelectedQuestionProps) => {
        if (!isValidToEdit(props.cardKey)) {
            return;
        }
        setIsRemoveQuestionModalOpen(true);
        setSelectedQuestion(props);
    };

    const handleCancelMeasurementTypeSelectorCard = ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard): void => {
        FlemingAnalyticsTracker.trackQuestionnaireQuestionCancel({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType:
                helpers.mapQuestionTypeToString(QuestionType.Numbered) ?? null,
        });
        clearAllErrors();
        setValidationErrorsByKey({});
        removeCardErrors(CardId.measurementTypeSelector);
        setCardsCanBeReordered(true);
        dispatch({
            type: Actions.REMOVE_MEASUREMENT_SELECTOR,
            payload: {
                questionLevel,
            },
        });
        dispatch({
            type: Actions.ADD_QUESTION_SELECTOR,
            payload: {
                questionLevel,
            },
        });
    };

    const handleSaveMeasurementTypeSelectorCard = async ({
        branchId,
        branchPosition,
        event,
        questionLevel,
    }: HandleSaveMeasurmentType): Promise<boolean> => {
        //this mapping is to give readable values in the analytics
        const mapTemplateType = (
            questionTemplateType: QuestionTemplateType | string,
        ) => {
            switch (questionTemplateType) {
                case QuestionTemplateType.Weight:
                    return QuestionTemplateDataType.Weight;
                case QuestionTemplateType.Height:
                    return QuestionTemplateDataType.Height;
                default:
                    return QuestionTemplateDataType.None;
            }
        };

        event.preventDefault();
        removeCardErrors(CardId.measurementTypeSelector);

        const setIsSavingValue = (isSaving: boolean) => {
            dispatch({
                type: Actions.UPDATE_CARD_IS_SAVING,
                payload: {
                    cardKey: CardId.measurementTypeSelector,
                    isSaving,
                    questionLevel,
                    branchPosition,
                },
            });
        };

        const measurementValue =
            event.currentTarget.elements.measurementRadioGroup.value;
        const addingQuestionTemplateType =
            measurementValue && getQuestionTemplateType(measurementValue);

        FlemingAnalyticsTracker.trackSelectQuestionTemplate({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            templateType: mapTemplateType(addingQuestionTemplateType),
        });
        setIsSavingValue(true);

        const isValidTemplateType =
            addingQuestionTemplateType === QuestionTemplateType.Weight ||
            addingQuestionTemplateType === QuestionTemplateType.Height;
        if (!isValidTemplateType) {
            setQuestionnaireErrors([
                {
                    id: CardId.measurementTypeSelector,
                    message: ErrorMessage.measurementQuestionSelectOption,
                },
            ]);
            setIsSavingValue(false);
            return false;
        }

        if (!questionnaireId) {
            setQuestionnaireErrors([
                {
                    id: CardId.measurementTypeSelector,
                    message: ErrorMessage.templateQuestionGeneric,
                },
            ]);
            setIsSavingValue(false);
            return false;
        }

        const response = await addQuestionFromTemplate(orgIdString, {
            organisationId: orgId,
            questionnaireId: questionnaireId,
            questionTemplateType: addingQuestionTemplateType,
            branchId,
        });
        setIsSavingValue(false);
        if (response.success && response.result) {
            const questionnaire =
                mapQuestionnaireResponseToSelectedQuestionnaire(
                    response.result,
                );
            setInitialQuestionnaireData(questionnaire);
            dispatch({
                type: Actions.SET_QUESTIONNAIRE,
                payload: questionnaire,
            });
            genericSuccess("Creating this question has been successful");
            return true;
        }
        if (!response.success) {
            setQuestionnaireErrors([
                {
                    id: CardId.measurementTypeSelector,
                    message:
                        response.error ??
                        "We could not add this measurement question type. Please refresh and try again.",
                },
            ]);
            return false;
        }
        removeCardErrors(CardId.measurementTypeSelector);
        return true;
    };

    const handleCancelPhotoUploadSelectorCard = ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard): void => {
        FlemingAnalyticsTracker.trackQuestionnaireQuestionCancel({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType:
                helpers.mapQuestionTypeToString(QuestionType.ImageAttachment) ??
                null,
        });
        clearAllErrors();
        setValidationErrorsByKey({});
        removeCardErrors(CardId.photoUploadSelector);
        setCardsCanBeReordered(true);
        dispatch({
            type: Actions.REMOVE_PHOTO_UPLOAD_SELECTOR,
            payload: {
                questionLevel,
            },
        });
        dispatch({
            type: Actions.ADD_QUESTION_SELECTOR,
            payload: {
                questionLevel,
            },
        });
    };

    const handleSavePhotoUploadSelectorCard = async ({
        branchId,
        branchPosition,
        questionLevel,
    }: Omit<HandleSaveMeasurmentType, "event">): Promise<boolean> => {
        removeCardErrors(CardId.photoUploadSelector);

        const setIsSavingValue = (isSaving: boolean) => {
            dispatch({
                type: Actions.UPDATE_CARD_IS_SAVING,
                payload: {
                    cardKey: CardId.photoUploadSelector,
                    isSaving,
                    questionLevel,
                    branchPosition,
                },
            });
        };

        FlemingAnalyticsTracker.trackSelectQuestionTemplate({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            templateType: QuestionTemplateDataType.ImageAttachment,
        });
        setIsSavingValue(true);

        if (!questionnaireId) {
            setQuestionnaireErrors([
                {
                    id: CardId.photoUploadSelector,
                    message: ErrorMessage.templateQuestionGeneric,
                },
            ]);
            setIsSavingValue(false);
            return false;
        }

        const response = await addQuestionFromTemplate(orgIdString, {
            organisationId: orgId,
            questionnaireId: questionnaireId,
            questionTemplateType: QuestionTemplateType.ImageAttachment,
            branchId,
        });
        setIsSavingValue(false);
        if (response.success && response.result) {
            const questionnaire =
                mapQuestionnaireResponseToSelectedQuestionnaire(
                    response.result,
                );
            setInitialQuestionnaireData(questionnaire);
            dispatch({
                type: Actions.SET_QUESTIONNAIRE,
                payload: questionnaire,
            });
            genericSuccess("Creating this question has been successful");
            return true;
        }
        if (!response.success) {
            setQuestionnaireErrors([
                {
                    id: CardId.photoUploadSelector,
                    message:
                        response.error ??
                        "We could not add this photo upload question. Please refresh and try again.",
                },
            ]);
            return false;
        }
        removeCardErrors(CardId.photoUploadSelector);
        return true;
    };

    const questionnaireNameCard = state.cardsByKey["questionnaireName"];
    const PageContents = (): JSX.Element => {
        switch (loadingStatus) {
            case LoadingStatus.Failed:
                return (
                    <>
                        <Feedback
                            colour="error"
                            title="There was an issue with loading the questionnaire. Please try again."
                        ></Feedback>
                    </>
                );
            case LoadingStatus.Loaded:
                return (
                    <>
                        {questionnaireFlow === QuestionnaireFlow.Import && (
                            <Feedback
                                colour="success"
                                title="Florey Questionnaire Imported"
                                children="Review the Florey questionnaire before saving it. You will be able to see the saved questionnaire on your Custom Floreys page"
                            />
                        )}
                        <SaveableCard
                            cardTitle="Add a questionnaire name"
                            editChildren={<>{QuestionnaireNameEditDisplay()}</>}
                            initialChildren={
                                <>{QuestionnaireNameEditDisplay()}</>
                            }
                            previewChildren={
                                <>{QuestionnaireNamePreviewDisplay()}</>
                            }
                            handleCancelChanges={handleCancelNameChange}
                            handleSaveChanges={handleSaveName}
                            handleEditChanges={handleEditName}
                            viewStatus={state.nameViewStatus}
                            cardActionErrorRef={cardActionErrorRef}
                            cardActionErrors={questionnaireErrors
                                .filter(
                                    (error: QuestionsPageError) =>
                                        error.id ===
                                        helpers.generateCardKey(
                                            questionnaireName,
                                        ),
                                )
                                .map(
                                    (error: QuestionsPageError) =>
                                        error.message,
                                )}
                            isSaving={questionnaireNameCard.isSaving}
                            cardKey={"questionnaireName"}
                        />
                        <DragDropContext
                            onDragEnd={async (result) => {
                                if (!cardsCanBeReordered) {
                                    return;
                                }
                                if (
                                    !areCardsAllSaved() ||
                                    questionnaireErrors.length !== 0
                                ) {
                                    setCardsCanBeReordered(false);
                                    return;
                                }
                                if (!result.destination) {
                                    return;
                                }
                                const newQuestionOrder = reorder(
                                    questions,
                                    result.source.index,
                                    result.destination.index,
                                );

                                if (!isQuestionOrderValid(newQuestionOrder)) {
                                    toast(
                                        Feedback({
                                            colour: "error",
                                            title: "Could not reorder",
                                            children:
                                                "Branch must be after the relevant condition question",
                                        }),
                                    );
                                    return;
                                }

                                dispatch({
                                    //we're doing this before sending the API call to avoid the order flicking backwards and forwards
                                    type: Actions.SET_QUESTIONS,
                                    payload: newQuestionOrder,
                                });

                                const response = await reorderQuestions(
                                    orgId,
                                    questionnaireId?.toString() ?? "",
                                    {
                                        organisationId: orgId,
                                        questionnaireId: questionnaireId ?? 0,
                                        questions: newQuestionOrder.map(
                                            (question, index) => {
                                                return {
                                                    id: question.id,
                                                    order: index,
                                                } as QuestionOrder;
                                            },
                                        ),
                                    },
                                );

                                if (!response.success) {
                                    toast(
                                        Feedback({
                                            colour: "error",
                                            title: "Could not reorder",
                                            children:
                                                "Please refresh and try again",
                                        }),
                                    );
                                    dispatch({
                                        type: Actions.SET_QUESTIONS,
                                        payload: questions,
                                    });
                                    return;
                                }
                            }}
                        >
                            <Questions
                                activeCardErrorRef={cardActionErrorRef}
                                cardsByKey={state.cardsByKey}
                                handleCancelMeasurementTypeSelectorCard={
                                    handleCancelMeasurementTypeSelectorCard
                                }
                                handleCancelPhotoUploadSelectorCard={
                                    handleCancelPhotoUploadSelectorCard
                                }
                                handleCancelQuestion={handleCancelQuestion}
                                handleCloseQuestionSelectCard={
                                    handleCloseQuestionSelectCard
                                }
                                handleQuestionEditChanges={
                                    handleQuestionEditChanges
                                }
                                handleRemoveQuestionModal={
                                    handleRemoveQuestionModal
                                }
                                handleSaveMeasurementTypeSelectorCard={
                                    handleSaveMeasurementTypeSelectorCard
                                }
                                handleSavePhotoUploadSelectorCard={
                                    handleSavePhotoUploadSelectorCard
                                }
                                handleSaveQuestion={handleSaveQuestion}
                                handleSelectQuestionType={
                                    handleSelectQuestionType
                                }
                                questionLevel="Questionnaire"
                                questionnaireErrors={questionnaireErrors}
                                questionnaireId={questionnaireId}
                                questions={questions}
                                validationErrorsByKey={validationErrorsByKey}
                                cardsCanBeReordered={cardsCanBeReordered}
                            />
                        </DragDropContext>
                        {questionnaireId && showAddQuestionButton && (
                            <AddQuestionButton
                                onClick={handleAddQuestion}
                                maxQuestionLimit={maxQuestionLimit}
                                limitError={addQuestionLimitError}
                            />
                        )}
                        {isRemoveQuestionModalOpen && (
                            <ConfirmationModal
                                isModalOpen={isRemoveQuestionModalOpen}
                                closeModal={handleCloseQuestionModal}
                                headerText={
                                    selectedQuestion?.questionType ===
                                    QuestionType.Branching
                                        ? `Removing branch`
                                        : `Removing question`
                                }
                                secondaryAction={handleCancelQuestionModal}
                                secondaryText="Cancel"
                                primaryAction={async () => {
                                    await handleRemoveQuestion(
                                        selectedQuestion,
                                    );
                                }}
                                primaryText={
                                    isRemovingQuestion
                                        ? "Removing..."
                                        : "Remove"
                                }
                                primaryButtonTheme="danger"
                                isActionInProgress={isRemovingQuestion}
                            >
                                <Text variant="label">
                                    {selectedQuestion?.questionType ===
                                    QuestionType.Branching
                                        ? "Are you sure you want to remove this branch?"
                                        : "Are you sure you want to remove this question?"}
                                </Text>
                                <Text>This cannot be undone.</Text>
                            </ConfirmationModal>
                        )}
                    </>
                );
            case LoadingStatus.Loading:
            default:
                return <Ds.Spinner></Ds.Spinner>;
        }
    };

    const handleSaveAndNext = (): void => {
        FlemingAnalyticsTracker.trackQuestionnairePageContinue({
            ...analyticsLoggedInProps,
            pageOrigin: "QuestionnaireNameAndQuestions",
            withSnomedCode: false,
            questionnaireId: questionnaireId ?? null,
        });
        if (false === areCardsAllSaved()) {
            return;
        }
        let questionnaireValidationErrorMessage = "";
        const questionnaireIsValid = (): boolean => {
            if (!state.selectedQuestionnaire) {
                questionnaireValidationErrorMessage =
                    "There is no selected questionnaire.";
                return false;
            }
            if (!state.selectedQuestionnaire.id) {
                questionnaireValidationErrorMessage =
                    "There is no questionnaire id.";
                return false;
            }
            if (!state.selectedQuestionnaire.name) {
                questionnaireValidationErrorMessage =
                    "There is no questionnaire name.";
                return false;
            }
            if (!state.selectedQuestionnaire.questions) {
                questionnaireValidationErrorMessage = "There are no questions.";
                return false;
            }
            if (
                state.selectedQuestionnaire.questions.length <
                MIN_QUESTION_LIMIT
            ) {
                questionnaireValidationErrorMessage = `Need a minimium ${MIN_QUESTION_LIMIT} question.`;
                return false;
            }
            // To add more validation checks
            return true;
        };
        if (!questionnaireIsValid()) {
            genericError(
                "There was an issue with saving this questionnaire",
                questionnaireValidationErrorMessage,
            );
            return;
        }
        const branchValidationErrors = checkBranchConditionValidity(questions);
        if (branchValidationErrors.length !== 0) {
            setHasError(true);
            branchValidationErrors.forEach((error) =>
                addError({ id: error.id, body: error.message }),
            );
            setQuestionnaireErrors(branchValidationErrors);

            return;
        }

        history.push(
            routeInvitationMessage(orgIdString, questionnaireIdString),
        );
    };

    const handleBackClick = (): void => {
        FlemingAnalyticsTracker.trackQuestionnairePageBack({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            pageOrigin: "QuestionnaireNameAndQuestions",
        });
        history.push(routeSummary(orgIdString));
        return;
    };

    return (
        <>
            <PageContainerForStickyFooter>
                <StackPanel gutter={2}>
                    <PageTitle
                        pageNumber="1"
                        pageTitle={
                            questionnaireFlow === QuestionnaireFlow.Create
                                ? "Create Florey Questionnaire"
                                : "Edit Florey Questionnaire"
                        }
                    />
                    <ErrorSummary isShown={hasError} />
                    {PageContents()}
                </StackPanel>
            </PageContainerForStickyFooter>
            <StepsFooter
                backText={PageActionText.Back}
                forwardText={
                    isSavingPage
                        ? PageActionText.Saving
                        : PageActionText.SaveAndNext
                }
                backClickFunction={handleBackClick}
                forwardClickFunction={handleSaveAndNext}
                disabled={isSavingPage}
                zIndex={200}
            />
        </>
    );
};
