import { useCallback, useEffect, useRef, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { conversationIdMapper } from "@accurx/concierge";
import { SubscriptionEvent, useTransport } from "@accurx/transport";
import { CurrentlyViewingConversations } from "domains/inbox/components/CurrentlyViewingContext/types";

import { SocketEvents } from "shared/hubClient/HubClient";
import { ThreadActiveReceive } from "shared/hubClient/payload.types";

export const INACTIVE_CHECK_INTERVAL = 60_000; // 1 minute
export const INACTIVE_THRESHOLD = 1_200_000; // 20 minutes

const mapUserIsViewingTicketIdToConversationId = (
    payload: ThreadActiveReceive,
): string | undefined => {
    if (!payload.ticketIdentity?.type || !payload.ticketIdentity.id) return;

    const processedTicketIdentity = {
        type: payload.ticketIdentity.type,
        id: payload.ticketIdentity.id,
    };

    return conversationIdMapper.ticket.fromSource(processedTicketIdentity);
};

export const useCurrentlyViewingUsersSubscription =
    (): CurrentlyViewingConversations => {
        const [
            currentlyViewingConversations,
            setCurrentlyViewingConversations,
        ] = useState<CurrentlyViewingConversations>({});

        const { transport, connectionState } = useTransport();
        const { orgId: workspaceId } = useCurrentWorkspace();

        const updateViewer = ({
            userId,
            name,
            conversationId,
        }: {
            userId: string;
            name: string;
            conversationId: string;
        }): void => {
            setCurrentlyViewingConversations((prevMap) => {
                const userViewingUpdate = {
                    name,
                    lastActiveTime: Date.now(),
                };

                /**
                 * If the conversation does not exist already, create a new conversation.
                 * Add/update the user's details for that conversation
                 */
                return {
                    ...prevMap,
                    [conversationId]: {
                        ...(prevMap[conversationId] ?? {}),
                        [userId]: userViewingUpdate,
                    },
                };
            });
        };

        const removeViewer = ({ userId }: { userId: string }): void => {
            setCurrentlyViewingConversations((prevMap) => {
                const newConversationIdCurrentlyViewingUsersMap = {
                    ...prevMap,
                };

                // Remove the user from all conversations
                Object.entries(
                    newConversationIdCurrentlyViewingUsersMap,
                ).forEach(([conversationId, users]) => {
                    if (!users) return;

                    // Delete the user from the conversation's list of currently viewing users
                    if (Object.hasOwn(users, userId)) {
                        delete users[userId];

                        newConversationIdCurrentlyViewingUsersMap[
                            conversationId
                        ] = users;
                    }

                    // If there are no more users viewing the conversation, remove the conversation from the map entirely
                    if (Object.keys(users).length === 0) {
                        delete newConversationIdCurrentlyViewingUsersMap[
                            conversationId
                        ];
                    }
                });

                return newConversationIdCurrentlyViewingUsersMap;
            });
        };

        const onEvent = useCallback(
            ({ payload }: SubscriptionEvent<"OnThreadActive">): void => {
                if (!payload.sender.accuRxId || !payload.sender.name) return;

                const notificationConversationId =
                    mapUserIsViewingTicketIdToConversationId(payload);

                /**
                 * If a users leaves a conversation, the conversation ID will be null - so we'll remove them from the map (and all conversations).
                 * When a user is viewing a conversation, we'll add them to the map and/or update their viewership details
                 */
                if (!notificationConversationId) {
                    removeViewer({
                        userId: payload.sender.accuRxId,
                    });
                } else {
                    updateViewer({
                        userId: payload.sender.accuRxId,
                        name: payload.sender.name,
                        conversationId: notificationConversationId,
                    });
                }
            },
            [],
        );

        useEffect(() => {
            if (connectionState !== "Connected" || !transport) return;

            const subscription = transport.subscribe({
                methodName: SocketEvents.OnThreadActive,
                eventFilter: (event) =>
                    event.payload.organisationId === workspaceId,
                onEvent,
            });

            return () => {
                subscription.unsubscribe();
            };
        }, [connectionState, transport, workspaceId, onEvent]);

        // Store a ref so that currentlyViewingConversations doesn't need to be a dependency in the interval useEffect
        const currentlyViewingConversationsRef = useRef(
            currentlyViewingConversations,
        );
        currentlyViewingConversationsRef.current =
            currentlyViewingConversations;

        useEffect(() => {
            // Set up an interval that checks to remove users who have been inactive for too long
            const removeInactiveUsers = setInterval(() => {
                Object.values(currentlyViewingConversationsRef.current).forEach(
                    (users) => {
                        if (!users) return;

                        const currentTime = Date.now();

                        Object.entries(users).forEach(([userId, user]) => {
                            if (!user) return;

                            // If the user has been inactive for too long, remove them from the currently viewing map.
                            if (
                                currentTime - user.lastActiveTime >=
                                INACTIVE_THRESHOLD
                            ) {
                                removeViewer({ userId });
                            }
                        });
                    },
                );
            }, INACTIVE_CHECK_INTERVAL);

            return () => {
                clearInterval(removeInactiveUsers);
            };
        }, []);

        return currentlyViewingConversations;
    };
