import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";

export const PatientTriageFeatureFlags = new Set<FeatureName>([
    FeatureName.PatientInitiatedInbox,
    FeatureName.AccurxPlusPatientTriage,
    FeatureName.PatientTriageSolicitedAppointmentBooking,
    FeatureName.PatientTriageSolicitedAppointmentBookingSendLink,
    FeatureName.PatientTriageReceptionFlow,
    FeatureName.PatientTriageDashboards,
]);

export const isPatientTriageEnabled = (workspace: Organisation) => {
    let isEnabled = true;

    PatientTriageFeatureFlags.forEach((featureFlag) => {
        if (!isFeatureEnabledForWorkspace(featureFlag, workspace)) {
            isEnabled = false;
        }
    });
    return isEnabled;
};
