import { PayloadAction } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { updateUnreadState } from "domains/concierge/internal/util/updateUnreadState";

export const recalculateUnreadCounts = (
    state: ConciergeState["conversations"],
    action: PayloadAction<void, string>,
) => {
    const userId = (action as ConciergeAction<void>).meta.userId;

    // create a clean version of the state where unread counts have been reset
    // so we can build up new unread counts and compare to the real state.
    state.unreadCounts = {
        ticket: {
            user: 0,
            teams: {},
            patients: {},
        },
        clinicianMessaging: {
            user: 0,
            teams: {},
        },
    };

    for (const id in state.unreadItems) {
        updateUnreadState({
            state,
            current: undefined,
            next: state.unreadItems[id],
            userId,
        });
    }

    return state;
};
