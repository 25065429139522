import React, { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { startNativeSubscription, useNativeTransport } from "@accurx/native";
import { useNativeConversationListener } from "domains/inbox/native/subscriptions/useNativeConversationListener";
import { useNativePatientDemographicsBackgroundUpdates } from "domains/inbox/native/subscriptions/useNativePatientDemographicsBackgroundUpdates";
import { useNativeWorkspaceChangeListener } from "domains/inbox/native/subscriptions/useNativeWorkspaceChangeListener";

export const NativeSubscriptionsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const transport = useNativeTransport();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;

    useNativePatientDemographicsBackgroundUpdates();
    useNativeWorkspaceChangeListener();
    useNativeConversationListener();

    useEffect(() => {
        if (!transport) return;

        const unsubscribeCurrentPatient = startNativeSubscription({
            subscription: "SubscribeCurrentPatient",
            transport,
            workspaceId,
        });
        const unsubscribeMedicalRecordConnection = startNativeSubscription({
            subscription: "SubscribeMedicalRecordConnection",
            transport,
            workspaceId,
        });

        return () => {
            unsubscribeCurrentPatient();
            unsubscribeMedicalRecordConnection();
        };
    }, [transport, workspaceId]);

    return <>{children}</>;
};
