import * as UI from "@accurx/design";

import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";

export const ResponseAssignee = ({
    assigneeLabel,
}: {
    assigneeLabel: string;
}) => {
    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="1"
        >
            <UI.Flex gap="1" alignItems="center">
                <BodyInfoBox.Icon name="Assign" theme="Fill" />
                <BodyInfoBox.Text colour="night">Assigned to</BodyInfoBox.Text>
            </UI.Flex>
            <UI.Ds.Badge radius="round" color="greyscale">
                <strong>{assigneeLabel}</strong>
            </UI.Ds.Badge>
        </StyledActionStack.Row>
    );
};
