import { PatientSummary } from "shared/concierge/patients/types/patient.types";

import { AssigneeSummary, NoAssignee } from "./assignee.types";
import type { BaseConversationItem } from "./conversation.types";

// =======================================
// top level conversation item definitions
// =======================================

type ConversationItemCreatedByUser = {
    type: "User";
    id: string;
};

type ConversationItemCreatedByPatient = {
    type: "Patient";
};

type ConversationItemCreatedBySystem = {
    type: "System";
};

export type ConversationItemCreatedBy =
    | ConversationItemCreatedByUser
    | ConversationItemCreatedByPatient
    | ConversationItemCreatedBySystem;

type BaseConversationItemWithoutContent<ContentType extends string> =
    BaseConversationItem & {
        /** Unique string to identify the content type with */
        contentType: ContentType;
        createdBy: ConversationItemCreatedBy;
    };

type BaseConversationItemWithContent<
    ContentType extends string,
    Content extends Record<string, unknown>,
> = BaseConversationItemWithoutContent<ContentType> & Content;

/**
 * Should not be seen in the wild unless someone is using a stale client with missing support
 * Placeholder item that we can show in the right location, but we don't currently know how to render details for */
export type UnknownConversationItem =
    BaseConversationItemWithoutContent<"Unknown">;

/** Outgoing clinician Sms to patient */
export type PatientSmsConversationItem = BaseConversationItemWithContent<
    "PatientSms",
    PatientMessageContentBase & {
        /** Mobile number that the Sms was sent to */
        mobileNumber: string;
        deliveryScheduledAt?: string;
    }
>;

/** Outgoing clinician Email to patient */
export type PatientEmailConversationItem = BaseConversationItemWithContent<
    "PatientEmail",
    PatientMessageContentBase & {
        /** Email address that the email was sent to */
        emailAddress: string;
        deliveryScheduledAt?: string;
    }
>;

/** Outgoing clinician NHS App message to patient */
export type PatientNhsAppMessageConversationItem =
    BaseConversationItemWithContent<
        "NhsAppMessage",
        PatientMessageContentBase & {
            deliveryScheduledAt?: string;
        }
    >;

/** Patient message containing questionnaire responses */
export type FloreyResponseConversationItem = BaseConversationItemWithContent<
    "FloreyResponseNote",
    MessageContentWithSections<PatientResponseSection> & {
        /** Title of the questionnaire */
        floreyName: string | null;
        patientThreadMarkdown?: string | null;
    }
>;

/** Patient message containing a single patient response */
export type PatientSingleResponseConversationItem =
    BaseConversationItemWithContent<
        "PatientSingleResponse",
        MessageContentWithSections<PatientResponseSection>
    >;

/** Patient initiated message - Patient Triage OR PIFU request */
export type PatientTriageRequestConversationItem =
    BaseConversationItemWithContent<
        "PatientTriageRequestNote",
        MessageContentWithSections<PatientResponseSection> & {
            requestType: "Admin" | "Medical";
            requestTitle: string | null;
            initialRequestMatchState: PatientMatchState;
            suggestedMatch: PatientTriageSuggestedPatient | null;
            patientProxy: PatientTriageProxyInformation | null;
            reception: PatientTriageReceptionInformation | null;
        }
    >;

/** Patient initiated message - relating to a specific appointment */
export type AppointmentRequestConversationItem =
    BaseConversationItemWithContent<
        "PatientAppointmentRequestNote",
        MessageContentWithSections<PatientResponseSection> & {
            requestType: PatientAppointmentRequestItemType;
        }
    >;

/**
 * State change item:
 * A user manually (or the system automatically) changed the state of the conversation
 * A state change action can be:
 * Mark as done / reopen / patient match / patient unmatch / assignment / mark as urgent / mark as non-urgent
 * */
export type StateChangeConversationItem = BaseConversationItemWithContent<
    "StateChange",
    GenericStateChangeItemContent | AssignmentStateChangeItemContent
>;

/** Clinician to clinician communication within a workspace */
export type NoteConversationItem = BaseConversationItemWithContent<
    "Note",
    {
        segments: NoteSegment[];
    }
>;

/**
 * Message which contains notice of a message which previously failed to sent
 */
export type FailedDeliveryReceiptLinkItem = BaseConversationItemWithContent<
    "FailedDeliveryReceiptLink",
    LinkToItem
>;

/**
 * Message which contains the link to the Video Consult
 * that the clinician had previously sent to the patient
 */
export type VideoConsultLinkItem = BaseConversationItemWithContent<
    "VideoConsultLink",
    LinkToItem & {
        clinicianUrl: string;
    }
>;

export type GenericNoteTheme = "Information" | "Warning" | "Alarm";
type GenericNoteContent = {
    /** Formatted markdown */
    markdown: string;
    /** The source of this message - can be the patient or an automated sender */
    displaySender: string | null;
    /** Informs what type of message this is */
    theme: GenericNoteTheme;
};

/** Contains markdown content, currently used by self-book for appointment updates */
export type GenericNoteConversationItem = BaseConversationItemWithContent<
    "GenericNote",
    GenericNoteContent
>;

/** Used by Patient Triage, not yet fully supported just basic mapping */
export type LabelTagConversationItem = BaseConversationItemWithContent<
    "LabelTag",
    { outcome?: Optional<string> }
>;

export type ConversationItem =
    // Unknown
    | UnknownConversationItem
    // Clinician message to patient
    | PatientSmsConversationItem
    | PatientEmailConversationItem
    | PatientNhsAppMessageConversationItem
    // Clinician message to clinician in workspace
    | NoteConversationItem
    // Inbound messages
    | PatientSingleResponseConversationItem
    | FloreyResponseConversationItem
    | PatientTriageRequestConversationItem
    | AppointmentRequestConversationItem
    // Conversation update notes
    | StateChangeConversationItem
    // Auto-generated messages related to another message
    | FailedDeliveryReceiptLinkItem
    | VideoConsultLinkItem
    // Automated generic note
    | GenericNoteConversationItem
    | LabelTagConversationItem;

export type ConversationItemType = ConversationItem["contentType"];

// =======================================
// content of supported item types
// =======================================

export const DeliveryStatusTypes = [
    "Unknown",
    "Queued",
    "Cancelled",
    "Sent",
    "Delivered",
    "Failed",
    "InvalidCredentials",
] as const;

export type DeliveryStatus = (typeof DeliveryStatusTypes)[number];

type GenericStateChangeItemContent = {
    changeType:
        | "Done"
        | "ReOpened"
        | "PatientMatch"
        | "PatientUnmatch"
        | "Urgent"
        | "NonUrgent";
};

export type StateChangeAssignedToId = Exclude<AssigneeSummary, NoAssignee>;

export type AssignmentStateChangeItemContent = {
    changeType: "Assignment";
    assignedToId: StateChangeAssignedToId;
};

type PatientMessageContentBase = {
    body: string;
    deliveryStatus: DeliveryStatus;
};

export type TextSegment = {
    type: "Text";
    text: string;
};
export type UserTagSegment = {
    type: "UserTag";
    userId: string;
};
export type TeamTagSegment = {
    type: "TeamTag";
    teamId: string;
};

export type NoteSegment = TextSegment | UserTagSegment | TeamTagSegment;

type MessageContentWithSections<T> = {
    sections: T[];
};
export type PatientResponseSection = {
    responses: PatientResponseItem[];
};

export type PatientMatchState = "NoMatch" | "Suggested" | "Verified";

export type PatientTriageProxyInformation = {
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    relationshipToPatient: string | null;
    patientPreferredPhoneNumber: string | null;
};

export type PatientTriageReceptionInformation = {
    userId: string | null;
};

export type PatientTriageSuggestedPatient = Pick<
    PatientSummary,
    | "firstName"
    | "familyName"
    | "prefixName"
    | "gender"
    | "dateOfBirth"
    | "externalIds"
    | "ageYear"
> & { postCode: string | null };

type ResponseWithAttachments = {
    attachmentIds: string[] | null;
};

export type PatientResponseItem = {
    displayQuestionBold: boolean;
    question: string | null;
    answer: string | null;
} & ResponseWithAttachments;

export type PatientAppointmentRequestItemType =
    | "None"
    | "Reschedule"
    | "Cancellation"
    | "General enquiry";

type LinkToItem = {
    /** Unique id of the linked note */
    linkedItemId: string;
};
