import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledOuterContainer = styled.div`
    padding: 0 ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1]};
    // create a new stacking context so that the top box-shadow remains visible
    position: relative;
    flex: 1;
    display: flex;
`;

export const StyledInnerContainer = styled.section`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-radius: ${UI.Tokens.BORDERS.radius};
    border: ${UI.Tokens.BORDERS.normal.silver};
    box-shadow: 0px ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[2]}
        rgba(67.83, 72.3, 125.97, 0.32);
    display: flex;
    flex-direction: column;
    flex: 1;
`;
