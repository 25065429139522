import {
    GenericNoteTheme as ApiGenericNoteTheme,
    PatientThreadGenericNote,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    GenericNoteConversationItem,
    GenericNoteTheme,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    conversationItemMappingLog,
    mapBaseConversationItem,
} from "./conversationItem.helpers";

/**
 * Maps ticket Generic Note item to conversation Self Book item
 *
 * @param ticketGenericNoteItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapGenericNoteItem(
    ticketGenericNoteItem: PatientThreadGenericNote,
): GenericNoteConversationItem | undefined {
    if (!ticketGenericNoteItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    const baseConversationItem = mapBaseConversationItem(ticketGenericNoteItem);

    if (!ticketGenericNoteItem.message.markdown) {
        throw new Error("Cannot map ticket item as prop markdown is invalid");
    }

    let theme = mapGenericNoteTheme(ticketGenericNoteItem.message.theme);
    if (!theme) {
        conversationItemMappingLog({
            message:
                "Could not map to a known theme, defaulting to Information",
            itemId: ticketGenericNoteItem.id,
            itemType: ticketGenericNoteItem.type,
        });
        theme = "Information";
    }

    return {
        ...baseConversationItem,
        contentType: "GenericNote",
        createdBy: { type: "System" },
        markdown: ticketGenericNoteItem.message.markdown,
        theme,
        displaySender: ticketGenericNoteItem.message.displaySender || null,
    };
}

function mapGenericNoteTheme(
    apiTheme?: Optional<ApiGenericNoteTheme>,
): GenericNoteTheme | undefined {
    switch (apiTheme) {
        case ApiGenericNoteTheme.Alarm:
            return "Alarm";
        case ApiGenericNoteTheme.Information:
            return "Information";
        case ApiGenericNoteTheme.Warning:
            return "Warning";
        default:
            return;
    }
}
