import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { User } from "../../types/User";
import { useUpdateCurrentUser } from "../../useUpdateCurrentUser";

export type LogInMutationVariables = {
    username: string;
    password: string;
    isLoginOrg: boolean;
};

enum RegisterAccountProductType {
    Chain = 1,
    Fleming = 2,
}

export const useLogInMutation = (
    options: UseMutationOptions<User, Error, LogInMutationVariables> = {},
) => {
    const update = useUpdateCurrentUser();
    return useMutation({
        mutationKey: ["logIn"],
        ...options,
        mutationFn: async ({ username, password, isLoginOrg }) => {
            const requestDto = {
                userName: username,
                password: password,
                product: isLoginOrg
                    ? RegisterAccountProductType.Chain
                    : RegisterAccountProductType.Fleming,
            };

            // "weblogin" send error emails, "login" have some extra analytics & logs
            const endpoint = isLoginOrg
                ? "/api/account/weblogin"
                : "/api/account/login";

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: endpoint,
                }),
                requestDto,
                false, //  DO NOT NOTIFY IF UNAUTHORIZED
            );

            if (response.success === true && response.result !== null) {
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return response.result;
            }

            const errorMessage =
                response.statusCode === 401
                    ? // Majority of the time 401 will be due to invalid email or password. Can't get a more specific error
                      // from the server due to security issues with leaking account information.
                      "Invalid email or password. Please try again."
                    : response.error;

            throw new Error(
                errorMessage ??
                    "Oops! We're sorry but we could not log you in.",
            );
        },
        onSuccess: (data) => {
            update(data);
        },
    });
};
