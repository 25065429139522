import { MouseEventHandler } from "react";

import { Feedback } from "@accurx/inbox-design-library";
import { DropdownItem } from "domains/compose/components/DropdownItem";

import { StyledContainer } from "./AttachNHSAdviceButton.styles";

type AttachNHSAdviceButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isDisabled: boolean;
    showWarning: boolean;
};

export const AttachNHSAdviceButton = ({
    onClick,
    isDisabled,
    showWarning,
}: AttachNHSAdviceButtonProps) => {
    return (
        <StyledContainer>
            {showWarning && (
                <Feedback colour="warning" title="NHS advice limit reached" />
            )}
            <DropdownItem.Button
                aria-label={"Attach NHS.UK advice"}
                text={"NHS.UK advice"}
                onClick={onClick}
                theme={"transparent"}
                icon={{
                    name: "Link",
                    colour: isDisabled ? "stone" : "metal",
                }}
                disabled={isDisabled}
            />
        </StyledContainer>
    );
};
