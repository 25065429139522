import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import styled from "styled-components";

export const StyledContainer = styled.div`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.dishwater};
    padding: ${UI.Tokens.SIZES[2]};

    min-height: 97px;
`;

export const StyledSkeletonLoader = styled(SkeletonLoader)`
    margin: ${UI.Tokens.SIZES[0.5]} 0;
`;

export const StyledPatientDetails = styled(UI.Flex)`
    gap: ${UI.Tokens.SIZES[0.5]};
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: ${UI.Tokens.BREAKPOINTS.sm}) {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: ${UI.Tokens.SIZES[3]};
    }
`;

export const StyledFeedback = styled(UI.Feedback)`
    flex: 1;
    padding: ${UI.Tokens.SIZES[1]};
    margin: ${UI.Tokens.SIZES[1.5]};
    * > * {
        font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    }
`;

export const StyledBackButtonContainer = styled(UI.Flex).attrs({
    alignItems: "center",
})`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.dishwater};
`;
