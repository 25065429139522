import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/compose/context";

import { StyledPillButton } from "../Attach/components/AttachmentInfoBox.styles";
import { ScheduleSendCustomDate } from "../ScheduleSend/components/CustomDate/ScheduleSendCustomDate";
import { ScheduleSendAt } from "../ScheduleSend/types/ScheduleSend.types";
import { StyledScheduledSendInfo } from "./ScheduleSendInfo.styles";
import { ScheduledSendText } from "./ScheduledSendText";

export const ScheduledSendInfo = () => {
    const { dispatch, state } = useCompose();
    const [showQuickView, setShowQuickView] = useState(false);

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    if (!state.sendAt) return null;

    return (
        <>
            <StyledScheduledSendInfo>
                <Ds.Flex
                    gap="1"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Ds.Icon
                        name="Clock"
                        color="metal"
                        size="small"
                        type="Line"
                        aria-hidden={true}
                    />
                    <ScheduledSendText sendAt={state.sendAt} />
                </Ds.Flex>
                <Ds.Flex gap="1">
                    <StyledPillButton
                        onClick={() => {
                            setShowQuickView(true);
                            track("ConversationScheduleEdit Button Click", {
                                scheduleType: state.sendAt ? "Custom" : "Now",
                                conversationParticipant:
                                    state.conversationParticipant,
                                ...nativeTrackingFields,
                            });
                        }}
                        disabled={state.isScheduling}
                        title="Change scheduled send time"
                    >
                        <Pill.Icon name="Pencil" colour="blue" size={3} />
                    </StyledPillButton>
                    <StyledPillButton
                        disabled={state.isScheduling}
                        onClick={() => {
                            dispatch({
                                type: "UPDATE_SEND_AT",
                                payload: { sendAt: null },
                            });
                            track("ConversationScheduleRemove Button Click", {
                                scheduleType: state.sendAt ? "Custom" : "Now",
                                conversationParticipant:
                                    state.conversationParticipant,
                                ...nativeTrackingFields,
                            });
                        }}
                        title="Remove scheduled send time"
                    >
                        <Pill.Icon name="Cross" colour="red" size={3} />
                    </StyledPillButton>
                </Ds.Flex>
            </StyledScheduledSendInfo>
            <QuickViewPortal
                isOpen={showQuickView}
                onClose={() => {
                    setShowQuickView(false);
                }}
            >
                <ScheduleSendCustomDate
                    onConfirm={(sendAt: ScheduleSendAt) => {
                        dispatch({
                            type: "UPDATE_SEND_AT",
                            payload: { sendAt },
                        });
                        setShowQuickView(false);
                    }}
                    sendAt={state.sendAt}
                />
            </QuickViewPortal>
        </>
    );
};
