import { useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { FeatureName, useAuth } from "@accurx/auth";
import {
    Ds,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import {
    SplitButtonGroup,
    StyledDivider,
    StyledListHeader,
    StyledPopoverItem,
} from "@accurx/navigation";
import { cleanUpSession } from "appLifecycle";
import { useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { StyledTriggerButton } from "../UnifiedLayout.styles";
import { HelpPopover } from "./HelpPopover/HelpPopover";
import { StyledPrimaryNavigation } from "./UnifiedPrimaryNavigation.styles";

/**
 * The simplified navigation might be used whilst logged out, so we need to be
 * careful with assumptions regarding user or workspace status
 */
export const UnifiedSimplePrimaryNavigation = () => {
    const history = useHistory();
    const track = useAnalytics();

    const { user, logOut } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const workspace =
        user.isLoggedIn &&
        user.organisations.find(({ orgId }) => orgId === selectedOrgId);

    const settingsButtonLabel = useMemo(() => {
        if (workspace) {
            return workspace.organisationName;
        } else if (user.isLoggedIn) {
            return user.fullName;
        } else {
            return undefined;
        }
    }, [user, workspace]);

    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const [popoverOpen, setPopoverOpen] = useState(false);

    const handleLogOut = () => {
        track("NavigationAccount MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "Logout",
        });
        setPopoverOpen(false);
        logOut({ cleanUpFn: cleanUpSession });
    };

    return (
        <StyledPrimaryNavigation data-testid="simple-primary-nav">
            <Ds.Flex justifyContent="flex-end" gap="1">
                {user.isLoggedIn && (
                    <SplitButtonGroup>
                        <Popover
                            open={popoverOpen}
                            onOpenChange={setPopoverOpen}
                            modal
                        >
                            <PopoverTrigger asChild>
                                <StyledTriggerButton
                                    text={settingsButtonLabel}
                                    icon={{ name: "Cog", title: "Settings" }}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                {workspace && (
                                    <>
                                        <StyledListHeader>
                                            <Text skinny>
                                                {workspace.organisationName}
                                            </Text>
                                            {isFlexibleWorkspaceEnabled && (
                                                <Text skinny>
                                                    {
                                                        workspace.organisationNodeName
                                                    }
                                                </Text>
                                            )}
                                        </StyledListHeader>
                                        <StyledDivider />
                                    </>
                                )}
                                <StyledListHeader>
                                    {user.email}
                                </StyledListHeader>
                                <StyledPopoverItem onClick={handleLogOut}>
                                    <Ds.Icon
                                        name="Reply"
                                        size="small"
                                        color="stone"
                                    />
                                    <Text skinny variant="label">
                                        Log out
                                    </Text>
                                </StyledPopoverItem>
                            </PopoverContent>
                        </Popover>
                    </SplitButtonGroup>
                )}
                <HelpPopover />
            </Ds.Flex>
        </StyledPrimaryNavigation>
    );
};
