import { useCallback } from "react";

import { FeatureName } from "@accurx/auth";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    ClinicianConversationViewProps,
    CopyButtonAnalyticsContext,
    FlemingLoggedInCustomProperties,
} from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsFeatureEnabled } from "store/hooks";

import { useIsMessageGpTestFlow } from "../hooks/useIsMessageGpTestFlow";
import { Template } from "../types";

export type AnalyticsPropsFromConversation = {
    clinicianConversationId: number;
    countParticipants: number;
};

export type TrackSendMessageClickArgs = AnalyticsPropsFromConversation & {
    hasError: boolean;
    countAttachments: number;
    template?: Template;
    numberOfPreviousMessages: number;
    usedKeyboardShortcut: boolean;
    isPostPatientMessage: boolean;
};

export type Origin =
    | "Email"
    | "MessagePractice"
    | "RecentMessages"
    | "GPServicesDropdown"
    | "PatientListDropdown"
    | "PatientSearchDropdown"
    | "MedicalRecords"
    | "ClinicianConversations";

const trackPageViewSuccess = (
    conversationUrlId: string | null,
    conversationProps: AnalyticsPropsFromConversation,
    loggedInCustomProperties: FlemingLoggedInCustomProperties,
    isMessageGpEnabled: boolean,
    isTestPatient: boolean,
    origin?: Origin,
    btnSource?: string,
    templateSource?: string,
    emailReferrer?: string,
): void => {
    const props: ClinicianConversationViewProps = {
        conversationUrlId,
        hasError: false,
        btnSource,
        templateSource,
        appOrigin: origin,
        emailReferrer,
        isMessageGpEnabled,
        ...loggedInCustomProperties,
        ...conversationProps,
        isTestPatient,
    };

    FlemingAnalyticsTracker.trackClinicianConversationPageView(props);
};

const trackPageViewFailure = (
    conversationUrlId: string | null,
    loggedInCustomProperties: FlemingLoggedInCustomProperties,
    isMessageGpEnabled: boolean,
    isTestPatient: boolean,
    origin?: Origin,
    btnSource?: string,
    templateSource?: string,
    emailReferrer?: string,
): void => {
    const props: ClinicianConversationViewProps = {
        conversationUrlId,
        btnSource,
        templateSource,
        hasError: true,
        appOrigin: origin,
        emailReferrer,
        isMessageGpEnabled,
        isTestPatient,
        ...loggedInCustomProperties,
    };
    FlemingAnalyticsTracker.trackClinicianConversationPageView(props);
};

type useClinicianConversationAnalyticsArgs = {
    appOrigin?: Origin;
    patientOrigin: string;
    search: string;
    urlId: string | null;
    emailReferrer: string;
};

export const useClinicianConversationAnalytics = ({
    appOrigin,
    patientOrigin,
    search,
    urlId,
    emailReferrer,
}: useClinicianConversationAnalyticsArgs) => {
    const isTestPatient = !!useIsMessageGpTestFlow();
    const loggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();
    const btnSource = new URLSearchParams(search).get("btnSource") || undefined;
    const templateSource =
        new URLSearchParams(search).get("templateSource") || undefined;
    const isMessageGpEnabled = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );

    const trackClinicianConversationPageViewSuccess = useCallback(
        (conversationProps: AnalyticsPropsFromConversation) => {
            trackPageViewSuccess(
                urlId,
                conversationProps,
                loggedInProps,
                isMessageGpEnabled,
                isTestPatient,
                appOrigin,
                btnSource,
                templateSource,
                emailReferrer,
            );
        },
        [
            urlId,
            loggedInProps,
            appOrigin,
            btnSource,
            templateSource,
            emailReferrer,
            isMessageGpEnabled,
            isTestPatient,
        ],
    );

    const trackClinicianConversationPageViewFailure = useCallback(() => {
        trackPageViewFailure(
            urlId,
            loggedInProps,
            isMessageGpEnabled,
            isTestPatient,
            appOrigin,
            btnSource,
            templateSource,
        );
    }, [
        urlId,
        loggedInProps,
        appOrigin,
        btnSource,
        templateSource,
        isMessageGpEnabled,
        isTestPatient,
    ]);

    const trackSendMessageClick = useCallback(
        ({
            clinicianConversationId,
            countParticipants,
            hasError,
            countAttachments,
            numberOfPreviousMessages,
            template,
            usedKeyboardShortcut,
            isPostPatientMessage,
        }: TrackSendMessageClickArgs) => {
            FlemingAnalyticsTracker.trackMessagePracticeSendButtonClick({
                ...loggedInProps,
                ...meaningfulActionProps,
                withAttachment: countAttachments > 0,
                countAttachment: countAttachments,
                hasError: hasError,
                origin: patientOrigin, // this is how the patient was found, eg patientLists, patientSearch
                appOrigin: appOrigin, // This is how the user got to the conversation page (eg previous conversations, message GP, other)
                emailReferrer: emailReferrer, // This tells us which email the user came from (eg initial confirmation email, notification email etc )
                clinicianConversationId: clinicianConversationId,
                countParticipants: countParticipants,
                isReply: numberOfPreviousMessages > 0,
                withTemplate: template !== undefined,
                templateName: template?.title,
                templateGroup: template?.heading,
                usedKeyboardShortcut: usedKeyboardShortcut,
                isPostPatientMessage,
                isTestPatient,
            });
        },

        [
            appOrigin,
            patientOrigin,
            loggedInProps,
            meaningfulActionProps,
            emailReferrer,
            isTestPatient,
        ],
    );

    const trackMessageCopy = useCallback(
        (isCurrentUserMessage: boolean) => {
            FlemingAnalyticsTracker.trackCopyMessage({
                ...loggedInProps,
                context: CopyButtonAnalyticsContext.accuMail,
                isCurrentUserMessage,
                isTestPatient,
            });
        },

        [isTestPatient, loggedInProps],
    );

    // Used to send an event to intercom when a user who has come in via the reply from web sends a message, so we can trigger a survey
    const trackIntercomReplyFromWebSent = useCallback(() => {
        window.Intercom("trackEvent", "reply-from-web-message-sent");
    }, []);

    const trackBackButtonClick = useCallback(
        () =>
            FlemingAnalyticsTracker.trackClinicianMessageBackButtonClick({
                ...loggedInProps,
                isTestPatient,
            }),
        [loggedInProps, isTestPatient],
    );

    return {
        trackSendMessageClick: trackSendMessageClick,
        trackPageViewFailure: trackClinicianConversationPageViewFailure,
        trackPageViewSuccess: trackClinicianConversationPageViewSuccess,
        trackMessageCopy: trackMessageCopy,
        trackBackButtonClick,
        trackIntercomReplyFromWebSent,
    };
};
