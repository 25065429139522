import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledThread = styled.section`
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    overflow-y: overlay;
    height: 100%;
    padding: 0 ${UI.Tokens.SIZES[3]};
`;

export const StyledLoadingStateContainer = styled(StyledThread)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${UI.Tokens.COLOURS.greyscale.metal};
`;

const NEW_MESSAGES_MARKER_HEIGHT = "68px";
const MESSAGE_INSET = UI.Tokens.SIZES[5];

/** When items are not full width of its container,
 * this is the max width they can take */
export const CONVERSATION_ITEM_MAX_WIDTH = "500px";
/** The width for conversation items that are not "FullWidth" */
export const CONVERSATION_ITEM_WIDTH = `calc(100% - ${MESSAGE_INSET})`;

export const StyledNewMessageMarker = styled.li`
    justify-content: center;
    align-items: center;
    display: flex;
    height: ${NEW_MESSAGES_MARKER_HEIGHT};
    padding-bottom: ${UI.Tokens.SIZES[2]};

    scroll-margin-top: ${UI.Tokens.SIZES[5]};

    &:after,
    &:before {
        content: "";
        flex: 1;
        border-bottom: 1px solid ${UI.Tokens.COLOURS.primary.red[100]};
    }
`;

/**
 * Adds a little bit of empty space at the bottom of the thread. This has three
 * purposes:
 * 1. It looks nice if the bottom message is a bit separated from the bottom
 *    reply bar
 * 2. It gives a margin of error for knowing when the thread has scrolled to the
 *    bottom, making the mark-as-read functionality a bit more robust.
 * 3. In the case of the floating compose buttons, this provides a necessary
 *    bottom margin so the buttons are not always displayed over the top of the
 *    last message in the thread.
 */
export const StyledBottomThreadSpacing = styled.div<{ $padding: 1 | 4 }>`
    width: 100%;
    padding: ${({ $padding }) => UI.Tokens.SIZES[$padding]};
`;
