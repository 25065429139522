import React, { useEffect, useState } from "react";

import { Button, ButtonIconProps } from "@accurx/design";
import { useTransport } from "@accurx/transport";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { stopTimer } from "shared/Timer.helper";
import { SocketEvents } from "shared/hubClient/HubClient";
import { useAppSelector } from "store/hooks";

import { VaccinePatientStatusUpdate } from "../models/VaccineAllPatientsInvitedDTO";
import {
    PatientInviteSearchFilters,
    VaccineCourse,
} from "../models/VaccineDeliveryDTO";
import { getVaccineAllPatientsInvited } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import {
    Actions as VaccineAllPatientsActions,
    addPatientToNetwork,
    getPatientDetails,
    resetAddToNetworkInfo,
    resetInviteNoteError,
    resetNimsDataError,
} from "./VaccineAllPatientsInvited.actions";
import { initialState as AllPatientsInvitedInitialState } from "./VaccineAllPatientsInvited.reducer";
import { VaccineAllPatientsInvitedDetailsBookingDetails } from "./VaccineAllPatientsInvitedDetailsBookingDetails";
import { VaccineAllPatientsInvitedDetailsUserNotes } from "./VaccineAllPatientsInvitedDetailsUserNotes";
import { VaccineAllPatientsInvitedDetailsVaccinationDetails } from "./VaccineAllPatientsInvitedDetailsVaccinationDetails";
import { VaccineDeliveryAddToNetworkModal } from "./VaccineDeliveryAddToNetworkModal";

export interface VaccineAllPatientsInvitedDetailsProps {
    inviteId: string;
    updateSearchTerm: (searchInput: string) => void;
    inviteBatchId: string;
    notInNetwork: boolean;
    practiceId: string; // Used for when invites reload call needs to be made
    searchFilters: PatientInviteSearchFilters; // Used for when invites reload call needs to be made
    selectedCourseTab: VaccineCourse;
}
type TabListProps = {
    children: React.ReactNode;
};
const TabList = ({ children }: TabListProps): JSX.Element => {
    return (
        <ul role="tablist" className="tablist">
            {children}
        </ul>
    );
};

type TabPanelProps = {
    children: React.ReactNode;
    id: string;
    tabId: string;
    className?: string;
    selected: boolean;
    selectedClassName?: string;
};

const TabPanel = ({
    children,
    className = "",
    id,
    selected,
    selectedClassName = "",
    tabId,
}: TabPanelProps): JSX.Element => (
    <div
        aria-labelledby={tabId}
        id={id}
        role="tabpanel"
        className={`${className} ${selected ? selectedClassName : ""}`}
    >
        {selected ? children : null}
    </div>
);

type TabProps = {
    id: string;
    text: string;
    iconProps?: ButtonIconProps;
    selected: boolean;
    onClick: () => void;
};
const Tab = ({
    id,
    text,
    iconProps,
    selected,
    onClick,
}: TabProps): JSX.Element => (
    <li role="presentation" className="tablist__item align-items-stretch">
        <Button
            role="tab"
            id={id}
            theme="transparent"
            dimension="large"
            text={text}
            icon={iconProps}
            aria-selected={selected}
            className="tablist__tab"
            onClick={onClick}
        />
    </li>
);

export const VaccineAllPatientsInvitedDetails = ({
    inviteId,
    inviteBatchId,
    updateSearchTerm,
    notInNetwork,
    practiceId,
    searchFilters,
    selectedCourseTab,
}: VaccineAllPatientsInvitedDetailsProps): JSX.Element => {
    const dispatch = useDispatch();
    const { transport } = useTransport();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const {
        patientDetailsInviteId,
        patientDetails,
        nimsData,
        nimsDataLoading,
        nimsDataError,
        patientDetailsLoading,
        patientDetailsError,
        patientNoteSaving,
        patientNoteError,
    } = useSelector(
        ({ vaccineAllPatientsInvited }: ApplicationState) =>
            vaccineAllPatientsInvited || AllPatientsInvitedInitialState,
    );
    const nbsData = useSelector(
        ({ vaccineAllPatientsInvited }: ApplicationState) =>
            vaccineAllPatientsInvited?.nbsData ||
            AllPatientsInvitedInitialState.nbsData,
        shallowEqual,
    );
    const addingToNetworkStatus = useSelector(
        ({ vaccineAllPatientsInvited }: ApplicationState) =>
            vaccineAllPatientsInvited?.addingToNetworkStatus ||
            AllPatientsInvitedInitialState.addingToNetworkStatus,
        shallowEqual,
    );
    const addingToNetworkError = useSelector(
        ({ vaccineAllPatientsInvited }: ApplicationState) =>
            vaccineAllPatientsInvited?.addingToNetworkError ||
            AllPatientsInvitedInitialState.addingToNetworkError,
        shallowEqual,
    );

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const [isOpen, setIsOpen] = useState(false);
    const [newNote, setNewNote] = useState("");
    const [showAddToNetworkModal, setShowAddToNetworkModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(-1); // No tab selected initially

    useEffect(() => {
        setIsOpen(patientDetailsInviteId === inviteId);
        if (patientDetailsInviteId !== inviteId) {
            setSelectedTabIndex(-1);
        }
    }, [patientDetailsInviteId, inviteId]);

    // PERFORMANCE_CHECK: Update timer on mount, timeout is here to only fire after rendering
    useEffect(() => {
        setTimeout(() => stopTimer("ALL_PATIENTS"), 0);
    }, []);

    useEffect(() => {
        if (patientDetailsInviteId === inviteId && selectedPractice) {
            // Reload details after adding a new note to display the new note, and after refreshing Vaccination data to display new data
            dispatch(
                getPatientDetails({
                    organisationId: `${selectedPractice}`,
                    inviteId,
                }),
            );
            if (!patientNoteSaving && patientNoteError === "") {
                setNewNote("");
            }
        }
    }, [
        dispatch,
        patientNoteSaving,
        nimsDataLoading,
        selectedPractice,
        inviteId,
        patientDetailsInviteId,
        patientNoteError,
    ]);

    useEffect(() => {
        if (!transport) return;

        const subscription = transport.subscribe({
            methodName: SocketEvents.OnInviteStatusChanged,
            onEvent: (data) => {
                const { payload } = data;
                if (selectedPractice !== `${payload.organisationId}`) {
                    return;
                }
                const update: VaccinePatientStatusUpdate = {
                    inviteId: payload.inviteId,
                    status: payload.currentInviteStatus,
                };
                dispatch({
                    type: VaccineAllPatientsActions.UPDATE_PATIENT_INVITE_STATUS,
                    update,
                });
            },
        });

        return () => subscription.unsubscribe();
    }, [dispatch, selectedPractice, transport]);

    const handleTab = (index: number): void => {
        setSelectedTabIndex((prevState) => {
            if (prevState === index) {
                // close if selecting selected tab
                setIsOpen(false);
                return -1;
            }
            return index;
        });

        if (!isOpen) {
            dispatch(
                getPatientDetails({
                    organisationId: `${selectedPractice}`,
                    inviteId,
                }),
            );
            dispatch(resetInviteNoteError());
            dispatch(resetNimsDataError());
            dispatch(resetAddToNetworkInfo());
        }
    };

    // region Add to network modal

    const handleToggleAddToNetworkModal = (): void => {
        dispatch(resetAddToNetworkInfo()); // need to reset in case any previous info still set
        setShowAddToNetworkModal((prevState) => !prevState);
    };

    const handleAddPatientToNetworkConfirm = (inviteId: string): void => {
        ChainAnalyticsTracker.trackVaccineClickedInviteThroughYourPractice(
            analyticsLoggedInProps,
        );
        dispatch(
            addPatientToNetwork(
                { organisationId: `${selectedPractice}` },
                { inviteId },
            ),
        );
    };

    const handleAddPatientToNetworkFinish = (): void => {
        setShowAddToNetworkModal(false);
        dispatch(getVaccineAllPatientsInvited(practiceId, searchFilters)); // reload all invites to show updated info
    };

    // endregion
    const tabDetails: {
        id: string;
        text: string;
        iconProps: ButtonIconProps;
    }[] = [
        {
            id: "vacc",
            text: "Vaccination Details",
            iconProps: {
                name: "Medication",
                colour: "green",
                title: "",
            },
        },
        {
            id: "book",
            text: "Booking Details",
            iconProps: {
                name: "Calendar",
                colour: "blue",
                title: "",
            },
        },
        {
            id: "note",
            text: "Notes",
            iconProps: {
                name: "Document",
                colour: "orange",
                title: "",
            },
        },
    ];

    return (
        <>
            <section>
                <TabList>
                    {tabDetails.map((tab, index) => (
                        <Tab
                            key={tab.id}
                            id={tab.id}
                            text={tab.text}
                            iconProps={tab.iconProps}
                            selected={selectedTabIndex === index}
                            onClick={() => handleTab(index)}
                        />
                    ))}
                </TabList>
                <TabPanel
                    id="tab-vacc"
                    tabId="vacc"
                    selected={selectedTabIndex === 0}
                >
                    <VaccineAllPatientsInvitedDetailsVaccinationDetails
                        selectedPractice={selectedPractice}
                        inviteId={inviteId}
                        inviteBatchId={inviteBatchId}
                        notInNetwork={notInNetwork}
                        patientDetailsLoading={patientDetailsLoading}
                        patientDetailsError={patientDetailsError}
                        nimsData={nimsData}
                        nimsDataLoading={nimsDataLoading}
                        nimsDataError={nimsDataError}
                    />
                </TabPanel>
                <TabPanel
                    id="tab-book"
                    tabId="book"
                    selected={selectedTabIndex === 1}
                >
                    <VaccineAllPatientsInvitedDetailsBookingDetails
                        updateSearchTerm={updateSearchTerm}
                        handleToggleAddToNetworkModal={
                            handleToggleAddToNetworkModal
                        }
                        patientDetails={patientDetails}
                        patientDetailsLoading={patientDetailsLoading}
                        patientDetailsError={patientDetailsError}
                        nbsData={nbsData}
                        selectedCourseTab={selectedCourseTab}
                    />
                </TabPanel>
                <TabPanel
                    id="tab-note"
                    tabId="note"
                    selected={selectedTabIndex === 2}
                >
                    <VaccineAllPatientsInvitedDetailsUserNotes
                        selectedPractice={selectedPractice}
                        inviteId={inviteId}
                        inviteBatchId={inviteBatchId}
                        patientDetails={patientDetails}
                        patientDetailsLoading={patientDetailsLoading}
                        patientDetailsError={patientDetailsError}
                        newNote={newNote}
                        setNewNote={setNewNote}
                        patientNoteSaving={patientNoteSaving}
                        patientNoteError={patientNoteError}
                    />
                </TabPanel>
                {showAddToNetworkModal && (
                    <VaccineDeliveryAddToNetworkModal
                        inviteId={inviteId}
                        showAddToNetworkModal={showAddToNetworkModal}
                        onToggleModal={handleToggleAddToNetworkModal}
                        onConfirm={handleAddPatientToNetworkConfirm}
                        onFinish={handleAddPatientToNetworkFinish}
                        addingToNetworkStatus={addingToNetworkStatus}
                        addingToNetworkError={addingToNetworkError}
                    />
                )}
            </section>
        </>
    );
};
