import { useEffect } from "react";

import { useCurrentUser } from "@accurx/auth";
import { ComposeProvider } from "@accurx/compose";
import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { ReplyState } from "domains/inbox/components/ConversationView/ConversationView.types";
import { usePatientContactDetails } from "domains/inbox/hooks/util";
import { useCanUseQuestionnaires } from "domains/inbox/hooks/util/useCanUseQuestionnaires";
import { getDefaultContactDetail } from "domains/inbox/hooks/util/useSetDefaultContactDetail/getDefaultContactDetail";
import { generatePatientGreeting } from "domains/inbox/util/greeting";
import { generateSignature } from "domains/inbox/util/signature";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { ReplyContentsNew } from "../Contents/ReplyContentsNew";
import { ReplyActionButton } from "../MessageActionButton/MessageActionButton";

/**
 * This is determined and validated by backend
 * and needs to be kept in sync with
 * MessagePatientDocumentAttachConstants.cs
 */
const MAX_ATTACHMENT_COUNT = 1;

export type ReplyNewProps = {
    patient: PatientSummary;
    conversation: Conversation;
    replyState: ReplyState;
    setReplyState: (state: ReplyState) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const ReplyNew = ({
    patient,
    conversation,
    replyState,
    setReplyState,
    isExpanded,
    onExpandClick,
}: ReplyNewProps) => {
    const { user } = useCurrentUser();
    const canUseQuestionnaires = useCanUseQuestionnaires();

    const patientGreeting = generatePatientGreeting(patient);
    const userSignature = generateSignature(user);
    const { status, data: patientContactDetails } = usePatientContactDetails({
        patient,
        conversation,
        // We only want to fetch patient details when the user has clicked the button to open the reply area
        enabled: replyState === "Opening" || replyState === "Open",
    });

    const { trackReplyButtonClick } = useButtonClickAnalytics(conversation);

    useEffect(() => {
        if (replyState === "Opening" && status !== "loading") {
            setReplyState("Open");
        }
    }, [replyState, setReplyState, status]);

    if (replyState === "Closed") {
        return (
            <ReplyActionButton
                onClick={() => {
                    setReplyState("Opening");
                    trackReplyButtonClick();
                }}
            />
        );
    }

    if (replyState === "Opening") {
        return <ReplyActionButton isLoading />;
    }

    return (
        <ComposeProvider
            settings={{
                // In case there's an error when fetching demographics we start up with no default details
                contactDetails:
                    getDefaultContactDetail(patientContactDetails) ?? undefined,
                greeting: patientGreeting,
                editableBody: "",
                editableSignature: userSignature,
                maxAttachmentCount: MAX_ATTACHMENT_COUNT,
                canUseQuestionnaires,
                conversationParticipant: "WithPatient",
            }}
        >
            <ReplyContentsNew
                patient={patient}
                conversation={conversation}
                contactDetails={patientContactDetails}
                replyState={replyState}
                setReplyState={setReplyState}
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
            />
        </ComposeProvider>
    );
};
