import { useMemo } from "react";

import { GenericNoteItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type GenericNoteProps = {
    conversationItem: GenericNoteItem;
};

export const GenericNote = ({ conversationItem }: GenericNoteProps) => {
    /**
     * More info on why we have using this approach here: https://github.com/Accurx/rosemary/pull/20395/files#r1314826070
     */
    const summary = useMemo(
        () =>
            conversationItem.markdown
                .replaceAll("\\\n", " ")
                .replaceAll("**", ""),
        [conversationItem.markdown],
    );

    const isSelfBook = conversationMarkdown(conversationItem.markdown);

    const respondedBy = isSelfBook ? "Patient:" : "System:";
    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {respondedBy}
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {summary}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};

export function conversationMarkdown(markdown: string) {
    if (
        markdown.includes("Patient has booked") ||
        markdown.includes("Patient did not book") ||
        markdown.includes("Patient failed to book")
    ) {
        return true;
    } else {
        return false;
    }
}
