import { Log } from "@accurx/shared";
import { Unsubscribable } from "rxjs";

import { EventMeta, PollerOptions, Teardown } from "./poller.types";

export const poller = (options: PollerOptions): Teardown => {
    let initialized = false;
    let isLive = true;
    let intervalHandle: ReturnType<typeof setTimeout> | null = null;

    const subscriptionHandles: Unsubscribable[] = [];

    const teardown = () => {
        isLive = false;
        if (intervalHandle) {
            clearTimeout(intervalHandle);
        }
        subscriptionHandles.forEach((handle) => handle.unsubscribe());
    };

    const setupNextRefreshTick = () => {
        if (!isLive) return;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        intervalHandle = setTimeout(onRefreshTick, options.refreshRate);
    };

    const performFetch = async () => {
        const meta: EventMeta = {
            initialized,
            refreshRate: options.refreshRate,
            online: navigator.onLine,
            polling: true,
            connected: false,
        };

        // Try to fetch data
        try {
            Log.debug(`${options.name}: fetch start`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchStart && options.onFetchStart(meta);

            await options.fetchFn({ isInitialFetch: !initialized });

            Log.debug(`${options.name}: fetch success`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchSuccess && options.onFetchSuccess(meta);
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (e.statusCode === 401) {
                Log.debug(`${options.name}: 401 unauthorized received`, {
                    tags: {
                        product: "Inbox",
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                        statusCode: e.statusCode,
                        ...meta,
                    },
                    originalException: e,
                });
            } else {
                Log.error(`${options.name}: fetch failed`, {
                    tags: {
                        product: "Inbox",
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                        statusCode: e.statusCode,
                        ...meta,
                    },
                    originalException: e,
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            options.onFetchError && options.onFetchError(e, meta);
        } finally {
            initialized = true;
        }
    };

    const onRefreshTick = async () => {
        const meta: EventMeta = {
            initialized,
            refreshRate: options.refreshRate,
            online: navigator.onLine,
            polling: true,
            connected: false,
        };
        Log.info(`${options.name}: refresh interval`, {
            tags: { product: "Inbox", ...meta },
        });

        options.onRefreshInterval && options.onRefreshInterval(meta);

        await performFetch();

        setupNextRefreshTick();
    };

    const initialFetch = async () => {
        await performFetch();
        setupNextRefreshTick();
    };

    void initialFetch();

    return teardown;
};
