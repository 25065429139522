import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { ReplyState } from "domains/inbox/components/ConversationView/ConversationView.types";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { NoteContentsNew } from "../Contents/NoteContentsNew";
import { NoteActionButton } from "../MessageActionButton/MessageActionButton";

export type NoteNewProps = {
    patient?: PatientSummary;
    conversation: Conversation;
    noteState: ReplyState;
    setNoteState: (state: ReplyState) => void;
    setSentNoteStatus: (status: string | null) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const NoteNew = ({
    patient,
    conversation,
    noteState,
    setNoteState,
    setSentNoteStatus,
    isExpanded,
    onExpandClick,
}: NoteNewProps) => {
    const { trackNoteButtonClick } = useButtonClickAnalytics(conversation);

    if (noteState === "Closed") {
        return (
            <NoteActionButton
                onClick={() => {
                    setNoteState("Open");
                    trackNoteButtonClick();
                }}
            />
        );
    }

    return (
        <NoteContentsNew
            noteState={noteState}
            setNoteState={setNoteState}
            conversation={conversation}
            patient={patient}
            isExpanded={isExpanded}
            onExpandClick={onExpandClick}
            setSentNoteStatus={setSentNoteStatus}
        />
    );
};
