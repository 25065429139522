import { Button } from "@accurx/design";

import { useMobileLayout } from "../Mobile/MobileLayoutContext";
import { HelpPopover } from "../PrimaryNavigation/HelpPopover/HelpPopover";
import { StyledMobileHeader } from "./MobileHeader.styles";

export const MobileHeader = ({ showHelp }: { showHelp?: boolean }) => {
    const { secondaryNav } = useMobileLayout();

    return (
        <StyledMobileHeader>
            <Button
                theme="transparent"
                dimension="medium"
                icon={{ name: "Menu", title: "Menu" }}
                onClick={secondaryNav.toggle}
            />
            {showHelp && (
                <HelpPopover>
                    <Button
                        theme="transparent"
                        dimension="medium"
                        icon={{ name: "Question", title: "Help" }}
                    />
                </HelpPopover>
            )}
        </StyledMobileHeader>
    );
};
