import { IntercomUser, Specialty, WorkspaceStatus } from "@accurx/api/account";
import { PatientSearchResultBase } from "@accurx/api/portal";
import {
    PatientExternalIdentityDto,
    PatientThreadEmail,
    PatientThreadPatientDetails,
    PatientThreadPatientId,
    PatientThreadSMS,
    PatientThreadUser,
    PatientThreadUserGroup,
    TicketIdentity,
} from "@accurx/api/ticket";
import { IFeature } from "@accurx/shared";

import { ClinicianConversation } from "api/ClinicianConversationApi";
import { PreviewUrl } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";

// Message patient
// src/Web/accuRx.FlemingServer/Controllers/MessagePatientController.cs

// Keep in sync with accuRx.FlemingServer MessagePatientRequest C# DTO.
type MessagePatientRequestCommon = {
    mobileNumber: string | null;

    messageBody: string;
    nhsNumber: string;
    emailSubjectLine: string;
    emailBody: string;
    isVideoConsult: boolean;
    organisationId: number | null;

    attachedDocumentIds: Array<string>;
    emailAddress: string | null;
    enablePatientResponse: boolean | null;
    patientListId: number | null;
    patientListEntryId: number | null;
    videoConsultTime: string | null;
    conditionId?: string;
    conditionName?: string;

    messageTemplate?: {
        id: number;
        attachmentIds: number[];
        isPreset?: boolean | null;
        group?: string | null;
        name?: string | null;
        level?: string | null;
    };

    /**
     * Used to add to an existing conversation
     *
     * Leave null if the message should be part of a new conversation
     * */
    ticketIdentity?: TicketIdentity | null;

    trySendViaNhsApp?: boolean | null;
};

/**
 * Request DTO for adding a message to a conversation or sending a message as part of a new conversation
 * without a patient PDS search
 *
 * Requires 2FA login
 */
export type MessagePatientWithTwoFactorRequest = MessagePatientRequestCommon & {
    patientExternalIdentity: PatientExternalIdentityDto;
};

/**
 * Request DTO for adding a message to a conversation or sending a message as part of a new conversation
 * after a successful patient PDS search.
 *
 * Does not require 2FA login
 */
export type MessagePatientWithTokenRequest = MessagePatientRequestCommon & {
    patientToken: string;
    /**
     * Only needed with the message patient with token endpoint, as PDS result only happens after a patient search
     * */
    useMobileNumberFromPDS: boolean;
};

/**
 * DTO used to build the request payload at the point of
 * dispatching the action to send a message
 */
export type MessagePatientAllEndpointsRequest =
    MessagePatientWithTwoFactorRequest & MessagePatientWithTokenRequest;

// Keep in sync with accuRx.FlemingServer MessagePatientResponse C# DTO.
export type IMessagePatientResponse = {
    videoConsultUrl: string | null;
    videoConsultId: string | null;
    // TODO: this is being deprecated: remove once deprecation completed. Use `messages` instead
    messageText: string | null;
    messages?: Array<PatientThreadSMS> | null;
    emailMessages?: Array<PatientThreadEmail> | null;
};

// Document Upload
// Keep in sync with accuRx.Shared DocumentUploadResponse C# DTO.
export type IDocumentUploadResponse = {
    link: string;
    id: string;
};

export type IDocumentUploadRequest = {
    file: File;
    organisationId: number | null;
};

export type ITemplateAttachmentUploadRequest = {
    file: File;
    organisationId: string;
};

// Keep in sync with accuRx.FlemingServer MessagePracticeRequest C# DTO.
export type IMessagePracticeRequest = {
    patientToken: string;
    nhsNumber: string;
    practiceCode: string;
    messageBody: string;
    organisationId: number | null;
    externalEmailAttachmentIds: Array<string>;
};

// Keep in sync with accuRx.FlemingServer MessagePracticeResponse C# DTO.
export type IMessagePracticeResponse = ClinicianConversation;

// Keep in sync with accuRx.ClinicianMessaging > Dto PracticeActivityResponse, PracticeUserOnlineStatus C# DTO.

export type IPracticeActivityResponse = {
    practiceUserOnlineStatus: IUserOnlineStatus[];
    isOpenToReplies: boolean;
};

export type IUserOnlineStatus = {
    lastOnlineAt: Date;
};

export type IExternalEmailUploadAttachmentResponse = {
    externalEmailAttachmentId: string;
    emailAddresses: Array<string>;
};

export type IExternalEmailUploadAttachmentRequest = {
    file: File;
    organisationId: number | null;
};

// Account

// Keep in sync with accuRx.FlemingServer UserResponse C# DTO.
export type IUserResponse = {
    accuRxUserId: string;
    email: string;
    fullName: string;
    messageSignature: string;
    organisations: IOrganisation[];
    isSecureEmail: boolean;
    analyticsUserIdentifier?: string;
    healthcareRole: string | null;
    specialistArea: string | null;
    isLoggedIn: boolean;
    isSetupFor2Fa?: boolean;
    is2FAed: boolean;
    onboarding?: OnboardingResponse;
    intercom?: IntercomUser | undefined;
    isTrustedDevice: boolean;
    lastActiveWorkspaceId?: number | null;
};

type OnboardingResponse = {
    isFirstTimeUser: boolean;
    hasAcceptedCookies: boolean | null;
    hasAcceptedTermsService: boolean;
};

export type IOrganisation = {
    orgId: number;
    organisationName: string;
    organisationNodeName: string;
    description?: string;
    nationalCode: string;
    roleCode?: string;
    settings: IOrganisationSettings;
    userSpecialtyRequired: boolean;
    defaultWorkspaceId: number;
    userSpecialty?: Specialty;
    status: WorkspaceStatus;
};

export type IOrganisationSettings = {
    features: IFeature[];
    isApprovedUser: boolean;
    isAdminUser: boolean;
};

export type IAllowedOrganisationResponse = {
    nationalCode: string;
    organisationName: string;
    postCode: string;
    isSuggested: boolean;
};

export type IAllowedNhsOrganisationResponse = {
    nationalCode: string;
    name: string;
    supportsMultipleWorkspaces: boolean;
};

export type ISetupOrganisationRequest = {
    nationalCode: string;
};

export type IJoinOrganisationFormRequest = {
    organisationName: string;
    organisationType: string;
    organisationPostCode: string;
    reasonForAccess: string;
    // We validate the SSO ODS code against the allowed organisations, as if it is an allowed org then
    ssoOdsCode?: string;
};

export type ISetupOrganisationResponse = {
    success: boolean;
    organisations: IOrganisation[];
    organisationId: number | null;
};

//Delivery Receipts
export interface IDeliveryReceiptRequest {
    organisationId: number | null;
}

export type IDeliveryReceiptResponse = {
    success: boolean;
    deliveryReceipts: IDeliveryReceipt[];
    organisationId: number | null;
};

export type IDeliveryReceipt = {
    nhsNumber: string;
    sentTime: string;
    status: string;
};

// Video Progress
export type PatientVideoProgressResponse = {
    consultationId: string | null;
    patientMessageSent: string | null;
    patientMessageDelivered: string | null;
    patientClickedLink: string | null;
    patientSettingUpDevice: string | null;
    patientLastSeenInCall: string | null;
    numberOfPeopleInCall: number;
};
export type PatientsVideoProgressRequest = {
    consultationIds: string[];
};
export type PatientsVideoProgressResponse = {
    videoTrackingResponses: PatientVideoProgressResponse[];
};

// Two factor auth
export type TwoFactorRegisterRequest = {
    mobileNumber: string;
};
export type TwoFactorRegisterEmailRequest = {
    secondaryEmail: string;
};

export type TwoFactorNumberResponse = {
    obfuscatedPhoneNumber: string | undefined | null; // already obfuscated mobile number
    obfuscatedSecondaryEmail: string | undefined | null; // already obfuscated email
};

export type TwoFactorVerifyRequest = {
    accessCode: string;
    rememberTwoFactor: boolean;
};

// Keep in sync with accuRx.FlemingServer MessageTemplateType C# DTO
export enum MessageTemplateType {
    Sms,
    VideoConsult,
}

// Keep in sync with accuRx.FlemingServer MessageTemplateOwner C# DTO
export enum MessageTemplateOwner {
    User,
    Organisation,
}

export type TemplateAttachment = {
    documentId: string;
    fileSize: number;
    fileName: string;
    previewUrl: PreviewUrl;
};

// Keep in sync with accuRx.FlemingServer MessageTemplate C# DTO.
export type MessageTemplate = {
    id: number | null;
    title: string;
    body: string;
    isPatientResponseRequired?: boolean;
    type: MessageTemplateType | null;
    isDefault: boolean | null;
    owner: MessageTemplateOwner | null;
    attachedDocumentIds?: string[];
    attachedDocuments?: TemplateAttachment[];
};

export type IMessageTemplateRequest = {
    organisationId: number | null;
};

export type ICreateEditMessageTemplateRequest = {
    organisationId: number | null;
    newTemplate: MessageTemplate;
};

export type IDeleteMessageTemplateRequest = {
    organisationId: number | null;
    id: number;
};

export type PostPatientTokenRequest = {
    token: string;
};

export type PostPatientTokenResponse = {
    nhsNumber: string;
    organisationId: number;
    dateOfBirthDay?: number;
    dateOfBirthMonth?: number;
    dateOfBirthYear?: number;
};

// Keep in sync with accuRx.FlemingServer HealthcareProfileOptions C# DTO.
export type GetHealthcareProfileOptionsResponse = {
    healthcareRoles: string[] | null;
    specialistAreas: string[] | null;
};

// Keep in sync with accuRx.FlemingServer HealthcareProfile C# DTO.
export type PostUserProfileRequest = {
    healthcareRole?: string;
    specialistArea?: string;
    hasAcceptedTermsService?: boolean;
    nationalCode?: string;
};

export type PostUserProfileResponse = {
    organisationResponse: PostUserProfileOrganisationResponse | null;
};

export type PostUserProfileOrganisationResponse = {
    organisationId: number;
    organisationName: string;
    organisations: IOrganisation[];
};

/*
 * The types in @accurx/api/ticket are automatically generated from the
 * types defined on accuRx.TicketServer.PublicAPI.json.
 * Here's an extract:
    "PatientThreadPatientId": {
      "type": "object",
      "properties": {
        "AccuRxId": {
          "type": "string"
        },
        "PatientExternalIdentity": {
          "$ref": "#/definitions/PatientExternalIdentityDto"
        }
      }
    },
 * It appears that in the Server code, the fields accuRxId, patientExternalIdentity, etc are required
 * but not marked as required in the API.txt file. So we are here manually overriding to ensure these
 * types are required.
 */
export type PatientDemographicData = Required<PatientThreadPatientDetails> &
    Required<PatientThreadPatientId>;

export type ConversationUser = Required<PatientThreadUser>;

export type ConversationUserGroup = Required<PatientThreadUserGroup>;

export type StructuredUserName = string | undefined;

export type WebInboxPracticeUserDataById = Record<string, ConversationUser>;
export type WebInboxPracticeUserGroupDataById = Record<
    string,
    ConversationUserGroup
>;

export type SaveRecentSearchRequest = {
    patientToken: string;
    organisationId: number | null;
};

export type GetRecentSearchesRequest = {
    organisationId: number | null;
};

export type RecentSearchedResult = PatientSearchResultBase & {
    // ISO date
    dateCreated: string;
};

export type GetRecentSearchesResponse = {
    patients: RecentSearchedResult[];
    totalCount: number;
};

export type GetPatientAttachmentsRequest = {
    patientToken: string;
    organisationId: number;
};

export type DeletePatientAttachmentsRequest = {
    patientToken: string;
    organisationId: number;
    documentUrlId: string;
};

export type PatientAttachment = {
    fileName: string;
    sentBy: string;
    sentByCurrentUser: boolean;
    sentAt: string;
    documentUrlId: string;
    downloadUrl: string;
    deletedAt: string | null;
};

export type GetPatientAttachmentsResponse = {
    attachments: PatientAttachment[];
};

export type QuestionnairePreviewRequest = {
    workspaceId: number;
    questionnaireId: string;
};

export type QuestionnairePreviewResponse = {
    enrolmentUrl: string;
};
