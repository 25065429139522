import { ReactNode, useEffect } from "react";

import { Ds } from "@accurx/design";
import { Colours } from "@accurx/navigation";
import { useLocation } from "react-router";
import styled from "styled-components";

import { SLIDEOUT_PORTAL_ID, SlideOut } from "app/sharedComponents/SlideOut";

import { MobileNavButton } from "../../PrimaryNavigation/MobileNavItem/MobileNavItem";
import { StyledHeader } from "../MobileNavigationModal/MobileNavigationModal.styles";

const StyledSlideOut = styled(SlideOut)`
    background-color: ${Colours.midnightGreen};
    display: flex;
    flex-direction: column;
    width: 300px;
`;

type TabletNavigationDrawerProps = {
    isOpen: boolean;
    toggleOpen: (open: boolean) => void;
    children: ReactNode;
};

export const TabletNavigationDrawer = ({
    isOpen,
    toggleOpen,
    children,
}: TabletNavigationDrawerProps) => {
    const { pathname } = useLocation();

    useEffect(() => toggleOpen(false), [toggleOpen, pathname]);

    return (
        <>
            <StyledSlideOut
                isOpen={isOpen}
                toggleOpen={() => toggleOpen(!isOpen)}
                showTab={false}
                placement="left"
                useFixedWidth
            >
                <StyledHeader>
                    <MobileNavButton
                        onClick={() => toggleOpen(false)}
                        aria-label="Close"
                    >
                        <Ds.Icon name="Cross" size="small" color="white" />
                    </MobileNavButton>
                </StyledHeader>
                {children}
            </StyledSlideOut>
            <div id={SLIDEOUT_PORTAL_ID} />
        </>
    );
};
