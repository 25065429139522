import { AppSettings, SessionStorageHelpers } from "@accurx/shared";
import isEqual from "lodash/isEqual";

export type FeatureFlagOverrideValues = {
    [featureName: string]: boolean;
};

export const STORAGE_KEY = "useFeatureFlagOverrides";

export const getFeatureOverrides = (): FeatureFlagOverrideValues => {
    if (AppSettings.accurxEnvironment === "production") return {};

    const overrides = SessionStorageHelpers.getSessionStorageItem(STORAGE_KEY);
    return overrides
        ? (JSON.parse(overrides) as FeatureFlagOverrideValues)
        : {};
};

export const setFeatureOverrides = ({
    featureName,
    isEnabled,
}: {
    featureName: string;
    isEnabled: boolean;
}): void => {
    if (AppSettings.accurxEnvironment === "production") return;

    const overrides = getFeatureOverrides();
    const newOverrides = {
        ...overrides,
        [featureName]: isEnabled,
    };

    if (isEqual(overrides, newOverrides)) return;

    SessionStorageHelpers.setSessionStorageItem(
        STORAGE_KEY,
        JSON.stringify(newOverrides),
    );
};

export const convertOverridesToString = (
    overrides: FeatureFlagOverrideValues,
): string => {
    return Object.entries(overrides)
        .map(([featureName, isEnabled]) => `${featureName}:${isEnabled}`)
        .join(",");
};
