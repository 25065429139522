import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledButton = styled(Pill.Button)<{
    $showIconOnly: boolean;
}>`
    padding: ${({ $showIconOnly }) =>
        $showIconOnly ? "0 5px" : `0px ${UI.Tokens.SIZES[1]}`};
`;

export const StyledTooltipTriggerWrapper = styled.span`
    // Do not show focus state of the tooltip
    &:focus-within {
        span[tabindex="-1"]:focus,
        span[tabindex="-1"]:focus-visible {
            box-shadow: none;
        }
    }
`;
