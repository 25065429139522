import {
    MessageTemplate,
    QuestionnaireTemplate,
} from "@accurx/message-templates";
import { TemplateItem } from "domains/compose/types";

export const combineTemplates = ({
    messageTemplates,
    questionnaireTemplates,
}: {
    messageTemplates: MessageTemplate[];
    questionnaireTemplates: QuestionnaireTemplate[];
}) => {
    const combined: TemplateItem[] = [
        ...messageTemplates.map(
            (t): TemplateItem => ({
                type: "MessageTemplate",
                value: t,
            }),
        ),
        ...questionnaireTemplates.map(
            (t): TemplateItem => ({
                type: "QuestionnaireTemplate",
                value: t,
            }),
        ),
    ];
    return combined;
};
