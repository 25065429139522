import { PatientThreadUserGroup } from "@accurx/api/ticket";
import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { TeamSummary } from "domains/concierge/types";

/**
 * Maps from a Ticket User Group to a Team Summary
 * @param dto the ticket user group
 * @returns mapped version to Team Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserGroupToTeamSummary = (
    dto: PatientThreadUserGroup,
): TeamSummary | undefined => {
    return mapUserGroupToTeamSummary({
        id: dto.id,
        name: dto.name,
        type: dto.groupType,
    });
};
