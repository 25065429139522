import { ReactNode } from "react";

import { useBrowserEnvironment } from "@accurx/native";
import { useToggle } from "@accurx/navigation";
import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";
import { CrossWorkspaceSidebar } from "domains/inbox/components/Sidebar/CrossWorkspaceSidebar";
import { PatientMessagingSidebar } from "domains/inbox/components/Sidebar/PatientMessagingSidebar";
import { useViewportSize } from "domains/inbox/hooks/util";
import { useHasClinicianMessagingInbox } from "domains/inbox/hooks/util/useHasClinicianMessagingInbox";
import { MobileNavigationModal } from "layouts/UnifiedLayout/Mobile/MobileNavigationModal/MobileNavigationModal";
import { TabletNavigationDrawer } from "layouts/UnifiedLayout/Mobile/TabletNavigationDrawer/TabletNavigationDrawer";
import { UnifiedMobilePrimaryNavigation } from "layouts/UnifiedLayout/PrimaryNavigation/UnifiedMobilePrimaryNavigation";
import { UnifiedPrimaryNavigation } from "layouts/UnifiedLayout/PrimaryNavigation/UnifiedPrimaryNavigation";
import styled from "styled-components";

import { LayoutProps, PATIENT_SEARCH_PANEL_ROOT_ID } from "./InboxLayout";
import { StyledGrid, StyledQuickViewContainer } from "./Layout.styles";
import { CentrePane, LeftPane, RightPane } from "./components";
import { LayoutContext } from "./context";

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;
`;

export const UnifiedInboxLayout = (
    props: LayoutProps & { children: ReactNode },
) => {
    const leftPane = useToggle();
    const rightPane = useToggle();
    const quickView = useQuickViewContext();
    const viewport = useViewportSize();
    const env = useBrowserEnvironment();
    const hasClinicianMessagingInbox = useHasClinicianMessagingInbox();

    const isWebView = env === "WebView";
    const isMobile = ["xs", "sm"].includes(viewport);
    const isTablet = "md" === viewport;
    const isDesktop = ["lg", "xl"].includes(viewport);

    const secondaryNavigation = hasClinicianMessagingInbox ? (
        <CrossWorkspaceSidebar />
    ) : (
        <PatientMessagingSidebar />
    );

    return (
        <StyledLayout>
            {!isWebView && !isMobile && <UnifiedPrimaryNavigation />}
            <LayoutContext.Provider value={{ leftPane, rightPane }}>
                <StyledGrid
                    $rightPaneIsOpen={rightPane.isOpen}
                    $quickViewIsOpen={quickView.isOpen}
                >
                    {isDesktop && <LeftPane>{secondaryNavigation}</LeftPane>}
                    <CentrePane>{props.children}</CentrePane>
                    <RightPane>{props.rightPane}</RightPane>
                    <StyledQuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
                </StyledGrid>
            </LayoutContext.Provider>
            {!isWebView && isMobile && <UnifiedMobilePrimaryNavigation />}

            {isMobile && (
                <MobileNavigationModal
                    open={leftPane.isOpen}
                    onOpenChange={leftPane.setIsOpen}
                >
                    {secondaryNavigation}
                </MobileNavigationModal>
            )}

            {isTablet && (
                <TabletNavigationDrawer
                    isOpen={leftPane.isOpen}
                    toggleOpen={leftPane.setIsOpen}
                >
                    {secondaryNavigation}
                </TabletNavigationDrawer>
            )}

            <div id={PATIENT_SEARCH_PANEL_ROOT_ID} />
        </StyledLayout>
    );
};
