import { Ds, Flex, Link, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPageWrapper = styled(Flex)`
    background: ${Tokens.COLOURS.greyscale.white};
    flex: 1;
    overflow: auto;
`;

export const StyledMain = styled(Flex)`
    padding: ${Tokens.SIZES[3]};
    width: 100%;
`;

export const StyledCardList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    margin: 0;
    padding: 0;
`;

export const StyledCardItem = styled(Flex)`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    padding: ${Tokens.SIZES[1.5]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledWorkspaceDetails = styled(Flex)`
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    align-items: flex-start;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        flex-direction: row;
        align-items: center;
        gap: 0;
    }
`;

// this is a copy of StyledTwoThirdsContainer
// from /app/layout/TwoThirdsContainer

export const StyledTwoThirdsContainer = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        width: calc(100% * 2 / 3);
    }
`;

export const StyledLink = styled(Link)`
    display: inline-flex;
`;

export const StyledTopContainer = styled(Ds.Flex).attrs({
    flexDirection: "column",
})`
    gap: ${Tokens.SIZES[1]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;
