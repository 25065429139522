import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { Subscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { actions } from "domains/concierge/internal/store";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import { Event, SocketEvents } from "shared/hubClient/HubClient";

const mapToTeamSummary = (
    update: Event<"OnUserGroupChanged">,
    currentUserId: string,
): TeamSummary | undefined => {
    if (isNil(update.payload.organisationId)) {
        return undefined;
    }

    if (isNil(update.payload.userMembership)) {
        return undefined;
    }

    const isMember = !!update.payload.userMembership.find(
        (membership) => membership.userId === currentUserId,
    )?.userHasConfirmedMemberShip;

    return mapUserGroupToTeamSummary({
        id: update.payload.userGroup?.id,
        name: update.payload.userGroup?.name,
        type: update.payload.userGroup?.groupType,
        isMember,
    });
};

export const OnUserGroupChanged: Subscription = ({
    transport,
    dispatch,
    getState,
    workspaceId,
    currentUserId,
}) => {
    const subscription = transport.subscribe({
        methodName: SocketEvents.OnUserGroupChanged,
        eventFilter: (event) => event.payload.organisationId === workspaceId,
        onEvent: (event) => {
            const teamUpdate = mapToTeamSummary(event, currentUserId);
            if (!teamUpdate) return;
            const beforeState = getState();

            dispatch(
                actions.processUpdates({
                    source: "SignalR:OnUserGroupChanged",
                    teams: [teamUpdate],
                    users: [],
                    patients: [],
                    conversations: [],
                }),
            );
            const afterState = getState();

            // When team membership changes we need to recalculate unread counts
            // because patient unread counts may have changed. If the user
            // toggled notifications on the current device then we will have
            // already recalculated unread counts. So only recalculate them if
            // this is a background update and the membership state in the
            // update does not match the membership state we currently have.
            const currentUserMembershipChanged =
                beforeState.conversations.teamMembership[teamUpdate.id] !==
                afterState.conversations.teamMembership[teamUpdate.id];
            if (currentUserMembershipChanged) {
                dispatch(actions.conversations.recalculateUnreadCounts());
            }
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
