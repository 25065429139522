import React, { ReactNode } from "react";

import { useToggle } from "@accurx/navigation";
import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";

import { MAIN_CONTENT_ID } from "app/layout/Layout.constants";
import { SkipToContentLink } from "app/layout/skipToContentLink";
import { PORTAL_ID_NAV_SUBMENU } from "app/navbar/NavSubMenuComponent";
import { useSimplifiedNavigation } from "store/hooks";

import { MobileLayoutContext } from "./Mobile/MobileLayoutContext";
import { MobileNavigationModal } from "./Mobile/MobileNavigationModal/MobileNavigationModal";
import { TabletNavigationDrawer } from "./Mobile/TabletNavigationDrawer/TabletNavigationDrawer";
import { MobileHeader } from "./MobileHeader/MobileHeader";
import { UnifiedMobilePrimaryNavigation } from "./PrimaryNavigation/UnifiedMobilePrimaryNavigation";
import { UnifiedPrimaryNavigation } from "./PrimaryNavigation/UnifiedPrimaryNavigation";
import { UnifiedSimplePrimaryNavigation } from "./PrimaryNavigation/UnifiedSimplePrimaryNavigation";
import { UnifiedSecondaryNavigation } from "./SecondaryNavigation/UnifiedSecondaryNavigation";
import {
    PageContainer,
    QuickViewContainer,
    StyledLayout,
    StyledMainContent,
    StyledQuickViewWrapper,
    StyledWrapper,
} from "./UnifiedLayout.styles";

const MainContent = ({ children }: { children?: ReactNode }) => {
    const quickView = useQuickViewContext();

    return (
        <StyledMainContent id={MAIN_CONTENT_ID}>
            <StyledQuickViewWrapper $quickViewIsOpen={quickView.isOpen}>
                <PageContainer>
                    <div id={PORTAL_ID_NAV_SUBMENU}></div>
                    {children}
                </PageContainer>
                <QuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
            </StyledQuickViewWrapper>
        </StyledMainContent>
    );
};

export const UnifiedLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();

    return (
        <StyledLayout data-testid="unified-layout">
            <SkipToContentLink />
            {isSimpleNav ? (
                <UnifiedSimplePrimaryNavigation />
            ) : (
                <UnifiedPrimaryNavigation />
            )}
            <StyledWrapper>
                {!isSimpleNav && <UnifiedSecondaryNavigation />}
                <MainContent>{children}</MainContent>
            </StyledWrapper>
        </StyledLayout>
    );
};

export const UnifiedTabletLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();
    const secondaryNav = useToggle();

    return (
        <MobileLayoutContext.Provider value={{ secondaryNav }}>
            <StyledLayout data-testid="unified-tablet-layout">
                <SkipToContentLink />
                {isSimpleNav ? (
                    <UnifiedSimplePrimaryNavigation />
                ) : (
                    <UnifiedPrimaryNavigation />
                )}
                <MobileHeader />
                <StyledWrapper>
                    <MainContent>{children}</MainContent>
                </StyledWrapper>
            </StyledLayout>
            <TabletNavigationDrawer
                isOpen={secondaryNav.isOpen}
                toggleOpen={secondaryNav.setIsOpen}
            >
                <UnifiedSecondaryNavigation />
            </TabletNavigationDrawer>
        </MobileLayoutContext.Provider>
    );
};

export const UnifiedMobileLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();
    const secondaryNav = useToggle();

    return (
        <MobileLayoutContext.Provider value={{ secondaryNav }}>
            <StyledLayout data-testid="unified-mobile-layout">
                <SkipToContentLink />
                <MobileHeader showHelp />
                <StyledWrapper>
                    <MainContent>{children}</MainContent>
                </StyledWrapper>
                {!isSimpleNav && <UnifiedMobilePrimaryNavigation />}
            </StyledLayout>
            <MobileNavigationModal
                open={secondaryNav.isOpen}
                onOpenChange={secondaryNav.setIsOpen}
            >
                <UnifiedSecondaryNavigation />
            </MobileNavigationModal>
        </MobileLayoutContext.Provider>
    );
};
