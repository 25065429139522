/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface FeedbackData {
    processKey?: string | null;
    user?: string | null;
    text?: string | null;
    feedbackType?: FeedbackType | null;
    /** @format date-time */
    time?: string | null;
    /** @format int32 */
    organisationId?: number | null;
}

export enum FeedbackType {
    None = 0,
    Bug = 1,
    Improvement = 2,
    ClinicalContent = 3,
    FeedbackWindow = 4,
    HappyFaceSummary = 5,
    SadFaceSummary = 6,
    ReferChain = 7,
}

export interface InboxInitialiseRequest {
    workspace_id?: string | null;
    admin?: Admin | null;
    conversation?: Conversation | null;
    customer?: Customer | null;
}

export interface Admin {
    type?: string | null;
    id?: string | null;
    name?: string | null;
    email?: string | null;
    away_mode_enabled?: boolean | null;
    away_mode_reassign?: boolean | null;
    has_inbox_seat?: boolean | null;
    team_ids?: number[] | null;
}

export interface Conversation {
    type?: string | null;
    id?: string | null;
    /** @format int32 */
    created_at?: number | null;
    /** @format int32 */
    updated_at?: number | null;
    /** @format int32 */
    waiting_since?: number | null;
    snoozed_until?: any;
    conversation_message?: ConversationMessage | null;
    user?: User | null;
    customers?: ConversationCustomer[] | null;
    customer_first_reply?: CustomerFirstReply | null;
    assignee?: Assignee | null;
    open?: boolean | null;
    state?: string | null;
    read: boolean;
    tags?: Tags | null;
    conversation_parts?: ConversationParts | null;
    conversation_rating?: ConversationRating | null;
}

export interface ConversationMessage {
    type?: string | null;
    id?: string | null;
    delivered_as?: string | null;
    subject?: string | null;
    body?: string | null;
    author?: Author | null;
    attachments?: any[] | null;
    url?: any;
}

export interface Author {
    type?: string | null;
    id?: string | null;
    name?: string | null;
    email?: string | null;
}

export interface User {
    type?: string | null;
    id?: string | null;
}

export interface ConversationCustomer {
    type?: string | null;
    id?: string | null;
}

export interface CustomerFirstReply {
    /** @format int32 */
    created_at?: number | null;
    type?: string | null;
    url?: string | null;
}

export interface Assignee {
    type?: string | null;
    id?: any;
}

export interface Tags {
    type?: string | null;
    tags?: any[] | null;
}

export interface ConversationParts {
    type?: string | null;
    conversation_parts?: ConversationPart[] | null;
    /** @format int32 */
    total_count?: number | null;
}

export interface ConversationPart {
    type?: string | null;
    id?: string | null;
    part_type?: string | null;
    body?: string | null;
    /** @format int32 */
    created_at?: number | null;
    /** @format int32 */
    updated_at?: number | null;
    /** @format int32 */
    notified_at?: number | null;
    assigned_to?: any;
    author?: ConversationPartAuthor | null;
    attachments?: any[] | null;
    external_id?: any;
}

export interface ConversationPartAuthor {
    id?: string | null;
    type?: string | null;
    name?: string | null;
    email?: string | null;
}

export interface ConversationRating {
    rating?: any;
    remark?: any;
    created_at?: any;
    customer?: ConversationCustomer | null;
    teammate?: Teammate | null;
}

export interface Teammate {
    type?: string | null;
    id?: string | null;
}

export interface Customer {
    type?: string | null;
    id?: string | null;
    user_id?: string | null;
    anonymous?: boolean | null;
    email?: string | null;
    phone?: any;
    name?: any;
    pseudonym?: string | null;
    avatar?: Avatar | null;
    app_id?: string | null;
    companies?: Companies | null;
    location_data?: LocationData | null;
    /** @format date-time */
    last_request_at?: string | null;
    /** @format date-time */
    created_at?: string | null;
    remote_created_at?: any;
    signed_up_at?: any;
    /** @format date-time */
    updated_at?: string | null;
    /** @format int32 */
    session_count: number;
    social_profiles?: SocialProfiles | null;
    unsubscribed_from_emails?: boolean | null;
    marked_email_as_spam?: boolean | null;
    has_hard_bounced?: boolean | null;
    tags?: Tags | null;
    segments?: Segments | null;
    custom_attributes?: CustomAttributes | null;
    referrer?: string | null;
    utm_campaign?: any;
    utm_content?: any;
    utm_medium?: any;
    utm_source?: any;
    utm_term?: any;
    do_not_track?: any;
}

export interface Avatar {
    type?: string | null;
    image_url?: string | null;
}

export interface Companies {
    type?: string | null;
    companies?: any[] | null;
}

export interface LocationData {
    type?: string | null;
    city_name?: string | null;
    continent_code?: string | null;
    country_name?: string | null;
    postal_code?: string | null;
    region_name?: string | null;
    timezone?: string | null;
    country_code?: string | null;
}

export interface SocialProfiles {
    type?: string | null;
    social_profiles?: any[] | null;
}

export interface Segments {
    type?: string | null;
    segments?: any[] | null;
}

export type CustomAttributes = object;

export type SubmitRequest = InboxInitialiseRequest & {
    component_id?: string | null;
    current_canvas?: any;
    input_values?: any;
};

export interface OrganisationComputerIndexViewModel {
    practice?: PracticeViewModel | null;
    userCanEdit?: boolean | null;
    computers?: OrganisationComputerViewModel[] | null;
    numberOfComputers?: string | null;
}

export interface PracticeViewModel {
    /** @format int32 */
    id: number;
    name?: string | null;
    /** @format int32 */
    customTemplateLimit: number;
}

export interface OrganisationComputerViewModel {
    machineIdentifier?: string | null;
    computerLocation?: string | null;
    telephoneExtension?: string | null;
    computerName?: string | null;
}

export interface OrganisationResourceResponse {
    /** @format int32 */
    id: number;
    fileName: string;
    title?: string | null;
    type?: OrganisationResourceType | null;
    uploadedBy: string;
    /** @format date-time */
    uploadedDate: string;
    downloadUrl: string;
    uploadedByCurrentUser: boolean;
}

export enum OrganisationResourceType {
    Map = "Map",
    Other = "Other",
    AdviceAndGuidance = "AdviceAndGuidance",
    Instructions = "Instructions",
}

export enum OrganisationResourceType2 {
    Map = 0,
    Other = 1,
    AdviceAndGuidance = 2,
    Instructions = 3,
}

export interface AllOrganisationResources {
    resources: OrganisationResourceResponse[];
}

export interface OrganisationResourceRequest {
    /** @format int32 */
    resourceId: number;
    /** @format int32 */
    organisationId: number;
}

export interface OrganisationSetupIndexViewModel {
    practice?: PracticeViewModel | null;
    hasApprovalRights: boolean;
    approvedUsers: OrganisationSetupUserViewModel[];
    unapprovedUsers: OrganisationSetupUserViewModel[];
    archivedUsers: OrganisationSetupUserViewModel[];
}

export interface OrganisationSetupUserViewModel {
    /** @format int32 */
    userId: number;
    name?: string | null;
    email?: string | null;
    role?: string | null;
    confirmedEmail: boolean;
    userRoleState?: UserPermissionsStateOfUserRole | null;
    userStatusState?: UserPermissionsStateOfUserStatus | null;
}

export interface UserPermissionsStateOfUserRole {
    currentValue: UserRole;
    allowedChanges: UserRole[];
}

export enum UserRole {
    Member = 0,
    Admin = 1,
    Visitor = 2,
}

export interface UserPermissionsStateOfUserStatus {
    currentValue: UserStatus;
    allowedChanges: UserStatus[];
}

export enum UserStatus {
    Unapproved = 0,
    Approved = 1,
    Archived = 2,
    Deleted = 3,
}

export interface UserStatusChange {
    /** @format int32 */
    userId: number;
    status: UserStatus;
}

export interface UserRoleChange {
    /** @format int32 */
    userId: number;
    role: UserRole;
}

export interface AddVaccineUserRequest {
    /**
     * @format email
     * @minLength 1
     */
    emailAddress: string;
}

export interface PrescriptionSettings {
    isEnabled?: boolean | null;
    isRedirectEnabled?: boolean | null;
    redirectSettings?: RedirectSettings | null;
}

export interface RedirectSettings {
    title?: string | null;
    url?: string | null;
    isPrescriptionFormEnabled?: boolean | null;
}

export interface PatientTriageStatus {
    isAdmin: boolean;
    enabled: boolean;
    url?: string | null;
    /** @format int32 */
    responseTimeWorkingDays: number;
    outOfHoursEnabled: boolean;
    welcomePageMessage?: string | null;
    submissionPageMessage?: string | null;
    submissionPageSms?: string | null;
    outOfHoursPageMessage?: string | null;
    /** @format date-time */
    enableByDate?: string | null;
    /** @format date-time */
    makePublicByDate?: string | null;
    /** @format date-time */
    disableUntilDate?: string | null;
    allowAdminRequests?: boolean | null;
    disabledMessageHeading?: string | null;
    disabledMessageBody?: string | null;
    outOfHoursSettings?: PatientTriageUpdateScheduleOutOfHoursSettings | null;
    patientSelfReferralSettings: PatientTriagePatientSelfReferralSettings;
    prescriptionSettings: PrescriptionSettings;
    prescriptionRedirectEnabled: boolean;
    prescriptionRedirectUrl?: string | null;
    prescriptionRedirectTitle?: string | null;
    workingHoursSettings?: WorkingHoursSettingsResponse | null;
    region?: string | null;
}

export interface PatientTriageUpdateScheduleOutOfHoursSettings {
    /** @format int32 */
    outOfHoursDisableFromHour: number;
    /** @format int32 */
    outOfHoursDisableToHour: number;
    outOfHoursSuspendPatientTriage: boolean;
    outOfHoursAllowAdminRequests: boolean;
}

export interface PatientTriagePatientSelfReferralSettings {
    patientSelfReferralEnabled?: boolean | null;
    patientSelfReferralMessage?: string | null;
}

export interface WorkingHoursSettingsResponse {
    allowAdminOutOfHours: boolean;
    excludeAdminFromLimit: boolean;
    suspendRequestsOutOfHours: boolean;
    workingHours: WorkingHoursDaySettings[];
    bankHolidayWorkingHours: BankHolidayWorkingHours;
    suspensionMessageBody: string;
    suspensionMessageHeader: string;
}

export type WorkingHoursDaySettings = PatientInitiatedWorkingHours & {
    day: DayOfWeek;
};

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export interface PatientInitiatedWorkingHours {
    from?: string | null;
    to?: string | null;
    /** @format int32 */
    limit?: number | null;
}

export type BankHolidayWorkingHours = PatientInitiatedWorkingHours & object;

export interface PatientTriageDisableUntilRequest {
    /** @format date-time */
    disableUntil?: string | null;
    allowAdminRequests?: boolean | null;
    reason?: string | null;
    comment?: string | null;
}

export interface PatientTriageUpdateDisabledMessageRequest {
    disabledMessageHeading?: string | null;
    disabledMessageBody?: string | null;
}

export interface PatientTriageUpdateWelcomePageRequest {
    welcomePageMessage?: string | null;
}

export interface PatientTriageUpdateSubmissionPageRequest {
    submissionPageMessage?: string | null;
}

export interface PatientTriageUpdateSubmissionSmsRequest {
    submissionPageSms?: string | null;
}

export interface PatientTriageUpdateOutOfHoursPageRequest {
    outOfHoursPageMessage?: string | null;
}

export interface PatientTriageUsageData {
    /** @format date-time */
    date: string;
    type: string;
    topic: string;
    matchState: string;
    createdBy: string;
}

export interface PatientTriageDashboardData {
    usage: PatientTriageDashboardRow[];
}

export interface PatientTriageDashboardRow {
    /** @format date-time */
    date: string;
    type: string;
    isReception: boolean;
}

export enum DateType {
    Fortnightly = 0,
    Monthly = 1,
    Yearly = 2,
}

export interface PatientInitiatedWorkingHoursSettingsRequest {
    allowAdminOutOfHours?: boolean | null;
    excludeAdminFromLimit?: boolean | null;
    suspendRequestsOutOfHours?: boolean | null;
    workingHours: WorkingHoursDaySettings[];
    bankHolidayWorkingHours: BankHolidayWorkingHours;
}

export interface PatientInitiatedWorkingHoursSuspensionMessageRequest {
    messageHeader: string;
}

export interface PowerBIReportViewModel {
    id: string;
    name: string;
    embedUrl: string;
    token: string;
    pages: Record<string, string>;
}

export interface PracticeListing {
    /** @format int32 */
    id: number;
    name: string;
    nationalCode: string;
    approved: boolean;
    admin: boolean;
    isResident: boolean;
    currentUserAnalyticsId: string;
    features: Feature[];
    modules: Modules;
    principalSystemsLast7Days: string[];
}

export interface Feature {
    name?: string | null;
    isEnabled?: boolean | null;
}

export interface Modules {
    sms: string;
    floreyPlus: boolean;
    batchAndAppointmentReminders: boolean;
    videoConsultations: boolean;
    patientTriage: boolean;
}

export interface SmsSettingsViewModel {
    practice?: PracticeViewModel | null;
    senderId?: string | null;
    statusMessage?: string | null;
}

export interface SenderIdUpdateViewModel {
    /**
     * @minLength 1
     * @pattern [a-zA-Z0-9]{3,11}
     */
    senderId: string;
}

export interface SearchForPatientResponse {
    searched: boolean;
    searchedResult?: SearchedResult | null;
    searchedNhsNumber?: string | null;
}

export interface SearchedResult {
    matchFound: boolean;
    patientToken?: string | null;
    patient?: Patient | null;
}

export type Patient = PatientSearchResultBase & {
    deceased: boolean;
    /** @format int32 */
    ageYear: number;
    mobileNumber?: string | null;
    practiceCode?: string | null;
    practiceName?: string | null;
    practicePhoneNumber?: string | null;
    practiceAddress?: string | null;
    featuresAvailable: Feature[];
    recordViewSupported: GPRequestResult;
};

export enum GPRequestResult {
    None = "None",
    Success = "Success",
    FailureNoValidMobile = "FailureNoValidMobile",
    FailureNoOrganisation = "FailureNoOrganisation",
    FailureNoActiveUsers = "FailureNoActiveUsers",
    FailureGpNotOptedIn = "FailureGpNotOptedIn",
    FailureNotGP = "FailureNotGP",
    FailureNoRecentConnection = "FailureNoRecentConnection",
    FailureNotInUserOrganisation = "FailureNotInUserOrganisation",
    FailureNoApprovedUsersWithFeatureEnabled = "FailureNoApprovedUsersWithFeatureEnabled",
    FailureUnsupportedSystem = "FailureUnsupportedSystem",
}

export interface PatientSearchResultBase {
    displayName?: string | null;
    firstName?: string | null;
    familyName?: string | null;
    prefixName?: string | null;
    gender?: string | null;
    nhsNumber?: string | null;
    dateOfBirth?: string | null;
    ageDisplay?: string | null;
}

export interface SearchForPatientByNhsNumberRequest {
    /** @minLength 1 */
    nhsNumber: string;
    /** @format int32 */
    dateOfBirthYear: number;
    /** @format int32 */
    dateOfBirthMonth: number;
    /** @format int32 */
    dateOfBirthDay: number;
    /** @format int32 */
    organisationId?: number | null;
    isTestPatient?: boolean | null;
    isUserAction?: boolean | null;
}

export interface SearchForPatientByDemographicsRequest {
    /** @minLength 1 */
    firstName: string;
    /** @minLength 1 */
    lastName: string;
    /** @minLength 1 */
    postcode: string;
    /** @format int32 */
    dateOfBirthYear: number;
    /** @format int32 */
    dateOfBirthMonth: number;
    /** @format int32 */
    dateOfBirthDay: number;
    /** @minLength 1 */
    gender: string;
    /** @format int32 */
    organisationId?: number | null;
}

export interface ReplaySearchForPatientResponse {
    isValid: boolean;
    searchedResult?: SearchedResult | null;
}

export interface ReplaySearchForPatientRequest {
    /** @format int32 */
    organisationId?: number | null;
    patientToken?: string | null;
}

export interface PdsSearchResultDemographics {
    didMatchPatient: boolean;
    nhsNumber: string;
    names?: PatientName[] | null;
    gender?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    mobileNumber?: string | null;
    isPatientDeceased?: boolean | null;
}

export interface PatientName {
    use: PatientNameUse;
    prefix?: string[] | null;
    given?: string[] | null;
    family?: string[] | null;
}

export enum PatientNameUse {
    None = 0,
    Usual = 1,
    Official = 2,
    Temp = 3,
    Nickname = 4,
    Anonymous = 5,
    Old = 6,
    Maiden = 7,
}

export interface PdsSearchRequest {
    /** @format int32 */
    organisationId?: number | null;
    nhsNumber?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
}

export interface TeamsData {
    teams: Team[];
    practiceUsers: PracticeUser[];
    isEditable: boolean;
}

export interface Team {
    /** @format int32 */
    id?: number | null;
    name?: string | null;
    users: TeamUser[];
}

export interface TeamUser {
    /** @format int32 */
    id?: number | null;
    isMember: boolean;
}

export interface PracticeUser {
    /** @format int32 */
    id?: number | null;
    name?: string | null;
    email?: string | null;
}

export interface UpdateOrganisationTeamMembership {
    /** @format int32 */
    userId: number;
    /** @format int32 */
    teamId: number;
    isMember?: boolean | null;
}

export interface UsersAndTeamsResult {
    users: UserInfo[];
    teams: TeamInfo[];
}

export interface UserInfo {
    /** @format int32 */
    id: number;
    name: string;
    email: string;
    role: UserRole2;
    status: UserStatus2;
}

export enum UserRole2 {
    Member = "Member",
    Admin = "Admin",
    Visitor = "Visitor",
}

export enum UserStatus2 {
    Unapproved = "Unapproved",
    Approved = "Approved",
    Archived = "Archived",
    Deleted = "Deleted",
}

export interface TeamInfo {
    /** @format int32 */
    id: number;
    displayName: string;
    groupType: UserGroupType;
    identityName: string;
}

export enum UserGroupType {
    Manual = "Manual",
    FailedDeliveryReceipts = "FailedDeliveryReceipts",
    FloreyCondition = "FloreyCondition",
    PatientSingleResponse = "PatientSingleResponse",
    ToAssign = "ToAssign",
    DefaultRoles = "DefaultRoles",
    PracticeGroup = "PracticeGroup",
}

export interface UserWorkspaceSpecialtyResponse {
    /** @format int32 */
    id: number;
    /** @format int32 */
    workspaceId: number;
    specialty: string;
}

export interface UserWorkspaceSpecialtyRequest {
    /** @format int32 */
    workspaceId: number;
    userMessage?: string | null;
    /** @format int32 */
    specialtyId: number;
}

export interface ViewPatientTokenUnprotectResponse {
    nhsNumber?: string | null;
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    dateOfBirthDay?: number | null;
    /** @format int32 */
    dateOfBirthMonth?: number | null;
    /** @format int32 */
    dateOfBirthYear?: number | null;
}

export interface ViewPatientTokenUnprotectRequest {
    /** @minLength 1 */
    token: string;
}

export interface AllWorkspaceInvitesResponse {
    invites: WorkspaceInviteResponse[];
}

export interface WorkspaceInviteResponse {
    /** @format int32 */
    id: number;
    email: string;
}

export interface CreateWorkspaceInviteRequest {
    /** @minLength 1 */
    inviteeEmailAddress: string;
}
