import {
    WorkspaceCreationRequest,
    WorkspaceCreationResponse,
} from "@accurx/api/account";
import { Organisation, useCurrentUser } from "@accurx/auth";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

const ENDPOINT = "/api/account/v2/workspaces";

const createWorkspace = (
    request: WorkspaceCreationRequest,
): Promise<IWrappedResult<WorkspaceCreationResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({ path: ENDPOINT }),
        request,
    );

type createWorkspaceMutationVariables = Omit<
    WorkspaceCreationRequest,
    "nationalCode"
> & { nationalCode?: string };

export const useCreateWorkspaceByNationalCodeMutation = (
    options?: UseMutationOptions<
        WorkspaceCreationResponse,
        Error,
        createWorkspaceMutationVariables
    >,
) => {
    const dispatch = useDispatch();
    const { update, user } = useCurrentUser();
    const { setSelectedOrgId } = useSelectedOrganisation();

    return useMutation<
        WorkspaceCreationResponse,
        Error,
        createWorkspaceMutationVariables
    >({
        mutationKey: ["CreateWorkspace"],
        mutationFn: async ({
            nationalCode,
            name,
            specialities,
            description,
            isExample,
            isRecommended,
        }) => {
            if (!nationalCode) {
                throw new Error(
                    "No national code provided to create a workspace",
                );
            }
            const response = await createWorkspace({
                nationalCode,
                name,
                specialities,
                description,
                isExample,
                isRecommended,
            });

            return returnDataOrThrow(response);
        },
        ...options,
        onSuccess: (...args) => {
            const { workspace } = args[0];

            // it's very unlikely to happen, but as orgId is nullable in DTO, let's log an error
            if (!workspace.orgId)
                throw new Error(
                    "Something went wrong while creating a workspace",
                );

            const organisations = [
                ...user.organisations,
                workspace as Organisation,
            ];
            update({ ...user, organisations });
            // Whilst we should avoid Redux dependencies in the domain
            // architecture, this is an exception because a lot of components
            // still rely on the list of workspaces in Redux being up-to-date
            dispatch({ type: "ORGANISATIONS_UPDATE", organisations });
            setSelectedOrgId(workspace.orgId);

            // Workaround for dealing with `onSuccess` callback in `mutate` not
            // firing due to premature unmounting in E2E tests
            // https://tkdodo.eu/blog/mastering-mutations-in-react-query#some-callbacks-might-not-fire
            options?.onSuccess && options.onSuccess(...args);
        },
    });
};
