import { Organisation } from "@accurx/auth";
import { Text, Tokens } from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    PendingApprovalButton,
    WORKSPACE_MANAGEMENT_PRIMARY_ROUTES,
    isMyWorkspace,
    supportsMultipleWorkspaces,
    usePendingApproval,
} from "@accurx/workspace-management";
import { useLocation } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import styled from "styled-components";

import { StyledHeaderText } from "app/layout/PageHeader.styles";
import { ROUTES_PRIMARY } from "shared/Routes";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";
import {
    useAppSelector,
    useCurrentUserFullName,
    useCurrentWorkspace,
    useSimplifiedNavigation,
} from "store/hooks";

import { AccountNavigation } from "./AccountNavigation";
import { BatchMessagesTrustNavigation } from "./BatchMessagesTrustNavigation";
import { PatientsNavigation } from "./PatientsNavigation";
import { QuestionnaireScoreboardNavigation } from "./QuestionnaireScoreboardNavigation";
import {
    StyledFooterUserDetailsContainer,
    StyledPendingApprovalContainer,
    StyledSecondaryNavigation,
} from "./SecondaryNavigation.styles";
import { SimplifiedNavigation } from "./SimplifiedNavigation";
import { WorkspaceConversationsNavigation } from "./WorkspaceConversationsNavigation";
import { WorkspaceNavigation } from "./WorkspaceNavigation";

const LinkList = ({ onClick }: { onClick: () => void }): JSX.Element => {
    const matchWorkspaceConversations = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.workspaceConversations],
    });
    const matchAccount = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.account],
    });
    const matchWorkspaceSettings = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.workspaceSettings],
    });
    const matchPatients = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.patients],
    });
    // Trust wide
    const matchBatchMessageTrust = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.batchMessageTrust],
    });
    const matchQuestionnaireScoreboard = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.questionnaireScoreboard],
    });

    const isFlexibleWorkspaceRoute = !!useRouteMatchOrTwoFactorPrompt({
        path: [WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding],
    });

    const isSimpleNav = useSimplifiedNavigation();
    const location = useLocation();

    // Putting this first as some of the simple URLs start with /account
    if (isSimpleNav || isFlexibleWorkspaceRoute) {
        return <SimplifiedNavigation />;
    }

    if (matchWorkspaceConversations) {
        return <WorkspaceConversationsNavigation onClick={onClick} />;
    }

    if (matchAccount) {
        return <AccountNavigation onClick={onClick} />;
    }

    if (matchWorkspaceSettings) {
        return <WorkspaceNavigation onClick={onClick} />;
    }

    if (matchQuestionnaireScoreboard) {
        return <QuestionnaireScoreboardNavigation onClick={onClick} />;
    }

    if (matchPatients) {
        return <PatientsNavigation onClick={onClick} />;
    }

    if (matchBatchMessageTrust) {
        return <BatchMessagesTrustNavigation onClick={onClick} />;
    }

    Log.info(`No secondary navigation defined`, {
        tags: {
            logger: "SecondaryNavigation",
            for: location.pathname,
        },
    });
    return <PatientsNavigation onClick={onClick} />;
};

const FooterUserDetails = (): JSX.Element => {
    const username = useCurrentUserFullName();
    const workspace = useCurrentWorkspace();
    const defaultOrgName = workspace?.organisationNodeName;

    const workspaceName =
        workspace && isMyWorkspace(workspace as Organisation)
            ? "My Workspace"
            : workspace?.organisationName;

    const isFlexibleWorkspacesEnabled =
        workspace && supportsMultipleWorkspaces(workspace as Organisation);

    return (
        <StyledFooterUserDetailsContainer>
            {Boolean(username) && <Text skinny>{username}</Text>}
            {Boolean(workspaceName) && workspaceName !== "Unknown" && (
                <>
                    <Text variant="label" skinny>
                        {workspaceName}
                    </Text>
                    {isFlexibleWorkspacesEnabled && (
                        <Text variant="note">{defaultOrgName}</Text>
                    )}
                </>
            )}
        </StyledFooterUserDetailsContainer>
    );
};

export const SecondaryNavigation = ({
    linkOnClick,
    onClose,
}: {
    linkOnClick?: () => void;
    onClose?: () => void;
}): JSX.Element | null => {
    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    const { selectedOrgId } = useSelectedOrganisation();

    const { isPendingApproval } = usePendingApproval(selectedOrgId);

    if (!isLoggedIn) {
        return null;
    }

    return (
        <StyledSecondaryNavigation aria-label="Secondary navigation">
            <LinkList onClick={() => linkOnClick?.()} />
            <FooterUserDetails />
            {isPendingApproval && (
                <StyledPendingApprovalContainer>
                    <PendingApprovalButton onClick={onClose} />
                </StyledPendingApprovalContainer>
            )}
        </StyledSecondaryNavigation>
    );
};

export const SecondaryNavigationHeader = styled(StyledHeaderText).attrs(() => ({
    forwardedAs: "h2",
    variant: "subtitle",
    skinny: true,
}))`
    padding-top: ${Tokens.SIZES[3]};
    padding-left: ${Tokens.SIZES[1.5]};
    padding-right: ${Tokens.SIZES[1.5]};
`;

export const SecondaryNavigationSubHeader = styled(StyledHeaderText).attrs(
    () => ({
        forwardedAs: "p",
        variant: "body",
        skinny: true,
    }),
)`
    padding-left: ${Tokens.SIZES[1.5]};
    padding-right: ${Tokens.SIZES[1.5]};
`;

export const SecondaryNavigationContent = styled.div`
    padding-left: ${Tokens.SIZES[1.5]};
    padding-right: ${Tokens.SIZES[1.5]};
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;
