import { Fields } from "@accurx/analytics";
import { ConversationItem } from "@accurx/concierge/types";

type ConversationDisplayStatus = Extract<
    Fields,
    {
        key: "conversationDisplayStatus";
    }
>["value"];

export const getConversationDisplayStatus = ({
    item,
    userId,
}: {
    item: ConversationItem;
    userId: string;
}): ConversationDisplayStatus | undefined => {
    if (item.contentType === "Note" && item.createdBy.type === "User") {
        return userId === item.createdBy.id ? "NoteSent" : "NoteReceived";
    }

    if (item.contentType === "FailedDeliveryReceiptLink") return "NotDelivered";

    if (item.contentType === "ClinicianMessage") return "ClinicianMessage";

    if (item.createdBy.type === "Patient") return "FromPatient";
    if (item.createdBy.type === "User") return "ToPatient";

    return undefined;
};
