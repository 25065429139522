import { useEffect } from "react";

import { AppSettings, Log } from "@accurx/shared";
import { useLocation } from "react-router";

import {
    FeatureFlagOverrideValues,
    convertOverridesToString,
    setFeatureOverrides,
} from "../utils/features";

/**
 * Ability to override feature flags via query params in the URL.
 * The overrides will be persisted through the entire session via `sessionStorage` (i.e. it will be reset after closing the browser tab)
 *
 * To override a feature, use the `featuresOn` or `featuresOff` params followed by a comma separated list
 * of feature names to override them to be On or Off respectively.
 *
 * Example:
 * `accurx.com?featuresOn=MyFeature,MyOtherFeature&featuresOff=FeatureIDontWant,OtherFeatureIDontWant`
 *
 * `MyFeature` & `MyOtherFeature` will be `On` and `FeatureIDontWant` & `OtherFeatureIDontWant` will be `Off`.
 */
export const useFeatureQueryParamOverrideListener = (): void => {
    const location = useLocation();

    useEffect(() => {
        if (AppSettings.accurxEnvironment === "production") return;

        const params = new URLSearchParams(location.search);
        const overrides: FeatureFlagOverrideValues = {};

        params.getAll("featuresOn").forEach((v) => {
            const flags = v.split(",");
            flags.forEach((flag) => {
                overrides[flag] = true;
            });
        });

        params.getAll("featuresOff").forEach((v) => {
            const flags = v.split(",");
            flags.forEach((flag) => {
                overrides[flag] = false;
            });
        });

        if (Object.keys(overrides).length > 0) {
            Log.info("Feature flags overridden via query param 🚩", {
                tags: {
                    overrides: convertOverridesToString(overrides),
                },
            });

            for (const [featureName, isEnabled] of Object.entries(overrides)) {
                setFeatureOverrides({
                    featureName,
                    isEnabled,
                });
            }
        }
    }, [location.search]);
};
