import { Tokens } from "@accurx/design";
import { SizeVariants } from "@accurx/design/dist/styles/types";
import styled, { css } from "styled-components";

import { Colours } from "../../styles/colours";

export const SIDENAV_WIDTH = "250px";

export const StyledSidenav = styled.nav`
    background-color: ${Colours.midnightGreen};
    color: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: calc(${Tokens.SIZES[1]} - 3px);
    overflow: hidden;

    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        width: ${SIDENAV_WIDTH};
    }
`;

export const StyledTitle = styled.h1`
    margin: 0;
    color: ${Tokens.COLOURS.greyscale.white};
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    padding: ${Tokens.SIZES[1.5]} 0px ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

export const StyledSubtitle = styled.h2`
    margin: 0;
    color: ${Tokens.COLOURS.greyscale.white};
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: ${Tokens.SIZES[1.5]} 0px ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export const StyledFooter = styled.div`
    flex: 0;
    padding-top: ${Tokens.SIZES[1]};
`;

export const StyledDividerContainer = styled.div<{
    $spacing: `${SizeVariants}`;
}>`
    padding: ${(props) => `${Tokens.SIZES[props.$spacing]} 0`};
`;

export const StyledDivider = styled.hr`
    border: 1px solid ${Tokens.COLOURS.primary.green[130]};
    margin: 0;
`;

export const applyFocusStyle = css`
    outline: none;
    background-color: ${Tokens.COLOURS.primary.green[100]};
    box-shadow: 0px 0px 0px 2px ${Tokens.COLOURS.greyscale.white},
        0px 0px 0px 4px ${Tokens.COLOURS.primary.blue[100]};
`;

export const applyHoverStyle = css`
    text-decoration: none;
    background-color: ${Tokens.COLOURS.primary.green[130]};
    border: 1px solid ${Tokens.COLOURS.primary.green[100]};
`;
