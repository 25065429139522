import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPrimaryNavigation = styled.nav`
    background-color: ${Tokens.COLOURS.primary.green[100]};
    color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;

export const StyledThreeColumnGrid = styled.div`
    gap: ${Tokens.SIZES[1]};

    @media (max-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        justify-content: space-between;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const StyledNavLinkList = styled.ul`
    align-items: center;
    display: flex;
    gap: ${Tokens.SIZES[1]};
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;
