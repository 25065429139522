import * as UI from "@accurx/design";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { AutoFocusHeading } from "domains/inbox/components/AutoFocusHeading/AutoFocusHeading";
import { PatientName } from "domains/inbox/components/PatientName/PatientName";
import { formatDateShortWithHyphens } from "domains/inbox/util/format/date";

import { StyledPatientDetails } from "./PatientHeader.styles";

type PatientDetailsProps = {
    nhsNumber?: string;
    dateOfBirth: string;
    name: string;
    ageYear: number;
    gender: string;
};

export const PatientDetails = ({
    nhsNumber,
    dateOfBirth,
    name,
    ageYear,
    gender,
}: PatientDetailsProps) => {
    return (
        <UI.Flex flexDirection="column" gap="1">
            <UI.Item>
                <AutoFocusHeading as="h2" skinny>
                    <PatientName
                        name={name}
                        ageYear={ageYear}
                        gender={gender}
                        textProps={{
                            variant: "subtitle",
                        }}
                    />
                </AutoFocusHeading>
            </UI.Item>
            <StyledPatientDetails
                justifyContent="flex-start"
                flexDirection="row"
            >
                {nhsNumber && (
                    <UI.Item>
                        <UI.Text skinny colour="metal">
                            {`NHS: ${formatNhsNumber(nhsNumber)}`}
                        </UI.Text>
                    </UI.Item>
                )}
                {!!dateOfBirth && (
                    <UI.Item>
                        <UI.Text skinny colour="metal">
                            {`DOB: ${formatDateShortWithHyphens(dateOfBirth)}`}
                        </UI.Text>
                    </UI.Item>
                )}
            </StyledPatientDetails>
        </UI.Flex>
    );
};
