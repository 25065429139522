import { ROUTES_WORKSPACE } from "shared/Routes";

export type ReportsLinks = {
    [reportName: string]: {
        name: string;
        description: string;
        path: string;
    };
};

export const REPORTS_LINKS: ReportsLinks = {
    PatientTriage: {
        name: "Patient Triage",
        description:
            "Use this report to better understand and plan for your organisation's inbound demand.",
        path: ROUTES_WORKSPACE.reportingPatientTriage,
    },
    ResponseRates: {
        name: "Response rates: Questionnaires & Self-Book",
        description:
            "Use this report to better understand the performance of the questionnaires you send, and the appointments that you offer.",
        path: ROUTES_WORKSPACE.reportingResponseRates,
    },
    Fragments: {
        name: "Fragments",
        description:
            "Use this report to better understand the number of fragments and SMS messages sent by your organisation, as well as insights about other messaging channels (e.g. the NHS App).",
        path: ROUTES_WORKSPACE.reportingFragments,
    },
    TeamUsage: {
        name: "Team usage",
        description:
            "Use this report to see how many texts have been sent by your organisation and each team member.",
        path: ROUTES_WORKSPACE.reportingTeamUsage,
    },
} as const;
