import { ReactNode } from "react";

import { StyledInfoBoxText } from "./InfoBoxText.styles";

export type TextColour = "night" | "metal" | "orange";

export const InfoBoxText = ({
    colour,
    children,
}: {
    colour: TextColour;
    children: ReactNode;
}) => {
    return <StyledInfoBoxText $colour={colour}>{children}</StyledInfoBoxText>;
};
