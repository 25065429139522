import { useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import {
    Compose,
    ContactDetail,
    OnMessageSendFn,
    useIsComposing,
} from "@accurx/compose";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useSendMessageMutation } from "@accurx/concierge/hooks/mutations/useSendMessageMutation";
import { Conversation, ConversationItem } from "@accurx/concierge/types";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import { useMedicalRecordConnection } from "@accurx/native";
import { useSearchForPatientQuery } from "domains/inbox/hooks/queries/useSearchForPatientQuery";
import { isTestPatient } from "domains/inbox/util";
import { formatAssigneeDisplayName } from "domains/inbox/util/format/names";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { toast } from "react-toastify";

import { SaveToRecordAction } from "../../../SaveToRecordAction/SaveToRecordAction";
import { ComposeNote } from "../ComposeNote/ComposeNote";
import { ReplyProps } from "./Reply.types";
import { ReplyFailedFeedback } from "./ReplyFailedFeedback";
import { ReplyTabs } from "./ReplyTabs";
import { SaveToRecordFailedFeedback } from "./SaveToRecordFailedFeedback";
import { useIsReplyPatientSameAsEmrPatient } from "./useIsReplyPatientSameAsEmrPatient";
import {
    QuestionnaireTrackingFields,
    TemplateTrackingFields,
    useReplyAnalytics,
} from "./useReplyAnalytics";
import { useSendUserIsTyping } from "./useSendUserIsTyping";

const formatPatientResponseAssigneeLabel = (
    conversationAssigneeType: Conversation["assignee"]["type"],
    mappedAssignee: ReturnType<typeof useAssignee>,
): string => {
    // If there is no assignee, response is going back to sender
    if (conversationAssigneeType === "None") {
        return "You";
    }
    return formatAssigneeDisplayName(mappedAssignee);
};

type ReplyContentsProps = Pick<
    ReplyProps,
    "selectedMessageAction" | "conversation" | "patient"
> & {
    /**
     * Callback for when we want to completely close (not just minimise)
     * Reply component
     */
    close: () => void;
    setSentNoteStatus: (status: string | null) => void;
};

export const ReplyContents = ({
    patient,
    conversation,
    contactDetails,
    close,
    selectedMessageAction,
    setSentNoteStatus,
}: ReplyContentsProps & { contactDetails: ContactDetail[] }) => {
    const isComposing = useIsComposing();
    const isReplyPatientSameAsCurrentMedicalRecordPatient =
        useIsReplyPatientSameAsEmrPatient({ replyPatient: patient });

    const mappedAssignee = useAssignee(conversation.assignee);
    const patientResponseAssigneeLabel = formatPatientResponseAssigneeLabel(
        conversation.assignee.type,
        mappedAssignee,
    );

    const mutation = useSendMessageMutation();

    const trackSendMessageResponse = useReplyAnalytics();

    const [saveToRecordData, setSaveToRecordData] = useState<{
        conversationItem: ConversationItem;
        conversationSource: Conversation["source"];
        snomedCodes: string[];
    }>();
    // Medical record
    const connection = useMedicalRecordConnection();

    const { data: patientToken } = useSearchForPatientQuery({
        patient,
        select: (data) => data.searchedResult.patientToken,
        enabled: false,
        staleTime: Infinity,
    });
    const workspace = useCurrentWorkspace();

    // User typing
    const sendUserIsTyping = useSendUserIsTyping();
    const trySendViaNhsApp = useFeatureFlag(FeatureName.SendSmsViaNhsApp);

    const handleMessageSend: OnMessageSendFn = (args) => {
        // We only save to record if we're connected to EMR
        // and it allows to save to record
        // and compose is set to save to record
        const shouldSaveToRecord =
            connection.status === "Connected" &&
            connection.capabilities.saveToRecord &&
            args.isSaveToRecordEnabled;

        mutation.mutate(
            {
                workspaceId: workspace.orgId,
                patient,
                patientToken,
                conversation,
                shouldSaveToRecord,
                mobileNumber:
                    args.contactDetails.method === "Mobile"
                        ? args.contactDetails.value
                        : null,
                /** We only need to send this value if the number we want to send the message to is from the EMR.
                 * This is so the server knows to do a fresh EMR query at the time of sending the message,
                 * in the case of scheduled messages. If this value is not set, or is not equal to the mobileNumber
                 * we send, then the number from the EMR will not be used.
                 */
                demographicsMobileNumber:
                    args.contactDetails.method === "Mobile" &&
                    args.contactDetails.origin === "EMR"
                        ? args.contactDetails.value
                        : null,
                emailAddress:
                    args.contactDetails.method === "Email"
                        ? args.contactDetails.value
                        : null,
                messageBody: args.messageBody,
                messageSignature: args.messageSignature,
                enablePatientResponse: args.isPatientResponseEnabled,
                attachments: args.attachments,
                template: args.template.value ? args.template : null,
                useMobileNumberFromPds: args.contactDetails.origin === "PDS",
                sendTime: args.sendAt ? args.sendAt.sendAtDateTime : null,
                trySendViaNhsApp,
                nhsChoicesLink: args.nhsAdviceLink,
                selfBook: args.selfBookLink
                    ? {
                          slotType: args.selfBookLink.slotType,
                          type: args.selfBookLink.appointmentType,
                          clinicianGroupIds:
                              args.selfBookLink.specificClinician?.map(
                                  (clinician) => clinician.clinicianGroupId,
                              ),
                          targetOdsCode:
                              args.selfBookLink.crossOrgBooking?.targetOdsCode,
                          timeoutDays: args.selfBookLink.timeoutDays,
                      }
                    : null,
            },
            {
                onSuccess: ({ updatedConversation }, variables) => {
                    if (variables.shouldSaveToRecord) {
                        const snomedCodes = args.template.value?.snomedCodes
                            ? args.template.value.snomedCodes.map(
                                  ({ id }) => id,
                              )
                            : [];

                        setSaveToRecordData({
                            conversationItem: updatedConversation.items[0],
                            conversationSource: updatedConversation.source,
                            snomedCodes,
                        });
                    } else {
                        close();
                    }
                },
                onError: (err) => {
                    if (err.message === "Save to record error! Item missing") {
                        toast(<SaveToRecordFailedFeedback />);

                        close();
                    } else {
                        toast(<ReplyFailedFeedback />, { autoClose: false });
                    }
                },
                onSettled: (_, err, variables) => {
                    const templateTrackingFields: TemplateTrackingFields =
                        args.template.type === "MessageTemplate"
                            ? {
                                  withTemplate: true,
                                  isPresetTemplate:
                                      args.template.value.owner === "Accurx",
                                  templateName: args.template.value.title,
                                  templateGroup: args.template.value.heading,
                              }
                            : { withTemplate: false };

                    const questionnaireTrackingFields: QuestionnaireTrackingFields =
                        args.template.type === "QuestionnaireTemplate"
                            ? {
                                  withFlorey: true,
                                  isPresetTemplate:
                                      args.template.value.owner === "Accurx",
                                  floreyName: args.template.value.title,
                              }
                            : { withFlorey: false };

                    trackSendMessageResponse({
                        conversationId: getConversationServerId(conversation),
                        countAttachmentFromTemplate: variables.template
                            ? variables.attachments.length
                            : 0,
                        isMobileNumberOverridden:
                            args.contactDetails.origin === "UserInput" &&
                            args.contactDetails.method === "Mobile",
                        isTestPatient: isTestPatient(
                            patientNhsNumber(patient.externalIds),
                        ),
                        messageLength: args.characterCount,
                        countFragment: args.fragmentCount,
                        messageType: variables.mobileNumber ? "sms" : "email",
                        withAttachment: !!variables.attachments.length,
                        withPatientResponse: variables.enablePatientResponse,
                        hasError: !!err,
                        enabledSaveToMedicalRecord:
                            variables.shouldSaveToRecord,
                        withUnicodeCharacter: !!args.isUnicode,
                        withSchedule: !!variables.sendTime,
                        withNHSAdviceLink: !!variables.nhsChoicesLink,
                        ...templateTrackingFields,
                        ...questionnaireTrackingFields,
                    });
                },
            },
        );
    };

    const [noteText, setNoteText] = useState<string>("");

    const isComposingNote = noteText.length > 0;

    return (
        <ReplyTabs
            selectedMessageAction={selectedMessageAction}
            isComposing={isComposing}
            isComposingNote={isComposingNote}
            conversation={conversation}
        >
            <ReplyTabs.ReplyPanel>
                <Compose
                    assigneeLabel={patientResponseAssigneeLabel}
                    contactDetails={contactDetails}
                    patient={patient}
                    patientMatchesCurrentMedicalRecordPatient={
                        isReplyPatientSameAsCurrentMedicalRecordPatient.isSamePatient
                    }
                    conversationId={getConversationServerId(conversation)}
                    onMessageSend={handleMessageSend}
                    isMessageSending={mutation.isLoading}
                    onUserTyping={() => {
                        sendUserIsTyping({
                            workspaceId: workspace.orgId,
                            conversationId: conversation.id,
                            patientId: patient.patientId,
                        });
                    }}
                    isHeightRestricted
                />
                {saveToRecordData && (
                    <SaveToRecordAction
                        conversationSource={saveToRecordData.conversationSource}
                        patientId={patient.patientId}
                        patientExternalIds={patient.externalIds}
                        contentType={
                            saveToRecordData.conversationItem.contentType
                        }
                        itemServerId={
                            saveToRecordData.conversationItem.serverId
                        }
                        onSaveToRecordInitiated={close}
                        snomedCodeIds={saveToRecordData.snomedCodes}
                    />
                )}
            </ReplyTabs.ReplyPanel>
            <ReplyTabs.NotesPanel>
                <ComposeNote
                    noteText={noteText}
                    setNoteText={setNoteText}
                    conversation={conversation}
                    patient={patient}
                    closeNotePanel={close}
                    setSentNoteStatus={setSentNoteStatus}
                    isHeightRestricted
                />
            </ReplyTabs.NotesPanel>
        </ReplyTabs>
    );
};
