import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Tokens } from "@accurx/design";
import { generatePath } from "react-router-dom";
import styled from "styled-components";

import { ROUTES, ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";

import { MobileNavButton, MobileNavLink } from "./MobileNavItem/MobileNavItem";
import { NewMessage } from "./NewMessage/NewMessage";
import { PatientSearch } from "./PatientSearch/PatientSearch";
import {
    StyledNavLinkList,
    StyledPrimaryNavigation,
} from "./UnifiedPrimaryNavigation.styles";
import { WorkspaceMenu } from "./WorkspaceMenu/WorkspaceMenu";

const StyledMobileNavLinkList = styled(StyledNavLinkList)`
    gap: ${Tokens.SIZES[2]};
`;

export const UnifiedMobilePrimaryNavigation = () => {
    const { orgId: workspaceId, organisationName: workspaceName } =
        useCurrentWorkspace();

    return (
        <StyledPrimaryNavigation data-testid="mobile-primary-nav">
            <StyledMobileNavLinkList>
                <li>
                    <NewMessage hideText />
                </li>
                <li>
                    <PatientSearch>
                        <MobileNavButton aria-label="Patient search">
                            <Ds.Icon name="Search" color="white" size="small" />
                        </MobileNavButton>
                    </PatientSearch>
                </li>
                <li>
                    <MobileNavLink
                        aria-label="Inbox"
                        activePaths={[ROUTES.inbox]}
                        to={generatePath(ROUTES.inbox, {
                            workspaceId,
                        })}
                    >
                        <Ds.Icon name="Mailbox" color="white" size="small" />
                    </MobileNavLink>
                </li>
                <li>
                    <MobileNavLink
                        aria-label="Cohorts"
                        activePaths={[
                            ROUTES_PRIMARY.patients,
                            ROUTES_PRIMARY.batchMessageTrust,
                            ROUTES_PRIMARY.questionnaireScoreboard,
                            ROUTES_BASE.integratedClinicListsAll,
                        ]}
                        to={ROUTES_BASE.patientsListsAll}
                    >
                        <Ds.Icon name="Team" color="white" size="small" />
                    </MobileNavLink>
                </li>
                <li>
                    <WorkspaceMenu showWorkspaceSwitcher>
                        <MobileNavButton aria-label={workspaceName}>
                            <Ds.Icon name="Cog" color="white" size="small" />
                        </MobileNavButton>
                    </WorkspaceMenu>
                </li>
            </StyledMobileNavLinkList>
        </StyledPrimaryNavigation>
    );
};
