import { Feature } from "./types/Feature";
import { useCurrentWorkspace } from "./useCurrentWorkspace";
import { isFeatureEnabledForWorkspace } from "./utils/organisation";

/** Returns true if the provided feature is enabled for the current workspace,
 * otherwise returns false.
 */
export const useFeatureFlag = (feature: Feature) => {
    const workspace = useCurrentWorkspace();

    return isFeatureEnabledForWorkspace(feature, workspace);
};
