import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { StyledFeedbackContent } from "./ConversationActionsFeedback.styles";
import { StyledFeedback } from "./PatientHeader.styles";
import { PatientMatchingActions } from "./PatientMatchingActions";

export const ConversationActionsFeedback = ({
    conversation,
    onClickSearchForPatient,
}: {
    conversation: Conversation;
    onClickSearchForPatient: () => void;
}) => {
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const patientMatchState = getPatientMatchState(conversation);

    if (conversation.status === "Done") {
        return (
            <StyledFeedback
                colour="information"
                title={"To reply or change assignee, reopen the conversation"}
            />
        );
    }

    if (!patient && patientMatchState?.type === "NoMatch") {
        return (
            <StyledFeedback
                colour="warning"
                title={
                    "For more message actions, search for a patient to match to this conversation"
                }
            >
                <StyledFeedbackContent>
                    <PatientMatchingActions
                        patientMatchState={patientMatchState}
                        conversation={conversation}
                        onClickSearchForPatient={onClickSearchForPatient}
                    />
                </StyledFeedbackContent>
            </StyledFeedback>
        );
    }

    if (!patient && patientMatchState?.type === "SuggestedMatch") {
        return (
            <StyledFeedback
                colour="warning"
                title={
                    <span>
                        For more conversation actions, please check and confirm
                        the suggested patient match.{" "}
                        <UI.Link
                            href="https://support.accurx.com/en/articles/4247693-patient-triage-how-does-patient-identification-work"
                            openInNewTab
                        >
                            <UI.Link.Text text="How we suggest patients" />
                            <UI.Link.Icon />
                        </UI.Link>
                    </span>
                }
            >
                <StyledFeedbackContent>
                    <PatientMatchingActions
                        patientMatchState={patientMatchState}
                        conversation={conversation}
                        onClickSearchForPatient={onClickSearchForPatient}
                    />
                </StyledFeedbackContent>
            </StyledFeedback>
        );
    }

    // Conversation is Open and is matched to a patient so we show nothing here.
    return null;
};
