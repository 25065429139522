import { AppSettings, Log } from "@accurx/shared";
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    IHttpConnectionOptions,
    LogLevel,
} from "@microsoft/signalr";
import { Primitive } from "@sentry/types";
import { BehaviorSubject, Subject, Subscribable } from "rxjs";

import { PatientThreadItemUpdate } from "api/FlemingDtos";
import { BehaviorSubscribable } from "shared/types/rxjs.types";

import { ConnectionState, ConnectionStateNew } from "./ConnectionState";
import {
    RECONNECT_ATTEMPTS,
    hubClientRetryPolicy,
} from "./hubClientRetryPolicy";
import {
    HubReconnectDataInbox,
    MachineChangeStatusReceive,
    NoteTypingReceive,
    PatientThreadUserGroupChanged,
    PatientThreadUserGroupChangedLegacy,
    ReconnectPushRequest,
    ThreadActiveReceive,
    VaccineInviteStatusPushUpdate,
    VaccineInviteStatusPushUpdateLegacy,
    VideoConsultationStatusChanged,
    VideoConsultationStatusChangedLegacy,
} from "./payload.types";

type ErrorWithExtras = Error & Partial<Record<string, Primitive>>;
const logHubException = (
    err: ErrorWithExtras,
    extras?: Record<string, Primitive>,
) => {
    if (extras) {
        for (const key in extras) {
            if (["name", "message", "stack"].indexOf(key) === -1) {
                err[key] = extras[key];
            }
        }
    }

    Log.error("HubClient#connect() exception", { originalException: err });
};

// exported for testing only - may be removed once BE switch has rolled out
export const mapLegacyVideoConsultationStatusChanged = (
    payload:
        | VideoConsultationStatusChanged
        | VideoConsultationStatusChangedLegacy,
): VideoConsultationStatusChanged => {
    if (payload.hasOwnProperty("patientListId")) {
        return payload as VideoConsultationStatusChanged;
    }
    const legacy = payload as VideoConsultationStatusChangedLegacy;
    return {
        consultationId: legacy.ConsultationId,
        patientListId: legacy.PatientListId,
    };
};

// exported for testing only - may be removed once BE switch has rolled out
export const mapLegacyPatientThreadUserGroupChanged = (
    payload:
        | PatientThreadUserGroupChanged
        | PatientThreadUserGroupChangedLegacy,
): PatientThreadUserGroupChanged => {
    if (payload.hasOwnProperty("organisationId")) {
        return payload as PatientThreadUserGroupChanged;
    }
    const legacy = payload as PatientThreadUserGroupChangedLegacy;
    return {
        organisationId: legacy.OrganisationId,
        userGroup: legacy.UserGroup && {
            groupType: legacy.UserGroup.GroupType,
            id: legacy.UserGroup.Id,
            name: legacy.UserGroup.Name,
        },
        userMembership: legacy.UserMembership?.map((x) => ({
            userId: x.UserId,
            userHasConfirmedMemberShip: x.UserHasConfirmedMemberShip,
        })),
    };
};

// exported for testing only - may be removed once BE switch has rolled out
export const mapLegacyVaccineInviteStatusPushUpdate = (
    payload: VaccineInviteStatusPushUpdate | number,
    legacyPayload: VaccineInviteStatusPushUpdateLegacy | undefined,
): VaccineInviteStatusPushUpdate => {
    if (typeof payload === "object") {
        return payload;
    }

    return {
        organisationId: payload,
        inviteId: legacyPayload?.InviteId ?? "",
        currentInviteStatus: legacyPayload?.CurrentInviteStatus ?? "",
    };
};

/**
 * The list of websocket events that the application is aware of and able to
 * receive. Note - this is *not* the same as the set of events that the server
 * is publishing, it is most likely a subset.
 *
 * In order to subscribe to and handle a websocket event, it needs to be listed here.
 */
export const SocketEvents = {
    OnVideoConsultationStatusChanged: "OnVideoConsultationStatusChanged",
    OnPatientThreadItemChanged: "OnPatientThreadItemChanged",
    UserAdded: "UserAdded", // PracticeUsers
    UserApprovalChanged: "UserApprovalChanged", // PracticeUsers
    UserRoleChanged: "UserRoleChanged", // PracticeUsers
    OnInviteStatusChanged: "OnInviteStatusChanged", // VaccineAllPatientsInvitedDetails
    OnUserGroupChanged: "OnUserGroupChanged",
    OnPatientThreadNoteTyping: "OnPatientThreadNoteTyping",
    OnThreadActive: "OnThreadActive",
    OnLatestHubConnectInbox: "OnLatestHubConnectInbox",
    OnMachineChangeStatus: "OnMachineChangeStatus",
    RegisterForReconnectPush: "RegisterForReconnectPush",
} as const;

/**
 * A dictionary of websocket events and the associated payload for each. If an
 * event is listed in the SocketEvents enum, it's payload must be described here.
 * This type is used to construct the types for the argument to the event callback
 * provided to HubClient#subscribe().
 */
export type SocketEventPayloads = {
    [SocketEvents.OnVideoConsultationStatusChanged]: VideoConsultationStatusChanged;
    [SocketEvents.OnPatientThreadItemChanged]: PatientThreadItemUpdate;
    [SocketEvents.UserAdded]: null; // Currently using as a trigger while ignoring payload
    [SocketEvents.UserApprovalChanged]: null; // Currently using as a trigger while ignoring payload
    [SocketEvents.UserRoleChanged]: null; // Currently using as a trigger while ignoring payload
    [SocketEvents.OnInviteStatusChanged]: VaccineInviteStatusPushUpdate;
    [SocketEvents.OnUserGroupChanged]: PatientThreadUserGroupChanged;
    [SocketEvents.OnPatientThreadNoteTyping]: NoteTypingReceive;
    [SocketEvents.OnThreadActive]: ThreadActiveReceive;
    [SocketEvents.OnMachineChangeStatus]: MachineChangeStatusReceive;
    [SocketEvents.RegisterForReconnectPush]: ReconnectPushRequest;
    [SocketEvents.OnLatestHubConnectInbox]: HubReconnectDataInbox;
};

/**
 * Depreciated: do not add to this type. See comment in type Event
 */
type SocketEventLegacyPayloads = {
    [SocketEvents.OnInviteStatusChanged]: VaccineInviteStatusPushUpdateLegacy;
};

/**
 * All supported event types
 */
export type Events = keyof typeof SocketEvents;

/**
 * A websocket event object
 * Most new event received only a single object payload which can contain several piece of info (ie: OnPatientThreadItemChanged, OnVideoConsultationStatusChanged)
 * Some older event is sending data in separate data field. OnInviteStatusChanged signature is "practiceId, VaccineInviteStatusPushUpdate"
 * We need to temporary support legacyPayload just for events that would send two dataField.
 */
export type Event<T extends Events> = {
    event: T;
    payload: SocketEventPayloads[T];
    legacyPayload?: SocketEventLegacyPayloads[typeof SocketEvents.OnInviteStatusChanged]; // We need to change OnInviteStatusChanged in the BE and remove this field
};

export type HubClient = {
    connect(): Promise<HubConnection | void>;
    disconnect(): Promise<void>;
    restartConnection(): Promise<void>;
    /**
     * If connected, sends a fire and forget message sent to signalr
     */
    send: (eventName: string, argument: object) => Promise<void>;

    get connectionStateSubscription(): Subscribable<ConnectionState>;

    get connectionStateSubscriptionNew(): BehaviorSubscribable<ConnectionStateNew>;

    getSubscription<T extends Events>(name: T): Subject<Event<T>>;
};

/**
 * A websocket event callback
 */
export type EventCallback<T extends Events> = (event: Event<T>) => void;

const FLEMING_CLIENT_LOCALHOST = "localhost:8001";
export const INITIAL_CONNECTION_TIMEOUT = 10_000;

export class HubClientImpl implements HubClient {
    private _connectionStarted?: Promise<void>;
    private _connection?: HubConnection;
    private _subjects = new Map<Events, Subject<Event<Events>>>();

    /**
     * This is the old connection state feed that the Web Inbox uses. It will be
     * deleted soon in favour of the new feed below.
     *
     * The new connection feed differs from the old connection feed in the
     * following ways:
     * 1. After two failed attempts to connect, it moves into the "disconnected" state
     * 2. It treats "connecting" status as "disconnected"
     *
     * TODO: cleanup with
     * https://linear.app/accurx/issue/GPWIN-409/delete-the-epoch-feed-and-clean-up-concierge-layer
     */
    private readonly _connectionStateSubject: Subject<ConnectionState> =
        new BehaviorSubject<ConnectionState>({
            state: "None",
        });

    get connectionStateSubscription(): Subscribable<ConnectionState> {
        return this._connectionStateSubject;
    }

    /**
     * The "New" connection feed. (we'll remove the "New" suffix once the old
     * feed is removed)
     */
    private readonly _connectionStateSubjectNew =
        new BehaviorSubject<ConnectionStateNew>({
            state: "Initialising",
        });

    get connectionStateSubscriptionNew(): BehaviorSubscribable<ConnectionStateNew> {
        return this._connectionStateSubjectNew;
    }

    connect(): Promise<HubConnection | void> {
        // if no connection has been kicked off, start it going, and resolve to it
        if (
            this._connectionStarted === undefined ||
            this._connection?.state === HubConnectionState.Disconnected
        ) {
            this.raiseConnecting();
            this._connectionStarted = this.tryStartHubSafe();
        }

        // a connection was already initiated, resolve to it
        return this._connectionStarted.then(() => this._connection);
    }

    async disconnect(): Promise<void> {
        await this._connection?.stop();
        this._connection = undefined;
        this._connectionStarted = undefined;
        this.raiseDisconnected(undefined);
        this.raiseDisconnectedNew();
    }

    /**
     * Drops the existing hub client connection and establishes a new one. Can be used when a users
     * permissions on the signalr changes (e.g. when moving from non-2FA to 2FA)
     */
    async restartConnection(): Promise<void> {
        await this.disconnect();
        await this.connect();
        // For each subject being tracked, ensure they are listening to events from the new connection
        this._subjects.forEach((subject, name) => {
            this.registerSubject(name, subject);
        });
    }

    async send(eventName: string, argument: object): Promise<void> {
        try {
            if (this._connection?.state === HubConnectionState.Connected) {
                await this._connection.send(eventName, argument);
            }
        } catch (err) {
            // Check if the hub is no longer connected which may be the cause of the error
            if (this._connection?.state === HubConnectionState.Connected) {
                Log.error("Error sending message to signalr", {
                    originalException: err,
                    context: [
                        {
                            name: "eventName",
                            data: { eventName },
                        },
                    ],
                });
            }
        }
    }

    /**
     * Obtain a subscription which you can use to subscribe to websocket events.
     *
     * Makes use of a function overload[1] to help narrow type arguments.
     * Helpfully pointed towards this solution by an answer on stackoverflow[2]
     *
     * [1] https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
     * [2] https://stackoverflow.com/questions/69891490/how-to-correctly-infer-t-for-an-rxjs-subjectt-from-generic-function-with-type
     *
     * @param {T} name
     * The name of the event to subscribe to. Must be one of the supported events
     * listed in the Events type
     */
    getSubscription<T extends Events>(name: T): Subject<Event<T>>;
    getSubscription(name: Events) {
        // if a subject for this event exists, return it
        const existingSubject = this._subjects.get(name);

        if (existingSubject) {
            return existingSubject;
        }

        // if a subject for this event doesn't exist, create one, push incoming
        // events to it, cache it, and return it
        const subject = new Subject<Event<Events>>();

        this.registerSubject(name, subject);

        this._subjects.set(name, subject);
        return subject;
    }

    private registerSubject(name: Events, subject: Subject<Event<Events>>) {
        this._connection?.on(
            name,
            (
                payload: SocketEventPayloads[Events],
                legacyPayload?: SocketEventLegacyPayloads[typeof SocketEvents.OnInviteStatusChanged],
            ) => {
                if (name === "OnVideoConsultationStatusChanged") {
                    subject.next({
                        event: name,
                        payload: mapLegacyVideoConsultationStatusChanged(
                            payload as VideoConsultationStatusChanged,
                        ),
                    });
                } else if (name === "OnUserGroupChanged") {
                    subject.next({
                        event: name,
                        payload: mapLegacyPatientThreadUserGroupChanged(
                            payload as PatientThreadUserGroupChanged,
                        ),
                    });
                } else if (name === "OnInviteStatusChanged") {
                    subject.next({
                        event: name,
                        payload: mapLegacyVaccineInviteStatusPushUpdate(
                            payload as VaccineInviteStatusPushUpdate,
                            legacyPayload,
                        ),
                    });
                } else {
                    subject.next({ event: name, payload, legacyPayload });
                }
            },
        );
    }

    /**
     * Allows us to fake SignalR being broken by setting an
     * `ACCURX_DISABLE_SIGNALR` cookie to `true`. Makes it easier for
     * us to test inbox behaviour with SignalR connection failures.
     */
    private isDisabled(): boolean {
        if (typeof document === "undefined") return false;

        if (document.cookie.match(/ACCURX_DISABLE_SIGNALR=true/)) {
            Log.warn(
                "HubClient is disabled because ACCURX_DISABLE_SIGNALR cookie is set",
            );
            return true;
        }

        if (sessionStorage.getItem("ACCURX_DISABLE_SIGNALR") === "true") {
            Log.warn(
                "HubClient is disabled because ACCURX_DISABLE_SIGNALR session storage is set",
            );
            return true;
        }

        return false;
    }

    private async tryStartHubSafe(attempts = 0): Promise<void> {
        try {
            this._connection = this.buildHubConnection();
            await this._connection.start();

            this.raiseConnected();
            this.raiseConnectedNew();
        } catch (err) {
            // we couldn't build the connection object, let alone try to start it
            if (this._connection === undefined) {
                this.raiseDisconnected(err);
                this.raiseDisconnectedNew(err);

                logHubException(err);

                throw err;
            }

            Log.info(`SignalR hub is ${this._connection?.state}`);

            // Timeout: If we're still initialising after three connection attempts
            // we emit a disconnected state. this lets consumers such as the concierge layer
            // know to continue initializing without expecting signalr to be available
            if (attempts === 2) {
                this.raiseDisconnectedNew(err);
                this.raiseDisconnected(err);

                Log.info(
                    `[${new Date().toISOString()}] Information: ${attempts} consecutive connection attempts failed.`,
                );

                logHubException(err, {
                    connectionId: this._connection?.connectionId,
                    connectionBaseUrl: this._connection?.baseUrl,
                    connectionState: this._connection?.state,
                    serverTimeoutMilliseconds:
                        this._connection?.serverTimeoutInMilliseconds,
                });
            }

            // if localhost:8001 connection 404s
            if (
                this._connection.baseUrl.includes(FLEMING_CLIENT_LOCALHOST) &&
                err.statusCode === 404
            ) {
                Log.info(
                    `[${new Date().toISOString()}] Information: Stopping further attempts to reconnect.`,
                );
                Log.info(
                    `[${new Date().toISOString()}] Information: Running on ${FLEMING_CLIENT_LOCALHOST} and ${
                        this._connection.baseUrl
                    } 404'd, assuming you don't need/want a connection.`,
                );

                // this is only a localhost case, so can safely ignore the error
                this.raiseConnected();
                this.raiseDisconnectedNew();
                return;
            }

            const delay = this.reconnectDelay(attempts);
            setTimeout(() => this.tryStartHubSafe(attempts + 1), delay);
        }
    }

    /**
     * RECONNECT_ATTEMPTS defines increasingly large intervals
     * at which to attempt reconnects. we get the interval to use
     * based on the current number of attempts to connect we've made;
     * if we've made more attempts than are defined in RECONNECT_ATTEMPTS,
     * then we repeatedly use the last (longest) value from it
     */
    private reconnectDelay(attempts: number): number {
        const index = Math.min(attempts, RECONNECT_ATTEMPTS.length - 1);
        return RECONNECT_ATTEMPTS[index];
    }

    /**
     * If we have explicit version information in the client app configuration, send it over to be
     * picked up via `HeaderHelpers.GetAppVersionDisplayStringFromHeaders`.
     */
    private buildExtraConnectionHeaders() {
        const appName = AppSettings.getWebClientAppName();
        const appVersion = AppSettings.getWebClientAppVersion();
        return appName && appVersion
            ? { "app-name": appName, "app-version": appVersion }
            : undefined;
    }

    protected buildHubConnection(): HubConnection {
        const connectionOptions: IHttpConnectionOptions = {
            headers: this.buildExtraConnectionHeaders(),
        };
        const connection = new HubConnectionBuilder()
            .withUrl(
                // if we have disabled SignalR in development mode
                // by setting the ACCURX_DISABLE_SIGNALR cookie to true,
                // we simulate a connection failure by giving the connection
                // a garbage url
                this.isDisabled()
                    ? "/push/intentionally-broken-for-testing"
                    : "/push/hub",
                connectionOptions,
            )
            .withAutomaticReconnect(hubClientRetryPolicy)
            // Enable logging with
            .configureLogging(
                process.env.NODE_ENV === "test"
                    ? LogLevel.None
                    : process.env.NODE_ENV === "production"
                    ? LogLevel.Error
                    : LogLevel.Information,
            )
            .build();

        connection.onreconnecting((error) => {
            Log.info("SignalR hub re-connecting");
            //Handle on re-connecting, might want to display a message
            this.raiseConnecting();
            this.raiseDisconnectedNew(error);
        });

        connection.onreconnected((connectionId) => {
            Log.info("SignalR hub re-connected");
            this.raiseConnected();
            this.raiseConnectedNew();
            //Handle on re-connected, might want to display a message
        });

        connection.onclose((error) => {
            Log.info("SignalR hub closed");
            this.raiseDisconnected(error);
            this.raiseDisconnectedNew(error);
            //Ask user to manually refresh page?
        });

        return connection;
    }

    private raiseConnecting(): void {
        this._connectionStateSubject.next({ state: "Connecting" });
    }

    private raiseConnected(): void {
        this._connectionStateSubject.next({ state: "Connected" });
    }

    private raiseDisconnected(error?: Error): void {
        this._connectionStateSubject.next({
            state: "Disconnected",
            error: error,
        });
    }

    /**
     * These methods post updates to the new connection state feed
     *
     * TODO: rename with
     * https://linear.app/accurx/issue/GPWIN-409/delete-the-epoch-feed-and-clean-up-concierge-layer
     */
    private raiseConnectedNew(): void {
        this._connectionStateSubjectNew.next({ state: "Connected" });
    }

    private raiseDisconnectedNew(error?: Error): void {
        this._connectionStateSubjectNew.next({
            state: "Disconnected",
            error: error,
        });
    }
}

/**
 * Do not use directly, exposed through TransportContextProvider (via useOptionalHubClient).
 */
export const createHubClient = (): HubClient => new HubClientImpl();
