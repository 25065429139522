import {
    PatientThreadContentType,
    PatientThreadItem,
    PatientThreadItemTransport,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import {
    mapNhsAppMessageItem,
    mapPatientEmailItem,
    mapPatientSmsItem,
} from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/patientMessage";
import { ConversationItem } from "domains/concierge/types";
import isNil from "lodash/isNil";

import {
    mapAppointmentRequest,
    mapEmailLinkItem,
    mapFloreyResponseItem,
    mapGenericNoteItem,
    mapLabelTagItem,
    mapNhsAppMessageLinkItem,
    mapPatientTriageItem,
    mapSmsLinkItem,
    mapStateChangeItem,
    mapUserNoteItem,
} from "./conversationItemMappers";

type BasePatientThreadItem = PatientThreadItem & {
    type: PatientThreadContentType;
};

/**
 * If we receive an item transport with a type we don't know about we try to
 * find some field in that transport that looks like a ticket item (i.e. it has
 * a string ID) so that we can at least log some details about that item.
 */
export function tryFindPatientThreadItem(
    itemTransport: PatientThreadItemTransport,
): BasePatientThreadItem | undefined {
    if (typeof itemTransport.type !== "number") {
        return undefined;
    }

    const potentialItems = Object.values(itemTransport).filter(
        (val): val is BasePatientThreadItem =>
            typeof val === "object" &&
            val != null &&
            "id" in val &&
            typeof val.id === "string",
    );
    return potentialItems.length === 1 ? potentialItems[0] : undefined;
}

/**
 * Maps from an external "Ticket" DTO version of a conversation item
 * to an internal domain view - A "Conversation item"
 * @param itemTransport - An external "Patient thread item" DTO
 * @returns A conversation item
 */
export function mapTicketItemToConversationItem(
    itemTransport: PatientThreadItemTransport,
): ConversationItem | undefined {
    if (isNil(itemTransport) || isNil(itemTransport.type)) {
        return undefined;
    }

    switch (itemTransport.type) {
        case PatientThreadContentType.SMS:
            return safeMapItem(
                mapPatientSmsItem,
                itemTransport.sms,
                itemTransport.type,
            );
        case PatientThreadContentType.PatientEmail:
            return safeMapItem(
                mapPatientEmailItem,
                itemTransport.patientEmail,
                itemTransport.type,
            );
        case PatientThreadContentType.NhsAppMessage:
            return safeMapItem(
                mapNhsAppMessageItem,
                itemTransport.nhsAppMessage,
                itemTransport.type,
            );
        case PatientThreadContentType.FloreyResponseNote:
            return safeMapItem(
                mapFloreyResponseItem,
                itemTransport.floreyResponseNote,
                itemTransport.type,
            );
        case PatientThreadContentType.PatientTriageRequestNote:
            return safeMapItem(
                mapPatientTriageItem,
                itemTransport.patientThreadTriageRequestNote,
                itemTransport.type,
            );
        case PatientThreadContentType.PatientAppointmentRequestNote:
            return safeMapItem(
                mapAppointmentRequest,
                itemTransport.patientThreadPatientAppointmentRequestNote,
                itemTransport.type,
            );
        case PatientThreadContentType.StateChange:
            return safeMapItem(
                mapStateChangeItem,
                itemTransport.ticketStateChangeNote,
                itemTransport.type,
            );
        case PatientThreadContentType.Note:
            return safeMapItem(
                mapUserNoteItem,
                itemTransport.note,
                itemTransport.type,
            );
        case PatientThreadContentType.SmsLinkNote:
            return safeMapItem(
                mapSmsLinkItem,
                itemTransport.smsLinkNote,
                itemTransport.type,
            );
        case PatientThreadContentType.PatientEmailLinkNote:
            return safeMapItem(
                mapEmailLinkItem,
                itemTransport.patientEmailLinkNote,
                itemTransport.type,
            );
        case PatientThreadContentType.NhsAppMessageLinkNote:
            return safeMapItem(
                mapNhsAppMessageLinkItem,
                itemTransport.nhsAppMessageLinkNote,
                itemTransport.type,
            );
        case PatientThreadContentType.GenericNote:
            return safeMapItem(
                mapGenericNoteItem,
                itemTransport.genericNote,
                itemTransport.type,
            );
        case PatientThreadContentType.LabelTag:
            return safeMapItem(
                mapLabelTagItem,
                itemTransport.conversationLabel,
                itemTransport.type,
            );

        default:
            return safeMapItem(
                // We don't know how to map this ticket item to a conversation
                // so we throw an error by default
                () => {
                    throw new Error("Unknown item type");
                },
                tryFindPatientThreadItem(itemTransport),
                itemTransport.type,
            );
    }
}

function safeMapItem<
    TSource extends BasePatientThreadItem,
    TItem extends ConversationItem,
>(
    mapper: (x: TSource) => TItem | undefined,
    ticketItem: TSource | null | undefined,
    itemType: PatientThreadContentType,
): TItem | undefined {
    try {
        if (!ticketItem) {
            Log.error("Unrecognized item content or null data", {
                tags: { product: "Inbox", itemType },
            });
            return;
        }

        return mapper(ticketItem);
    } catch (e) {
        const errorMessage = typeof e === "string" ? e : e.message;

        Log.error(errorMessage, {
            tags: {
                product: "Inbox",
                itemType: ticketItem?.type,
                itemId: ticketItem?.id,
            },
        });
    }
}
