import { validateNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { PatientExternalId } from "domains/compose/types";

const TEST_PATIENT_IDS = [
    "0000000000",
    "1111111111",
    "2222222222",
    "3333333333",
    "4444444444",
    "5555555555",
    "6666666666",
    "7777777777",
    "8888888888",
    "9999999999",
    "0123456789",
    "9876543210",
    "8000000008",
];

/**
 * Using custom isTestPatient function because we are
 * replicating backend enrolment validation functionality
 * and in this case we exclude questionnaires that are meant for
 * test patients only for non-test patient patients
 */
export const customIsTestPatient = (
    patientExternalIds: PatientExternalId[],
) => {
    const nhsNumber = patientExternalIds.find(
        (id) => id.type === "NhsNumber",
    )?.value;
    if (!nhsNumber) {
        return false;
    }

    // Consider test patients the ones which are one of the list above
    // or that don't have valid nhs numbers
    return (
        TEST_PATIENT_IDS.includes(nhsNumber) ||
        validateNhsNumber(nhsNumber).success === false
    );
};
