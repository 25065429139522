import { ReactNode, useMemo, useState } from "react";

import { useAuth } from "@accurx/auth";
import { Button, Ds, Popover, PopoverTrigger, Text } from "@accurx/design";
import {
    StyledPopoverContent,
    StyledPopoverItem,
    StyledPopoverItemWithSpaceBetween,
    TokenOverrides,
    useCustomIntercomLauncher,
} from "@accurx/navigation";
import { SupportUrls } from "@accurx/shared";
import styled from "styled-components";

const StyledTrigger = styled(Button).attrs({
    theme: "success",
    icon: {
        name: "Question",
        title: "Help",
    },
})`
    border-radius: ${TokenOverrides.BORDERS.radius};

    &[data-state="open"] {
        background-color: ${TokenOverrides.COLOURS.primary.green["130"]};
    }
`;

export const HelpPopover = ({
    resetIntercomOnUnmount = true,
    children,
}: {
    resetIntercomOnUnmount?: boolean;
    children?: ReactNode;
}) => {
    const { user } = useAuth();
    const { showIntercom } = useCustomIntercomLauncher({
        resetDefaultLauncherOnUnmount: resetIntercomOnUnmount,
    });

    const [popoverOpen, setPopoverOpen] = useState(false);

    const feedbackURL = useMemo(() => {
        if (!user.isLoggedIn) {
            return undefined;
        }

        const url = new URL(
            "https://survey.survicate.com/7c49dc274131f625/?p=intercom",
        );
        url.searchParams.set("first_name", user.fullName);
        url.searchParams.set("email", user.email);
        url.searchParams.set("uid", user.accuRxUserId);
        return url.toString();
    }, [user]);

    const handleContactSupport = () => {
        setPopoverOpen(false);
        showIntercom();
    };

    return (
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
            <PopoverTrigger asChild>
                {children || <StyledTrigger />}
            </PopoverTrigger>
            <StyledPopoverContent side="bottom" align="end">
                <StyledPopoverItemWithSpaceBetween
                    onClick={() => window.open(SupportUrls.Home, "_blank")}
                >
                    <Ds.Flex gap="1">
                        <Ds.Icon name="Hospital" size="small" color="stone" />
                        <Text skinny variant="label">
                            Help centre
                        </Text>
                    </Ds.Flex>
                    <Ds.Icon name="OpenWindow" size="small" color="metal" />
                </StyledPopoverItemWithSpaceBetween>
                <StyledPopoverItem onClick={handleContactSupport}>
                    <Ds.Icon name="Message" size="small" color="stone" />
                    <Text skinny variant="label">
                        Contact support
                    </Text>
                </StyledPopoverItem>
                {user.isLoggedIn && (
                    <StyledPopoverItemWithSpaceBetween
                        onClick={() => window.open(feedbackURL, "_blank")}
                    >
                        <Ds.Flex gap="1">
                            <Ds.Icon name="Survey" size="small" color="stone" />
                            <Text skinny variant="label">
                                Send feedback
                            </Text>
                        </Ds.Flex>
                        <Ds.Icon name="OpenWindow" size="small" color="metal" />
                    </StyledPopoverItemWithSpaceBetween>
                )}
            </StyledPopoverContent>
        </Popover>
    );
};
