import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { TextColour } from "./InfoBoxText";

const mapColour = (colour: TextColour) => {
    switch (colour) {
        case "night":
            return Tokens.COLOURS.greyscale.night;
        case "metal":
            return Tokens.COLOURS.greyscale.metal;
        case "orange":
            return Tokens.COLOURS.primary.orange[130];
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
            const exhaustive: never = colour;
    }
};

export const StyledInfoBoxText = styled(Text).attrs({
    variant: "preview",
    skinny: true,
})<{ $colour: TextColour }>`
    color: ${({ $colour }) => mapColour($colour)};
`;
