import { HttpClientUrl, Log, httpClient } from "@accurx/shared/dist";
import Bowser from "bowser";

/*
 * For context on Whereby browser, device and networking compatability see:
 * - https://whereby.helpscoutdocs.com/article/318-supported-devices.
 * - https://www.notion.so/accurx/Whereby-Snippets-237e8ccfa0234cdeb1677d0dd90d5b53
 */

enum WherebyBrowswerSupportLevel {
    OfficiallyFullySupported,
    LikelyFullySupported,
    OfficiallyPartiallySupported,
    AppRequired,
    LikelyNotSupported,
    NotSupported,
}

class VideoCompatibilityService {
    static isBrowserSupportedByWhereby(
        userAgent: string,
    ): [boolean, string | null] {
        const [level, solution] = this.getWherebyBrowserSupportLevel(userAgent);
        Log.info("Detected whereby support level", {
            tags: { wherebySupportLevel: WherebyBrowswerSupportLevel[level] },
        });
        return [
            level === WherebyBrowswerSupportLevel.OfficiallyFullySupported ||
                level === WherebyBrowswerSupportLevel.LikelyFullySupported ||
                level ===
                    WherebyBrowswerSupportLevel.OfficiallyPartiallySupported,
            solution,
        ];
    }

    static async isNetworkOk(): Promise<[boolean, string | null]> {
        try {
            const results = await Promise.all([
                this.withTestLogging("Whereby Accurx Subdomain Page", () =>
                    this.testWherebyAccuRxSubdomainPage(),
                ),
                this.withTestLogging("Whereby Stats WebSocket", () =>
                    this.testStatsWebSocket(),
                ),
                this.withTestLogging("Whereby Signal WebSocket", () =>
                    this.testSignalWebSocket(),
                ),
            ]);

            if (results.some((x) => x === false)) {
                Log.info("Detected network issues.");
                return [false, null];
            }
        } catch (error) {
            Log.error("Could not check network ok.", {
                originalException: error,
            });
            return [true, null];
        }

        Log.info("Detected no network issues.");
        return [true, null];
    }

    private static getWherebyBrowserSupportLevel(
        userAgent: string,
    ): [WherebyBrowswerSupportLevel, string | null] {
        const browser = Bowser.getParser(userAgent);

        //Key browsers we know are not supported
        if (browser.satisfies({ ios: { chrome: ">60" } })) {
            Log.info("Detected iOS Chrome");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Safari, iOS Chrome is not supported",
            ];
        }

        // Key browsers we know are officially supported.

        if (browser.satisfies({ chrome: ">79" })) {
            // Chrome releases every 6 weeks. Latest Chrome version is 80 as of 19/03/2020.
            Log.info("Detected recent version of Chrome.");
            return [WherebyBrowswerSupportLevel.OfficiallyFullySupported, null];
        } else if (browser.satisfies({ desktop: { firefox: ">=73" } })) {
            // Latest desktop Firefox version is 74 as of 19/03/2020.
            Log.info("Detected recent desktop version of Firefox.");
            return [WherebyBrowswerSupportLevel.OfficiallyFullySupported, null];
        }

        // Key browsers that aren't officially supported but we've tested

        if (browser.satisfies({ edge: ">79" })) {
            // First New/Chromium/Blink version of Edge was 79 released 07/02/2020.
            Log.info(
                "Detected recent version of New/Chromium/Blink version of Edge.",
            );
            return [WherebyBrowswerSupportLevel.LikelyFullySupported, null];
        } else if (browser.satisfies({ opera: ">=46" })) {
            Log.info("Detected recent version of opera.");
            return [WherebyBrowswerSupportLevel.OfficiallyFullySupported, null];
        } else if (browser.satisfies({ samsung_internet: ">=7" })) {
            Log.info("Detected recent Samsung Internet.");
            return [WherebyBrowswerSupportLevel.OfficiallyFullySupported, null];
        } else if (browser.satisfies({ "Android Browser": ">=7" })) {
            Log.info("Detected recent Android Browser.");
            return [WherebyBrowswerSupportLevel.OfficiallyFullySupported, null];
        }

        // Key browsers we know are likely not to be as well supported.

        if (browser.satisfies({ chrome: "<66" })) {
            // Chrome releases every 6 weeks. Latest Chrome version is 80 as of 19/03/2020.
            // So 66 about 2 years old and likely WebRTC experience would be impaired.
            Log.info("Detected old version of Chrome.");
            return [
                WherebyBrowswerSupportLevel.LikelyNotSupported,
                "Try updating Chrome to the latest version",
            ];
        }

        // Key browser we know are not supported.

        if (browser.getBrowserName() === Bowser.BROWSER_MAP.ie) {
            Log.info("Detected Internet Explorer.");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.getBrowserName() === Bowser.BROWSER_MAP.edge) {
            Log.info("Detected Legacy/EdgeHTML version of Edge.");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.satisfies({ desktop: { safari: "<=11" } })) {
            Log.info("Detected old desktop Safari.");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.satisfies({ samsung_internet: "<7" })) {
            Log.info("Detected old Samsung Internet.");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.satisfies({ "Android Browser": "<7" })) {
            Log.info("Detected old Android Browser");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.satisfies({ opera: "<46" })) {
            Log.info("Detected old version of Opera.");
            return [
                WherebyBrowswerSupportLevel.NotSupported,
                "Try using Chrome",
            ];
        } else if (browser.satisfies({ desktop: { firefox: "<45" } })) {
            Log.info("Detected old desktop version of Firefox.");
            return [
                WherebyBrowswerSupportLevel.LikelyNotSupported,
                "Try updating your browser",
            ];
        } else if (browser.satisfies({ ios: { safari: "<11" } })) {
            Log.info("Detected old mobile Safari.");
            return [WherebyBrowswerSupportLevel.NotSupported, null]; // Can be null default text on select product component is try another device
        }

        // Key browser we know are only partially supported.

        if (browser.satisfies({ safari: ">=13" })) {
            Log.info("Detected recent version of Safari.");
            return [
                WherebyBrowswerSupportLevel.OfficiallyPartiallySupported,
                null,
            ];
        }

        if (browser.satisfies({ desktop: { safari: ">=12" } })) {
            Log.info("Detected recent desktop version of Safari.");
            return [
                WherebyBrowswerSupportLevel.OfficiallyPartiallySupported,
                null,
            ];
        }

        // Key browsers that require an app

        if (browser.satisfies({ ios: { safari: "<13" } })) {
            // 11 and 12 at this point. We exclude below 11 and explicitly allow 13 and above
            Log.info("Detected app requiring version of Safari.");
            return [
                WherebyBrowswerSupportLevel.AppRequired,
                "You can continue and will need to install an app when prompted",
            ];
        }

        // There are so many browsers and versions out there we won't be able to accurately classify each one.
        // Ere on the side of letting browsers through and we'll update rules as we learn more.

        Log.info("Could not detect browser.", { tags: { userAgent } });
        return [WherebyBrowswerSupportLevel.LikelyFullySupported, null];
    }

    private static async withTestLogging(
        name: string,
        perform: () => Promise<boolean>,
    ): Promise<boolean> {
        Log.info(`Running test: ${name}`);
        const result = await perform();
        if (result) {
            Log.info(`Passed test: ${name}`);
        } else {
            Log.info(`Failed test: ${name}`);
        }
        return result;
    }

    // Ways to test below include network request blocking in Chrome and disabling AllowWherebyTests in CSP configuration.

    // Provides a good heuristic for whether API calls are likely to be blocked.
    // Can't test connectivity to API servers directly due to cross origin limitations.
    private static testWherebyAccuRxSubdomainPage(): Promise<boolean> {
        // Workaround for #inc-460-cannot-start-video-consults-from-patient-profile-page
        return Promise.resolve(true);

        // return await this.testHttpHtmlGet(
        //     AppSettings.getWherebyAccurxSubdomainPageUrl(),
        //     "Whereby",
        // );
    }

    // We've concretely seen these Web Sockets being blocked, e.g. at Blackpool by the Smoothwall Web Filter product.
    // When they were blocked, users would not be able to send or receive audio and video.
    private static async testStatsWebSocket(): Promise<boolean> {
        return await this.testWebSocket(
            "wss://rtcstats.appearin.net/accurx-pre-call-test",
        );
    }

    private static async testSignalWebSocket(): Promise<boolean> {
        return await this.testWebSocket(
            "wss://signal.appearin.net/protocol/socket.io/v1/?EIO=3&transport=websocket",
        );
    }

    private static async testHttpHtmlGet(
        url: string,
        successSubstring: string,
    ): Promise<boolean> {
        const httpClientUrl = {
            url,
            urlLogDescription: url,
        } as HttpClientUrl;
        const configureInit = (init: RequestInit) => {
            return {
                ...init,
                credentials: "omit",
            } as RequestInit;
        };
        const result = await httpClient.getReturnHtmlSafeAsync(
            httpClientUrl,
            false, //  DO NOT NOTIFY IF UNAUTHORIZED
            configureInit,
        );
        return (
            result.success &&
            result.result !== null &&
            result.result.includes(successSubstring)
        );
    }

    private static testWebSocket(url: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const webSocket = new WebSocket(url);

            const timeout = setTimeout(() => {
                webSocket.close();
                resolve(false);
            }, 30 * 1000);

            webSocket.onopen = (event) => {
                Log.info("WebSocket is open.");
                clearTimeout(timeout);
                webSocket.close();
                resolve(true);
            };

            webSocket.onerror = (event) => {
                Log.info("WebSocket error observed:", {
                    context: [{ name: "Websocket event", data: { ...event } }],
                });
                clearTimeout(timeout);
                webSocket.close();
                resolve(false);
            };
        });
    }
}

export default VideoCompatibilityService;
