import { PatientSummary } from "@accurx/concierge/types";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";

import { formatDateShortWithHyphens } from "./format/date";

export const generatePatientGreeting = (
    patient: Pick<
        PatientSummary,
        "ageYear" | "prefixName" | "familyName" | "firstName" | "nickname"
    >,
): string => {
    const preferredFirstName = patient.nickname ?? patient.firstName;

    if (patient.ageYear <= 7) {
        return preferredFirstName
            ? `Dear parent/guardian of ${preferredFirstName},`
            : "Dear parent/guardian,";
    }

    // Informal greeting
    if (patient.ageYear < 30) {
        if (preferredFirstName) {
            return `Hi ${preferredFirstName},`;
        }
        if (patient.prefixName && patient.familyName) {
            return `Hi ${patient.prefixName} ${patient.familyName},`;
        }
        return "Hi,";
    }

    // Formal greeting
    if (patient.prefixName && patient.familyName) {
        return `Dear ${patient.prefixName} ${patient.familyName},`;
    }
    if (preferredFirstName) {
        return `Dear ${preferredFirstName},`;
    }

    // Fallback greeting
    return "Dear patient,";
};

const getPatientNameForClinicianGreeting = ({
    firstName,
    familyName,
    prefixName,
}: PatientSummary) => {
    if (firstName && familyName) {
        return `${firstName} ${familyName}`;
    }

    if (familyName && prefixName) {
        return `${prefixName} ${familyName}`;
    }

    return "Patient";
};

export const generateClinicianGreeting = (patient?: PatientSummary): string => {
    if (!patient) {
        return "Dear Colleague,";
    }

    const patientName = getPatientNameForClinicianGreeting(patient);

    const dateOfBirth = patient.dateOfBirth
        ? formatDateShortWithHyphens(patient.dateOfBirth)
        : undefined;

    const nhsNumberExternalId = patient.externalIds.find(
        (externalId) => externalId.type === "NhsNumber",
    );
    const nhsNumber = nhsNumberExternalId
        ? formatNhsNumber(nhsNumberExternalId.value)
        : undefined;

    const patientDetails = [patientName, dateOfBirth, nhsNumber, patient.gender]
        .filter((v) => !!v) // Remove missing patient details e.g. empty DOB
        .join(", ");

    return `Dear Colleague,\nRE: ${patientDetails}`;
};
