import { ReactNode, createContext, useEffect, useState } from "react";

import { Button, Feedback, Tokens } from "@accurx/design";
import { Helpers, Log } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import styled from "styled-components";

export const VersionCheckerContext = createContext<{
    current?: number | null;
    latest?: number | null;
}>({});

const StyledButton = styled(Button)`
    margin-top: ${Tokens.SIZES[1]};
`;

export const VersionChecker = ({ children }: { children: ReactNode }) => {
    const [current, setCurrent] = useState<number | null>(null);
    const [latest, setLatest] = useState<number | null>(null);

    const { data } = useQuery({
        queryKey: ["VersionChecker"],
        queryFn: async () => {
            try {
                const result = await fetch("/version.txt", {
                    headers: { iframe: "false" },
                });
                const body = await result.text();
                try {
                    const matches = body.match(/version:\s*(\d*)/);
                    return matches && matches[1] ? parseInt(matches[1]) : null;
                } catch (e) {
                    Log.error("Failed to parse fleming-client version", {
                        tags: {
                            current,
                            latest,
                        },
                        originalException: e,
                    });
                }
            } catch (e) {
                if (!Helpers.isNetworkIssue(e)) {
                    Log.error(e);
                }
                return null;
            }
        },
        refetchInterval: 15 * 60 * 1000,
    });

    useEffect(() => {
        if (data !== undefined && data !== null) {
            setCurrent((current) => current ?? data);
            setLatest(data);
        }
    }, [data]);

    useEffect(() => {
        if (current && latest && current < latest) {
            Log.debug("New version detected. Asking user to refresh.", {
                tags: { latest, current },
            });
            toast(
                <Feedback title="Update available" colour="warning">
                    An essential update to Accurx Web is available. Please
                    finish what you are doing and refresh the page to update.
                    <br />
                    <StyledButton
                        onClick={() => window.location.reload()}
                        text="Refresh now"
                    />
                </Feedback>,
                {
                    autoClose: false,
                    onClose: () => {
                        // reset latest so we'll show the toast again
                        // on the next interval
                        setLatest(current);
                    },
                },
            );
        }
    }, [current, latest]);

    return (
        <VersionCheckerContext.Provider value={{ current, latest }}>
            {children}
        </VersionCheckerContext.Provider>
    );
};
