import { getEmbedMode } from "@accurx/native";
import { HttpError, Log, httpClient, httpClient2 } from "@accurx/shared";

import { reloadApp, restartApp } from "./appLifecycle";

const expected401Responses = [
    ["POST", "/api/account/login"],
    ["POST", "/api/account/weblogin"],
    ["POST", "/api/account/ConfirmEmail/Index"],
];

/**
 * This sets up some global listeners on our two Http Clients. If the API
 * returns a 401 response we take that as a sign that the user's session has
 * expired or they were never authorized. Either way we need them to
 * reauthorize.
 */
export const setupReAuthFlow = () => {
    const { embedMode } = getEmbedMode();

    const on401Response = () => {
        if (embedMode === "Desktop") {
            Log.error("User unauthorised in WebView2");
            window.alert(
                "Something went wrong, we'll need to reload the Inbox. \n\nPlease contact support if this problem continues.",
            );
            reloadApp();
        } else {
            window.alert(
                "You are about to be logged off as you haven't used Accurx for a while.\n\nPlease log in to continue.",
            );
            restartApp();
        }
    };

    /**
     * Any calls made through httpClient which trigger the
     * unauthorizedObservable should trigger the re-auth flow.
     */
    const httpClientSubscription = httpClient.unauthorizedObservable.subscribe(
        () => {
            on401Response();
        },
    );

    /**
     * With httpClient2 we can hook into all responses and look for any 401
     * responses. There are some endpoints we expect to receive 401 responses
     * from but anything else should trigger the re-auth flow.
     */
    const teardownHttpClient2Subscription =
        httpClient2.callbacks.onErrorResponse(({ init, request, error }) => {
            if (error instanceof HttpError && error.statusCode === 401) {
                const isExpected = expected401Responses.some(
                    ([method, url]) => {
                        return method === request.method && url === init.path;
                    },
                );

                if (!isExpected) {
                    on401Response();
                }
            }
        });

    /**
     * Return a teardown function
     */
    return () => {
        httpClientSubscription.unsubscribe();
        teardownHttpClient2Subscription();
    };
};
