import { useEffect, useMemo, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Feedback, Spinner, Text } from "@accurx/design";
import { useTransport } from "@accurx/transport";
import { WORKSPACE_MANAGEMENT_PRIMARY_ROUTES } from "@accurx/workspace-management";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, matchPath, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useSafeAsync } from "api/Api.utils";
import FlemingApiClient from "api/FlemingApiClient";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    clearReferredFromState,
    firePageLoadAnalyticAndResetReferredFrom,
} from "app/analytics/AnalyticsHelper";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";
import {
    getNextUrlOrHomepage,
    getSinglePatientListRoute,
} from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { TwoFactorNumberResponse } from "../../api/FlemingDtos";
import { actionCreators as patientListActionCreators } from "../patientLists/PatientListsActions";
import { StyledContainer, StyledPageWrapper } from "./TwoFactor.styles";
import { actionCreators } from "./TwoFactorActions";
import { TwoFactorMethod } from "./TwoFactorModels";
import {
    TwoFactorChooseMethod,
    TwoFactorConfirmationCode,
    TwoFactorSendCode,
    TwoFactorSuccess,
} from "./steps";

export enum TwoFactorContext {
    PatientList = "PatientList",
    PostLogin = "PostLogin",
    RecordView = "RecordView",
    Conversations = "Conversations",
    Workspaces = "Workspaces",
    Unspecified = "Unspecified",
}

export enum TwoFactorFlow {
    Unknown = "Unknown",
    Register = "Register",
    AlreadyRegistered = "AlreadyRegistered",
    Error = "Error",
}

export enum TwoFactorSteps {
    // (new registration only) user is prompted to choose mobile or email and register
    RegisterChooseTwoFactorMethod = "RegisterChooseTwoFactorMethod",

    // (already registered only) User is prompted to send a two factor authentication code
    CodeSend = "CodeSend",

    // (new registration & already registered) user is prompted to enter the code
    CodeEnter = "CodeEnter",

    // (new registration only) user sees registration success screen
    RegisterSuccess = "RegisterSuccess",
}

const getTwoFactorContext = (
    nextPageUrl: string,
    prevPageUrl: string | null,
): TwoFactorContext => {
    if (nextPageUrl.startsWith(getSinglePatientListRoute(""))) {
        return TwoFactorContext.PatientList;
    } else if (
        nextPageUrl.includes(ROUTES.record_view_request_medical_record) ||
        nextPageUrl.includes(ROUTES.record_view_medical_record_requests) ||
        nextPageUrl.includes(ROUTES.record_view_medical_record)
    ) {
        return TwoFactorContext.RecordView;
    } else if (
        matchPath(nextPageUrl, {
            path: WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding,
            exact: false,
            strict: false,
        }) ||
        matchPath(nextPageUrl, {
            path: ROUTES_WORKSPACE.workspaceEdit,
            exact: false,
            strict: false,
        })
    ) {
        return TwoFactorContext.Workspaces;
    } else if (nextPageUrl.includes("conversations")) {
        return TwoFactorContext.Conversations;
    } else if (prevPageUrl === ROUTES.postlogin) {
        return TwoFactorContext.PostLogin;
    } else {
        return TwoFactorContext.Unspecified;
    }
};

const getToastContent = (context: TwoFactorContext): string | undefined => {
    switch (context) {
        case TwoFactorContext.PatientList:
            return "You may now use Patient List";
        case TwoFactorContext.RecordView:
            return "You may now use Record View";
        default:
            return undefined;
    }
};

const isNonEmptyString = (s: string | null | undefined): s is string =>
    typeof s === "string" && s !== "";

const isTwoFactorNumberResponse = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any,
): result is TwoFactorNumberResponse =>
    result !== null &&
    (result.obfuscatedPhoneNumber || result.obfuscatedSecondaryEmail);

const TwoFactor = (): JSX.Element => {
    const { update, user } = useCurrentUser();
    const dispatch = useDispatch();
    const location = useLocation<{ from?: string }>();
    const history = useHistory();
    const accountState = useAppSelector((state) => state.account, shallowEqual);
    const analyticsState = useAppSelector(
        ({ sessionAnalytics: analytics }) => analytics,
        shallowEqual,
    );
    const currentUserEmail = useAppSelector((state) => {
        return state.account.user?.email || "";
    });

    const safeAsync = useSafeAsync();

    // Where should we be redirected after we complete 2FA
    const nextPageUrl = getNextUrlOrHomepage(location.search, false);

    // Determines which product the user will be taken to so we can display relevant content
    const twoFactorContext = getTwoFactorContext(
        nextPageUrl.pathname,
        location.state?.from ?? null,
    );

    const isAuthenticated = user.is2FAed;
    const { hubClient } = useTransport();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Whether we are registering or signing in
    const [twoFactorFlow, setTwoFactorFlow] = useState(TwoFactorFlow.Unknown);

    // Whether user has chosen mobile or email as two factor methods - set to mobile by default
    const [twoFactorMethod, setTwoFactorMethod] = useState<TwoFactorMethod>(
        TwoFactorMethod.Mobile,
    );
    const [obfuscatedMethod, setObfuscatedMethod] = useState("");

    const [step, setStep] = useState<TwoFactorSteps | null>(null);
    const [stepError, setStepError] = useState("");
    const [stepRequestLoading, setStepRequestLoading] = useState(false);

    // If user is already 2FA authenticated
    const shouldRedirectAway = isAuthenticated;

    const title = useMemo(() => {
        switch (step) {
            case TwoFactorSteps.RegisterChooseTwoFactorMethod:
                return "Set up account security";
            case TwoFactorSteps.CodeEnter:
                return "Confirm verification code";
            case TwoFactorSteps.CodeSend:
                switch (twoFactorContext) {
                    case TwoFactorContext.PatientList:
                        return "Get access to the patient list feature";
                    case TwoFactorContext.RecordView:
                        return "Get access to Record View";
                    case TwoFactorContext.Conversations:
                        return "Get access to conversations";
                    case TwoFactorContext.Workspaces:
                        return "Get access to workspaces";
                    default:
                        return "Get access to Accurx Web";
                }
            case TwoFactorSteps.RegisterSuccess:
                return "Account security is now set up";
            default:
                return "Get access to Accurx Web";
        }
    }, [step, twoFactorContext]);

    const subtitle = useMemo(() => {
        switch (step) {
            case TwoFactorSteps.RegisterChooseTwoFactorMethod:
                return (
                    <Text skinny>
                        To protect patient data we need to be able to verify
                        your identity.
                    </Text>
                );
            case TwoFactorSteps.CodeEnter:
                return (
                    <Text skinny>
                        You should have just received a code{" "}
                        {twoFactorMethod === TwoFactorMethod.Email
                            ? "to the email address"
                            : "via SMS to the phone number"}
                        : <strong>{obfuscatedMethod}</strong>
                    </Text>
                );
            case TwoFactorSteps.CodeSend:
                return (
                    <Text skinny>
                        To protect patient data we need to be able to verify
                        your identity.
                    </Text>
                );
            default:
                return null;
        }
    }, [step, twoFactorMethod, obfuscatedMethod]);

    useEffect(() => {
        if (!shouldRedirectAway) {
            firePageLoadAnalyticAndResetReferredFrom(
                history,
                accountState,
                analyticsState,
                "Two Factor",
            );
        } else {
            clearReferredFromState(history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search]);

    /**
     * Load the current 2Fa method,
     * set the authentication flow
     * and set the first step depending
     * on the type of flow we are in
     * */
    useEffect(() => {
        if (shouldRedirectAway) {
            return;
        }

        const getTwoFactorMethods = async () => {
            const { statusCode, result } = await safeAsync(
                FlemingApiClient.getTwoFactorMethod(),
            );

            if (statusCode === 404) {
                setTwoFactorFlow(TwoFactorFlow.Register);
                setStep(TwoFactorSteps.RegisterChooseTwoFactorMethod);
                return;
            }

            if (!isTwoFactorNumberResponse(result)) {
                setTwoFactorFlow(TwoFactorFlow.Error);
                return;
            }

            if (isNonEmptyString(result.obfuscatedPhoneNumber)) {
                setObfuscatedMethod(result.obfuscatedPhoneNumber);
                setTwoFactorMethod(TwoFactorMethod.Mobile);
                setTwoFactorFlow(TwoFactorFlow.AlreadyRegistered);
                setStep(TwoFactorSteps.CodeSend);
                return;
            }

            if (isNonEmptyString(result.obfuscatedSecondaryEmail)) {
                setObfuscatedMethod(result.obfuscatedSecondaryEmail);
                setTwoFactorMethod(TwoFactorMethod.Email);
                setTwoFactorFlow(TwoFactorFlow.AlreadyRegistered);
                setStep(TwoFactorSteps.CodeSend);
                return;
            }

            setTwoFactorFlow(TwoFactorFlow.Error);
        };

        getTwoFactorMethods();
    }, [shouldRedirectAway, safeAsync]);

    // Reset error and loading states at every step change
    useEffect(() => {
        setStepError("");
        setStepRequestLoading(false);
    }, [step]);

    if (shouldRedirectAway) {
        return (
            <Redirect
                to={{
                    ...nextPageUrl,
                    state: { referredFrom: "Successful 2FA" },
                }}
            />
        );
    }

    const handleTwoFactorFirstStepCancel = (): void => {
        switch (twoFactorContext) {
            // user is in generic context, so cancel click is to skip the 2fa step and go to patient search
            case TwoFactorContext.PostLogin:
                history.replace(nextPageUrl);
                return;

            // user is in a product context, so cancel click is to go back to previous page
            default:
                history.goBack();
                return;
        }
    };

    const setAuthenticateSuccess = () => {
        dispatch(patientListActionCreators.resetCurrentList());
        dispatch(actionCreators.setTwoFactorIsAuthenticated(true));
        update({
            ...user,
            is2FAed: true,
            isSetupFor2Fa: true,
            isTrustedDevice: true,
        });
    };

    /** Flow 1: New user that didn't registered for 2FA - handlers */

    const handleChooseTwoFactorMethod = (
        chosenMethod: TwoFactorMethod,
    ): void => {
        setTwoFactorMethod(chosenMethod);
    };

    const handleRegister = async (
        inputValue: string, // Email or Mobile
    ): Promise<void> => {
        setStepError("");
        setStepRequestLoading(true);

        const isMethodMobile = twoFactorMethod === TwoFactorMethod.Mobile;
        let res = null;
        if (isMethodMobile) {
            res = await safeAsync(
                FlemingApiClient.postTwoFactorRegisterMobileNumber({
                    mobileNumber: inputValue,
                }),
            );
        } else {
            res = await safeAsync(
                FlemingApiClient.postTwoFactorRegisterSecondaryEmail({
                    secondaryEmail: inputValue,
                }),
            );
        }

        setStepRequestLoading(false);
        if (res && res.success && res.result !== null) {
            if (isMethodMobile) {
                FlemingAnalyticsTracker.trackTwoFactorRegisterMobileSuccess(
                    analyticsLoggedInProps,
                );
            } else {
                FlemingAnalyticsTracker.trackTwoFactorRegisterEmailSuccess(
                    analyticsLoggedInProps,
                );
            }

            const obfuscatedValue =
                (isMethodMobile
                    ? res.result.obfuscatedPhoneNumber
                    : res.result.obfuscatedSecondaryEmail) ?? "";

            toast(
                Feedback({
                    colour: "success",
                    title: `A 6-digit code has been sent to your ${
                        isMethodMobile ? "mobile" : "email"
                    }`,
                    content: obfuscatedValue,
                }),
            );

            setObfuscatedMethod(obfuscatedValue);
            setStep(TwoFactorSteps.CodeEnter);
            return;
        }

        if (isMethodMobile) {
            FlemingAnalyticsTracker.trackTwoFactorRegisterMobileFailure(
                analyticsLoggedInProps,
            );
        } else {
            FlemingAnalyticsTracker.trackTwoFactorRegisterEmailFailure(
                analyticsLoggedInProps,
            );
        }

        setStepError(
            res.error ||
                `Sorry, we couldn't register your ${
                    isMethodMobile ? "mobile number" : "email address"
                }. Please try again`,
        );
    };

    const handleRegisterEnterCode = async (
        accessCode: string,
        remember: boolean,
    ): Promise<void> => {
        setStepError("");
        setStepRequestLoading(true);
        const { success, error } = await safeAsync(
            FlemingApiClient.postTwoFactorVerify({
                accessCode,
                rememberTwoFactor: remember,
            }),
        );
        setStepRequestLoading(false);

        const analyticsProps = {
            ...analyticsLoggedInProps,
            isRegisterFlow: true,
            isRemembered: remember,
            method: twoFactorMethod,
        };

        if (success) {
            FlemingAnalyticsTracker.trackTwoFactorVerifySuccess(analyticsProps);

            // When not 2FAed your connection to signalr filters out notifications with PII in them
            // so after 2FA we establish a new connection so that the notifications are no longer filtered
            hubClient?.restartConnection();

            setStep(TwoFactorSteps.RegisterSuccess);
            return;
        }

        FlemingAnalyticsTracker.trackTwoFactorVerifyFailure(analyticsProps);

        setStepError(
            error ||
                "Something is wrong with the code. Please check and re-enter.",
        );
    };

    /**
     * Flow 1: New user that hasn't set up 2FA
     * - Choose register method (mobile or email) and send code (TwoFactorChooseMethod)
     * - Registered (TwoFactorConfirmationCode)
     * - Success (TwoFactorSuccess)
     */
    const renderRegisterSteps = (): JSX.Element | null => {
        switch (step) {
            case TwoFactorSteps.RegisterChooseTwoFactorMethod:
                return (
                    <TwoFactorChooseMethod
                        prevStep={null}
                        nextStep={handleRegister}
                        currentChosenMethod={twoFactorMethod}
                        handleChangeMethod={handleChooseTwoFactorMethod}
                        error={stepError}
                        requestLoading={stepRequestLoading}
                        currentUserEmail={currentUserEmail}
                    />
                );

            case TwoFactorSteps.CodeEnter:
                return (
                    <TwoFactorConfirmationCode
                        prevStep={() =>
                            setStep(
                                TwoFactorSteps.RegisterChooseTwoFactorMethod,
                            )
                        }
                        nextStep={handleRegisterEnterCode}
                        requestLoading={stepRequestLoading}
                        error={stepError}
                    />
                );

            case TwoFactorSteps.RegisterSuccess:
                return (
                    <TwoFactorSuccess
                        twoFactorMethod={{
                            chosenMethod: twoFactorMethod,
                            obfuscatedMethod,
                        }}
                        prevStep={null}
                        nextStep={setAuthenticateSuccess}
                        context={twoFactorContext}
                    />
                );

            default:
                return null;
        }
    };

    /** Flow 2: User that already register for 2FA before - handlers */
    const handleSendCode = async (): Promise<void> => {
        setStepRequestLoading(true);
        const { success } = await safeAsync(
            FlemingApiClient.postTwoFactorSend(),
        );
        setStepRequestLoading(false);

        const useMobile = twoFactorMethod === TwoFactorMethod.Mobile;

        if (success) {
            if (useMobile) {
                FlemingAnalyticsTracker.trackTwoFactorSendSmsSuccess(
                    analyticsLoggedInProps,
                );
            } else {
                FlemingAnalyticsTracker.trackTwoFactorSendEmailSuccess(
                    analyticsLoggedInProps,
                );
            }

            toast(
                Feedback({
                    colour: "success",
                    title: `A 6-digit code has been sent to your ${
                        useMobile ? "mobile" : "email"
                    }`,
                    content: obfuscatedMethod,
                }),
            );

            setStep(TwoFactorSteps.CodeEnter);
            return;
        }

        if (useMobile) {
            FlemingAnalyticsTracker.trackTwoFactorSendSmsFailure(
                analyticsLoggedInProps,
            );
        } else {
            FlemingAnalyticsTracker.trackTwoFactorSendEmailFailure(
                analyticsLoggedInProps,
            );
        }

        setStepError(
            "Sorry, we couldn't send you a verification code. Please try again.",
        );
    };

    const handleEnterCode = async (
        accessCode: string,
        remember: boolean,
    ): Promise<void> => {
        setStepError("");
        setStepRequestLoading(true);
        const { success, error } = await safeAsync(
            FlemingApiClient.postTwoFactorVerify({
                accessCode,
                rememberTwoFactor: remember,
            }),
        );
        setStepRequestLoading(false);

        const analyticsProps = {
            ...analyticsLoggedInProps,
            isRegisterFlow: false,
            isRemembered: remember,
            method: twoFactorMethod,
        };

        if (success) {
            FlemingAnalyticsTracker.trackTwoFactorVerifySuccess(analyticsProps);

            toast(
                Feedback({
                    colour: "success",
                    title: "Correct code given",
                    content: getToastContent(twoFactorContext),
                }),
            );

            // When not 2FAed your connection to signalr filters out notifications with PII in them
            // so after 2FA we establish a new connection so that the notifications are no longer filtered
            hubClient?.restartConnection();

            setAuthenticateSuccess();
            return;
        }

        FlemingAnalyticsTracker.trackTwoFactorVerifyFailure(analyticsProps);

        setStepError(
            error ||
                "Something is wrong with the code. Please check and re-enter.",
        );
    };

    /**
     * Flow 2: User that already register for 2FA before
     * - CodeSend (TwoFactorSendCode)
     * - CodeVerify (TwoFactorConfirmationCode)
     * - TwoFAed (Redirect away)
     */
    const renderAlreadyRegisteredSteps = () => {
        switch (step) {
            case TwoFactorSteps.CodeSend:
                return (
                    <TwoFactorSendCode
                        prevStep={handleTwoFactorFirstStepCancel}
                        nextStep={handleSendCode}
                        twoFactorMethod={{
                            chosenMethod: twoFactorMethod,
                            obfuscatedMethod,
                        }}
                        error={stepError}
                        requestLoading={stepRequestLoading}
                        context={twoFactorContext}
                    />
                );

            case TwoFactorSteps.CodeEnter:
                return (
                    <TwoFactorConfirmationCode
                        prevStep={() => setStep(TwoFactorSteps.CodeSend)}
                        nextStep={handleEnterCode}
                        error={stepError}
                        requestLoading={stepRequestLoading}
                    />
                );

            default:
                return null;
        }
    };

    const renderFlows = () => {
        switch (twoFactorFlow) {
            case TwoFactorFlow.Register:
                return renderRegisterSteps();

            case TwoFactorFlow.AlreadyRegistered:
                return renderAlreadyRegisteredSteps();

            case TwoFactorFlow.Unknown:
                return <Spinner />;

            case TwoFactorFlow.Error:
                return (
                    <Feedback colour="error" title="An error occurred">
                        <Text skinny>
                            Please refresh the page and try again
                        </Text>
                    </Feedback>
                );
        }
    };

    return (
        <StyledPageWrapper data-testid="two-factor-card">
            {twoFactorFlow !== TwoFactorFlow.Unknown && (
                <>
                    <Text as="h1" variant="title">
                        {title}
                    </Text>
                    {subtitle}
                </>
            )}
            <StyledContainer>{renderFlows()}</StyledContainer>
        </StyledPageWrapper>
    );
};

export default TwoFactor;
