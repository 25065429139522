import { useEffect, useRef, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useConversation } from "@accurx/concierge/hooks/data/useConversation";
import {
    Conversation,
    PatientTriageRequestItem,
    TeamSummary,
    UserSummary,
} from "@accurx/concierge/types";
import { ROUTES_INBOX } from "domains/inbox/routes";
import { useHistory } from "react-router";

import { LocationObject, useInboxLink } from "./useInboxLink";
import { useSearchParams } from "./useSearchParams";

/**
 * Navigates to a conversation's conversation group.
 * This is only done when "source=native" is a query param.
 */
export const useNavigateToConversationGroup = () => {
    const { conversationId: id, source } = useSearchParams();

    const conversationId = decodeURIComponent(id ?? "");

    const history = useHistory();
    const link = useInboxLink();
    const linkToRef = useRef(link.to);

    const [nextLocation, setNextLocation] = useState<LocationObject>();

    const currentUser = useCurrentUser();
    const userId = currentUser.user.accuRxUserId;

    const conversation = useConversation({ conversationId });
    const assignee = useAssignee(conversation?.assignee ?? { type: "None" });

    useEffect(() => {
        if (nextLocation) history.push(nextLocation);
    }, [nextLocation, history]);

    useEffect(() => {
        if (source === "native" && conversation) {
            const conversationGroupRoute = getConversationGroupRoute({
                conversation,
                assignee,
                userId,
            });
            const status = conversation.status === "Done" ? "Done" : undefined;

            switch (conversationGroupRoute) {
                case "Teams":
                case "Questionnaires":
                case "Unassigned":
                case "UnassignedAdmin":
                case "UnassignedMedical":
                case "UnassignedSelfReferral":
                case "Shared":
                    if (!assignee) break;

                    setNextLocation(
                        linkToRef.current(conversationGroupRoute, {
                            conversationId: conversation.id,
                            teamId: assignee.id,
                        }),
                    );
                    return;
                case "Colleagues":
                    if (!assignee) break;

                    setNextLocation(
                        linkToRef.current(conversationGroupRoute, {
                            conversationId: conversation.id,
                            colleagueId: assignee.id,
                            status,
                        }),
                    );
                    return;
                default:
                    setNextLocation(
                        linkToRef.current(conversationGroupRoute, {
                            conversationId: conversation.id,
                            status,
                        }),
                    );
                    return;
            }
        }
    }, [source, assignee, conversation, userId]);
};

const getConversationGroupRoute = ({
    conversation,
    assignee,
    userId,
}: {
    conversation: Conversation;
    assignee: TeamSummary | UserSummary | undefined;
    userId: string;
}): keyof typeof ROUTES_INBOX => {
    if (assignee && "isCurrentUser" in assignee && assignee.isCurrentUser) {
        const hasScheduledItemByUser = conversation.items.some(
            (item) =>
                item.createdBy.type === "User" &&
                item.createdBy.id === userId &&
                "deliveryStatus" in item &&
                item.deliveryStatus === "Queued",
        );
        const hasSentItemByUser = conversation.items.some(
            (item) =>
                item.createdBy.type === "User" &&
                item.createdBy.id === userId &&
                "deliveryStatus" in item,
        );

        if (hasScheduledItemByUser) {
            return "MyInboxScheduled";
        } else if (hasSentItemByUser) {
            return "MyInboxSent";
        }

        return "MyInbox";
    }

    if (assignee && "type" in assignee) {
        switch (assignee.type) {
            case "ToAssignPatientMessaging": {
                const patientTriageItem = conversation.items.find(
                    (item, index) =>
                        index === 0 &&
                        item.contentType === "PatientTriageRequestNote",
                ) as PatientTriageRequestItem | undefined;

                if (patientTriageItem?.requestType === "Admin") {
                    return "UnassignedAdmin";
                } else if (patientTriageItem?.requestType === "Medical") {
                    return "UnassignedMedical";
                } else if (patientTriageItem?.requestType === "SelfReferral") {
                    return "UnassignedSelfReferral";
                }

                return "Unassigned";
            }
            case "Default":
                return "Teams";
            case "Florey":
                return "Questionnaires";
            case "Hub":
                return "Shared";
        }
    }

    const hasPatientTriageItem = conversation.items.some(
        (item, index) =>
            index === 0 && item.contentType === "PatientTriageRequestNote",
    );

    const hasPatientResponseItem = conversation.items.some(
        (item) => item.contentType === "PatientSingleResponse",
    );

    const hasFailedItem = conversation.items.some(
        (item) => item.contentType === "FailedDeliveryReceiptLink",
    );

    const hasScheduledItem = conversation.items.some(
        (item) => "deliveryStatus" in item && item.deliveryStatus === "Queued",
    );

    const hasSentItem = conversation.items.some(
        (item) => "deliveryStatus" in item,
    );

    if (hasPatientTriageItem) return "AllPatientTriages";
    if (hasPatientResponseItem) return "AllPatientResponses";
    if (hasFailedItem) return "AllFailed";
    if (hasScheduledItem) return "AllScheduled";
    if (hasSentItem) return "AllSent";

    if (assignee && "isCurrentUser" in assignee && !assignee.isCurrentUser)
        return "Colleagues";

    return "All";
};
