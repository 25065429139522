import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { PatientExternalId } from "domains/compose/types";

import { combineTemplates } from "../components/TemplatesCombobox/combineTemplates";
import { TemplatesBrowser } from "./TemplatesBrowser";
import { StyledFetchingError, StyledReloadButton } from "./TemplatesNew.style";
import { useAllTemplates } from "./useAllTemplates";

type TemplatesNewProps = {
    patientExternalIds: PatientExternalId[];
    disabled?: boolean;
};

export const TemplatesNew = ({
    patientExternalIds,
    disabled,
}: TemplatesNewProps) => {
    const workspace = useCurrentWorkspace();

    const templatesResult = useAllTemplates({ patientExternalIds });

    if (templatesResult.status === "loading") {
        return <SkeletonLoader width="100%" height="20px" />;
    }

    if (templatesResult.status === "error") {
        return (
            <StyledFetchingError>
                <UI.Ds.Flex alignItems={"center"}>
                    <UI.Ds.Icon size="xsmall" name="Error" appearance="solid" />
                </UI.Ds.Flex>
                <UI.Text skinny variant="preview" colour="zinc">
                    Templates failed to load.
                </UI.Text>
                <StyledReloadButton onClick={templatesResult.refetch}>
                    Reload templates
                </StyledReloadButton>
            </StyledFetchingError>
        );
    }

    const combinedTemplates = combineTemplates(templatesResult.data);

    const manageTemplatesUrl = window.chrome?.webview
        ? `/practices/${workspace.orgId}/manage-templates?tab=UserTemplates`
        : `/w/${workspace.orgId}/settings/personal-templates`;

    return (
        <TemplatesBrowser
            templates={combinedTemplates}
            patientExternalIds={patientExternalIds}
            disabled={disabled}
            manageTemplatesUrl={manageTemplatesUrl}
        />
    );
};
