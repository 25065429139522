const name = (feature: string, subFeature: string) =>
    `inbox-${feature}-${subFeature}`;

export const userflowIds = {
    environment: {
        web: name("environment", "web"),
        webview: name("environment", "webview"),
    },
    sidebar: {
        withPatients: name("sidebar", "with-patients"),
        withCareProviders: name("sidebar", "with-care-providers"),
        allConversations: name("sidebar", "all-conversations"),
        colleagues: name("sidebar", "colleagues"),
        currentPatient: name("sidebar", "current-patient"),
        myInbox: name("sidebar", "my-inbox"),
        questionnaires: name("sidebar", "questionnaires"),
        sentAndScheduled: name("sidebar", "sent-and-scheduled"),
        shared: name("sidebar", "shared"),
        teams: name("sidebar", "teams"),
        unassigned: name("sidebar", "unassigned"),
        withCareProvidersMyInbox: name(
            "sidebar",
            "with-care-providers-my-inbox",
        ),
        withCareProvidersAllConversations: name(
            "sidebar",
            "with-care-providers-all-conversations",
        ),
        withCareProvidersUnassigned: name(
            "sidebar",
            "with-care-providers-unassigned",
        ),
    },
    conversationList: {
        doneTab: name("conversation-list", "done-tab"),
        firstConversation: name("conversation-list", "first-conversation"),
        notificationsToggle: name("conversation-list", "notifications-toggle"),
        openTab: name("conversation-list", "open-tab"),
        previewActions: name("conversation-list", "preview-actions-button"),
    },
    conversationView: {
        assignButton: name("conversation-view", "assign-button"),
        confirmAssignButton: name("conversation-view", "confirm-assign-button"),
        markDoneButton: name("conversation-view", "mark-done-button"),
        messageActionsBar: name("conversation-view", "message-actions-bar"),
        messageHeaderLabel: name("conversation-view", "message-header-label"),
        replyButton: name("conversation-view", "reply-button"),
    },
};
