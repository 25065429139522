import { createSlice } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";

import * as extraReducers from "./extraReducers";
import * as reducers from "./reducers";

export const initialState: ConciergeState["conversations"] = {
    items: {},
    total: 0,
    groups: {
        items: {},
        cache: {},
        subscriberCounts: {},
    },
    unreadCounts: {
        ticket: {
            user: 0,
            teams: {},
            patients: {},
        },
        clinicianMessaging: {
            user: 0,
            teams: {},
        },
    },
    unreadItems: {},
    connectionState: { state: "Initialising" },
    conversationFreshAt: {},
    teamMembership: {},
};

export const conversations = createSlice({
    name: "conversations",
    initialState,
    reducers,
    extraReducers,
});
