import { PatientThreadUserGroupUserMembership } from "@accurx/api/ticket";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

export const mapPatientThreadUserGroupUserMembershipToTeamSummary = (
    dto: PatientThreadUserGroupUserMembership,
): TeamSummary | undefined => {
    const team = mapPatientThreadUserGroupToTeamSummary(dto);

    if (!team) {
        return undefined;
    }

    if (!isNil(dto.currentUserIsMember)) {
        team.isMember = dto.currentUserIsMember;
    }

    return team;
};
