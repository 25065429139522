import { Icon, Text, Thread } from "@accurx/design";

import {
    GenericNoteConversationItem,
    GenericNoteTheme,
} from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";

export type GenericNoteItemProps =
    BaseConversationThreadItem<GenericNoteConversationItem>;

export const GenericNoteItem = ({
    item,
    handleOnItemRead,
}: GenericNoteItemProps) => {
    return (
        <Thread.Message
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <>
                {getGenericNoteIcon(item.theme)}
                {item.displaySender && (
                    <Text variant="label" skinny>
                        {item.displaySender}
                    </Text>
                )}
                {item.markdown && (
                    <Text skinny>
                        {replaceSpecialMarkdownCharacters(item.markdown)}
                    </Text>
                )}
            </>
        </Thread.Message>
    );
};

export const replaceSpecialMarkdownCharacters = (markdown: string): string => {
    return markdown
        .replace(/\\/g, "") // remove all "\" in between lines
        .replace(/\*\*/g, ""); // remove all "**" bold
};

const getGenericNoteIcon = (theme: GenericNoteTheme): JSX.Element | null => {
    switch (theme) {
        case "Warning":
            return (
                <span role="img" aria-label={"warning"} className="d-block">
                    <Icon
                        name="Warning"
                        colour={"orange"}
                        theme="Fill"
                        size={4}
                    />
                </span>
            );
        case "Alarm":
            return (
                <span role="img" aria-label={"danger"} className="d-block">
                    <Icon name="Warning" colour={"red"} theme="Fill" size={4} />
                </span>
            );
        case "Information":
        default:
            return null;
    }
};
