import React, { useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Feedback, Icon, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal } from "reactstrap";

import { MessageTemplate, MessageTemplateOwner } from "api/FlemingDtos";
import { TemplateInList } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";
import { OrganisationHelper } from "shared/OrganisationHelper";

import {
    useAppSelector,
    useCurrentOrgId,
    useCurrentOrgName,
    useIsFeatureEnabled,
} from "../../store/hooks";
import { actionCreators as fileUploadActionCreators } from "../fileUpload/FileUploadActions";
import { PageHeader, PageHeaderType } from "../layout/PageHeader";
import MessageTemplateCard from "./MessageTemplateCardComponent";
import { actionCreators } from "./MessageTemplatesActions";
import { getNewMessageTemplate } from "./MessageTemplatesHelper";
import {
    ManageMessageTemplate,
    ManageTemplateActionType,
} from "./messageTemplatesEditCreate/ManageMessageTemplate";
import { MESSAGE_TEMPLATE_FORM_TITLE_ID } from "./messageTemplatesEditCreate/ManageMessageTemplateConstants";

enum ComponentState {
    Loading = "loading",
    Failed = "failed",
    Success = "success",
}

const MessageTemplatesPage = ({
    filter,
}: {
    filter: "Organisation" | "User";
}): JSX.Element => {
    useAccurxWebTitle(
        filter === "Organisation"
            ? "Manage organisation templates"
            : "Manage my message templates",
    );

    const dispatch = useDispatch();
    const orgId = useCurrentOrgId();
    const orgName = useCurrentOrgName();

    // Feature flags
    const webBatchEnabled = useIsFeatureEnabled(FeatureName.WebBatchMessaging);

    // State
    const [isManageTemplateFormOpen, setIsManageTemplateFormOpen] =
        useState(false);
    const [newTemplate, setNewTemplate] = useState<MessageTemplate>();

    // Store
    const account = useAppSelector(({ account }) => account);
    const smsTemplates = useAppSelector(
        ({ messageTemplates }) => messageTemplates.smsTemplates,
        shallowEqual,
    );
    const videoConsultTemplates = useAppSelector(
        ({ messageTemplates }) => messageTemplates.videoConsultTemplates,
        shallowEqual,
    );

    useEffect(() => {
        if (orgId !== null) {
            dispatch(
                actionCreators.getTemplatesForManagementView(
                    {
                        organisationId: orgId,
                    },
                    webBatchEnabled,
                ),
            );
        }

        dispatch(
            actionCreators.setTemplateLastActionLocation("templates-page"),
        );
    }, [orgId, webBatchEnabled, dispatch]);

    const renderHeaderText = (): JSX.Element => {
        const title =
            filter === "Organisation"
                ? `${orgName} templates`
                : "My message templates";
        return <PageHeader type={PageHeaderType.ListPage} title={title} />;
    };

    const renderCardList = (
        templates: TemplateInList[],
        showAvailabilitySwitches = false,
    ): JSX.Element => {
        if (templates.length === 0) {
            return <Feedback colour="information" title="Your list is empty" />;
        }

        return (
            <ul className="list-group">
                {templates.map((template) => {
                    if (template.id !== null) {
                        return (
                            <li key={template.id} className="list-group-item">
                                <MessageTemplateCard
                                    template={template}
                                    showAvailabilitySwitches={
                                        showAvailabilitySwitches
                                    }
                                />
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
        );
    };

    const ownerFilter: MessageTemplateOwner =
        filter === "User"
            ? MessageTemplateOwner.User
            : MessageTemplateOwner.Organisation;

    const renderPagePanelContents = (): JSX.Element => {
        // Don't show unapproved users org-wide templates
        if (
            filter === "Organisation" &&
            !OrganisationHelper.getIsApprovedOrgUser(account)
        ) {
            return (
                <Feedback
                    colour="information"
                    title="Sorry, you need to be approved to access organisation-wide templates"
                >
                    <Text skinny>
                        Please contact your workspace admin to have your user
                        account approved
                    </Text>
                </Feedback>
            );
        }

        const messagePatientsTemplatesList = smsTemplates.filter(
            (template) => template.owner === ownerFilter,
        );
        const vcTemplatesList = videoConsultTemplates.filter(
            (template) => template.owner === ownerFilter,
        );

        return (
            <>
                <div className="mb-4">
                    <div className="d-flex justify-content-between">
                        <Text variant="label">
                            <Icon
                                name="Clock"
                                size={3}
                                halo={{
                                    colour: "blue",
                                    luminosity: "high",
                                }}
                                props={{ className: "mr-2" }}
                            />
                            Message Patients ·{" "}
                            {messagePatientsTemplatesList.length}
                        </Text>
                        <Button
                            text="Create a new template"
                            dimension="large"
                            className="mt-2 mr-3"
                            onClick={createTemplate}
                            data-userflow-id="message-templates-create-button"
                        />
                    </div>
                    <Text skinny>
                        These templates will only be available to message
                        patients.
                    </Text>
                </div>
                {renderCardList(messagePatientsTemplatesList, true)}
                <div className="my-4">
                    <Text variant="label">
                        Video Consult · {vcTemplatesList.length}
                    </Text>
                    <Text skinny>
                        These templates will only be available for Video Consult
                        invites.
                    </Text>
                </div>
                {renderCardList(vcTemplatesList)}
            </>
        );
    };

    const renderComponentState = () => {
        if (!account.user || !orgId) {
            return null;
        }

        switch (getComponentState()) {
            case ComponentState.Loading:
                return (
                    <>
                        {renderHeaderText()}
                        <Spinner />
                    </>
                );

            case ComponentState.Failed:
                return (
                    <>
                        {renderHeaderText()}
                        <Feedback
                            colour="error"
                            title="Sorry, there was a problem fetching your templates!"
                        >
                            <Text skinny>
                                Please reload this page to try again
                            </Text>
                        </Feedback>
                    </>
                );
            case ComponentState.Success:
                return (
                    <div>
                        {renderHeaderText()}
                        {renderPagePanelContents()}
                        {newTemplate && (
                            <Modal
                                isOpen={isManageTemplateFormOpen}
                                toggle={handleModalClose}
                                unmountOnClose
                                aria-labelledby={MESSAGE_TEMPLATE_FORM_TITLE_ID}
                            >
                                <ManageMessageTemplate
                                    actionType={ManageTemplateActionType.Create}
                                    template={newTemplate}
                                    onCancelAction={handleModalClose}
                                    onSuccessAction={handleModalClose}
                                />
                            </Modal>
                        )}
                    </div>
                );
        }
    };

    const createTemplate = (): void => {
        setIsManageTemplateFormOpen(true);
        setNewTemplate(
            getNewMessageTemplate({
                owner: ownerFilter,
                type: null,
            }),
        );
    };

    const handleModalClose = (): void => {
        dispatch(fileUploadActionCreators.resetFileUpload());
        setIsManageTemplateFormOpen(false);
    };

    const isMessageTemplatesLoading = useAppSelector(
        ({ messageTemplates }) => messageTemplates.isMessageTemplatesLoading,
    );

    const lastGetMessageTemplatesFailed = useAppSelector(
        ({ messageTemplates }) =>
            messageTemplates.lastGetMessageTemplatesFailed,
    );

    const getComponentState = (): ComponentState => {
        if (!isMessageTemplatesLoading && lastGetMessageTemplatesFailed) {
            return ComponentState.Failed;
        }

        if (!isMessageTemplatesLoading && !lastGetMessageTemplatesFailed) {
            return ComponentState.Success;
        }

        // Default to loading if not in either of the other states
        return ComponentState.Loading;
    };

    if (account.user === null) {
        Log.error("MessageTemplatesComponent expects non-null user prop.");
        return <Redirect to="/" />;
    }

    return <div className="bottom-spacing">{renderComponentState()}</div>;
};

export { MessageTemplatesPage };
