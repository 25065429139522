import { ReactNode, forwardRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";

import { StyledAttachSelector } from "./AttachSelector.styles";
import { AttachDocumentButton } from "./components/AttachDocumentButton";

type AttachSelectorProps = {
    children: ReactNode;
    isOpen: boolean;
    toggle: () => void;
    iconOnlyButton?: boolean;
    disabled?: boolean;
};

export const AttachSelector = forwardRef<
    HTMLButtonElement,
    AttachSelectorProps
>(
    (
        { children, isOpen, toggle, disabled = false, iconOnlyButton = false },
        ref,
    ) => {
        const isMessageComponentV1_1Enabled = useFeatureFlag(
            "MessageComponentV1_1",
        );

        return (
            <UI.Popover open={isOpen} onOpenChange={toggle}>
                <UI.PopoverTrigger asChild={true}>
                    <AttachDocumentButton
                        toggle={toggle}
                        ref={ref}
                        iconOnly={iconOnlyButton}
                        disabled={disabled}
                    />
                </UI.PopoverTrigger>
                <StyledAttachSelector.PopoverContent
                    align="start"
                    $minWidth={isMessageComponentV1_1Enabled ? 244 : 300}
                >
                    <StyledAttachSelector.Header>
                        <UI.Text skinny variant="label" colour="night">
                            Attach
                        </UI.Text>
                    </StyledAttachSelector.Header>
                    <StyledAttachSelector.AttachOptionsContainer>
                        {children}
                    </StyledAttachSelector.AttachOptionsContainer>
                </StyledAttachSelector.PopoverContent>
            </UI.Popover>
        );
    },
);
