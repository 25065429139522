import { useRef, useState } from "react";

import { QuickViewPortal } from "@accurx/quick-view";
import { Log } from "@accurx/shared";
import { useCompose } from "domains/compose/context";
import { PatientExternalId, TemplateItem } from "domains/compose/types";

import { BrowseTemplatesButton } from "../components/BrowseTemplatesButton/BrowseTemplatesButton";
import { RemoveTemplateButton } from "../components/RemoveTemplateButton/RemoveTemplateButton";
import { PatientMessageTemplateSelector } from "./PatientMessageTemplateSelector";

export const Templates = ({
    patientExternalIds,
    onTemplateSelect,
}: {
    onTemplateSelect: (template: TemplateItem) => void;
    patientExternalIds: PatientExternalId[];
}) => {
    const { state, dispatch } = useCompose();
    const [showMessageTemplates, setShowMessageTemplates] = useState(false);
    const removeTemplateButtonRef = useRef<HTMLButtonElement>(null);

    const handleTemplateSelect = (templateItem: TemplateItem) => {
        setShowMessageTemplates(false);

        const { type, value: template } = templateItem;

        if (type === "QuestionnaireTemplate") {
            dispatch({
                type: "ADD_QUESTIONNAIRE_TEMPLATE",
                payload: { template },
            });
            onTemplateSelect(templateItem);
            return;
        }

        if (
            template.attachments &&
            template.attachments.length > state.maxAttachmentCount
        ) {
            Log.warn(
                "Unexpected: User attempted to add a template with more than the allowed number of attachments, discarding the ones in excess",
                {
                    tags: {
                        maxAttachmentsAllowed: state.maxAttachmentCount,
                        totalTemplateAttachments: template.attachments.length,
                    },
                },
            );
        }

        dispatch({
            type: "ADD_MESSAGE_TEMPLATE",
            payload: { template },
        });
        onTemplateSelect(templateItem);
    };

    return (
        <>
            {!state.template.value ? (
                <BrowseTemplatesButton
                    onClick={() => setShowMessageTemplates(true)}
                />
            ) : (
                <RemoveTemplateButton
                    ref={removeTemplateButtonRef}
                    template={state.template}
                    onClick={() =>
                        dispatch({
                            type: "REMOVE_TEMPLATE",
                        })
                    }
                />
            )}
            <QuickViewPortal
                isOpen={showMessageTemplates}
                onClose={() => setShowMessageTemplates(false)}
                returnFocusRef={removeTemplateButtonRef}
            >
                <PatientMessageTemplateSelector
                    onClose={() => setShowMessageTemplates(false)}
                    onClickTemplate={handleTemplateSelect}
                    patientExternalIds={patientExternalIds}
                />
            </QuickViewPortal>
        </>
    );
};
