import { useEffect } from "react";

import {
    startNativeSubscription,
    useIsNativeSignalRConnectionAvailable,
    useNativeTransport,
} from "@accurx/native";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

export const useNativeSignalRSubscriptions = (workspaceId: number): void => {
    const isNativeSignalRConnectionAvailable =
        useIsNativeSignalRConnectionAvailable();
    const nativeTransport = useNativeTransport();

    const isFeatureFlagEnabled = useFeatureFlag(
        "UnifiedInboxSingleSignalRConnection",
    );

    useEffect(() => {
        if (
            !isFeatureFlagEnabled ||
            !nativeTransport ||
            !isNativeSignalRConnectionAvailable
        )
            return;

        const unsubscribeSignalRLifecycleEvents = startNativeSubscription({
            subscription: "SubscribeSignalRLifecycleEvents",
            transport: nativeTransport,
            workspaceId,
        });
        const unsubscribeSignalRSubscriptionEvents = startNativeSubscription({
            subscription: "SubscribeSignalRInvocations",
            transport: nativeTransport,
            workspaceId,
        });

        return () => {
            unsubscribeSignalRLifecycleEvents();
            unsubscribeSignalRSubscriptionEvents();
        };
    }, [
        isNativeSignalRConnectionAvailable,
        nativeTransport,
        workspaceId,
        isFeatureFlagEnabled,
    ]);
};
