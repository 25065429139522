import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { TextArea } from "domains/compose/components/Textarea";
import { useCompose } from "domains/compose/context";

import { AttachmentDisplay } from "../Attach/components/AttachmentDisplay";
import { NHSAdviceInfoBox } from "../NHSAdviceInfoBox/NHSAdviceInfoBox";
import { PatientResponseEnabledInfo } from "../PatientResponseEnabledInfo/PatientResponseEnabledInfo";
import { QuestionnaireInfoBox } from "../QuestionnaireInfoBox/QuestionnaireInfoBox";
import { SelfBookLinkInfoBox } from "../SelfBookLinkInfoBox/SelfBookLinkInfoBox";
import { ComposeMessageContentPlaceholder } from "./components/ComposeMessageContentPlaceholder";
import { MessageHeader } from "./components/MessageHeader";

export const ComposeTextArea = ({
    onUserTyping,
}: {
    onUserTyping?: () => void;
}) => {
    const track = useAnalytics();
    const workspace = useCurrentWorkspace();
    const { state, dispatch } = useCompose();

    const hasQuestionnaire = state.template.type === "QuestionnaireTemplate";
    const nativeTrackingFields = useNativeTrackingFields();

    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    return (
        <TextArea.Container>
            <MessageHeader />
            <TextArea.EditableMessage onUserTyping={onUserTyping}>
                {isMessageComponentV1_1Enabled && (
                    <ComposeMessageContentPlaceholder />
                )}
                {!isMessageComponentV1_1Enabled && (
                    <UI.Flex flexDirection="column" gap="1">
                        {state.nhsAdviceLink && (
                            <NHSAdviceInfoBox
                                nhsAdviceLink={state.nhsAdviceLink}
                                onRemoveNHSAdviceLink={() => {
                                    track("NhsAdviceLinkRemove Button Click", {
                                        ...nativeTrackingFields,
                                        conversationParticipant:
                                            state.conversationParticipant,
                                    });
                                    dispatch({
                                        type: "REMOVE_NHS_ADVICE_LINK",
                                    });
                                }}
                            />
                        )}
                        {state.isPatientResponseEnabled && (
                            <PatientResponseEnabledInfo />
                        )}
                        {hasQuestionnaire && <QuestionnaireInfoBox />}
                        {state.attachments.length > 0 &&
                            state.attachments.map((attachment) => (
                                <AttachmentDisplay
                                    key={`${attachment.origin}-${attachment.id}`}
                                    attachment={attachment}
                                />
                            ))}
                        {state.selfBookLink !== null && (
                            <SelfBookLinkInfoBox
                                selfBookLink={state.selfBookLink}
                                onSelfBookLinkRemove={() => {
                                    dispatch({
                                        type: "REMOVE_SELF_BOOK_LINK",
                                    });
                                    track(
                                        "ConversationBookingLinkRemove Button Click",
                                        {
                                            accessType:
                                                nativeTrackingFields.accessType,
                                            medicalRecordSystem:
                                                nativeTrackingFields.medicalRecordSystem,
                                            appOrigin: "QuickReply",
                                        },
                                    );
                                }}
                            />
                        )}
                    </UI.Flex>
                )}
            </TextArea.EditableMessage>
            <TextArea.Footer>
                <TextArea.UneditableText>
                    {workspace.organisationName}
                </TextArea.UneditableText>
            </TextArea.Footer>
        </TextArea.Container>
    );
};
