import { useEffect } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Feedback, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Redirect, useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { useGetWorkspaceInvitesQuery } from "app/hooks/queries";
import { useGetAllowedNhsOrganisationsQuery } from "app/hooks/queries/useGetAllowedNhsOrganisationsQuery";
import { WorkspaceInvitation } from "app/workspaceSettings/WorkspaceInvitation";
import { ROUTES } from "shared/Routes";

import { JoinOrganisation } from "./JoinOrganisation";
import { useIsClinicianConversationOnboarding } from "./hooks";
import {
    OnboardingContainer,
    OnboardingPageWrapper,
} from "./layout-components";

const ErrorMessage = () => (
    <Feedback colour="error" title="An error has occurred">
        <Text skinny>Please refresh the page to try again</Text>
    </Feedback>
);

/**
 * @deprecated that is a copy of the original page for development purposes
 */
export const JoinOrganisationPage = () => {
    useAccurxWebTitle("Join an organisation");

    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const location = useLocation();
    const track = useAnalytics();
    const { user } = useCurrentUser();

    const isOnboarding = Boolean(
        useRouteMatch({ path: [ROUTES.joinOrganisation] }),
    );

    const {
        isSuccess: hasLoadedInvites,
        isError: hasInvitesError,
        isLoading: isLoadingInvites,
        data: invites = [],
    } = useGetWorkspaceInvitesQuery();

    const {
        isSuccess: hasLoadedAllowedOrganisations,
        isError: hasAllowedOrganisationsError,
        isLoading: isLoadingAllowedOrganisations,
        data: allowedOrganisations = [],
    } = useGetAllowedNhsOrganisationsQuery();

    const suggestedOrg =
        allowedOrganisations.length === 1 ? allowedOrganisations[0] : null;

    const isLoading = isLoadingInvites || isLoadingAllowedOrganisations;
    const isError = hasInvitesError || hasAllowedOrganisationsError;

    useEffect(() => {
        if (isLoading || isError) {
            return;
        }

        track("AccountOrganisation Page View", {
            onboarding: isOnboarding,
            hasPreselectedOrg: Boolean(suggestedOrg),
            pageOrigin: "Organisation",
        });
    }, [track, isLoading, isError, isOnboarding, suggestedOrg]);

    const getRedirectTo = (path: string) => {
        return { pathname: path, search: location.search };
    };

    /**
     * Only redirect after workspaces invites have been addressed
     */
    if (
        hasLoadedAllowedOrganisations &&
        hasLoadedInvites &&
        invites.length === 0
    ) {
        const isNewPharmacyUser =
            allowedOrganisations.length === 1 &&
            allowedOrganisations[0].nationalCode === "X31";

        const isExistingPharmacyUser = user.organisations.some(
            ({ nationalCode }) => nationalCode === "X31",
        );

        if (isNewPharmacyUser || isExistingPharmacyUser) {
            return <Redirect to={getRedirectTo(ROUTES.joinPharmacy)} />;
        }

        if (allowedOrganisations.length === 0) {
            return <Redirect to={getRedirectTo(ROUTES.addOrganisation)} />;
        }
    }

    const renderContent = () => {
        if (isError) {
            return <ErrorMessage />;
        }

        if (invites.length > 0) {
            const workspaceInvite = invites[0].workspace;
            return (
                <WorkspaceInvitation
                    workspaceId={workspaceInvite.id}
                    workspaceName={workspaceInvite.name}
                    organisationNodeName={workspaceInvite.organisationNodeName}
                    invitedByUser={invites[0].invitedByUser}
                    dismissible
                />
            );
        }

        return <JoinOrganisation />;
    };

    return (
        <OnboardingPageWrapper>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {!isBlurryChatLayout && (
                        <Text as="h1" variant="title">
                            {invites.length > 0
                                ? "You've been invited to join your colleagues"
                                : "Join an organisation"}
                        </Text>
                    )}
                    <OnboardingContainer>{renderContent()}</OnboardingContainer>
                </>
            )}
        </OnboardingPageWrapper>
    );
};
