import { useEffect } from "react";

import { useNativeSubscription } from "@accurx/native";
import { Log } from "@accurx/shared";
import { Subject } from "rxjs";

import { SocketEvents } from "shared/hubClient/HubClient";

import { EventName, SubscriptionEvent } from "../types";

type EventSubjects<M extends EventName> = Partial<
    Record<M, Subject<SubscriptionEvent<M>>>
>;

const EVENT_SUBJECTS: EventSubjects<EventName> = {};

export const useOnNativeSignalRSubscriptionEvent = () => {
    const event = useNativeSubscription("SubscribeSignalRInvocations");

    useEffect(() => {
        if (event.status === "loading" || event.status === "idle") return;
        if (event.status === "error") {
            Log.error(
                "useOnNativeSignalRSubscriptionEvent: Unsuccessful subscription event",
                {
                    tags: {
                        errorMessage: event.error.message,
                    },
                },
            );
            return;
        }

        if (!isValidMethodName(event.data.methodName)) {
            Log.error(
                "useOnNativeSignalRSubscriptionEvent: Invalid subscription event methodName",
                {
                    tags: {
                        methodName: event.data.methodName,
                    },
                },
            );
            return;
        }

        const subject = EVENT_SUBJECTS[event.data.methodName];
        if (!subject) {
            Log.warn(
                "useOnNativeSignalRSubscriptionEvent: Subject not found for subscription event",
                {
                    tags: { methodName: event.data.methodName },
                },
            );

            return;
        }

        subject.next({
            event: event.data.methodName,
            payload: event.data.parameter,
        } as SubscriptionEvent<typeof event.data.methodName>);
    }, [event]);
};

export function getEventSubscription<M extends EventName>({
    methodName,
    onNewSubscription,
}: {
    methodName: M;
    onNewSubscription?: () => void;
}): Subject<SubscriptionEvent<M>> {
    const existingSubject = EVENT_SUBJECTS[methodName];

    // Not ideal casting here, but it's catering for not being able to pass
    // a generic when defining an individual EventName when declaring EVENT_SUBJECTS,
    // enforcing the type being tied to the methodName passed into the argument
    if (existingSubject) {
        return existingSubject as unknown as Subject<SubscriptionEvent<M>>;
    }
    const eventSubject = new Subject<SubscriptionEvent<M>>();
    EVENT_SUBJECTS[methodName] = eventSubject as unknown as Subject<
        SubscriptionEvent<EventName>
    >;

    // A callback that can be called only when registering a subscription when it
    // has not already been registered (ie the first time). This will not be
    // called when re-registering a subscription
    onNewSubscription?.();
    return eventSubject;
}

export function clearAllSubscriptions(): void {
    Object.keys(EVENT_SUBJECTS).forEach((key) => {
        delete EVENT_SUBJECTS[key as EventName];
    });
}

function isValidMethodName(name: string): name is EventName {
    return Object.keys(SocketEvents).includes(name);
}
