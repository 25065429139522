import { useConciergeSelector } from "domains/concierge/internal/context";

export const useWithCareProvidersUnassignedUnreadCount = (): number => {
    return useConciergeSelector((state) => {
        const counts = state.conversations.unreadCounts.clinicianMessaging;
        const queryStatus = state.queries.clinicianMessagingUnreads.status;
        const team = Object.values(state.teams.items).find(
            (team) => team.type === "ToAssignClinicianMessaging",
        );

        if (!team || !team.isMember || queryStatus !== "success") return 0;

        return counts.teams[team.id] ?? 0;
    });
};
