import React, { useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    CollaborativeWorkspaceResponse,
    ExampleWorkspace,
} from "@accurx/api/account";
import {
    FeatureName,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    Button,
    Ds,
    Feedback,
    Input,
    Text,
    usePagination,
} from "@accurx/design";
import { LinkIcon } from "@accurx/design/dist/components/Link/LinkIcon";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { useFuzzyFilter } from "@accurx/hooks";
import { BackButton } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import {
    PaginationControls,
    PaginationLabel,
    ROOT_ROUTES,
    SUPPORT_ARTICLE_WORKSPACE_GUIDANCE,
    WORKSPACE_MANAGEMENT_ROUTES,
    useGetExampleWorkspacesByNationalCodeQuery,
    useGetWorkspacesByNationalCodeQuery,
} from "@accurx/workspace-management";
import {
    Link,
    generatePath,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";
import { StyledTwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer.styles";
import { SkeletonList } from "app/sharedComponents/loadingSkeleton/SkeletonList";

import { ONBOARDING_ROUTES_TEMPORARY } from "../routes";
import {
    StyledCardItem,
    StyledCardList,
    StyledLink,
    StyledMain,
    StyledPageWrapper,
} from "./Layout.styles";
import { ExampleWorkspaceItem } from "./WorkspaceItem/ExampleWorkspaceItem";
import { ExistingWorkspaceItem } from "./WorkspaceItem/ExistingWorkspaceItem";
import { UserflowIds } from "./constants";
import { getCombinedWorkspaces } from "./helpers";

export type JoinWorkspaceLocationState = {
    allowedOrganisation?: IAllowedNhsOrganisationResponse;
};

const exampleWorkspaceTypeCheck = (
    workspace: ExampleWorkspace | CollaborativeWorkspaceResponse,
): workspace is ExampleWorkspace => {
    return !("id" in workspace);
};

const CreateNewWorkspaceButton = ({
    workspaceId,
}: {
    workspaceId?: number;
}) => {
    const { search, state } = useLocation();

    const isOnboarding = Boolean(
        useRouteMatch([
            ROOT_ROUTES.workspaceJoin,
            ONBOARDING_ROUTES_TEMPORARY.workspaceJoin,
        ]),
    );
    const workspaceCreationPath = isOnboarding
        ? {
              pathname: ONBOARDING_ROUTES_TEMPORARY.workspaceCreate, //route needs to be updated later
              search,
              state,
          }
        : generatePath(WORKSPACE_MANAGEMENT_ROUTES.workspaceCreate, {
              workspaceId,
          });

    return (
        <Link to={workspaceCreationPath}>
            <Button
                as="span"
                theme="secondary"
                text="Create workspace"
                icon={{
                    id: "arrow",
                    name: "Plus",
                    colour: "blue",
                }}
                data-userflow-id={UserflowIds.joinWorkspace.createNewButton}
            />
        </Link>
    );
};

const CreateNewWorkspaceCard = ({ workspaceId }: { workspaceId?: number }) => {
    return (
        <StyledCardItem>
            <Text skinny>
                If you cannot find a relevant workspace you can create one here.
            </Text>
            <CreateNewWorkspaceButton workspaceId={workspaceId} />
        </StyledCardItem>
    );
};

/**
 * @deprecated that is a copy of the original page for development purposes
 */

export const JoinWorkspacePage = () => {
    const history = useHistory<
        ({ workspaceName?: string } & JoinWorkspaceLocationState) | undefined
    >();

    const { selectedOrgId } = useSelectedOrganisation();

    const { user } = useCurrentUser();

    const currentWorkspace = user.organisations?.find(
        (w) => w.orgId === selectedOrgId,
    );

    const { orgId: workspaceId, organisationNodeName } = currentWorkspace ?? {};

    const allowedOrganisation = history.location.state?.allowedOrganisation;

    const isOnboarding = Boolean(
        useRouteMatch([
            ROOT_ROUTES.workspaceJoin,
            ONBOARDING_ROUTES_TEMPORARY.workspaceJoin,
        ]),
    );

    const nationalCode =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.nationalCode
            : currentWorkspace?.nationalCode;

    const defaultOrgName =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.name
            : organisationNodeName || "";

    const track = useAnalytics();

    const pageWrapperRef = useRef<HTMLElement>(null);

    const [searchTerm, setSearchTerm] = useState("");

    const { data: existingWorkspaces = [], status: workspacesQueryStatus } =
        useGetWorkspacesByNationalCodeQuery(
            { nationalCode },
            { refetchOnMount: true },
        );

    const { data: exampleWorkspacesData, status: exampleWorkspacesStatus } =
        useGetExampleWorkspacesByNationalCodeQuery(
            { nationalCode },
            { refetchOnMount: true },
        );

    const isLoading = [workspacesQueryStatus, exampleWorkspacesStatus].includes(
        "loading",
    );

    const supportsMultipleWorkspaces = allowedOrganisation
        ? allowedOrganisation.supportsMultipleWorkspaces
        : currentWorkspace &&
          isFeatureEnabledForWorkspace(
              FeatureName.FlexibleWorkspace,
              currentWorkspace,
          );

    // List does not include `exampleWorkspacesStatus` since if this errors, the rest of the page can still function fine
    const hasError = [workspacesQueryStatus].includes("error");

    const combinedWorkspaces = getCombinedWorkspaces({
        existingWorkspaces,
        exampleWorkspaces: exampleWorkspacesData || [],
    });

    const filteredWorkspaces = useFuzzyFilter<
        CollaborativeWorkspaceResponse | ExampleWorkspace
    >(combinedWorkspaces, searchTerm, {
        threshold: 0.3,
        keys: ["name", "specialties", "description"],
    });

    const { currentPage, totalPages, onNext, onPrevious, goToPage, paginate } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: 20,
            total: filteredWorkspaces.length,
        });

    const paginatedWorkspaces = paginate(filteredWorkspaces);

    useEffect(() => {
        if (!isLoading && !hasError) {
            track("WorkspaceJoin Page View", {});
        }
    }, [isLoading, hasError, track]);

    const handleBackClick = () => {
        history.goBack();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        goToPage(1);
        setSearchTerm(e.target.value);
    };

    const showPreviousResults = () => {
        onPrevious();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    const showNextResults = () => {
        onNext();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    if (!supportsMultipleWorkspaces) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback
                        colour="error"
                        title="This organisation does not support workspaces"
                    />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (!allowedOrganisation && !currentWorkspace) {
        // NOTE: this error state needs validation before
        // combining join-organisation and join-workspace pages together

        Log.error("There is no organisation to fetch workspaces for");
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback colour="error" title="Something went wrong" />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    return (
        <StyledPageWrapper ref={pageWrapperRef} flexDirection="column">
            {!isOnboarding && <BackButton backCallback={handleBackClick} />}
            <StyledMain gap="3" flexDirection="column">
                {hasError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <>
                        <Text as="h1" variant="subtitle" skinny>
                            {`Join or create a workspace at ${defaultOrgName}`}
                        </Text>

                        <Ds.Flex justifyContent="space-between">
                            <StyledTwoThirdsContainer>
                                <Text skinny>
                                    A workspace is a group of people at your
                                    organisation who would like to collaborate
                                    in Accurx e.g. your specialty.{" "}
                                    <StyledLink
                                        href={
                                            SUPPORT_ARTICLE_WORKSPACE_GUIDANCE
                                        }
                                        openInNewTab
                                    >
                                        <LinkText text="Find out more about workspaces" />
                                        <LinkIcon />
                                    </StyledLink>
                                </Text>
                            </StyledTwoThirdsContainer>
                            <CreateNewWorkspaceButton
                                workspaceId={workspaceId}
                            />
                        </Ds.Flex>

                        <Input
                            isSearchInput
                            placeholder={`Search workspaces at ${defaultOrgName}`}
                            onChange={handleSearch}
                            disabled={isLoading}
                            aria-label={`Search workspaces at ${defaultOrgName}`}
                        />
                        {isLoading ? (
                            <SkeletonList quantity={3} />
                        ) : (
                            <>
                                <PaginationLabel
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    totalCount={filteredWorkspaces.length}
                                    appliedLimit={20}
                                />
                                <StyledCardList
                                    key={`${searchTerm}-${currentPage}`}
                                >
                                    {paginatedWorkspaces.map((workspace) => {
                                        const isExampleWorkspace =
                                            exampleWorkspaceTypeCheck(
                                                workspace,
                                            );

                                        return isExampleWorkspace ? (
                                            <ExampleWorkspaceItem
                                                key={`example-workspace-${workspace.name}`}
                                                name={workspace.name}
                                                specialtyId={
                                                    workspace.specialties[0].id
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                            />
                                        ) : (
                                            <ExistingWorkspaceItem
                                                id={workspace.id}
                                                key={workspace.id}
                                                name={workspace.name}
                                                description={
                                                    workspace.description
                                                }
                                                users={workspace.users}
                                                specialty={
                                                    workspace.specialties[0]
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                            />
                                        );
                                    })}
                                    {currentPage === totalPages && (
                                        <CreateNewWorkspaceCard
                                            workspaceId={workspaceId}
                                        />
                                    )}
                                </StyledCardList>
                                {filteredWorkspaces.length > 0 && (
                                    <PaginationControls
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPrevious={showPreviousResults}
                                        onNext={showNextResults}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};
