import { ReactNode } from "react";

import { useCurrentUser } from "@accurx/auth";
import { conversationIdMapper } from "@accurx/concierge";
import { ROUTES_INBOX } from "@accurx/inbox";
import { generatePath, useHistory } from "react-router";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { ClinicianConversationApi } from "api/ClinicianConversationApi";
import { isTestPatient } from "app/patientProfile/isTestPatient";
import { ROUTES } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import {
    useCurrentOrgId,
    useCurrentUserEmailAddress,
    useIsUserApproved,
} from "store/hooks";

import { ClinicianConversationContext } from ".";
import {
    buildSubjectFromPatient,
    getPatientDetailsWithDisplayNames,
} from "../clinicianConversation.helper";
import { usePatientDetailsFromState } from "../hooks/usePatientDetailsFromState";
import { ClinicianConversationContextType } from "./types";

export const FromNewConversation = (props: { children: ReactNode }) => {
    const userEmailAddress = useCurrentUserEmailAddress();
    const history = useHistory();
    const workspaceId = useCurrentOrgId();
    const currentUser = useCurrentUser();
    const isApprovedUser = useIsUserApproved();
    const hasCareProvidersinbox = useFeatureFlag(
        "UnifiedInboxAccumailSandboxBuild",
    );

    if (!userEmailAddress) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromNewConversation without having an userEmailAddress in the state",
        );
    }

    if (!workspaceId) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromNewConversation without having an org id in the state",
        );
    }

    const { patient, status, token } = usePatientDetailsFromState();

    const getContext: () => ClinicianConversationContextType = () => {
        if (status === "loading") {
            return {
                state: "loading",
                urlId: null,
                userEmailAddress,
                isNewConversation: true,
            };
        }

        if (status !== "success" || isTestPatient(patient)) {
            return {
                state: "error",
                urlId: null,
                error: "Failed to get patient",
                userEmailAddress,
                isNewConversation: true,
            };
        }

        const patientDetailsWithDisplayNames =
            getPatientDetailsWithDisplayNames(patient);

        return {
            state: "new",
            error: null,
            messages: null,
            conversationId: null,
            getAttachmentUrl: null,
            patient: patientDetailsWithDisplayNames,
            sendMessage: async ({ messageBody, attachedFiles, metadata }) =>
                await ClinicianConversationApi.firstMessage({
                    patientToken: token,
                    nhsNumber: patient.nhsNumber ?? "",
                    practiceCode: patient.practiceCode ?? "",
                    organisationId: workspaceId,
                    messageBody: messageBody,
                    externalEmailAttachmentIds: attachedFiles.map(
                        ({ id }) => id,
                    ),
                    metadata: {
                        ...metadata,
                        templateLevel: metadata.isPresetTemplate
                            ? "Accurx"
                            : null,
                    },
                }),
            onSendFn: ({ urlId, workspaceConversationId }, currentPathname) => {
                // Users who have access to the sandbox inbox will receive a
                // workspaceConversationId from the API.
                const hasSandboxInbox =
                    hasCareProvidersinbox &&
                    !!workspaceConversationId &&
                    !!workspaceId &&
                    isApprovedUser;

                // If they have access to the new Care Providers section of the
                // Unified inbox we should redirect them there, otherwise
                // redirect them to the sandbox inbox. The only exception is if
                // they're already inside the sandbox inbox currently (they
                // clicked "New message" in the sandbox inbox) we'll keep them
                // in there because redirecting them to the Unified Inbox would
                // be wierd.

                if (hasSandboxInbox && hasCareProvidersinbox) {
                    const conversationId =
                        conversationIdMapper.clinicianMessaging.fromSource(
                            workspaceConversationId,
                        );
                    const redirectPath =
                        generatePath(ROUTES_INBOX.CareProvidersMyInbox, {
                            workspaceId,
                        }) + `?conversationId=${conversationId}`;

                    history.push(redirectPath, history.location.state);
                    return;
                } else if (urlId) {
                    const path = `${findBaseRoute(currentPathname)}${
                        ROUTES.reply_from_web
                    }`;
                    const generatedPath = generatePath(path, {
                        urlId,
                    });

                    history.replace(generatedPath, history.location.state);
                    return;
                } else {
                    throw new Error(
                        "Message GP response did not contain a way to link to the conversation",
                    );
                }
            },
            urlId: null,
            participants: {
                participantWorkspaces: patient.practiceCode
                    ? [
                          {
                              practiceCode: patient.practiceCode,
                              workspaceName:
                                  patient.practiceName ??
                                  `Organisation with ODS code ${patient.practiceCode}`,
                              participants: [],
                          },
                      ]
                    : [],
                individualParticipants: [
                    {
                        emailAddress: userEmailAddress,
                        displayName: currentUser.user.fullName,
                        isCurrentUser: true,
                    },
                ],
            },
            userEmailAddress,
            subject: buildSubjectFromPatient(patientDetailsWithDisplayNames),
            workspaceId,
            hasPolled: false,
            isNewConversation: true,
        };
    };

    return (
        <ClinicianConversationContext.Provider value={getContext()}>
            {props.children}
        </ClinicianConversationContext.Provider>
    );
};
