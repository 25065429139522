import { ComponentProps } from "react";

import * as UI from "@accurx/design";
import styled from "styled-components";

type PillIconProps = {
    /** When true, colour prop will be overriden and icon colour will be set to stone */
    useDisabledColour?: boolean;
};

type IconProps = ComponentProps<typeof UI.Icon>;

type LoadingIconProps = {
    isLoading?: boolean;
    /** When used on dark background, use white colour setting - default blue */
    spinnerColour?: "white" | "blue";
};

const StyledContainer = styled.span<{ $size: IconProps["size"] }>`
    svg {
        width: ${(props) =>
            props.$size ? UI.Tokens.SIZES[props.$size] : "20px"};
        height: ${(props) =>
            props.$size ? UI.Tokens.SIZES[props.$size] : "20px"};
    }
`;

const StyledSpinnerContainer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;

export const Icon = ({
    isLoading,
    spinnerColour = "blue",
    ...props
}: IconProps & LoadingIconProps & PillIconProps) => {
    return isLoading ? (
        <StyledSpinnerContainer aria-hidden={true}>
            <UI.Ds.Spinner size="xsmall" color={spinnerColour} />
        </StyledSpinnerContainer>
    ) : (
        <StyledContainer aria-hidden="true" $size={props.size}>
            <UI.Icon
                {...props}
                colour={props.useDisabledColour ? "stone" : props.colour}
            />
        </StyledContainer>
    );
};
