import { createContext } from "react";

import { SignalRConnection, Transport } from "domains/transport/types";

import { HubClient } from "../../../shared/hubClient/HubClient";

export type TransportContextState = {
    transport: Transport | null;
    connectionState: SignalRConnection;
    // This is an initial tradeoff for us to migrate all usages of
    // hubClient over to transport to utilise the optional hubClient
    // provider logic for shared/concierge and the trust inbox ahead
    // of future deprecation
    hubClient: HubClient | null;
};

export const TransportContext = createContext<TransportContextState>({
    transport: null,
    connectionState: "Initialising",
    hubClient: null,
});
