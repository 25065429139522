import { createQueryHook } from "@accurx/api/QueryClient";

import FlemingApi from "api/FlemingApiClient";
import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";

export const ALLOWED_NHS_ORGANISATIONS_QUERY_KEY = "getAllowedNhsOrganisations";

// currently used within temporary onboarding pages only (new-onboarding folder)
// should replace a current hook when temporary onboarding pages are brought to existing routes
export const useGetAllowedNhsOrganisationsQuery = createQueryHook<
    void,
    IAllowedNhsOrganisationResponse[]
>(() => ({
    queryKey: [ALLOWED_NHS_ORGANISATIONS_QUERY_KEY, {}],
    queryFn: async () => {
        const response = await FlemingApi.getNhsOrganisations();
        return response;
    },
}));
