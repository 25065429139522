import {
    HTMLAttributes,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

const ContainerContext = createContext<number | null>(null);

export const useContainerHeight = () => {
    const height = useContext(ContainerContext);

    if (height === null) {
        throw new Error(
            "useContainerHeight must be used inside a TrackedDimensionContainer",
        );
    }

    return height;
};

export const TrackedDimensionContainer = ({
    children,
    ...props
}: {
    children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState(0);
    const observerRef = useRef<ResizeObserver>();

    useEffect(() => {
        if (containerRef.current) {
            observerRef.current = new ResizeObserver((entries) => {
                // borderBoxSize includes padding & border of the container
                setHeight(entries[0].borderBoxSize[0].blockSize);
            });

            observerRef.current.observe(containerRef.current);
        }
        return () => {
            observerRef.current?.disconnect();
        };
    }, []);

    return (
        <div ref={containerRef} {...props}>
            <ContainerContext.Provider value={height}>
                {children}
            </ContainerContext.Provider>
        </div>
    );
};
