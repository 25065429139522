import { useContext, useMemo } from "react";

import { CurrentlyViewingContext } from "domains/inbox/components/CurrentlyViewingContext/CurrentlyViewingContext";
import { User as CurrentlyViewingUser } from "domains/inbox/components/CurrentlyViewingContext/types";
import sortBy from "lodash/sortBy";

export const useCurrentlyViewingConversationUsers = (
    conversationId: string,
): string[] => {
    const { conversations } = useContext(CurrentlyViewingContext);
    const currentlyViewingUsers = conversations[conversationId];

    return useMemo((): string[] => {
        if (!currentlyViewingUsers) return [];

        const names = Object.values(currentlyViewingUsers)
            .filter((user): user is CurrentlyViewingUser => !!user)
            .map((user) => user.name);

        // Sort the names alphabetically
        return sortBy(names, (n) => n.toLowerCase());
    }, [currentlyViewingUsers]);
};
