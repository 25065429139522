import React from "react";

import { useCurrentlyViewingUsersSubscription } from "domains/inbox/hooks/useCurrentlyViewingUsersSubscription";

import { CurrentlyViewingContext } from "./CurrentlyViewingContext";

type CurrentlyViewingContextProviderProps = {
    children: React.ReactNode;
};

export const CurrentlyViewingContextProvider = ({
    children,
}: CurrentlyViewingContextProviderProps) => {
    const conversations = useCurrentlyViewingUsersSubscription();

    return (
        <CurrentlyViewingContext.Provider
            value={{
                conversations,
            }}
        >
            {children}
        </CurrentlyViewingContext.Provider>
    );
};
