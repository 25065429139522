import { FeatureName } from "@accurx/auth";
import { shallowEqual } from "react-redux";

import { WebPatient } from "app/patients/Patient.types";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

// If patient is selected then will return the selected patient
// If no patient is selected then will return null
export const useSelectedPatient = (): WebPatient | null => {
    const selectedPatient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getSelectedPatient(searchForPatient),
        shallowEqual,
    );

    return selectedPatient;
};

export const useIsFeatureEnabledForSelectedPatient = ({
    feature,
}: {
    feature: FeatureName;
}): boolean => {
    return useAppSelector(({ searchForPatient }) =>
        PatientHelper.isFeatureEnabled(
            searchForPatient.lastResponse?.searchedResult?.patient ?? null,
            feature,
        ),
    );
};

export const useCanSelectedPatientReceiveMessageGp = (): boolean => {
    /**
     * Note: even though this feature flag means something else in other contexts,
     * for the patient's available features, the patient search backend maps the
     * feature flags and their states (ExternalEmail: on and AccumailDisableWebInitiated: Off)
     * into this one feature flag.
     */
    return useIsFeatureEnabledForSelectedPatient({
        feature: FeatureName.CaregiverInitiated,
    });
};
// If patient is selected then returns the patient token (can be null)
// If no patient is selected then will return null
export const useSelectedPatientToken = (): string | null => {
    const selectedPatient = useSelectedPatient();

    // If no selected patient, then no patientId to return
    if (selectedPatient === null) {
        return null;
    }

    return selectedPatient.patientToken;
};
