import { useMemo, useState } from "react";

import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/compose/context";
import { useIsComposing } from "domains/compose/hooks/useIsComposing";
import { PatientExternalId, TemplateItem } from "domains/compose/types";

import { MessageTemplateSelector } from "../components/MessageTemplateSelector/MessageTemplateSelector";
import { RemoveTemplateButton } from "../components/RemoveTemplateButton/RemoveTemplateButton";
import { DisabledCombobox } from "../components/TemplatesCombobox/DisabledCombobox";
import { TemplatesCombobox } from "../components/TemplatesCombobox/TemplatesCombobox";
import { sortTemplates } from "../components/TemplatesCombobox/sortTemplates";
import { AlreadyComposingModal } from "./validation/AlreadyComposingModal";
import { useValidatePendingQuestionnaire } from "./validation/useValidatePendingQuestionnaire";

type TemplatesBrowserProps = {
    templates: TemplateItem[];
    patientExternalIds: PatientExternalId[];
    disabled?: boolean;
    /** When provided, a link will appear at the bottom of the preview quick view */
    manageTemplatesUrl?: string;
};

export const TemplatesBrowser = ({
    templates,
    patientExternalIds,
    disabled = false,
    manageTemplatesUrl,
}: TemplatesBrowserProps) => {
    const { state, dispatch } = useCompose();
    const isComposing = useIsComposing();
    const [pendingTemplate, setPendingTemplate] = useState<TemplateItem | null>(
        null,
    );
    const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
    useValidatePendingQuestionnaire(patientExternalIds);

    const sortedTemplates = useMemo(
        () => sortTemplates(templates),
        [templates],
    );

    if (state.template.value) {
        return (
            <RemoveTemplateButton
                template={state.template}
                onClick={() => {
                    dispatch({
                        type: "REMOVE_TEMPLATE",
                    });
                }}
            />
        );
    }

    const selectTemplate = (templateItem: TemplateItem) => {
        if (isComposing) {
            setPendingTemplate(templateItem);
            return;
        }

        confirmTemplateSelection(templateItem);
    };

    const confirmTemplateSelection = (templateItem: TemplateItem) => {
        setPendingTemplate(null);
        setIsTemplatePreviewOpen(false);

        const { type, value: template } = templateItem;

        if (type === "QuestionnaireTemplate") {
            dispatch({
                type: "ADD_PENDING_QUESTIONNAIRE_TEMPLATE",
                payload: { pendingTemplate: template },
            });
            return;
        }

        dispatch({
            type: "ADD_MESSAGE_TEMPLATE",
            payload: { template },
        });
    };

    const handlePreviewOptionSelected = () => {
        setIsTemplatePreviewOpen(true);
    };

    const labelPlaceholder = templates.some(
        (t) => t.type === "QuestionnaireTemplate",
    )
        ? "Search templates and questionnaires"
        : "Search templates";

    return (
        <>
            {disabled ||
            pendingTemplate ||
            state.template.type === "PendingQuestionnaireTemplate" ? (
                <DisabledCombobox
                    value={
                        pendingTemplate?.value.title ??
                        state.template.pendingValue?.title ??
                        ""
                    }
                    labelPlaceholder={labelPlaceholder}
                />
            ) : (
                <TemplatesCombobox
                    templates={sortedTemplates}
                    onItemSelected={selectTemplate}
                    onPreviewOptionSelected={handlePreviewOptionSelected}
                    labelPlaceholder={labelPlaceholder}
                />
            )}
            {pendingTemplate && (
                <AlreadyComposingModal
                    onConfirm={() => confirmTemplateSelection(pendingTemplate)}
                    onCancel={() => setPendingTemplate(null)}
                />
            )}
            <QuickViewPortal
                onClose={() => setIsTemplatePreviewOpen(false)}
                isOpen={isTemplatePreviewOpen}
            >
                <MessageTemplateSelector
                    status="loaded"
                    templates={templates}
                    onClickTemplate={confirmTemplateSelection}
                    patientExternalIds={patientExternalIds}
                    onClose={() => setIsTemplatePreviewOpen(false)}
                    manageTemplatesLink={manageTemplatesUrl}
                />
            </QuickViewPortal>
        </>
    );
};
