import { useMemo, useState } from "react";

import {
    useAnalytics,
    useMeaningfulActionAnalyticsProps,
} from "@accurx/analytics";
import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import {
    useMedicalRecordConnection,
    useNativeTrackingFields,
} from "@accurx/native";
import { containsAbuseWord } from "@accurx/shared/dist/AbuseWordsHelper";
import { isPast } from "date-fns";
import { useCompose } from "domains/compose/context";
import { ComposeActionsTypes } from "domains/compose/reducer.types";
import { isTestPatient } from "domains/compose/utils/isTestPatient";
import { validateMessage } from "domains/compose/utils/validateMessage";

import { ComposeProps } from "../../types";
import { OnMessageSendFn } from "../../types/Compose.types";
import { formatScheduledButtonText } from "../ScheduleSend/utils/formatScheduledButtonText";
import { SendSplitButton } from "../SendSplitButton/SendSplitButton";
import { AbuseWordCheckModal } from "./AbuseWordCheckModal";
import { SaveToRecordWithDifferentEmrPatientModal } from "./SaveToRecordWithDifferentEmrPatient";
import { ScheduledTimeHasPassedModal } from "./ScheduledTimeHasPassedModal";

export type SendMessageProps = Pick<
    ComposeProps,
    "conversationId" | "patient" | "patientMatchesCurrentMedicalRecordPatient"
> & {
    characterCount: number;
    fragmentCount: number;
    isUnicode?: boolean;
    onMessageSend: OnMessageSendFn;
    isLoading: boolean;
};

export const SendMessage = ({
    conversationId,
    patient,
    patientMatchesCurrentMedicalRecordPatient,
    characterCount,
    fragmentCount,
    isUnicode,
    onMessageSend,
    isLoading,
}: SendMessageProps) => {
    const workspace = useCurrentWorkspace();
    const { state, dispatch } = useCompose();
    const isNewMessageComponentEnabled = useFeatureFlag("MessageComponentV1_1");

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { isMultiFactorAuthActive, isMultiFactorAuthEnabled } =
        useMeaningfulActionAnalyticsProps();

    // Medical record
    const connection = useMedicalRecordConnection();

    const shouldSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord &&
        state.isSaveToRecordEnabled;

    // Send-blocking modals
    const [isAbuseCheckModalOpen, setIsAbuseCheckModalOpen] = useState(false);
    const [isDifferentPatientsModalOpen, setIsDifferentPatientsModalOpen] =
        useState(false);
    const [
        scheduledTimeHasPassedModalIsOpen,
        setScheduledTimeHasPassedModalIsOpen,
    ] = useState(false);

    // now we could have only one attachment max in total
    const countAttachmentFromTemplate = state.template.value
        ? state.attachments.length
        : 0;

    const templateTrackingFields = useMemo(() => {
        switch (state.template.type) {
            case "MessageTemplate":
                return {
                    withTemplate: true,
                    withFlorey: false,
                    templateName: state.template.value.title,
                    templateGroup: state.template.value.heading,
                };
            case "QuestionnaireTemplate":
                return {
                    withTemplate: false,
                    withFlorey: true,
                    floreyName: state.template.value.title,
                };
            default:
                return {
                    withTemplate: false,
                    withFlorey: false,
                };
        }
    }, [state.template]);

    const trackSendClick = (args: {
        warnings: string[];
        errors: string[];
        isModal: boolean;
        withSchedule: boolean;
    }) => {
        track("PatientMessageSend Button Click", {
            ...nativeTrackingFields,
            ...templateTrackingFields,
            conversationId,
            countAttachmentFromTemplate,
            isMobileNumberOverridden:
                state.contactDetails.origin === "UserInput" &&
                state.contactDetails.method === "Mobile",
            isPresetTemplate: state.template.value?.owner === "Accurx",
            isReply: true, // always true for now as we have only reply form
            isTestPatient: isTestPatient(patient),
            isMultiFactorAuthActive,
            isMultiFactorAuthEnabled,
            isModal: args.isModal,
            messageLength: characterCount,
            countFragment: fragmentCount,
            messageType:
                state.contactDetails.method === "Mobile" ? "sms" : "email",
            origin: "/inbox",
            withAttachment: !!state.attachments.length,
            withPatientResponse: state.isPatientResponseEnabled,
            enabledSaveToMedicalRecord: shouldSaveToRecord,
            withUnicodeCharacter: !!isUnicode,
            hasError: args.errors.length > 0,
            errorReason: args.errors,
            hasWarning: args.warnings.length > 0,
            warningReason: args.warnings,
            withSchedule: args.withSchedule,
            withNHSAdviceLink: !!state.nhsAdviceLink,
            eventVersion: 8,
        });
    };

    const triggerSendMessageMutation = (args: {
        isModal: boolean;
        sendNow?: boolean;
    }) => {
        trackSendClick({
            errors: [],
            warnings: [],
            isModal: args.isModal,
            withSchedule: args.sendNow ? false : !!state.sendAt,
        });

        onMessageSend({
            characterCount,
            fragmentCount,
            isUnicode: !!isUnicode,
            contactDetails: state.contactDetails,
            attachments: state.attachments,
            isPatientResponseEnabled: state.isPatientResponseEnabled,
            isSaveToRecordEnabled: state.isSaveToRecordEnabled,
            messageBody: state.messageBody,
            messageSignature: state.messageSignature,
            nhsAdviceLink: state.nhsAdviceLink,
            template: state.template,
            selfBookLink: state.selfBookLink,
            sendAt: args.sendNow ? null : state.sendAt,
        });
    };

    const onSendHandler = () => {
        const errors = validateMessage({
            workspaceName: workspace.organisationName,
            originalGreeting: state.originalGreeting,
            messageBody: state.messageBody,
            messageSignature: state.messageSignature,
            nhsAdviceLink: state.nhsAdviceLink,
            withSelfBookLink: !!state.selfBookLink,
            mobileNumber:
                state.contactDetails.method === "Mobile"
                    ? state.contactDetails.value
                    : null,
            emailAddress:
                state.contactDetails.method === "Email"
                    ? state.contactDetails.value
                    : null,
            attachments: state.attachments,
            withPatientResponseEnabled: state.isPatientResponseEnabled,
            template:
                state.template.type !== "NoTemplate" ? state.template : null,
        });

        const withSchedule = !!state.sendAt;

        // Either sets new errors or clears them if there's any
        dispatch({
            type: "SET_ERRORS",
            payload: errors,
        });

        if (errors.length > 0) {
            trackSendClick({
                errors,
                warnings: [],
                isModal: false,
                withSchedule,
            });
            return;
        }

        if (
            containsAbuseWord(`${state.messageBody} ${state.messageSignature}`)
        ) {
            setIsAbuseCheckModalOpen(true);
            trackSendClick({
                errors,
                warnings: ["ABUSE_WORD"],
                isModal: false,
                withSchedule,
            });
            return;
        }

        if (
            shouldSaveToRecord &&
            // Important to check for false value as null means undetermined
            patientMatchesCurrentMedicalRecordPatient === false
        ) {
            setIsDifferentPatientsModalOpen(true);
            trackSendClick({
                errors,
                warnings: ["SAVE_TO_RECORD_WITH_DIFFERENT_EMR_PATIENT"],
                isModal: false,
                withSchedule,
            });
            return;
        }

        if (state.sendAt && isPast(new Date(state.sendAt.sendAtDateTime))) {
            setScheduledTimeHasPassedModalIsOpen(true);
            trackSendClick({
                errors,
                warnings: ["SCHEDULED_TIME_HAS_PASSED"],
                isModal: false,
                withSchedule,
            });
            return;
        }

        triggerSendMessageMutation({ isModal: false });
    };

    return (
        <>
            {isNewMessageComponentEnabled ? (
                <SendSplitButton
                    isDropdownLoading={false}
                    isSending={isLoading}
                    sendMessageHandler={onSendHandler}
                >
                    {state.sendAt ? (
                        <UI.Icon
                            name="Clock"
                            colour="white"
                            size={3}
                            theme="Line"
                            aria-hidden={true}
                        />
                    ) : (
                        <UI.Icon
                            name="Send"
                            colour="white"
                            size={3}
                            theme="Fill"
                            aria-hidden={true}
                        />
                    )}
                    <span>Send</span>
                </SendSplitButton>
            ) : (
                <SendSplitButton
                    isDropdownLoading={false}
                    isSending={isLoading}
                    sendMessageHandler={onSendHandler}
                >
                    {!state.sendAt && (
                        <UI.Icon
                            name="Send"
                            colour="white"
                            size={3}
                            theme="Fill"
                            aria-hidden={true}
                        />
                    )}
                    <span>{formatScheduledButtonText(state.sendAt)}</span>
                </SendSplitButton>
            )}

            <AbuseWordCheckModal
                isOpen={isAbuseCheckModalOpen}
                onClose={() => setIsAbuseCheckModalOpen(false)}
                onSendMessage={() => {
                    setIsAbuseCheckModalOpen(false);
                    triggerSendMessageMutation({ isModal: true });
                }}
            />
            {connection.status === "Connected" && (
                <SaveToRecordWithDifferentEmrPatientModal
                    isOpen={isDifferentPatientsModalOpen}
                    onCancel={() => setIsDifferentPatientsModalOpen(false)}
                    onSendMessage={() => {
                        setIsDifferentPatientsModalOpen(false);
                        triggerSendMessageMutation({ isModal: true });
                    }}
                    medicalRecordSystem={connection.system}
                />
            )}
            {state.sendAt?.sendAtDateTime && (
                <ScheduledTimeHasPassedModal
                    isOpen={scheduledTimeHasPassedModalIsOpen}
                    onClose={() => setScheduledTimeHasPassedModalIsOpen(false)}
                    onSendMessage={() => {
                        dispatch({
                            type: ComposeActionsTypes.UpdateSendAt,
                            payload: {
                                sendAt: null,
                            },
                        });

                        setScheduledTimeHasPassedModalIsOpen(false);
                        /**
                         * We need to manually trigger the mutation with sendNow=true
                         * because there could be a race condition with updating state.sendAt to null
                         * and gathering the updated state properties for the mutation request
                         */
                        triggerSendMessageMutation({
                            isModal: true,
                            sendNow: true,
                        });
                    }}
                    date={new Date(state.sendAt.sendAtDateTime)}
                />
            )}
        </>
    );
};
